import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Button, Modal, ModalFooter, ProgressBar } from 'react-bootstrap';
import { Icon } from "react-icons-kit";
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import * as CheckMark from "../../lottie-gif/checkmark-animation.json";
import Lottie from 'react-lottie';
import {close} from 'react-icons-kit/ikons/close';
import { Link } from "react-router-dom";
import {
    freelancerVerficationStatus,
  } from "../../actions/freelancerProfile";
import { useDispatch } from "react-redux";  
import axios from "axios";
import { setAlert } from "../../actions/alert";
import { withRouter } from 'react-router-dom'; // Import withRouter
import { remove } from "react-icons-kit/fa/remove";

class UserProfileNavLink extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showProfileCompleteModal: false,
            showRejectedModal: false,
        }; 
    }
    
    componentDidMount() {
        // Check conditions and show the modal
        this.checkProfileCompletion();

    }
    
    componentDidUpdate(prevProps) {
        // Check conditions and show the modal when props change
        if (prevProps.freelancerProfile.profileCompletedPercentage !== this.props.freelancerProfile.profileCompletedPercentage) {
            this.checkProfileCompletion();
        }
    }
    
    
    checkProfileCompletion() {
        //console.log("freelancerss".this?.props?.freelancerProfile)
        const { profileCompletedPercentage } = this.props.freelancerProfile;
        const { isProfileCompleted } = this.props?.freelancerProfile || {};
        const alreadyShown = localStorage.getItem(`profileCompletedModalShown-${this?.props?.auth?.user?._id}`);
   
        if (profileCompletedPercentage === 100 && !isProfileCompleted && !alreadyShown) {
            // Show the modal
            this.setState({ showProfileCompleteModal: true });
            localStorage.setItem(`profileCompletedModalShown-${this?.props?.auth?.user?._id}`, true);
        }
    }


    componentDidMount() {
        const expertVerificationCheck = this.props?.freelancerProfile.freelancerprofile.verification_status;
        if (expertVerificationCheck === "Rejected") {
            //this.setState({ showRejectedModal: true });
        }
    }

    handleCloseModal = () => {
        // Close the modal
        this.setState({ showProfileCompleteModal: false });
        this.setState({ showRejectedModal: false });
    };

    show_resubmit = () => {
        this.setState({ showRejectedModal: true });
    };

    resubmitStatus = async () => { // Define the method as async
        //console.log("clicked");
        try {
            // Make the HTTP request using Axios
            const response = await axios.post('/api/auth/ver_status/' + this.props?.freelancerProfile.freelancerprofile._id);
            //console.log('Response:', response.data); // Handle the response as needed
            this.props.dispatch(setAlert(response.data.msg, "success"));
                        
            if(response.data){
                this.setState({ showRejectedModal: false });
                this.props.history.push('/dashboard');
            }
        } catch (error) {
            console.error('Error:', error); // Handle errors
        }
    };

    

    render() {
        const userType = this.props?.auth?.user?.type;
        const expertProfileCompletedPercentage = this.props?.freelancerProfile?.profileCompletedPercentage;
        const expertProfileCompletedItems = this.props?.freelancerProfile?.profileCompletedItems;
        let NavLinksItem;

        const expertVerificationCheck = this.props?.freelancerProfile.freelancerprofile.verification_status 
        const expertRejectedReason = this.props?.freelancerProfile.freelancerprofile.rejected_msg
        
        if (userType == "Freelancer") {
            NavLinksItem = (
                <>
                    <div className="dropdown-menu-show bg-white rounded shadow-sm mt-2">
                        <p className='' style={{color: '#000000', padding: "0rem 0rem 0.5rem 0.5rem"}}><span>Fill out to access the website</span></p>
                        <hr></hr>
                            <>  
                                <div className=''>
                                    <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/personaldetails">Personal Details <Icon icon={ic_check_circle} /></NavLink>
                                    <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/expertise">Expertise {expertProfileCompletedItems?.expertise && <Icon icon={ic_check_circle} />}</NavLink>
                                    <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/expertiselevel">Expertise Level {expertProfileCompletedItems?.expertLevel && <Icon icon={ic_check_circle} />}</NavLink>
                                    <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/languages">Languages {expertProfileCompletedItems?.languageVal && <Icon icon={ic_check_circle} />}</NavLink>
                                    <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/hourlyrate">Hourly Rate {expertProfileCompletedItems?.hourlyRate && <Icon icon={ic_check_circle} />}</NavLink>
                                    <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/titleandoverview">Title & Overview {expertProfileCompletedItems?.TitleAndOverview && <Icon icon={ic_check_circle} />}</NavLink>
                                    <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/profileandphoto">Profile & Photo {expertProfileCompletedItems?.profileAndPhoto && <Icon icon={ic_check_circle} />}</NavLink>
                                    {/* <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/location">Location {expertProfileCompletedItems?.Location && <Icon icon={ic_check_circle} />}</NavLink> */}
                                </div>
                                {/* <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/phone">Verification {expertProfileCompletedItems?.freelancerprofile && <Icon icon={ic_check_circle} />}</NavLink> */}
                            </>
                    </div>
                   
                    <div className="dropdown-menu-show bg-white rounded shadow-sm mt-2">
                        <p className='mt-4' style={{color: '#000000', padding: "0rem 0rem 0.5rem 0.5rem"}}>Fill out to get verified</p>
                        <hr></hr>
                        <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/education">Education {expertProfileCompletedItems?.education && <Icon icon={ic_check_circle} />}</NavLink>
                        <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/certificate">Certificate {expertProfileCompletedItems?.certification && <Icon icon={ic_check_circle} />}</NavLink>
                        <NavLink className="dropdown-item py-2 d-flex justify-content-between" activeClassName="active" to="/employment">Employment {expertProfileCompletedItems?.employment && <Icon icon={ic_check_circle} />}</NavLink>
                    </div>
                    {expertVerificationCheck === "Rejected"?
                    <div className="dropdown-menu-show">
                        <Link style={{width:"100%"}} className="btn btn-secondary" onClick={this.show_resubmit}>Resubmit</Link>
                    </div>
                     : null}
                </>
              )
        } else {
            NavLinksItem = (
                <div className="dropdown-menu-show">
                    {/*<NavLink className="dropdown-item py-2" activeClassName="active" to="/headline">Headline</NavLink>
                    <NavLink className="dropdown-item py-2" activeClassName="active" to="/skills">Skills</NavLink>
                    <NavLink className="dropdown-item py-2" activeClassName="active" to="/scope">Scope</NavLink>
                    <NavLink className="dropdown-item py-2" activeClassName="active" to="/budget">Budget</NavLink>*/}
                    {/* <NavLink className="dropdown-item py-2" activeClassName="active" to="/postjobclient">Post Job</NavLink> */}
                    <NavLink className="dropdown-item py-2" activeClassName="active" to="/clientcompleteprofile">Profile</NavLink>
                    
                </div>
            );
        }

        const UserCompleteprofileModal = (
            <>             
                <Modal onBackdropClick={this.handleCloseModal} className='px-5 py-5' size='md' show={this.state.showProfileCompleteModal} centered>
                <Modal.Header>
                    <Modal.Title>Profile Completed</Modal.Title>
                    <Icon
                        className="mx-2 icon-hover"
                        icon={remove}
                        onClick={this.handleCloseModal}
                    />
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex align-items-center justify-content-between'>
                            {/** <div style={{flex:'1'}}>
                                <h3 className='text-center'></h3>
                            </div>
                            <div  className="mb-2" style={{backgroundColor: 'transaprent', boxShadow: 'none', cursor:'pointer'}}  onClick={this.handleCloseModal}><Icon size={32} icon={close} /></div>*/}
                        </div>
                        <Lottie 
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: CheckMark,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                },
                            }}
                            height={180}
                            width={180}
                        />
                        <div className='text-center'><p style={{fontSize:'17px'}}>Thanks for completing the profile, Your profile is submitted for verification!</p></div>
                    </Modal.Body>
                </Modal>
            </>
        )

        const ExpertRejectionModule = (
            <>
            {console.log(this?.state.showRejectedModal)}
            <Modal onBackdropClick={this.handleCloseModal} className='px-5 py-5' size='md' show={this?.state.showRejectedModal} centered>
            <Modal.Header>
            <Modal.Title>Confirm Resubmission</Modal.Title>
            <Icon
                className="mx-2 icon-hover"
                icon={remove}
                onClick={this.handleCloseModal}
            />
            </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center justify-content-between'>
                    </div>
                    {/**  <div style={{flex:'1'}}>
                            <h3 className='text-center'></h3>
                         <div  className="mb-2" style={{backgroundColor: 'transparent', boxShadow: 'none', cursor:'pointer'}}  onClick={this.handleCloseModal}><Icon size={32} icon={close} /></div>
                    </div>*/}
                    <div className='text-left'>
                        <p style={{fontSize:'17px', color: 'red'}}>Reason: {expertRejectedReason}</p>
                    </div>
                    <br></br>
                    <div className='text-left'><p>Please resubmit if you have updated the above mentioned information. Thank you!</p></div>
                </Modal.Body>
                <Modal.Footer>
                <Link className="btn btn-secondary" onClick={this.resubmitStatus} >Resubmit</Link>
                </Modal.Footer>
            </Modal>

            </>
        )

    

        return(
            <div>
                {UserCompleteprofileModal}
                {ExpertRejectionModule}
                <div className="overflow-hidden sidebar-fix mb-3">
                    {NavLinksItem}
                </div>
                {userType === "Freelancer" && 
                    <div className="d-flex align-items-center py-2 px-3 bg-white rounded shadow-sm">
                        {/* {!pageLoaded? (
                        <Skeleton width={100} height={18} />
                        ) : ( */}
                        <span>Profile Completed</span>
                        {/* )} */}
                        <div className="font-weight-bold ml-auto d-flex align-items-center " style={{width: "35%"}} >
                        <ProgressBar now={expertProfileCompletedPercentage} variant="success" className="w-100 " />
                        {/* <span className="btn btn-primary border-0 py-1 px-4 mr-2"></span>{" "} */}
                        {/* {!pageLoaded ? (
                            <Skeleton width={40} height={18} />
                        ) : 
                    } */}
                        <span className="ml-1">{expertProfileCompletedPercentage}%</span>
                        </div>
                    </div>
                 }
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
   auth: state.auth,
   freelancerProfile: state.freelancerProfile,
});

export default connect(mapStateToProp)(UserProfileNavLink);