import React, {useEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { support } from "../../actions/auth";
import document from "ejs/ejs";
import {Helmet} from "react-helmet";

const ContactSupport = () => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    document.title = 'Contact Us';
  }, []);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let body;
    // Add your API call or form submission logic here
    // You can use the 'description' and 'email' state variables to send the data to the server
    if (description === "" || email === "") {
      setErrorMessage("Please fill in all the fields");
      setSuccessMessage("");
    } else {
      setErrorMessage("");
      setSuccessMessage("Your request has been submitted successfully");
      // Reset form
      setDescription("");
      setEmail("");
    }

    body = {
      email: email,
      text: description,
    }
    dispatch(support(body))
  };


  return (
    <div className="m-5 p-5">
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <h3 className="mb-4">Contact Support</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="description">Description: <span className="required-field">*</span></label>
          <textarea
            className="form-control"
            id="description"
            rows="4"
            value={description}
            onChange={handleDescriptionChange}
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email: <span className="required-field">*</span></label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        <button type="submit" className="btn btn-primary">
          Send
        </button>
      </form>
    </div>
  );
};

ContactSupport.propTypes = {
  // Add any prop types that you need here
};

export default connect(null, null)(ContactSupport);
