import React, {useEffect, useState} from "react";


const Copyright = () => {

    useEffect(() => {
        document.title = 'Copyright';
    }, []);

    return (
        <div className="m-5 p-5">
             <h3 className="mb-4">Copyright Policy</h3>
             <p> Lorem ipsum dolor sit amet. Et ratione doloremque sit dolorum ducimus aut alias facere sit nihil internos. Rem alias perspiciatis qui fugit amet quo voluptas soluta? Non accusantium laboriosam sed internos facilis aut voluptatibus corporis et dolor mollitia qui ducimus quas eum perferendis officiis eum voluptatem ipsa. Ea dolore possimus et minus mollitia qui aperiam debitis est eius quas sed accusamus accusantium in tempore cumque et delectus iure.

Qui internos iure aut atque blanditiis non quae placeat ut culpa consequatur sit dicta dolorem sed sunt natus consequatur minus. Hic soluta magni ea nihil assumenda aut impedit dolor. Qui repellat accusantium non omnis error sed sint quasi nam delectus dolorum qui ipsa suscipit.

Aut consequatur maxime a suscipit fugit ex voluptas voluptates a quidem culpa non nulla voluptas? 33 voluptatem perferendis ut recusandae tenetur ut explicabo neque in vero odio. Ut unde rerum et incidunt perspiciatis aut provident culpa eum facere sint est facere libero ut aliquid suscipit ab facilis optio. Ex nostrum aperiam et fugiat similique et laborum nostrum.</p>
        </div>
      );
};

export default Copyright;