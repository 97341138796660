import React, { Component }  from "react";
import { GoSell } from "@tap-payments/gosell";
import { payment } from "../../actions/auth";
import { connect , useDispatch} from "react-redux";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { setAlert } from "../../actions/alert";
import { GoSellElements } from "@tap-payments/gosell";
import { useHistory } from "react-router-dom";
import LoadingOverlay from './Loading';



class GoSellElementsDemo extends Component {
  constructor(props) {
    super(props);
    this.callbackFunc = this.callbackFunc.bind(this);
    this.state = {
      isLoading: false
    }
  }
  
  async callbackFunc(response) {
    //console.log("asdasdasd",response);
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    
    const { handleRedirect } = this.props;
    const { errorhandle } = this.props;
    
    const config = {
      headers: {
        'Content-Type': 'Application/json'
      }
    }
    
    //console.log(this.props)
    const { walletAmount} = this.props
    const { user_type} = this.props
    const { first_name } = this.props
    const { last_name } = this.props
    const { email } = this.props
    
    
    const data = JSON.stringify({
        token: response.id,
        user_type: user_type,
        first_name : first_name,
        last_name : last_name,
        email : email,
        walletAmount: walletAmount * 3.75,
        mode: response.live_mode
    });

    //console.log("data"  , data)

    this.setState({ isLoading: true });
    
   
    await axios.post('/api/checkout/charge',data, config).then((res)=>{

      localStorage.setItem('charge_id', res.data.id);
      console.log(res.data);
      
      if(res.data.errno === "-3008"){
        errorhandle('Limit exceeded')
        this.setState({ isLoading: false });
      }
      
      if(res.data.transaction.url){
        handleRedirect(res.data.transaction.url);
        console.log("taps_link: ",res.data.transaction.url)
      }
      else{
        this.setState({ isLoading: false });
      }

            
      if(res.data.error?.length > 0){
        this.setState({ isLoading: false });
        errorhandle(res.data.error[0].description)
      }

    })
  }

 
  componentDidMount() {
    const { walletAmount } = this.props;
    //console.log(this.props?.email , "email check")
    // Open the GoSell lightbox when the walletAmount is set
    if (walletAmount) {
      //GoSell.openLightBox();
      //localStorage.setItem('taps_check',JSON.stringify("taps_check"));
    }
  }

  componentDidUpdate(prevProps) {
    const { walletAmount } = this.props;
    // Check if the walletAmount prop has changed
    if (walletAmount !== prevProps.walletAmount && walletAmount) {
      //GoSell.openLightBox();
      //localStorage.setItem('taps_check',JSON.stringify("taps_check"));
    }
  }

  render() {
    const { walletAmount } = this.props;
    return (
      <div className="p-4">
        {this.state.isLoading && <LoadingOverlay />}
        <div className="d-flex p-3">
          <h1>{walletAmount * 3.75}</h1><span style={{marginTop:"33px" , marginLeft:"5px"}}>SAR</span> <span style={{marginTop:"33px" , marginLeft:"75px"}}>(Total amount in Saudi Riyal)</span>
        </div>
        <p className="error-message mb-2" id="msg"></p>
        <GoSellElements
          gateway={{
            publicKey: "pk_live_BUQI37dxoZ9Jua1TN5h0MSLW",       //pk_test_Y9JeLPbM4SRT08fxwI6vnFQi  //pk_live_BUQI37dxoZ9Jua1TN5h0MSLW
            language: "en",
            supportedCurrencies: "all",
            supportedPaymentMethods: "all",
            notifications: "msg",
            callback: this.callbackFunc,
            labels: {
              cardNumber: "Card Number",
              expirationDate: "MM/YY",
              cvv: "CVV",
              cardHolder: "Name on Card",
              actionButton: "Pay",
            },
            style: {
              base: {
                color: "#535353",
                lineHeight: "18px",
                fontFamily: "sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "rgba(0, 0, 0, 0.26)",
                  fontSize: "15px",
                },
              },
              invalid: {
                color: "red",
                iconColor: "#fa755a ",
              },
            },
          }}
        />
         <br></br>
        
        <div className="p-3"><button className="btn btn-secondary" style={{width:"100%"}}  onClick={() => GoSellElements.submit()}>Submit</button></div>
      </div>
    );
  }
}


export default GoSellElementsDemo;      