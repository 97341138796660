import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { Icon } from "react-icons-kit";
import { envelopeO } from "react-icons-kit/fa/envelopeO";
import UserProfileNavLink from "../../components/userProfileNavLink/userprofilenavlink";
import { Country, State, City } from "country-state-city";
import Alert from "../../components/alert/Alert";
import { addhireManager, skillsGet } from "../../actions/clientProfile";
import Input from "../../components/input/input";
import Input2 from "../../components/input/input";
import { SVGWidgetLogo, FormIcon } from "../../components/SVG";
import EmailVerification from "./emailVerification";

const ClientCompleteProfile = ({
  clientProfile: { hireManager, skills },
  setAlert,
  skillsGet,
  isAuthenticated
}) => {
  const [formData, setFormData] = useState({
    location: "",
  });

  useEffect(() => {
    document.title = 'Complete Your Profile';
  }, []);

  useEffect(() => {
    skillsGet();
    // setFormData(hireManager);
  }, []);

  useEffect(() => {
    let check = JSON.parse(localStorage.getItem("hireManager"));
    if (check?.data) {
      skillsGet();
      setFormData(check?.data);
    }
  }, []);

  const { location } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  let history = useHistory();

  const onSubmit = async (e) => {
    //debugger;
    e.preventDefault();
    if(hireManager.verified){
      history.push("/clientProfile")
    }
  };

  if (!isAuthenticated) {
    return <Redirect to="/login-register?role=client&type=login" />;
  }

  return (
    <section className="main-page page-dashboard">
      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-lg-3">
            <UserProfileNavLink />
          </div> */}
          <div className="col-lg-12">
            <div className="panel-box sidebar-page-right">

              <Alert />
              <div className="p-5">
              <div className="box-title p-4">
                <h4 className="h4 m-0">Email Verification</h4>
              </div>
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="row">
                    <EmailVerification />
                  </div>
                  <div className="d-flex justify-content-end mt-5 mr-4 mb-4">
                    {/*<Link to="/expertise" className="c-btn c-fill-color-btn text-uppercase"> Submit your Profile </Link>
                    <button className="btn btn-lg btn-primary" type="submit">
                      Next
                    </button>
                    */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ClientCompleteProfile.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  clientProfile: state.clientProfile,
});

export default connect(mapStateToProp, { setAlert, addhireManager, skillsGet })(
  ClientCompleteProfile
);
