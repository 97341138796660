import { useEffect, useState } from 'react';

import { Link, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Form } from 'react-bootstrap';

import useSweetAlert from '../../hooks/useSweetAlert';

import { SVGWidgetLogo } from "../../components/SVG";
import { addBank, getBankById, updateBank } from '../../actions/bank';

import LocalBankForm from './LocalBankForm';
import InternationalBankForm from './InternationalBankForm';

const BankDetails = ({
  auth,
  bank
}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { showToast } = useSweetAlert();

  const [operation, setOperation] = useState('create');
  const [mode, setMode] = useState('local');
  const [step, setStep] = useState(0);

  const [loading, setLoading] = useState(false);

  const [localBankData, setLocalBankData] = useState({
    mode: 'local',
    beneficiary_name: '',
    bank_name: '',
    iban: ''
  });
  const [internationalBankData, setInternationalBankData] = useState({
    mode: 'international',
    iban: '',
    bank_name: '',
    swift_code: '',
    bank_address: '',
    bank_city: '',
    bank_country: '',
    beneficiary_name: '',
    beneficiary_id_no: '',
    beneficiary_date_of_birth: '',
    beneficiary_phone: '',
    beneficiary_address: '',
    beneficiary_country: '',
    beneficiary_state: '',
    beneficiary_city: '',
    beneficiary_zip_code: '',
    place_of_issuance: '',
  });

  const handleChangeMode = (e) => {
    setMode(e.target.value);
  }

  const handleChangeTextLocal = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9]*$/;

    if (value === '' || regex.test(value)) {
      setLocalBankData(prev => {
        return {
          ...prev,
          [e.target.name]: value
        }
      })
    }
  }

  const handleChangeLetterLocal = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/; 

    if (value === '' || regex.test(value)) {
      setLocalBankData(prev => {
        return {
          ...prev,
          [e.target.name]: value
        }
      })
    }
  }

  const handleChangeTextInternational = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (value === '' || regex.test(value)) {
      setInternationalBankData(prev => {
        return {
          ...prev,
          [e.target.name]: value
        }
      })
    }
  }

  const handleChangeInternational = (e) => {
    setInternationalBankData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleChangeLetterInternational = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;

    if (value === '' || regex.test(value)) {
      setInternationalBankData(prev => {
        return {
          ...prev,
          [e.target.name]: value
        }
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (mode === 'international') {
      return await submitInternationalBank();
    }
    return await submitLocalBank();
  }

  const submitLocalBank = async () => {
    setLoading(true);
    if (Object.values(localBankData).indexOf('') >= 0) {
      setLoading(false);
      return await showToast('Please fill all the fields!', 'error');
    }

    if (operation === 'create') {
      await dispatch(addBank(localBankData));
    } else {
      await dispatch(updateBank(location.state?.bankId, localBankData));
    }
    setLoading(false);
    history.push('/finance');
  }
  
  const submitInternationalBank = async () => {
    setLoading(true);
    if (step === 0) {
      
      setLoading(false);
      if(internationalBankData.iban === '' || internationalBankData.bank_name === '' || internationalBankData.swift_code === '' ||
      internationalBankData.bank_address === '' || internationalBankData.bank_city ===  '' || internationalBankData.bank_country ===''){
        return await showToast('Please fill all the fields!', 'error');
      }
      setStep(1);
      return;
    }

    if (Object.values(internationalBankData).indexOf('') >= 0) {
      setLoading(false);
      return await showToast('Please fill all the fields!', 'error');
    }

    if (operation === 'create') {
      await dispatch(addBank(internationalBankData));
    } else {
      await dispatch(updateBank(location.state?.bankId, internationalBankData));
    }

    setLoading(false);
    history.push('/finance');
  }

  useEffect(() => {
    if (!auth.token) {
      history.push("/login-register?role=client&type=login");
    }
  }, []);

  useEffect(() => {
    async function getBank() {
      if (location.state?.bankId) {
        setLoading(true);
        await dispatch(getBankById(location?.state?.bankId));
        setOperation('update');
        setLoading(false);
      }
    }
    getBank();
  }, [location.state?.bankId]);
  
  useEffect(() => {
    if (bank.bank && operation === 'update') {
      if (bank.bank?.type === 'local') {
        setLocalBankData(prev => ({
          ...prev,
          ...bank.bank
        }));
      }
      if (bank.bank?.type === 'international') {
        setInternationalBankData(prev => ({
          ...prev,
          ...bank.bank
        }));
      }
      setMode(bank.bank?.type);
    }
  }, [bank.bank, operation]);

  return (
    <section className="box-under-container page-registration page-auth">
      <Helmet>
        <title>Add your bank details</title>
      </Helmet>
      <div className="container">
        <section className="box-shadow m-auto">
          <div className="text-center logo">
            <Link to="/">
              <SVGWidgetLogo />
            </Link>
          </div>
          
          <Form onSubmit={handleSubmit}>
            {/* Bank type */}
            <Form.Group
              className="mb-3 text-center"
            >
              <Form.Check
                inline
                type="radio"
                label="Local Saudi Banks"
                value="local"
                onChange={handleChangeMode}
                checked={mode === "local"}
                disabled={step !== 0 || operation !== 'create'}
                className="mx-4"
              />
              <Form.Check
                inline
                type="radio"
                label="International Banks"
                value="international"
                onChange={handleChangeMode}
                checked={mode === "international"}
                disabled={step !== 0 || operation !== 'create'}
                className="mx-4"
              />
            </Form.Group>

            {/* Local Bank */}
            {mode === 'local' && (
              <LocalBankForm
                localBankData={localBankData}
                handleChangeTextLocal={handleChangeTextLocal}
                handleChangeLetterLocal={handleChangeLetterLocal}
                loading={loading}
              />
            )}

            {/* International Bank */}
            {mode === 'international'  && (
              <InternationalBankForm
                step={step}
                setStep={setStep}
                internationalBankData={internationalBankData}
                handleChangeInternational={handleChangeInternational}
                handleChangeTextInternational={handleChangeTextInternational}
                handleChangeLetterInternational={handleChangeLetterInternational}
                loading={loading}
              />
            )}

          </Form>

        </section>
      </div>
    </section>
  );
}

const mapStateToProp = (state) => ({
  auth: state.auth,
  bank: state.bank
});

export default connect(mapStateToProp)(BankDetails);
