import { useEffect, useState } from "react";
import io from "socket.io-client";

const useChat = ({ user_id, username }) => {
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState({});

  useEffect(() => {
    if (user_id && username) {
      const newSocket = io("https://cybertocyber.com", {
        query: { user_id, username },
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [user_id, username]);

  useEffect(() => {
    if (socket) {
      socket.on("userConnected", (newUsers) => {
        setUsers(newUsers);
      });

      socket.on("userDisconnected", (userId) => {
        setUsers((prevUsers) => {
          const newUsers = { ...prevUsers };
          delete newUsers[userId];
          return newUsers;
        });
      });
      
      return () => {
        socket.off("userConnected");
        socket.off("userDisconnected");
      };
    }
  }, [socket]);

  return { socket, users };
};

export default useChat;
