import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, NavLink } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";

import "slick-carousel/slick/slick.css";

import Slider from "react-slick";

const RecentlyViewed = () => {
  const [selected, setselected] = useState(false);

  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    responsive: [
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const select2Style = {
    marginTop: windowWidth <= 800 && selected === false ? "520px" : null,
  };
  const containerStyle = {
    height: windowWidth <= 800 ? "920px" : "460px",
  };

  const handleClick = (check) => {
    //console.log(check);
    if (check === "back") {
      setselected(false);
    } else {
      setselected(true);
    }
  };

  return (
    <React.Fragment>
      <section className="main-find-services">
        <div className="mb-4">
          <div class="v2_185">
            <span style={{ color: "#2F327D" }}>How CybertoCyber</span>{" "}
            <span style={{ color: "#23BDEE" }}>works?</span>
          </div>
          <div className="d-flex justify-content-center">
            <div class="v2_197"></div>
            <div class="v2_196"></div>
          </div>
        </div>
        {/* <div className="container">
          <div class="v29_29">
            <div className="service-main-block">
              <div className="select">
                {selected == false ? <div class="v2_195"></div> : null}
                <div class="v2_189" onClick={() => handleClick("back")}>
                  <div class="v2_190">Cyber Pros</div>
                </div>
              </div>

              <div className="select2">
                {selected == true ? <div class="v2_195_1"></div> : null}
                <div class="v2_192" onClick={() => handleClick("forword")}>
                  <div class="v2_193">Cyber Client</div>
                </div>
              </div>
            </div>

            {selected === false ? (
              <div class="v2_239">
                <div class="v2_241">
                  <div class="v2_242">1</div>

                  <span className="v2_243">Create your profile</span>
                  <span className="v2_244">
                    Your complete and attractive profile is imperative to get
                    gigs, projects, and tasks
                  </span>
                </div>
                <div class="v2_245">
                  <div class="v2_246">3</div>

                  <span class="v2_248">Work with your client</span>
                  <span class="v2_249">
                    Help your customer get the best service
                  </span>
                </div>
                <div class="v2_250">
                  <div class="v2_251">4</div>

                  <span class="v2_253">Get paid for your service</span>
                  <span class="v2_254">
                    You deserve the best for the best work done!
                  </span>
                </div>
                <div class="v2_255">
                  <div class="v2_256">2</div>

                  <span class="v2_258">Provide your service</span>
                  <span class="v2_259">
                    Mention all the services that you can provide to your
                    clients
                  </span>
                </div>
                <div class="name"></div>
                <div class="name"></div>
              </div>
            ) : (
              <div class="v2_270">
                <div class="v2_241">
                  <div class="v2_242">1</div>

                  <span class="v2_243">Post a service you want </span>
                  <span class="v2_244">
                    Provide details about the work you want done!
                  </span>
                </div>
                <div class="v2_245">
                  <div class="v2_246">3</div>

                  <span class="v2_248">Hire the right professional</span>
                  <span class="v2_249">
                    Make your selection, chat with, and sign a contract with a
                    cyber pro.
                  </span>
                </div>
                <div class="v2_250">
                  <div class="v2_251">4</div>

                  <span class="v2_253">
                    Work with your professional to get best service
                  </span>
                  <span class="v2_254" style={{ marginTop: "40px" }}>
                    Keep track of the work progress being done by your cyber pro
                  </span>
                </div>
                <div class="v2_255">
                  <div class="v2_256">2</div>

                  <span class="v2_258">Search a vetted cyber professional</span>
                  <span class="v2_259" style={{ marginTop: "16px" }}>
                    Search and match the best talent to get your work done
                  </span>
                </div>
                <div class="name"></div>
                <div class="name"></div>
              </div>
            )}
          </div>
        </div> */}

        <div style={{ padding: 0 }} className="container">
          <div className="service-main-block">
            <div className="select col-md-6">
              {selected == false ? (
                <div class="v2_195">
                  <div className="object"></div>
                </div>
              ) : null}
              <div class="v2_189" onClick={() => handleClick("back")}>
                <div class="v2_190">Cyber Pros</div>
              </div>
            </div>

            <div className="select2 col-md-6">
              {selected == true ? (
                <div class="v2_195">
                  <div className="object"></div>
                </div>
              ) : null}
              <div class="v2_192" onClick={() => handleClick("forword")}>
                <div class="v2_193">Cyber Client</div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="row row-recently">
              {selected === false ? (
                <>
                  <div class="v2_241 col-md-6">
                    <div class="v2_242">1</div>

                    <div>
                      <div className="v2_243">Create your profile</div>
                      <div className="v2_244">
                        Your complete and attractive profile is imperative to
                        get gigs, projects, and tasks
                      </div>
                    </div>
                  </div>

                  <div class="v2_241 col-md-6">
                    <div class="v2_242 v2_256">2</div>
                    {/* <span class="v2_257">2</span> */}

                    <div>
                      <div class="v2_243">Provide your service</div>
                      <div class="v2_244">
                        Mention all the services that you can provide to your
                        clients
                      </div>
                    </div>
                  </div>
                  <div class="v2_241 col-md-6">
                    <div class="v2_242 v2_246">3</div>
                    {/* <span class="v2_247">3</span> */}

                    <div>
                      <div class="v2_243">Work with your client</div>
                      <div class="v2_244">
                        Help your customer get the best service
                      </div>
                    </div>
                  </div>
                  <div class="v2_241 col-md-6">
                    <div class="v2_242 v2_251">4</div>
                    {/* <span class="v2_252">4</span> */}

                    <div>
                      <div class="v2_243">Get paid for your service</div>
                      <div class="v2_244">
                        You deserve the best for the best work done!
                      </div>
                    </div>
                  </div>
                  <div class="name"></div>
                  <div class="name"></div>
                </>
              ) : (
                <>
                  <div class="v2_241 col-md-6">
                    <div class="v2_242">1</div>
                    {/* <span class="v2_262">1</span> */}

                    <div>
                      <div class="v2_243">Post a service you want </div>
                      <div class="v2_244">
                        Provide details about the work you want done!
                      </div>
                    </div>
                  </div>

                  <div class="v2_241 col-md-6">
                    <div class="v2_242 v2_256">2</div>
                    {/* <span class="v2_257">2</span> */}
                    <div>
                      <div class="v2_243">
                        Search a vetted cyber professional
                      </div>
                      <div class="v2_244">
                        Search and match the best talent to get your work done
                      </div>
                    </div>
                  </div>
                  <div class="v2_241 col-md-6">
                    <div class="v2_242 v2_246">3</div>
                    {/* <span class="v2_247">3</span> */}

                    <div>
                      <div class="v2_243">Hire the right professional</div>
                      <div class="v2_244">
                        Make your selection, chat with, and sign a contract with
                        a cyber pro.
                      </div>
                    </div>
                  </div>
                  <div class="v2_241 col-md-6">
                    <div class="v2_242 v2_251">4</div>
                    {/* <span class="v2_252">4</span> */}

                    <div>
                      <div class="v2_243">
                        Work with your professional to get best service
                      </div>
                      <div class="v2_244">
                        Keep track of the work progress being done by your cyber
                        pro
                      </div>
                    </div>
                  </div>
                  <div class="name"></div>
                  <div class="name"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default connect()(RecentlyViewed);
