import { Form, Button } from "react-bootstrap";

export default function LocalBank({
  localBankData,
  handleChangeTextLocal,
  handleChangeLetterLocal,
  loading,
}) {
  return (
    <>
      <Form.Group>
        <Form.Label>Beneficiary's Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's Name"
          name="beneficiary_name"
          value={localBankData.beneficiary_name}
          onChange={handleChangeLetterLocal}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's Bank Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's Bank Name"
          name="bank_name"
          value={localBankData.bank_name}
          onChange={handleChangeLetterLocal}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's IBAN No</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's IBAN No"
          name="iban"
          value={localBankData.iban}
          onChange={handleChangeTextLocal}
          className="mb-4"
        />
      </Form.Group>

      <div className="d-flex justify-content-center align-items-center">
        <Button
          variant="secondary"
          type="submit"
          className="w-75"
          disabled={loading}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
