import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import { Icon } from 'react-icons-kit';
import { sendO } from 'react-icons-kit/fa/sendO';
import { messageGetbyId, proposalGetbyId, messageAdd, messageDelete, messageRead } from "../../actions/proposalAndContract";
import './chat.css';
import { FileUploader } from "react-drag-drop-files";

import { filePdfO } from 'react-icons-kit/fa/filePdfO';
import { fileWordO } from 'react-icons-kit/fa/fileWordO';
import { fileExcelO } from 'react-icons-kit/fa/fileExcelO';
import { filePowerpointO } from 'react-icons-kit/fa/filePowerpointO';
import { fileImageO } from 'react-icons-kit/fa/fileImageO';
import { ic_folder } from 'react-icons-kit/md/ic_folder';
import { upload } from 'react-icons-kit/fa/upload';
import { trash } from 'react-icons-kit/fa/trash'
import { download } from 'react-icons-kit/fa/download'
import { fileDownload, uploadFile } from "../../actions/file-crud";
import { checkSquareO } from 'react-icons-kit/fa/checkSquareO';
import { check } from 'react-icons-kit/fa/check';
import moment from "moment";
import Highlighter from "react-highlight-words";
import { readAdminMessages } from "../../actions/manageUsers";

const AdminChat = ({ searchString, auth: { user },username, MessagesItems}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readAdminMessages(user._id))
  }, []);

  function isImageFileName(filename) {
    const imageExtensions = /^image\/(jpg|jpeg|png|gif)$/i;
    return imageExtensions.test(filename);
  }

  return (
    <>
        <div className="messages-box--chat-box p-3 border-bottom bg-light position-relative h-100">
          <ScrollToBottom className="message-container h-100">
            {MessagesItems?.length > 0 && [...MessagesItems].map((messageContent, index) => {
              return (
                <div key={messageContent?._id || `message${index}`} id={messageContent?._id || `message${index}`} className="chat-area">
                  
                  {moment(MessagesItems[index - 1]?.message_date || 0).format('MM-DD-YYYY') !== moment(messageContent.message_date).format('MM-DD-YYYY') && <div className="text-center chat-area--main-date">
                  <span> {moment(messageContent.message_date).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY') ? 'Today' : moment(messageContent.message_date).format('MMM-DD-YYYY')}
                    </span></div>}
                  <div className={`chat-area--${username === messageContent.user_name ? 'you' : 'other'}`} id={username === messageContent.user_name ? 'you'+messageContent?._id : "other"+messageContent?._id}>
                    {messageContent.message_type === "file" ? (
                      <p className="chat-area--bubble">
                        {isImageFileName(messageContent.message_file_properties.file_type) ? (
                          <img
                            src={messageContent.message_file_properties.file_url}
                            alt={messageContent.message_file_properties.file_name}
                            width="auto"
                            height={250}
                            className="mr-2 cursor w-100"
                            onClick={() => window.open(messageContent.message_file_properties.file_url, '_blank')}
                          />
                        ) : (
                          <div
                            className="mr-2 cursor"
                            style={{
                              paddingTop: "3px",
                              paddingBottom: "3px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              fontSize: "2rem",
                              border: "1px solid grey",
                              borderRadius: "10px"
                            }}
                            onClick={() => window.open(messageContent.message_file_properties.file_url, '_blank')}
                          >
                            <i className="mdi mdi-file-document"></i>
                            <span style={{ fontSize: "1rem" }}>
                              {messageContent.message_file_properties.file_name}
                            </span>
                          </div>
                        )}
                        
                        {username === messageContent.user_name && (
                          <Icon
                            className="mr-2 cursor"
                            icon={trash}
                            onClick={() => dispatch(messageDelete(messageContent._id))}
                          />
                        )}

                        <Icon
                            className="mr-2 cursor"
                            icon={download}
                            onClick={() => fileDownload(messageContent.message_file_properties.file_url, messageContent.message_file_properties.file_name)}
                        />

                        <span className="chat-area--bubble-text">
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchString]}
                            autoEscape={true}
                            textToHighlight={messageContent?.message_text || ""}
                          />
                        </span>
                        <span className="chat-area--bubble-time">
                          {messageContent.message_time}
                        </span>
                      </p>
                    ) : (
                      <p className="chat-area--bubble">
                        <span className="chat-area--bubble-text">
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchString]}
                            autoEscape={true}
                            textToHighlight={messageContent?.message_text || ""}
                          />
                        </span>
                        <span className="chat-area--bubble-time">
                          {moment(messageContent.message_date).format("h:mm A")}
                        </span>
                        {username === messageContent.user_name && (messageContent.read == "false" ? <Icon className="chat-area--bubble-icon" color="green" icon={checkSquareO} /> : <Icon className="chat-area--bubble-icon" icon={check} />)}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </ScrollToBottom>
        </div>
    </>
  );
}

const mapStateToProp = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProp)(AdminChat);