import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getWithdrawalRequests } from "../../actions/withdrawal";

import WithdrawalList from "../finance/WithdrawalList";

const PayoutApproval = ({
  auth: { isAuthenticated, user, token },
  withdrawal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push("/login-register?role=client&type=login");
    }
    dispatch(getWithdrawalRequests());
  }, []);
  
  return (
    <section className="main-page page-dashboard">
      <Helmet>
        <title>Payout Approval</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <WithdrawalList withdrawals={withdrawal.withdrawals} user={user} />
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  withdrawal: state.withdrawal,
});

export default connect(mapStateToProps)(PayoutApproval);
