import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect , NavLink, useHistory} from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Alert from '../../components/alert/Alert';
import { addtitleAndOverview } from "../../actions/freelancerProfile";
import {Helmet} from "react-helmet";


const TitleAndOverview = ( { freelancerProfile : { TitleAndOverview, freelancerprofile } , setAlert, addtitleAndOverview, isAuthenticated}) => {

   const [formData , setFormData] = useState({
    title: "",
    professional_overview: ""
  });

  useEffect(() => {
    let check = JSON.parse(localStorage.getItem('titleAndOverview'));
    //console.log(check)
    if(check)
    {
      setFormData(TitleAndOverview);
    }
    
  }, []);

  const { title, professional_overview } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let history = useHistory();
  const regex = /^[a-zA-Z\s]*$/;

  const titleRegex = /^[a-zA-Z0-9\s.,!?@#%^&*()_+-=<>:;"'/\[\]{}|~]*$/;
  // Allows alphanumeric characters, spaces, and most common punctuation. 

  const overviewRegex = /^[a-zA-Z0-9\s.,!?@#%^&*()_+-=<>:;"'/\[\]{}|~]*$/;
  // Allows alphanumeric characters, spaces, and most common punctuation.


  const onSubmit = async (e) => {
    e.preventDefault();
  
    // Check that Title and Professional Overview fields contain at least one letter
    //if (!regex.test(title) || !regex.test(professional_overview)) {
    //  setAlert("Title and Professional Overview must contain at least one letter", "danger");
    //  console.log("Title and Professional Overview must contain at least one letter")
    //  return;
    //}

    if (!titleRegex.test(title)) {
      setAlert("Please enter a valid title.", "danger");
      //console.log("Invalid title entered.");
      return;
    }

    if (!overviewRegex.test(professional_overview)) {
      setAlert("Please enter a valid professional overview.", "danger");
      //console.log("Invalid professional overview entered.");
      return;
    }
  
    addtitleAndOverview({
      title,
      professional_overview
    });
    
    history.push("/profileandphoto");

  };
  


   if (!isAuthenticated) {
        return <Redirect to="/login-register?role=pro&type=login" />;
    }
    if(typeof freelancerprofile === "string"){
      return <Redirect to="/expert-email-verify" />;
    }

  return (
    <section className="main-page page-dashboard">
      <Helmet>
        <title>Title & Overview</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
             <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
              <div className="bg-white rounded shadow-sm sidebar-page-right">
                    <div className="p-5">
                        <h3 className="text-primary">Title & Overview</h3>
                        <p className="font-weight-bold">06 of 10</p>
                        <Alert />
                          <form onSubmit={(e) => onSubmit(e)}>
                            {/** <p className="mb-4 mt-4"><Link to="" className="text-primary">Learn more</Link> about writing a great profile or <Link to="" className="text-primary">browse profiles</Link> in your category.</p>*/}
                              <div className="row d-flex align-items-center form-group">
                                  <div className="col-md-12 col-12">
                                      <p className="text-muted font-weight-bold">Title</p>
                                  </div>
                                  <div className="col-md-12 col-12">
                                      <input type="text" className="form-control" placeholder="Example: Legal"
                                      name="title"
                                      value={title}
                                      title="Allows alphanumeric characters, spaces, and most common punctuation."
                                      onChange={(e) => onChange(e)} />
                                  </div>
                                  <div className="col-md-12 col-12 mt-4">
                                      <p className="text-muted font-weight-bold">Professional Overview</p>
                                  </div>
                                  <div className="col-md-12 col-12">
                                      <textarea className="form-control" rows="6" placeholder="Highlight your top skills, experience, and interests. This is one of the first things clients will see on your profile"
                                      name="professional_overview"
                                      value={professional_overview}
                                      title="Allows alphanumeric characters, spaces, and most common punctuation."
                                      onChange={(e) => onChange(e)}></textarea>
                                  </div>
                              </div>
                                                        
                          <div className="row justify-content-center complete_profile_buttons">   
                                <div className="col">
                               {/*<Link to="/certificate" className="btn btn-normal">Skip this step</Link>*/}
                                </div>
                                <div className="text-right">
                                  <Link to="/hourlyRate" className="btn btn-default mr-3">Back</Link>
                                    <button type="submit" className="btn btn-primary" >Save & Next</button> 
                                </div>
                              </div>
                          </form>

                      </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TitleAndOverview.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile,
});


export default connect(mapStateToProp, { setAlert, addtitleAndOverview })(TitleAndOverview, Alert);