import React from "react";
import MainSlider from "../../components/mainSlider/mainSlider";
import Services from "../../components/Services/services";
import RecentlyViewed from "../../components/recentlyViewed/recentlyViewed";
import HomePageVideo from "../../components/homePageVideo/homepagevideo";
import FindCyber from "../../components/findCyber/findcyber";
import CyberPro from "../../components/cyberPro";
import CyberClient from "../../components/cyberClient";
import CyberEnterprise from "../../components/cyberEnterprise";
import Testimonials from "../../components/testimonials";
import MainSlider2 from "../../components/mainSliderv2/mainSlider2";

const Home = () => {
  return (
    <React.Fragment>
      {/* <MainSlider2 /> */}
      <MainSlider />
      {/* <Services /> */}
      <FindCyber />
      <CyberEnterprise />
      <CyberPro />
      <CyberClient />
      <Testimonials />
      {/* <HomePageVideo />
      <RecentlyViewed /> */}
    </React.Fragment>
  );
};

export default Home;
