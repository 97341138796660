import { useMemo, useRef, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";

import {
  TransactionAmount,
  TransactionDate,
  TransactionDetail,
} from "./transactionItem";
import Skeleton from "./../../components/skeleton";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";

export default function TransactionList({ transactionHistory, user }) {
  const searchInputRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");

  const [paginatedDataItem, setPaginatedDataItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);

  const handleSearch = () => {
    const searchTerm = searchInputRef.current.value;
    setSearchTerm(searchTerm);
  };

  const filteredRows = useMemo(() => {
    if (!searchTerm) return transactionHistory;

    setCurrentPage(1);

    if (transactionHistory.length > 0) {
      if (!searchTerm) {
        return transactionHistory;
      }

      const list = {};

      for (const current of transactionHistory) {
        const jobName = current.job_headline;
        const clientName = current.paymentFrom?.user_name;
        const expertName = current.paymentTo?.user_name;

        const isIncluded = (str) => {
          return str?.toLowerCase()?.includes(searchTerm.toLowerCase());
        };

        if (
          isIncluded(jobName) ||
          isIncluded(clientName) ||
          isIncluded(expertName)
        ) {
          list[current._id] = current;
        }
      }
      return Object.values(list);
    }

    return [];
  }, [searchTerm, transactionHistory]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setPaginatedDataItem(filteredRows);
  }, [filteredRows]);

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = paginatedDataItem
    ? [...paginatedDataItem].slice(startIndex, endIndex)
    : null;
  const totalPages = Math.ceil(paginatedDataItem?.length / resultsPerPage);

  return (
    <div className="box-table bg-white border border-top-0 p-2">
      <div className="d-flex justify-content-end align-items-center">
        <Form.Group
          className="d-flex my-3"
          style={{ gap: 8, marginRight: "12px" }}
        >
          <Form.Control
            className="w-auto search-table-input"
            type="text"
            ref={searchInputRef}
            placeholder="Search"
          />
          <Button variant="secondary" onClick={handleSearch}>
            Search
          </Button>
        </Form.Group>
      </div>
      <div className="overflow-scroll">
        <table className="table table-layout-fixed m-0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactionHistory === null &&
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                <tr key={i}>
                  <td>
                    <Skeleton height={25} />
                  </td>
                  <td>
                    <Skeleton height={25} />
                  </td>
                  <td>
                    <Skeleton height={25} />
                  </td>
                  <td>
                    <Skeleton height={25} />
                  </td>
                </tr>
              ))}

            {transactionHistory !== null &&
              (currentResults && currentResults.length > 0 ? (
                [...currentResults].map((tr) => (
                  <tr key={tr._id}>
                    <td>{tr._id}</td>
                    <td>
                      <TransactionDate date={tr.createdAt} />
                    </td>
                    <td>
                      <TransactionDetail
                        from={tr.paymentFrom?.user_name}
                        to={tr.paymentTo?.user_name}
                        type={tr.transaction_type}
                        jobName={tr.job_headline || "-"}
                      />
                    </td>
                    {user.type === "Admin" ? (
                      <td>
                        <TransactionAmount
                          amount={tr.amount}
                          operator={tr.paymentTo?.type === "Admin" ? "+" : "-"}
                        />
                      </td>
                    ) : (
                      <td>
                        <TransactionAmount
                          amount={tr.amount}
                          operator={tr.paymentTo?._id === user._id ? "+" : "-"}
                        />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    No record found
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {totalPages > 1 && (
          //FIXED PAGINATION
          <nav>
            <ul className="talent-pagination pagination justify-content-center">
              <div
                className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() =>
                  handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                }
              >
                <Icon icon={arrowLeft} />
              </div>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
              <div
                className={`icon ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                onClick={() =>
                  handlePageChange(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
              >
                <Icon icon={arrowRight} />
              </div>
            </ul>
          </nav>
          /* 
          OLD PAGINATION
          <nav>
            <ul className="pagination justify-content-center">
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
            </ul>
          </nav> */
        )}
      </div>
    </div>
  );
}
