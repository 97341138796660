import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect , NavLink , useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Alert from '../../components/alert/Alert';
import {Helmet} from "react-helmet";

import { PersonalDetailsUpdate } from "../../actions/freelancerProfile";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from 'react-icons-kit';
import { remove } from 'react-icons-kit/fa/remove'
import { Country } from "country-state-city";

const PersonalDetails = ({ freelancerProfile : { Location } , isAuthenticated, user, PersonalDetailsUpdate }) => {
    const [formData, setFormData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        location: '' 
    });

    const [showrejection, setShowRejection] = useState(false);

    const handleClose = () => {
      setShowRejection(false);
    }
  
    /*console.log("entered0")
    if(freelancerprofile?.verification_status === "Rejected"){
      console.log()
      setShowRejection(true)
    }*/
  
    useEffect(() => {
        if (user) {
            //console.log("Logged-in user data:", user);
    
            const userData = {
                username: user.user_name || '',
                firstname: user.first_name || '',
                lastname: user.last_name || '',
                email: user.email || '',
                location: Location?.country || '', 
            };

            setFormData(userData);

            setLocation(Location?.country || '');
        }
    }, [user, Location]);

    const { username, firstname, lastname, email, password } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const history = useHistory();

    const onSubmit = async e => {
        e.preventDefault();
        //console.log('Form data submitted', formData);

        try {
            await PersonalDetailsUpdate(user._id, formData);
            history.push('/expertise');
        } catch (error) {
            console.error("Error updating personal details", error);
        }
    };
    
    const [location, setLocation] = useState(Location?.country || "");

    const countries = Country.getAllCountries();

    const handleLocationChange = (e) => {
        const newLocation = e.target.value;
        setLocation(e.target.value);
        setFormData({ ...formData, location: newLocation });
      };
      
    if (!isAuthenticated) {
        return <Redirect to="/login-register?role=client&type=login" />;
    }

    return (
        <section className="main-page page-dashboard">
             {/*}  <Modal
                    show={showrejection}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                    <Modal.Title>Account Verification Rejected</Modal.Title>
                    <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleClose} />
                    </Modal.Header>
                    <Modal.Body>
                    Are you sure you want to logout?
                    {freelancerprofile?.rejected_msg}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button  variant="primary"  onClick={handleClose}>ok</Button>
                    </Modal.Footer>
                 </Modal>*/}
            <Helmet>
                <title>Personal Details</title>
            </Helmet>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <UserProfileNavLink />
                    </div>
                    <div className="col-lg-9">
                        <div className="bg-white rounded shadow-sm sidebar-page-right">
                            <div className="p-5">
                                <h3 className="text-primary">Personal Details</h3>                            
                                <p className="font-weight-bold">01 of 10</p>
                                <Alert />
                                <form onSubmit={onSubmit}>
                                    {/* Input fields for personal details here */}
                                    <div className="form-group"  style={{minHeight: "56vh"}}>
                                        <label className="form-label mb-2">Username<span className="required-field"></span></label>
                                        <input type="text" className="form-control mb-2" name="username" value={username} onChange={onChange} />

                                        <label className="form-label mb-2">Firstname<span className="required-field"></span></label>
                                        <input type="text" className="form-control mb-2" name="firstname" value={firstname} onChange={onChange} />
                                        
                                        <label className="form-label mb-2">Lastname<span className="required-field"></span></label>
                                        <input type="text" className="form-control mb-2" name="lastname" value={lastname} onChange={onChange} />

                                        <label className="form-label mb-2">Email<span className="required-field"></span></label>
                                        <input type="text" className="form-control mb-2" name="email" value={email} onChange={onChange} />
                                        
                                        <div className="form-group">
                                            <label className="form-label mb-2">Location <span className="required-field"></span></label>
                                            <select
                                            className="form-control mb-2"
                                            name="location"
                                            value={location} // Use state variable for value
                                            required={true}
                                            onChange={handleLocationChange} // Use handler for onChange
                                            >
                                            <option>Please Select</option>
                                            {countries.map((item) => (
                                                <option
                                                value={item.name}
                                                data-isoCode={item.isoCode}
                                                >
                                                {item.name}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>

                                    
                            <br></br>
                            <div className="row justify-content-center p-2">   
                                <div className="col-md">
                               {/*<Link to="/certificate" className="btn btn-normal">Skip this step</Link>*/}
                                </div>
                                <div className="text-right wm-100">
                                        <button type="submit" className="btn btn-primary wm-100" >Save & Next</button>
                                    </div>
                              </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

PersonalDetails.propTypes = {
    isAuthenticated: PropTypes.bool,
    user: PropTypes.object, 
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    freelancerProfile: state.freelancerProfile,
    user: state.auth.user, 
});

export default connect(mapStateToProps, {PersonalDetailsUpdate})(PersonalDetails);