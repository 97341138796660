import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getAllDisputes } from '../../actions/dispute'; // Ensure this is correct
import { connect, useDispatch } from "react-redux";

const Disputes = ({ getAllDisputes, dispute }) => {
    const [selectedDispute, setSelectedDispute] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllDisputes()); // Dispatch the action to load disputes
    }, []);

    //console.log("getAllDisputes is:",getAllDisputes)

    const handleDisputeClick = (dispute) => {
        setSelectedDispute(dispute);
        setShowDetails(true);
    };

    const handleCloseDetails = () => setShowDetails(false);

    return (
        <div className="dispute-page">
            <h1>Disputes</h1>
            <div className="dispute-list">
                {dispute.disputes && dispute.disputes.map((disputeItem) => (
                    <div key={disputeItem._id.$oid} className="dispute-item" onClick={() => handleDisputeClick(disputeItem)}>
                        <p>Dispute ID: {disputeItem._id.$oid}</p>
                        <p>Date: {new Date(disputeItem.created_at.$date.$numberLong).toLocaleDateString()}</p>
                    </div>
                ))}
            </div>

            {selectedDispute && (
                <Modal show={showDetails} onHide={handleCloseDetails} size="lg">
                    {/* Modal content */}
                </Modal>
            )}
        </div>
    );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
    dispute: state.dispute // Ensure this matches the structure of your Redux state
});

export default connect(mapStateToProps, {getAllDisputes})(Disputes);