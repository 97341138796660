import React, { useState, useEffect } from "react";
import { connect, useDispatch} from "react-redux";
import { Link, Redirect, NavLink, useHistory , useLocation} from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { addPostJob, complexityGet, expectedDurationGet, paymentTypeGet , updateJobGetById } from "../../actions/postJob";
import { skillsGet } from "../../actions/clientProfile";
import { FileUploader } from "react-drag-drop-files";
import { fileImageO } from "react-icons-kit/fa/fileImageO";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import { filePdfO } from "react-icons-kit/fa/filePdfO";
import { fileWordO } from "react-icons-kit/fa/fileWordO";
import { filePowerpointO } from "react-icons-kit/fa/filePowerpointO";
import { ic_folder } from "react-icons-kit/md/ic_folder";
import "./postjobclient.css"
import Icon from "react-icons-kit";
import { remove } from 'react-icons-kit/fa/remove'
import { uploadFile } from "../../actions/file-crud";

const PostJobClient = ({ clientProfile: { hireManager }, postJob: { postJobData, complexityLevel, expectedDurationVal, paymentTypeVal, skills , jobDetailData }, addPostJob, paymentTypeGet, complexityGet, expectedDurationGet, skillsGet, isAuthenticated , updateJobGetById }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState({});
    const [fileArray, setFileArray] = useState([]);
    const [loading, setLoading] = useState(false);

    const fileTypes = ["JPEG", "PNG", "JPG", "PDF", "DOC", "DOCX", "XLS", "XLSX", "PPT", "PPTX"];

    function isImageFileName(filename) {
        const imageExtensions = /^image\/(jpg|jpeg|png|gif)$/i;
        return imageExtensions.test(filename);
      }

      function IconType(type) {
        if (type.includes("jpg") || type.includes("jpeg") || type.includes("png")) {
          return fileImageO;
        } else if (type.includes("spreadsheet")) {
          return fileExcelO;
        } else if (type.includes("pdf")) {
          return filePdfO;
        } else if (type.includes(".doc")) {
          return fileWordO;
        } else if (type.includes("presentation")) {
          return filePowerpointO;
        } else {
          return ic_folder;
        }
      }  

    const [formData, setFormData] = useState({
        job_id : "",
        expected_duration_id: "",
        complexity_id: "",
        description: "",
        main_skill_id: "",
        payment_type_id: "",
        payment_amount: "",
        headline: "",
        location: ""
    });

    useEffect(() => {
        document.title = 'Post a Job';
    }, []);

    useEffect(() => {
        complexityGet()
        expectedDurationGet()
        paymentTypeGet()
        skillsGet()
    }, []);
    // useEffect(() => {
    //     if (Object.entries(file).length > 0) {
    //         console.log('hi');
    //         setFileArray(Object.entries(file).filter(item => !isNaN(parseInt(item[0]))).map(item => item[1]));
    //     }
    //     if (Object.entries(file).length === 0 && fileArray.length > 0) {
    //         console.log('file');
    //         setFileArray([])
    //     }
    // }, [file]);


    
    const homepage_query = useLocation();
    const searchParams = new URLSearchParams(homepage_query.search);
    const storedValue = searchParams.get("value");
    //console.log("coming from Client profile",storedValue)

    useEffect(() => {
        if (storedValue) {
            //console.log(jobDetailData);
    
            // Assuming jobDetailData has the same structure as formData
            setFormData({
                job_id: jobDetailData?._id || "",
                //expected_duration_id: jobDetailData?.expected_duration_id || "",
                complexity_id: jobDetailData?.complexity_id || "",
                description: jobDetailData?.description || "",
                main_skill_id: jobDetailData?.main_skill_id || "",
                payment_type_id: jobDetailData?.payment_type_id || "",
                payment_amount: jobDetailData?.payment_amount || "",
                headline: jobDetailData?.headline || "",
                //location: jobDetailData?.location || ""
                attachments: jobDetailData?.attachments || []
            });
        }
    }, [storedValue, jobDetailData]);
    


    
    const { job_id,
        expected_duration_id,
        complexity_id,
        description,
        main_skill_id,
        payment_type_id,
        payment_amount,
        headline,
        location } = formData;

    const onChange = (e) => {
        if(e.target.name === 'description' && e.target.value.length > 500){
            return;
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onChange_amount = (e) => {
        if (/^\d*$/.test(e.target.value)) {
            setFormData({ ...formData, [e.target.name]: e.target.value });   
          }
          else{
            dispatch(setAlert('Amount must be in digits','danger', 3000));
            //alert('Amount must be in digits')
          }
    };

    let history = useHistory();

    const onSubmit = async (e) => {
        e.preventDefault();
            // Check if formData.attachments is available and append to fileArray
        
            setLoading(true)
            if (Array.isArray(fileArray) && fileArray.length > 0) {

            let fileformData = new FormData();

            //console.log("formdata on submit",formData.attachments.length); 
           // console.log("file on submit",fileArray.length);

            for (let i = 0; i < fileArray.length; i++) {
                //console.log("loop in items upload",formData.attachments[i])
                fileformData.append("files", fileArray[i]);
            }
            
        

            Promise.all([dispatch(uploadFile(fileformData , true))]).then(async res => {
            //console.log("Documents uploaded")    
            let attachments = res[0].map((item=>({
                file_name:item.key,
                file_type:item.mimetype,
                file_url:item.location
            })))

                        
            //console.log("final attachments", attachments)
            
            if (formData.attachments && Array.isArray(formData.attachments)) {
                for (let i = 0; i < formData.attachments.length; i++) {
                    //console.log("loop in items",formData.attachments[i])
                    attachments.push(formData.attachments[i]);
                }

            } else {
                console.error("formData.attachments is not an array or is undefined.");
            }


            var location = hireManager.location;
        
            
            const result = await addPostJob({
            job_id,
            expected_duration_id,
            complexity_id,
            description,
            main_skill_id,
            payment_type_id,
            payment_amount,
            headline,
            location,
            attachments
        });
        if(!result) {
            dispatch(setAlert('Job posting failed',3000) ); //Logic for wallet checking
        }
        else {
            //setFormData({});
            history.push("/clientprofile");
        }
        })
        }
        else {
            var location = hireManager.location;
            const result = await addPostJob({
                job_id,
                expected_duration_id,
                complexity_id,
                description,
                main_skill_id,
                payment_type_id,
                payment_amount,
                headline,
                location
            });
            //console.log(result);
            if(!result) {
                dispatch(setAlert('Job posting failed',3000) );   //Logic for wallet checking
            }
            else {
                setFormData({
                    expected_duration_id: "",
                    complexity_id: "",
                    description: "",
                    main_skill_id: "",
                    payment_type_id: "",
                    payment_amount: "",
                    headline: "",
                    location: ""
                });
                history.push("/clientprofile");
            }
        }
        setLoading(false)
    };

    
    const handleChange = (file) => {
        let temp = [];
        //console.log("files", file)
        if (Object.entries(file).length > 0) {
            temp = Object.entries(file).filter(item => !isNaN(parseInt(item[0]))).map(item => item[1]);
        }

        setFileArray([...fileArray,...temp]);
    };

    const editjobs = () => {
       updateJobGetById();
    }


    const deletegetFile = (index) => {
        //console.log("reached", index);
    
        //console.log("index files", formData.attachments[index]);
    
        // Create a copy of the attachments array
        const updatedFileArray = [...formData.attachments];
    
        // Remove the object at the specified index
        updatedFileArray.splice(index, 1);
    
        // Update the form data with the modified attachments array
        setFormData(prevFormData => ({
            ...prevFormData,
            attachments: updatedFileArray
        }));
    }
    
    
    const deleteFile = (index) => {
        //console.log("clicking")

        const updatedFileArray = fileArray.filter((_, i) => i !== index);
        // Update the state with the new array
        setFileArray(updatedFileArray);

    }

    if (!isAuthenticated) {
        return <Redirect to="/login-register?role=client&type=login/login" />;
    }

    return (
        <section className="main-page page-dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="bg-white rounded shadow-sm sidebar-page-right-old">
                            <div>
                                <div className="p-5 border-bottom">
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <div className="row d-flex align-items-center form-group pt-3">
                                            <div className="col-md-12 col-12">
                                                <p className="font-weight-bold mb-1">Headline<span className="required-field">*</span></p>
                                            </div>
                                            <div className="col-md-12 col-12 mt-2 mb-2">
                                                <input type="text" className="form-control"
                                                    name="headline"
                                                    value={headline}
                                                    onChange={(e) => onChange(e)} required/>
                                            </div>
                                            <div className="col-md-12 col-12 mt-3">
                                                <p className="font-weight-bold mb-2">Describe your job  <span className="required-field">*</span></p>
                                                <p>This is how talent figures out what you need and why you're great to work with!</p>
                                                <p>Include your expectations about the task or deliverable, what you're looking for in a work relationship, and anything unique about your project, team or company. {/*<Link to="#">Here are several examples</Link> that ilustrate best practice for effective job posts.*/}</p>
                                            </div>
                                            <div className="col-md-12 col-12 mt-2 mb-2">
                                                <textarea className="form-control"
                                                    name="description"
                                                    value={description}
                                                    onChange={(e) => onChange(e)}
                                                    placeholder="Already have a job description? Paste it here!" rows="7" required></textarea>
                                                <p className="mt-2">{description && description.length <= 500 ? `Minimum ${500 - description.length} characters left` : `Total ${description.length} characters`}</p>
                                            </div>

                                            <div className="col-md-12 col-12 mt-2">
                                                <p className="font-weight-bold mb-0">Project Complexity? <span className="required-field">*</span></p>
                                            </div>

                                            <div className="col-md-12 col-12">
                                                <div className="d-flex flex-row flex-nowrap align-items-center mt-2">
                                                    {complexityLevel && complexityLevel.length > 0 && complexityLevel.map((itemofcomplexityLevel, index) => (
                                                        <div key={index} className="d-flex align-items-center mr-3">
                                                            <input type="radio" id={`complexityOption-${index}`} name="complexity_id" value={itemofcomplexityLevel._id} onChange={(e) => onChange(e)} />
                                                            <label htmlFor={`complexityOption-${index}`} className="font-weight-bold ml-2 mb-0">{itemofcomplexityLevel.complexity}</label>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>


                                            <div className="col-md-12 col-12 mt-2">
                                                <ul>
                                                    {expectedDurationVal && expectedDurationVal.length > 0 && expectedDurationVal.map((itemofexpectedDurationVal, index) => (
                                                        <li key={index}>
                                                            <input
                                                                type="radio"
                                                                name="expected_duration_id"
                                                                value={itemofexpectedDurationVal._id}
                                                                onChange={(e) => onChange(e)}
                                                                checked={itemofexpectedDurationVal._id === formData.expected_duration_id}
                                                            />
                                                            <span className="ml-1">{itemofexpectedDurationVal.duration_text}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className="col-md-12 col-12 mt-2 mb-2">
                                                <p className="font-weight-bold mb-1">Main Skill <span className="required-field">*</span></p>
                                                <select
                                                    className="form-control col-md-12 col-12"
                                                    name="main_skill_id"
                                                    onChange={(e) => onChange(e)}
                                                    value={formData.main_skill_id}
                                                >
                                                    <option value="">Select</option>
                                                    {skills && skills.length > 0 && skills.map((itemofskills, index) => (
                                                        <option key={index} value={itemofskills._id}>
                                                            {itemofskills.skill_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-12 col-12 mb-2">
                                                <p className="font-weight-bold mb-1">Budget Type  <span className="required-field">*</span></p>
                                            </div>
                                            <div className="row ml-2">
                                                {paymentTypeVal && paymentTypeVal.length > 0 && paymentTypeVal.map((itemofpaymentTypeVal, index) => (
                                                    <div className="col-md-12 col-12 mb-2" key={index}>
                                                        <input
                                                            type="radio"
                                                            name="payment_type_id"
                                                            value={itemofpaymentTypeVal._id}
                                                            onChange={(e) => onChange(e)}
                                                            checked={itemofpaymentTypeVal._id === formData.payment_type_id}
                                                        />
                                                        <span className="font-weight-bold">{itemofpaymentTypeVal.type_name}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <p className="font-weight-bold mb-1">Budget Amount  <span className="required-field">*</span></p>
                                                <input type="text" className="form-control col-md-12 col-12"
                                                    placeholder="$00.00"
                                                    name="payment_amount"
                                                    value={payment_amount}
                                                    onChange={(e) => onChange_amount(e)} required/>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <p className="font-weight-bold mb-1">Attachments</p>
                                                <FileUploader
                                                    classes={`drag-drop drag-drop-job col-md-12 col-12`}
                                                    multiple={true}
                                                    handleChange={handleChange}
                                                    name="file"
                                                    types={fileTypes}
                                                    
                                                />
                                                <div className="col-md-12 col-12 mt-2">
                                                    <div className="row ">
                                                    {fileArray && fileArray.length > 0 && (
                                                        fileArray.map((item, index) => (
                                                            <div key={index} className="mx-2">
                                                            <p className="font-weight-bold mb-1">
                                                                <Icon className="mx-2 icon-hover" icon={remove} onClick={() => deleteFile(index)} />
                                                                {item.name}
                                                            </p>
                                                        
                                                            </div>
                                                        ))
                                                    )}
                                                    </div>
                                                </div>

                                                {/*console.log("Itemsss",formData.attachments)*/}
                                                {formData.attachments && formData.attachments.length > 0 && (
                                                    <div className="col-md-12 col-12 mt-4">
                                                        <div className="row ">
                                                        {formData.attachments.map((item, index) => (
                                                            <div key={index} className="mx-2">
                                                                {/*console.log("Itemsss",item)*/}
                                                                <p className="font-weight-bold mb-1">
                                                                        <Icon className="mx-2 icon-hover" icon={remove} onClick={() => deletegetFile(index)} /> 
                                                                </p>
                                                                <a href={item.file_url} target="_blank" rel="noopener noreferrer" className="text-primary" onClick={(e) => { e.stopPropagation(); }}>
                                                                    <Icon size="30" icon={IconType(item.file_type)} />
                                                                    <span className="d-block mt-2">View</span>
                                                                </a>                                                                
                                                            </div>
                                                        ))}
                                                    </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                           <div className="col-6">
                                                 {/** <Link to="/budget" className="btn btn-default">Back</Link>*/}
                                            </div>
                                            <div className="col-6 text-right">
                                                                                           
                                            {console.log(loading)}
                                            
                                            <button className="btn btn-primary" type="submit" disabled={loading} >
                                            
                                            {loading ? (
                                                'Loading ...'
                                                ) : (
                                                <>
                                                   {storedValue ? "Save" : "Post Job" }
                                                </>
                                                )}
                                            </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

PostJobClient.propTypes = {
    isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    postJob: state.postJob,
    clientProfile: state.clientProfile
});


export default connect(mapStateToProp, { addPostJob, complexityGet, expectedDurationGet, paymentTypeGet, skillsGet , updateJobGetById})(PostJobClient);