import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import {  PhoneNumberVerify } from "../../actions/auth";
import {  addPhoneNumber } from "../../actions/auth";
import {  updateFreelancer, getFreelancerProfilePercentage } from "../../actions/freelancerProfile";
import 'react-phone-number-input/style.css'
import PhoneInput, {  isValidPhoneNumber } from 'react-phone-number-input';
import Alert from '../../components/alert/Alert';
import {Input2} from '../../components/input/input';
import {SVGWidgetLogo,FormIcon} from '../../components/SVG'
import { sendEmailFunc } from "../../actions/sendEmail";
import {Helmet} from "react-helmet";
//

const Phone = ({ user: { phoneNumber,user },  freelancerProfile : { freelancerprofile }, setAlert, addPhoneNumber, PhoneNumberVerify, isAuthenticated , updateFreelancer ,sendEmailFunc}) => {

  const [formData, setFormData] = useState({
    codeBodyTextVal: "",
    email: ""
  });

  //console.log(user);

  const [phone, setValue] = useState();
  const [update, setUpdate] = useState(false);

  const { codeBodyTextVal , email} = formData;

  
  useEffect(() => {
    let check = JSON.parse(localStorage.getItem('phoneNumber'));
    //console.log(check)
    if(check)
    {
      setFormData(phoneNumber);
    }
    
  }, []);

 
  useEffect(() => {
    
    //console.log(freelancerprofile);
    if (freelancerprofile !="") {
      setUpdate(false);
    } else {
      setUpdate(true);
    }
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let history = useHistory();

  

  let codeBody; 
  const sendCode = async (e) => {
    e.preventDefault();
    
    codeBody = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem('Verify', JSON.stringify(codeBody));
    
    if(phone && email){
      setAlert("Select any one option", "danger", 3000);
    }
    else if(email){
      /*
      addPhoneNumber({
        phone: email,
        codeBody
      });
      */
      
      var emailType = codeBody;
      var emailAddress = email;
      sendEmailFunc(
        emailAddress,{
        emailType
      });
    }
   
  }

  

  const codeVerify = async (e) => {
    e.preventDefault();

    // debugger;
    //console.log(codeBodyTextVal);
    //console.log(codeBody);
    /*
    PhoneNumberVerify({
      codeBodyTextVal
    })*/

    codeBody = JSON.parse(localStorage.getItem('Verify'));
    
    if(codeBodyTextVal && codeBodyTextVal == codeBody){
      setAlert("Code Verified Successfully", 'success')
      getFreelancerProfilePercentage();
      /*
      PhoneNumberVerify({
        codeBodyTextVal
      })*/
      //setUpdate(false);
    }
    else{
      setAlert("Invalid Token", "danger", 3000);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    debugger;

    if(user.type == 'Freelancer')
    {
      // updateFreelancer().then((res)=>{
       
      //   console.log(res);

      //   if(res.status == 200)
      //   {
      //      history.push("/profile");
      //   }
        
        
      // }).catch((err)=>{
      //   console.log(err) //
      //   //yahan error handle karo
      // })
      
    }else
    {
      user.type === 'Client' ? history.push("/clientprofile") : history.push("/profile");
    }
    
  };

  if (!isAuthenticated) {
    return <Redirect to="/login-register?role=client&type=login/login" />;
  }

  return (
    <section className="main-page page-dashboard">
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
            <div className="bg-white rounded shadow-sm sidebar-page-right">
            <div className="box-title border-bottom p-4">
                    <h4 className="h4 m-0">Verification</h4>
                    </div>
              <div className="p-5 border-bottom">
              
                {/* <p className="font-weight-bold">12 of 12</p> */}
                <form onSubmit={(e) => onSubmit(e)}>
                  <h4 className="font-weight-bold mb-4 mt-0">Add your phone number or email for verification.</h4>
                  <Alert />
                  {update ? <>
                  {/** <label className="form-label">Phone</label>*/}
                  <div className="row form-group">
                    {/* <div className="col-md-12 col-12">
                      <p className="text-muted font-weight-bold mb-2">Phone</p>
                    </div> */}
                    {/**
                      <div className="col-md-6 col-12">
                      <div className="cyber-form-control">
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="PK"
                          placeholder="Enter phone number"
                          value={phone}
                          onChange={setValue}
                          error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
                        
                        
                        </div>
                      </div>
                      */}
                      <div className="col-md-6 col-12">
                      <div className="cyber-form-control">
                          <Input2
                          labelfor={`Email`}
                          id={`Email`}
                          className={'error'}
                          // label={`Username`}
                          group={true}
                          name={`email`}
                          placeholder={`Email`}
                          icon={<FormIcon icon={'email'} />}
                          type={'email'}
                          required={true}
                          value={email}
                          handlerOnChange={(e) => onChange(e)}
                        >
                          {/* <span className="error-text">Error</span> */}
                        </Input2>
                        </div>
                    
                      </div>
                      {/** 
                      <div className="col-md-8 col-12">
                      
                        <div className="cyber-form-control">
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="PK"
                          placeholder="Enter phone number"
                          value={phone}
                          onChange={setValue}
                          error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
                        

                        
                        <label className="form-label">OR</label>

                        <br></br>
                        <label className="form-label">Email</label>
                        
                        </div>
                        <div className="cyber-form-control">
                          <Input2
                          labelfor={`Email`}
                          id={`Email`}
                          className={'error'}
                          // label={`Username`}
                          group={true}
                          name={`email`}
                          placeholder={`Email`}
                          icon={<FormIcon icon={'email'} />}
                          type={'email'}
                          required={true}
                          value={email}
                          handlerOnChange={(e) => onChange(e)}
                        >
                          {<span className="error-text">Error</span> 
                        </Input2>
                        </div>
                        
                      </div>
                    */}
                    
                    
                    </div>
                    
                    <div className="row justify-content-center">
                      <div className="col">
                      <button onClick={sendCode} className="btn btn-default" disabled={!phone && !email} >Send Code</button>
                      </div>
                    </div>

                    <br></br>

                    <div className="row form-group">
                      <div className="col-md-6 col-12">
                        <div className="form-group cyber-form-control">
                          <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Enter code"
                              name="codeBodyTextVal"
                              value={codeBodyTextVal}
                              onChange={(e) => onChange(e)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mt-2 col-12">
                        <button onClick={codeVerify} className="btn btn-primary">Verify</button>
                      </div>
                    </div>
                    <div className="row form-group">
                    <div className="col-md-12 col-12 pt-4">
                      <p>You'll receive a text message with a verification code you can enter on the next step. Messaging rates may apply. Your phone number will <strong>not</strong> be shared with clients.</p>
                    </div>
                  </div></> : (
                    <div className="row form-group">
                      {/* <div className="col-md-12 col-12">
                        <p className="text-muted font-weight-bold mb-2">Phone</p>
                      </div> */}
                      {/*
                         <div className="col-md-12 col-12">
                         <label className="form-label">Phone</label>
                         <input type="text" className="form-control"
                           value={"Verified"}
                           disabled />
                       </div>*/

                      }
                     
                      <div className="col-md-12 col-12 pt-4">
                          {/*<p>Your Phone Number is Verified to Update your number click:</p>*/}
                          <p>Verified User</p>
                          
                          {/*<button onClick={()=>setUpdate(true)} className="btn btn-primary">Update</button>*/}
                      </div>
                    </div>
                  )}
                  
                  <div className="text-right">
                    {!user.type === 'Client' && <Link to="/location" className="c-btn btn-default mr-1">Back</Link>}
                    {/*<Link to="/profile" className="c-btn c-fill-color-btn">Submit Profile</Link>*/}
                    {freelancerprofile !="" ? console.log("") : 
                    <button className="btn btn-primary" type="submit" >
                      Submit Profile
                    </button>}
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Phone.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth,
  freelancerProfile: state.freelancerProfile,
});


export default connect(mapStateToProp, { setAlert, addPhoneNumber, PhoneNumberVerify, sendEmailFunc})(Phone);