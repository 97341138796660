import { PENDING_JOBS_GET_SUCCESS } from "./types";
import axios from 'axios';
import { setAlert } from "./alert";
import setAuthToken from '../utils/setAuthToken';



export const getPendingJobs = () => async dispatch => {
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}
	//debugger;
    try {
		
		const res = await axios.get('/api/job/getPendingJobs', config);
		
		//const arr = [res.data];
		//console.log(arr)

		if (res.data == null)
			res.data = [];

		dispatch({
			type: PENDING_JOBS_GET_SUCCESS,
			payload: res.data
		})
		//console.log("check")


	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}
}

export const updateJobStatus = (body,cb) => async dispatch => {
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}
    try {
		await axios.post('/api/job/updateStatus', body,config);
		cb && cb();
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}
}


export const getAllUsers = ()=> async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	try {
		
		const res = await axios.get('/api/user/', config);
		
		//const arr = [res.data];
		//console.log(arr)

		if (res.data == null)
			res.data = [];

		// dispatch({
		// 	type: PENDING_JOBS_GET_SUCCESS,
		// 	payload: res.data
		// })
		// console.log("check")++


	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}

}