import { GETTRANSACTION, GET_TRANSACTION_HISTORY } from "./types";
import axios from "axios";
import setAuthToken from '../utils/setAuthToken';
import { json } from "body-parser";

export const getTransaction = () => async dispatch => {
    if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}
    let response = await axios.get('api/transaction', config);
    console.log(response);
    dispatch({
        type: GETTRANSACTION,
        payload: response.data
    })
}

export const getTransactionHistory = () => async dispatch => {
    if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}
    
    try {
        const response = await axios.get('api/transaction/history', config);

        dispatch({
            type: GET_TRANSACTION_HISTORY,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: GET_TRANSACTION_HISTORY,
            payload: {
                transactions: []
            }
        });
    }
}

export const setTransaction = (body) => async dispatch => {
    if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}
    let response = await axios.get('api/transaction/createTransaction', body, config);
    console.log(response);
    dispatch({
        type: GETTRANSACTION,
        payload: response.data
    })
}