import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect , NavLink } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Icon } from 'react-icons-kit';
import {wechat} from 'react-icons-kit/fa/wechat';
import Alert from '../../components/alert/Alert';
import {edit} from 'react-icons-kit/fa/edit';
import {trash} from 'react-icons-kit/fa/trash';
import { addLanguage, updateLanguage, deleteLanguage} from "../../actions/freelancerProfile";
import SweetAlert from 'react-bootstrap-sweetalert';
import { remove } from 'react-icons-kit/fa/remove'

const Language = ({ freelancerProfile : { languageVal, freelancerprofile } , setAlert, addLanguage, updateLanguage, deleteLanguage, isAuthenticated}) => {

   const [DataOfLanguage , setDataOfLanguage] = useState([]);
   const [deleteItem,setDeleteItem] = useState(null);
   const [alertBox,setAlertBox] = useState(false);
 
  const [formData , setFormData] = useState({
    language: "",
    proficiency: "",
    _id : ""
  });

    useEffect(() => {
        document.title = 'Language';
    }, []);

  const { language, proficiency, _id } = formData;

    const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    if(language === "" || language == null){
      setAlert("Language required", "danger");
      return;
    }

    if(proficiency === "" || proficiency == null){
      setAlert("Proficiency required", "danger");
      return;
    }

    if (showUpdate == true) {
      updateLanguage(_id, {
        language,
        proficiency
      });
    } else {
      addLanguage({
        language,
        proficiency
      });
    }
    // setDataOfLanguage(DataOfLanguage => [...DataOfLanguage, formData]);
    setFormData({});
    setShow(false);
    setShowUpdate(false);
  };

  const updateLanguageFunc = async (e) => {
    setShowUpdate(true);
    setShow(true);
    setFormData(e);
  }

  const deleteLanguageFunc = async (e) => {
    setDeleteItem(e);
    setAlertBox(true);
  }

  const confirmDelete = () => {
    setAlertBox(false);
    deleteLanguage(deleteItem);
  }

   useEffect(() => {
    let check = JSON.parse(localStorage.getItem('languageVal'));
    if(check)
    {
      setDataOfLanguage(languageVal)
    }
     
  }, [languageVal]);

   const [show, setShow] = useState(false);
   const [showUpdate, setShowUpdate] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => {
      setShowUpdate(false);
      setShow(true);
      setFormData({});
   }

   if (!isAuthenticated) {
        return <Redirect to="/login-register?role=pro&type=login" />;
    }

    if(typeof freelancerprofile === "string"){
      return <Redirect to="/expert-email-verify" />;
    }


  return (
    <section className="main-page page-dashboard">
       <SweetAlert
        warning
        showCancel
        show={alertBox}
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="secondary"
        cancelBtnCssClass="btn-cancel-popup btn-primary"
        title="Are you sure?"
        onConfirm={()=> confirmDelete()}
        onCancel={()=> setAlertBox(false)}
      >
        You will not be able to recover this data!
      </SweetAlert>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
             <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
              <div className="bg-white rounded shadow-sm sidebar-page-right">
                    <div className="p-5">
                              <Alert />
                              <h3 className="text-primary">Languages</h3>
                              <p className="font-weight-bold">04 of 10</p>

                              <div className="row form-group">
                                <div className="col-md-8">
                                  <h6 className="font-weight-bold mb-4 mt-4">Choose the language(s) you speak</h6>
                                </div>
                                <div className="col-md-4 text-right mt-4">
                                  <Button onClick={handleShow} className="btn btn-primary pull-right">{/** <Icon icon={wechat} />*/} Add Language</Button>
                                </div>
                              </div>

                              <Table responsive striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Language</th>
                                    <th>Proficiency</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {DataOfLanguage.length > 0 && DataOfLanguage.map((itemofLanguage,index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{itemofLanguage.language}</td>
                                    <td>{itemofLanguage.proficiency}</td>
                                    <td>
                                      
                                    <div className="d-flex align-items-center">
                                        <Icon icon={edit} onClick={() => updateLanguageFunc(itemofLanguage)} style={{ marginLeft: '10px' }} /> 
                                        <Icon icon={trash} onClick={() => deleteLanguageFunc(itemofLanguage._id)} style={{display: 'flex' }} />
                                        </div>
                                    </td>
                                    {/** <td><button onClick={() => updateLanguageFunc(itemofLanguage)} className="btn btn-info btn-sm"><Icon icon={edit} /></button> <button onClick={() => deleteLanguageFunc(itemofLanguage._id)} className="btn btn-danger btn-sm"><Icon icon={trashO} /></button></td>*/}
                                  </tr>
                                ))}
                               </tbody>
                              </Table>

                               <Modal show={show} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
                               <form onSubmit={(e) => onSubmit(e)}>
                              <Modal.Header>
                                <Modal.Title>Add Language</Modal.Title>
                                <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleClose} />
                              </Modal.Header>
                              <Modal.Body>
                              <Alert />
                                    <div className="row d-flex align-items-center form-group">
                                        <div className="col-md-12 col-12">
                                            <p className="text-muted font-weight-bold">Language <span className="required-field">*</span></p>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <select className="form-control"
                                            name="language"
                                            value={language}
                                            onChange={(e) => onChange(e)}
                                            required>
                                              <option value="">Select language</option>
                                              <option value="Arabic">Arabic</option>
                                              <option value="Bengali">Bengali</option>
                                              <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
                                              <option value="Czech">Czech</option>
                                              <option value="Dutch">Dutch</option>
                                              <option value="English">English</option>
                                              <option value="French">French</option>
                                              <option value="German">German</option>
                                              <option value="Greek">Greek</option>
                                              <option value="Gujarati">Gujarati</option>
                                              <option value="Hausa">Hausa</option>
                                              <option value="Hebrew">Hebrew</option>
                                              <option value="Hindi">Hindi</option>
                                              <option value="Hungarian">Hungarian</option>
                                              <option value="Indonesian">Indonesian</option>
                                              <option value="Italian">Italian</option>
                                              <option value="Japanese">Japanese</option>
                                              <option value="Javanese">Javanese</option>
                                              <option value="Kannada">Kannada</option>
                                              <option value="Korean">Korean</option>
                                              <option value="Malay">Malay</option>
                                              <option value="Malayalam">Malayalam</option>
                                              <option value="Mandarin Chinese">Mandarin Chinese</option>
                                              <option value="Marathi">Marathi</option>
                                              <option value="Nepali">Nepali</option>
                                              <option value="Oriya">Oriya</option>
                                              <option value="Pashto">Pashto</option>
                                              <option value="Persian">Persian</option>
                                              <option value="Polish">Polish</option>
                                              <option value="Portuguese">Portuguese</option>
                                              <option value="Punjabi">Punjabi</option>
                                              <option value="Romanian">Romanian</option>
                                              <option value="Russian">Russian</option>
                                              <option value="Sanskrit">Sanskrit</option>
                                              <option value="Serbo-Croatian">Serbo-Croatian</option>
                                              <option value="Sinhalese">Sinhalese</option>
                                              <option value="Somali">Somali</option>
                                              <option value="Spanish">Spanish</option>
                                              <option value="Swahili">Swahili</option>
                                              <option value="Swedish">Swedish</option>
                                              <option value="Tagalog">Tagalog</option>
                                              <option value="Tamil">Tamil</option>
                                              <option value="Telugu">Telugu</option>
                                              <option value="Thai">Thai</option>
                                              <option value="Turkish">Turkish</option>
                                              <option value="Ukrainian">Ukrainian</option>
                                              <option value="Urdu">Urdu</option>
                                              <option value="Vietnamese">Vietnamese</option>
                                              <option value="Yiddish">Yiddish</option>
                                              <option value="Yoruba">Yoruba</option>
                                              <option value="Zulu">Zulu</option>
                                              <option value="Abkhaz">Abkhaz</option>
                                              <option value="Afar">Afar</option>
                                              <option value="Afrikaans">Afrikaans</option>
                                              <option value="Akan">Akan</option>
                                              <option value="Albanian">Albanian</option>
                                              <option value="Amharic">Amharic</option>
                                              <option value="Armenian">Armenian</option>
                                              <option value="Assamese">Assamese</option>
                                              <option value="Aymara">Aymara</option>
                                              <option value="Azerbaijani">Azerbaijani</option>
                                              <option value="Bambara">Bambara</option>
                                              <option value="Belarusian">Belarusian</option>
                                              <option value="Bislama">Bislama</option>
                                              <option value="Bosnian">Bosnian</option>
                                              <option value="Breton">Breton</option>
                                              <option value="Bulgarian">Bulgarian</option>
                                              <option value="Burmese">Burmese</option>
                                              <option value="Catalan">Catalan</option>
                                              <option value="Chichewa">Chichewa</option>
                                              <option value="Chuvash">Chuvash</option>
                                              <option value="Cornish">Cornish</option>
                                              <option value="Corsican">Corsican</option>
                                              <option value="Cree">Cree</option>
                                              <option value="Croatian">Croatian</option>
                                              <option value="Danish">Danish</option>
                                              <option value="Divehi">Divehi</option>
                                              <option value="Dzongkha">Dzongkha</option>
                                              <option value="Esperanto">Esperanto</option>
                                              <option value="Estonian">Estonian</option>
                                              <option value="Ewe">Ewe</option>
                                              <option value="Faroese">Faroese</option>
                                              <option value="Fijian">Fijian</option>
                                              <option value="Finnish">Finnish</option>
                                              <option value="Frisian">Frisian</option>
                                              <option value="Galician">Galician</option>
                                              <option value="Georgian">Georgian</option>
                                              <option value="Gaelic (Scottish)">Gaelic (Scottish)</option>
                                              <option value="Gaelic (Irish)">Gaelic (Irish)</option>
                                              <option value="Ganda">Ganda</option>
                                              <option value="German (Swiss)">German (Swiss)</option>
                                              <option value="Greenlandic">Greenlandic</option>
                                              <option value="Guarani">Guarani</option>
                                              <option value="Haitian Creole">Haitian Creole</option>
                                              <option value="Hausa">Hausa</option>
                                              <option value="Hawaiian">Hawaiian</option>
                                              <option value="Herero">Herero</option>
                                              <option value="Hiri Motu">Hiri Motu</option>
                                              <option value="Icelandic">Icelandic</option>
                                              <option value="Igbo">Igbo</option>
                                              <option value="Iloko">Iloko</option>
                                              <option value="Inuktitut">Inuktitut</option>
                                              <option value="Interlingua">Interlingua</option>
                                              <option value="Interlingue">Interlingue</option>
                                              <option value="Inupiak">Inupiak</option>
                                              <option value="Irish">Irish</option>
                                              <option value="Italian (Swiss)">Italian (Swiss)</option>
                                              <option value="Javanese">Javanese</option>
                                              <option value="Kalaallisut">Kalaallisut</option>
                                              <option value="Kanuri">Kanuri</option>
                                              <option value="Kashmiri">Kashmiri</option>
                                              <option value="Kazakh">Kazakh</option>
                                              <option value="Kikuyu">Kikuyu</option>
                                              <option value="Kinyarwanda">Kinyarwanda</option>
                                              <option value="Kirghiz">Kirghiz</option>
                                              <option value="Komi">Komi</option>
                                              <option value="Kongo">Kongo</option>
                                              <option value="Kwanyama">Kwanyama</option>
                                              <option value="Laothian">Laothian</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 col-12 mt-4">
                                            <p className="text-muted font-weight-bold">Proficiency <span className="required-field">*</span></p>
                                        </div>
                                        <div className="col-md-12 col-12">
                                          <select className="form-control"
                                          name="proficiency"
                                            value={proficiency}
                                            onChange={(e) => onChange(e)} required>
                                              <option value="">Please select</option>
                                              <option value="Basic">Basic</option>
                                              <option value="Conversational">Conversational</option>
                                              <option value="Fluent">Fluent</option>
                                              <option value="Native or Bilingual">Native</option>
                                            </select>
                                            {(showUpdate == true) ?
                                            <input type="hidden" className="form-control"
                                              name="_id"
                                              value={_id}
                                              onChange={(e) => onChange(e)}
                                               /> : ""
                                              }
                                        </div>
                                        
                                    </div>

                                </Modal.Body>
                              <Modal.Footer>
                              <p className="text-right m-0">
                                    <button type="submit" className="btn btn-primary">
                                      {(showUpdate == true) ? "Update" : "Add"}
                                    </button>
                                    </p>
                              </Modal.Footer>
                              </form>
                            </Modal>

                             <div className="row justify-content-center complete_profile_buttons">
                                <div className="text-right">
                                  <Link to="/expertiselevel" className="btn btn-default mr-3">Back</Link>
                                  <Link to="/hourlyrate" className="btn btn-primary">Next</Link>
                                </div>
                              </div>
                            </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Language.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile,
});



export default connect(mapStateToProp, { setAlert, addLanguage, updateLanguage, deleteLanguage })(Language);