// BrandSlider.js
import React from 'react';
import styles from './main-slider.module.css';
import { ArrowDownward } from '@material-ui/icons';
import brix from "./brands/brix.svg"
import ninja from "./brands/Ninja.svg"
import funs from "./brands/funs.svg"
import ramotion from "./brands/ramotion.svg"
import refokus from "./brands/refokus.svg"
import Marquee from "react-fast-marquee";

import cism from "./brands/1.png"
import ceh from "./brands/2.png"
import cisa from "./brands/2.jpg"
import cysa from "./brands/3.png"
import oscp from "./brands/5.jpg"

const brands = [
    { id: 1, name: 'Brand 1', logo: cism},
    { id: 2, name: 'Brand 2', logo: ceh },
    { id: 3, name: 'Brand 3', logo: cisa},  
    { id: 3, name: 'Brand 3', logo: cysa},  
    { id: 3, name: 'Brand 3', logo: oscp },  
    { id: 1, name: 'Brand 1', logo: cism},
    { id: 2, name: 'Brand 2', logo: ceh },
    { id: 3, name: 'Brand 3', logo: cisa },  
    { id: 3, name: 'Brand 3', logo: cysa },  
    { id: 3, name: 'Brand 3', logo: oscp }, 
  // Add more brands as needed
];

const BrandSlider = () => {

    const extendedBrands = [...brands, ...brands, ...brands]; // Duplicating the brands

    const scrollToBottom = () => {
        window.scrollTo({
          top:  700,
          behavior: 'smooth' // You can change this to 'auto' or 'instant' for different scroll behavior
        });
      };
    

  return (
    <>
    <div className={styles.BrandSlider}>
      {/* <span className={styles.down} onClick={scrollToBottom}> <ArrowDownward  /> Scroll to bottom</span>   */}
      <div className="">
      <h3>Trusted & Qualified Experts with certifications in</h3>
      
      <div className={`${styles.brands}`}>
        {extendedBrands.map((brand, index) => (
        <div className={styles.brandItem}><img  src={brand.logo} /> </div>

        ))}
      </div>
      </div>
      <div className={styles.gradientOverlay}></div>

    </div>
    {/* <div className={styles.BrandSlider}>
    <Marquee direction="left" speed={50} gradient={true}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {extendedBrands.map((image, index) => (
        <div className={styles.brandItem}><img
          key={index}
          src={image.logo}
          alt={image.logo}
          style={{ marginRight: '20px' }}
        />
        </div>
      ))}
    </div>
  </Marquee>
  </div> */}
  </>
  );
};

export default BrandSlider;
