import { Link } from "@material-ui/core";
import { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import "./search.css";

export default function Search({ suggestions, setInput, handleSearch, pre_value, placeholder = '', icon = true }) {
  const [suggestionsList, setSuggestions] = useState([]);

  useEffect(() => {
    setInput(pre_value || '');
  }, [pre_value, setInput]);

  const getSuggestions = (inputValue) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    return suggestions.filter((suggestion) => {
      // Check if suggestion is a React element or a string
      const textContent = typeof suggestion === 'string'
        ? suggestion
        : suggestion.props.children[1];
      return textContent.toLowerCase().includes(inputValueLowerCase);
    });
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (_, { suggestion }) => {
    // Set the input based on the type of suggestion
    const value = typeof suggestion === 'string'
      ? suggestion
      : suggestion.props.children[1];
    setInput(value);
  };

  const getSuggestionValue = (suggestion) => {
    // Return the text part of the suggestion
    return typeof suggestion === 'string'
      ? suggestion
      : suggestion.props.children[1];
  };

  const inputProps = {
    placeholder: placeholder !== "" ? placeholder : "Search",
    value: pre_value || '', // Use pre_value directly
    onChange: (_, { newValue }) => setInput(newValue)
  };

  const renderSuggestion = (suggestion) => {
    // Render the suggestion directly
    return (
      <div className="d-flex justify-content-between align-items-center p-1">
        {suggestion}
      </div>
    );
  };

  return (
    <Form
      className="input-group search-engine w-100"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <Autosuggest
        containerProps={{
          className: "w-100"
        }}
        suggestions={suggestionsList}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          ...inputProps,
          className: "custom-placeholder"
        }}
        renderInputComponent={(inputProps) => (
          <InputGroup>
            <FormControl {...inputProps} />
            {icon && (
              <div className="input-group-prepend">
                <Link
                  to="#"
                  className="btn btn-primary text-light"
                  id="basic-addon1"
                  onClick={handleSearch}
                >
                  <i
                    className="fa fa-search fa-fw p-0"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            )}
          </InputGroup>
        )}
      />
    </Form>
  );
}
