import { formatDate } from "../../utils/date"

export const TransactionDate = ({ date }) => {
  return formatDate(date);
}

export const TransactionDetail = ({ type, jobName, from, to, toMe = false }) => {

  const transactionTypes = {
    C2C_PROFIT: `Service Fee for <span class="text-primary">${jobName}</span>.`,
    ADD_MONEY_TO_WALLET: `Top Up to Wallet`,
    CONTRACT_CREATED: `<span class="text-primary">${from}</span> Escrowed Funds for <span class="text-primary">${jobName}</span>.`,
    CONTRACT_REVOKED: `<span class="text-primary">${to}</span> Revoked contract for <span class="text-primary">${jobName}</span>.`,
    CONTRACT_DELETED: `<span class="text-primary">${to}</span> Deleted contract for <span class="text-primary">${jobName}</span>.`,
    JOB_SUCCESS: `Payment to <span class="text-success">${to}</span> from <span class="text-primary">${from}</span> for <span class="text-primary">${jobName}</span>.`,
    MARKETPLACE_FEE: `Marketplace Fee for <span class="text-primary">${jobName}</span>.`,
    CONTRACT_INITIATION_FEE: `Contract Initiated by <span class="text-primary">${from}</span> for <span class="text-primary">${jobName}</span>.`,
    MILESTONE_ADDED: `New Milestone added on <span class="text-primary">${jobName}</span> by <span class="text-primary">${from}</span>.`,
    REFUND_MONEY_FROM_WALLET: `Refund from Wallet`,
  }

  const content = transactionTypes[type] || `[${type}] ${jobName}`;

  return <p dangerouslySetInnerHTML={{ __html: content }}></p>

}

export const TransactionAmount = ({ amount, operator }) => {

  const content = `<span class=${operator === '+' ? "text-success" : "text-warning"}>${operator}$${amount.toFixed(2)}</span>`;

  return <p dangerouslySetInnerHTML={{ __html: content }}></p>;
}