import React, { useEffect } from 'react';
import { connect , useDispatch} from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { payment } from "../../actions/auth";
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const PayPalButton = ({ walletAmount,onTransactionComplete , email}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const setupPayPalButton = () => {
    window.paypal.Buttons({
      fundingSource: window.paypal.FUNDING.PAYPAL,
      async createOrder() {
        try {
          //const walletAmount = window.walletAmount;
          //const amount = localStorage.getItem('paypal_walletAmount');
          //const walletAmount = parseFloat(amount.replace('"', '')); // Remove double quotes and parse to a number
          
          const response = await fetch("/api/paypal/orders", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              
              cart: [
                {
                  id: "YOUR_PRODUCT_ID",
                  quantity: "YOUR_PRODUCT_QUANTITY",
                  //walletAmount: amount.walletAmount,
                  walletAmount: walletAmount,
                },
              ],
            }),
          });
  
          const orderData = await response.json();
  
          if (orderData.id) {
            return orderData.id;
          } else {
            const errorDetail = orderData?.details?.[0];
            const errorMessage = errorDetail
              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
              : JSON.stringify(orderData);
  
            throw new Error(errorMessage);
          }
        } catch (error) {
          console.error("masla",error);
        }
      },
      async onApprove(data, actions) {
        try {
          const response = await fetch(`/api/paypal/orders/${data.orderID}/capture`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          });
  
          const orderData = await response.json();
          const errorDetail = orderData?.details?.[0];
  
          if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
            return actions.restart();
          } else if (errorDetail) {
            throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
          } else if (!orderData.purchase_units) {
            throw new Error(JSON.stringify(orderData));
          } else {
            const transaction =
              orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
              orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

            //let body;
            //body = {
              //email: email,    //replace with the user email
              //text: "We're pleased to confirm that your payment has been successfully processed.",
            //}

            
            //dispatch(payment(body))
            onTransactionComplete("Payment completed successfully");
            
            if (localStorage.token) {
              setAuthToken(localStorage.token);
            }
      
            const config = {
              headers: {
                'Content-Type': 'Application/json'
              }
            }

            const data = JSON.stringify({
              id: orderData.id,
              amount: parseFloat(transaction?.amount?.value || 0),
              provider: 'paypal',
              email: email,
              transaction
            });
      
            await axios.post('/api/checkout/verify', data, config);

            //console.log("method called")
          }
        } catch (error) {
          console.error(error);
          onTransactionComplete("Payment failed");
        }
      },
    }).render("#paypal-button-container");
  };

    // Check if the PayPal SDK script is already loaded
    if (window.paypal) {
      setupPayPalButton();
    } else {
      // If the script is not loaded, create a script element to load it
      const paypalScript = document.createElement('script');
      //paypalScript.src = "https://www.paypal.com/sdk/js?client-id=AWB4VQkOj4Demyk655WOWYN6NzT1jEZrENInKlk14qhlum2UI8oxOdPcwCxNfaV9L2RXuXveFEVDit8P";  //TEST
      //paypalScript.src = "https://www.paypal.com/sdk/js?client-id=Ac0nqG7d6_L7DKRvwOp_woQqbji6e3x-xB9RJJn532wmcEKN9z7c6t9AV1jvAIs9o5LtmLqArCulwe_6"; //TEST

      paypalScript.src = "https://www.paypal.com/sdk/js?client-id=Ac0nqG7d6_L7DKRvwOp_woQqbji6e3x-xB9RJJn532wmcEKN9z7c6t9AV1jvAIs9o5LtmLqArCulwe_6";   //LIVE
      paypalScript.async = true;

      paypalScript.onload = () => {
        // Once PayPal SDK is loaded, set up the PayPal button
        setupPayPalButton();
      };
    
      document.body.appendChild(paypalScript);
    }
  }, [onTransactionComplete]);

  return (
    <div>
      <div id="paypal-button-container"></div>
      <p id="result-message"></p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PayPalButton);