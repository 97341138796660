import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useDispatch } from "react-redux";
import { releasePaymentAction } from "../../actions/proposalAndContract";
import { getWallet } from "../../actions/wallet";

import { removeFloatingPoint } from "../../utils/number";

export default function ReleasePayment({
  contractId,
  show,
  handleClose,
  contractType = "Fixed",
  walletAmount = 0,
  contractValue = 0,
  milestoneId = ''
}) {

  const dispatch = useDispatch();

  const [numOfHours, setNumOfHours] = useState(0);
  const [totalContractValue, setContractVal] = useState(contractValue);

  const [loading, setLoading] = useState(null);

  const handlePayment = async () => {
    setLoading(true);
    await dispatch(releasePaymentAction(contractId, numOfHours, milestoneId));
    dispatch(getWallet());
    setLoading(false);
    handleClose();
  }

  useEffect(() => {
    if (contractType === "Hourly") {
      setContractVal(numOfHours * contractValue);
    }
  }, [numOfHours, contractValue, contractType]);

  const marketplaceFee = removeFloatingPoint(totalContractValue * 0.05);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {contractType === "Hourly" ? (
          <>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Number of hours</Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  value={numOfHours}
                  onChange={(e) => {
                    const value = e.target.value;
                    //const max = Math.floor(walletAmount / contractValue);
                    //const min = 0;
                    // Use a regular expression to check for a valid non-negative number
                    
                    if (/^\d*$/.test(value) && value >= 0) {
                      setNumOfHours(value);
                    }
                    }}
                  max={Math.floor(walletAmount / contractValue)}
                />
              </Form.Group>
            </Form>
            <div>
              You currently have{" "}
              <span style={{ fontWeight: "bold" }}>${removeFloatingPoint(walletAmount)}</span> in your
              wallet. Upon proceeding with the payment, you will release{" "}
              <span style={{ fontWeight: "bold" }}>${removeFloatingPoint(totalContractValue)}</span> and <span style={{ fontWeight: "bold" }}>${marketplaceFee}</span> fee, leaving
              you with a remaining balance of{" "}
              <span style={{ fontWeight: "bold" }}>
                ${removeFloatingPoint(walletAmount - (totalContractValue + marketplaceFee))}
              </span>{" "}
              in your wallet.
            </div>
          </>
        ) : (
          <div>
            The escrowed amount will be sent to the expert.
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleClose}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          onClick={handlePayment}
          disabled={loading}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
