import React, { useState } from 'react';
import { Button, Offcanvas, Form } from 'react-bootstrap';
import { submitDispute as submitDisputeAction } from "../../actions/dispute";
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { close } from 'react-icons-kit/ikons/close';
import base64url from 'base64url';

const DisputeOffcanvas = ({ show, onHide, contractData, filteredProposals, submitDisputeProp, user }) => {
  const [disputeSubmitted, setDisputeSubmitted] = useState(false);
  const [disputeDetails, setDisputeDetails] = useState({
    reason: '',
    description: '',
    evidence: ''
  });


  const handleClose = () => onHide();

  // const handleDisputeChange = async (e) => {
  //   const { name, type } = e.target;
  //   const value = type === 'file' ? e.target.files : e.target.value;
  
  //   if (type === 'file') {
  //     const filesArray = Array.from(value);
  //     const evidenceFiles = await Promise.all(filesArray.map(async (file) => {
  //       const base64String = await getBase64(file);
  //       const compressedBase64 = base64url(base64String);
  //       return {
  //         file_name: file.name,
  //         base64: compressedBase64
  //       };
  //     }));
  
  //     setDisputeDetails(prevDetails => ({
  //       ...prevDetails,
  //       [name]: evidenceFiles
  //     }));
  //   } else {
  //     setDisputeDetails(prevDetails => ({
  //       ...prevDetails,
  //       [name]: value
  //     }));
  //   }
  // };
  
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };
  

  const handleDisputeChange = (e) => {
    const { name, type } = e.target;
    const value = type === 'file' ? e.target.files : e.target.value;
    setDisputeDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

//   const handleSubmitDispute = async (e) => {
//     e.preventDefault();
//     //console.log(disputeDetails);
//     //console.log(user);
//     //console.log(filteredProposals)

//     // const evidence = disputeDetails.evidence
//     // ? [{
//     //     file_name: "evidence file",
//     //   }]
//     // : [];
// debugger

//     const evidenceFiles = disputeDetails.evidence 
//     const proposalDetails = (filteredProposals.length > 0 ? filteredProposals[0] : {}).proposal_id;
//     //console.log(proposalDetails)

//     const success = await submitDisputeProp({
//       ...disputeDetails,
//       job_id: contractData.job_id,
//       user_id: user._id,
//       evidence: evidenceFiles,
//       status: 'Pending',
//       proposalDetails, 
//     });

//     if (success) {
//       setDisputeSubmitted(true);
//     } else {
//       console.log("Error in dispute submission")
//     }
//   };

  const handleSubmitDispute = async (e) => {
    e.preventDefault();
    debugger
    const formData = new FormData();
    // formData.append('job_id', contractData.job_id);
    formData.append('user_id', user._id);
    formData.append('status', 'Pending');

    // Append dispute details
    for (const [key, value] of Object.entries(disputeDetails)) {
      if (key !== 'evidence') {  // Skip evidence for now, handle it separately
        formData.append(key, value);
      }
    }

    // Append proposal details
    const proposalDetails = filteredProposals.length > 0 ? filteredProposals[0].proposal_id : null;
    if (proposalDetails) {
      formData.append('proposalDetails', proposalDetails);
    }

    // Append evidence files inside key 'evidence'
    if (disputeDetails.evidence) {
      Array.from(disputeDetails.evidence).forEach((file) => {
        formData.append('evidence', file);
      });
    }


    const success = await submitDisputeProp({
      
      job_id: contractData.job_id,
      user_id: user._id,
      reason: disputeDetails.reason,
      description: disputeDetails.description,
      evidence: disputeDetails.evidence,
      status: 'Pending',
      proposalDetails: proposalDetails
    });
    
        if (success) {
          setDisputeSubmitted(true);
        } else {
          console.log("Error in dispute submission")
        }
  };

  return (
    <>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>Dispute Section</Offcanvas.Title>
          <button className='btn btn-sm' style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }} onClick={handleClose}>
            <Icon size={30} icon={close} />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!disputeSubmitted ? (
            <Form onSubmit={handleSubmitDispute}>
              <Form.Group className="mb-3" controlId="disputeReason">
                <Form.Label>Dispute Reason</Form.Label>
                <Form.Control
                  type="text"
                  name="reason"
                  value={disputeDetails.reason}
                  onChange={handleDisputeChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="disputeDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  rows={3}
                  value={disputeDetails.description}
                  onChange={handleDisputeChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="disputeEvidence">
                <Form.Label>Evidence (optional)</Form.Label>
                <Form.Control
                  type="file"
                  name="evidence"
                  multiple
                  onChange={handleDisputeChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit Dispute
              </Button>
            </Form>
          ) : (
            <div>
              <h5>Dispute Status</h5>
              <p>Your dispute has been submitted and is currently under review.</p>
              {/* Display more details about the dispute status here */}
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  submitDisputeProp: (disputeDetails) => dispatch(submitDisputeAction(disputeDetails)),
});

export default connect(null, mapDispatchToProps)(DisputeOffcanvas);