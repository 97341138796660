import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import Alert from "../../components/alert/Alert";
import { Icon } from "react-icons-kit";
import { filter } from "react-icons-kit/fa/filter";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-bootstrap";
import {
  postJobGetAll,
  deleteJob,
  complexityGetbyId,
  complexityGet,
  expectedDurationGetbyId,
  paymentTypeGetbyId,
  skillsGetbyId,
  jobQuery
} from "../../actions/postJob";
import { skillsGet } from "../../actions/clientProfile";
import { freelancerStatusGet } from "../../actions/freelancerProfile";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { Country } from "country-state-city";
import { Input2 } from "../../components/input/input";
import { SVGLocation } from "../../components/SVG";
import moment from "moment";
import Search from "./search";
import Skeleton from "./../../components/skeleton";
import { remove } from "react-icons-kit/fa/remove";
import SearchIcon from "../../img/search.svg";
import MapIcon from "../../img/map-pin.svg";
import FilterIcon from "../../img/filter-square.svg";
import LocationIcon from "../../img/location-icon.svg";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";
import Flag from 'react-world-flags';
import { getCode } from 'country-list';

const Job = ({
  clientProfile: { skills },
  freelancerProfile: {
    freelancerStatus,
    freelancerprofile,
    isfreelancerActionable,
  },
  auth: { isAuthenticated, user },
  postJob: { postJobDataAll, applyforjob, jobDetailData, complexityLevel, jobCount},
  postJobGetAll,
  skillsGet,
  complexityGet,
  jobQuery
}) => {
  let history = useHistory();
  const [filteredJobData, setFilteredJobData] = useState([]);

  //const storedValue = JSON.parse(localStorage.getItem('search'));
  const homepage_query = useLocation();
  const searchParams = new URLSearchParams(homepage_query.search);
  const storedValue = searchParams.get("value");


  const [queryMode, setQueryMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryLocation, setSearchQueryLocation] = useState('');

  const searchInitiated = useRef(false); // Use ref to track if search has been initiated

  const [postJobData1, setPostJobData] = useState(null);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [selectedjob, setSelectedJob] = useState(null);
  const [showjob, setShowJob] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleClosejob = () => {
    setShowJob(false);
  };



  const [showpage, setShowPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);

  
  //console.log(storedValue)
  
  useEffect(() => { 
  if(!storedValue){
    postJobGetAll(currentPage , jobsPerPage);
  }  
  }, []);
  
  useEffect(() => {
    if (storedValue && !searchInitiated.current) {

      handleSearch(storedValue);
      searchInitiated.current = true; 

    } else if (postJobDataAll !== null) {
      const mappedJobs = postJobDataAll
        .map((item) => ({
          headline: item.headline,
          location: item.location,
          description: item.description,
          payment: item?.payment_amount,
          paymentType: item?.payment_type_id?.type_name,
          manager_name: item?.hire_manager_id?.first_name,
          jobProgress: item.job_progress,
          skills: [item.main_skill_id],
          jobposttime: item.job_post_time,
          jobExpiry: item?.job_expiry,
          _id: item._id,
          
        }))
        .reverse();

      setPostJobData(mappedJobs);
      setOriginalJobSearchResults(mappedJobs);
      //setCurrentPage(1);
    }
  }, [postJobDataAll, storedValue]);


  /*useEffect(() => {
    if(queryMode || storedValue){
      const formattedSearchQuery = searchQuery.toLowerCase();
      jobQuery(formattedSearchQuery, currentPage, jobsPerPage);

      if (searchQueryLocation !== "") {
        jobQuery(searchQueryLocation, currentPage, jobsPerPage);
    }
    if(storedValue && !searchInitiated.current){
      searchInitiated.current = true; 
    }
    }
    /*else if (storedValue && !searchInitiated.current) {
      console.log("test-2")
      const formattedSearchQuery = searchQuery.toLowerCase();
      jobQuery(formattedSearchQuery, currentPage, jobsPerPage);
      searchInitiated.current = true; 
    }
    else{
      postJobGetAll(newPage, jobsPerPage);
    }
  }, [jobsPerPage]);*/
  
  
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
 
  //console.log(postJobData1)
  
  let currentJobs = postJobData1

  currentJobs = currentJobs ? currentJobs : null;

  const totalPages = Math.ceil(jobCount / jobsPerPage);

  const handlePageChange = (newPage) => {

    setCurrentPage(newPage);
    console.log(newPage)

    const currentQuery = searchQuery.toLowerCase();
    
    if(currentQuery){
      jobQuery(currentQuery ,locationInput ,newPage, jobsPerPage);
    }
    else{
      postJobGetAll(newPage , jobsPerPage);
    }
  };


  useLayoutEffect(() => {
    //postJobGetAll(currentPage, jobsPerPage);
    skillsGet();
    complexityGet();
    if (storedValue) {
      setValue(storedValue);
    }
  }, []);

  
  useEffect(() => {
    document.title = "Find Work";
  }, []);

  useEffect(() => {
  }, [currentJobs]);

  const handleSortChange = (event) => {
    const sortValue = event.target.value;
    let sortedArray = [...postJobData1];

    if (sortValue === "highestPrice") {
      sortedArray.sort((a, b) => b.payment - a.payment);
    } else if (sortValue === "lowestPrice") {
      sortedArray.sort((a, b) => a.payment - b.payment);
    } else {
      // Handle other sorting criteria or default sorting
    }

    setPostJobData(sortedArray);
  };

  //console.log(currentJobs);

  const MAX_LENGTH = 150;

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const [formData, setFormData] = useState({
    priceRangeLower: "0",
    priceRangeGreator: "10",
    projectType: "Fixed",
    location: "",
    complexity: "all",
    skill: "all",
  });

  const onChangeVal = (e) => {
    const { name, value } = e.target;

    // Check if the input value is a valid number
    if (value && isNaN(Number(value))) {
      // If not, set the error message
      setError("Price range can only be in numbers");
    } else {
      // If it's a valid number, clear the error message
      setError("");
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeVal2 = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const applyForJob = async (e) => {
    applyforjob.id = e;
    history.push("/applyforjob");
  };

  const [state, setstate] = useState({
    value: { min: 10, max: 1000 },
  });

  const [value, setValue] = useState("");
  const [locationInput, setLocationInput] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showJobDetail, setShowJobDetail] = useState(false);

  const [filterOpen, setFilterOpen] = useState(false);
  const filterRef = useRef(null);
  useOutsideAlerter(filterRef, () => setFilterOpen(false));

  function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick();
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onOutsideClick]);
  }

  useEffect(() => {
    if (storedValue) {
      setValue(storedValue);
      localStorage.removeItem("search");
    }
  }, []);



  /*useEffect(() => {
    if (storedValue) {
      handleSearch(storedValue);
    } else {
      if (postJobDataAll !== null) {
        const mappedJobs = postJobDataAll
          .map((item) => ({
            headline: item.headline,
            location: item.location,
            description: item.description,
            payment: item?.payment_amount,
            paymentType: item?.payment_type_id?.type_name,
            manager_name: item?.hire_manager_id?.first_name,
            jobProgress: item.job_progress,
            skills: [item.main_skill_id],
            jobposttime: item.job_post_time,
            jobExpiry: item?.job_expiry,
            _id: item._id,
          }))
          .reverse();

        setPostJobData(mappedJobs);
        // Also initialize originalJobSearchResults with the full list
        setOriginalJobSearchResults(mappedJobs);
      }
    }
  }, [postJobDataAll, storedValue]);*/


  


  const countries = Country.getAllCountries();


  useEffect(() => {
    if (user.type == "freelancer" && freelancerprofile.verification == true) {
      //console.log(freelancerprofile.verification);
      setShowPage(true);
    }
  }, []);

  const [originalJobSearchResults, setOriginalJobSearchResults] = useState([]);

  const handleSearch = (search) => {
    setQueryMode(true);
    const formattedSearchQuery = search.toLowerCase();

    try {
    if (locationInput !== "") {
        setSearchQueryLocation(locationInput);
        jobQuery(formattedSearchQuery, locationInput, currentPage, jobsPerPage);
    }else{
      setSearchQuery(formattedSearchQuery);

      jobQuery(formattedSearchQuery, locationInput,  currentPage, jobsPerPage);
    }

    if(locationInput !== "" &&  search!== ""){

      jobQuery(formattedSearchQuery, locationInput,  currentPage, jobsPerPage);
    }


      /*const formattedSearchQuery = search.toLowerCase();
      var filteredList = postJobDataAll.filter(
        (job) =>
          job.headline.toLowerCase().includes(formattedSearchQuery) ||
          job.description.toLowerCase().includes(formattedSearchQuery) ||
          (job.location &&
            job.location.toLowerCase().includes(formattedSearchQuery)) ||
          (job.payment_amount &&
            job.payment_amount.toString().includes(formattedSearchQuery)) /*||
          (job.main_skill_id.skill_name &&
            job.main_skill_id.skill_name
              .toLowerCase()
              .includes(formattedSearchQuery))
      );

      if (locationInput !== "") {
        filteredList = filteredList.filter(
          (job) =>
            job.location &&
            job.location.toLowerCase().includes(locationInput.toLowerCase())
        );
      }

      const mappedResults = filteredList
        .map((item) => ({
          headline: item?.headline,
          location: item?.location,
          description: item?.description,
          payment: item?.payment_amount,
          paymentType: item?.payment_type_id?.type_name,
          manager_name: item?.hire_manager_id?.first_name,
          jobProgress: item?.job_progress,
          skills: [item?.main_skill_id],
          jobposttime: item?.job_post_time,
          jobExpiry: item?.job_expiry,
          _id: item?._id,
        }))
        .reverse();

      // Update postJobData for display
      if (mappedResults.length > 0) {
        setPostJobData(mappedResults);
      } else {
        setPostJobData([]);
      }

      // Store the original search results for future filtering
      setOriginalJobSearchResults(mappedResults);

      setCurrentPage(1);*/
      setCurrentPage(1);
    } catch (err) {
      console.log(err);
    }
  };

  const clearFilter = () => {
    // Reset the form data to their default state
    setFormData({
      priceRangeLower: "0",
      priceRangeGreater: "10",
      projectType: "Fixed",
      location: "",
      complexity: "all",
      skill: "all",
      status: "all",
    });

    // Reset the job data to the original search results or full job list
    setPostJobData(originalJobSearchResults);

    setCurrentPage(1);
  };

  /*const clearSearch = () => {
    setFormData({
      priceRangeLower: "0",
      priceRangeGreator: "10",
      projectType: "Fixed",
      location: "",
      complexity: "all",
      skill: "all",
    });

    setPostJobData(
      postJobDataAll?.map((item) => ({
        headline: item.headline,
        location: item.location,
        description: item.description,
        payment: item.payment_amount,
        paymentType: item.payment_type_id.type_name,
        manager_name: item.hire_manager_id.first_name,
        jobProgress: item.job_progress,
        skills: [item.main_skill_id],
        jobposttime: item.job_post_time,
        jobExpiry: item?.job_expiry,
        _id: item._id,
      }))
    );
  };*/

  const searchSort = (search) => {
    if (search === "") {
      setPostJobData(
        postJobDataAll?.map((item) => ({
          headline: item.headline,
          location: item.location,
          description: item.description,
          payment: item?.payment_amount,
          paymentType: item?.payment_type_id?.type_name,
          manager_name: item?.hire_manager_id?.first_name,
          jobProgress: item.job_progress,
          skills: [item.main_skill_id],
          jobposttime: item.job_post_time,
          jobExpiry: item?.job_expiry,
          _id: item._id,
        }))
      );
      return;
    }
    const filteredList = postJobDataAll.slice().sort((jobOne, jobTwo) => {
      if (search === "alphabetic") {
        //console.log("alphas");
        return jobOne.headline
          .toLowerCase()
          .localeCompare(jobTwo.headline.toLowerCase());
      } else {
        return jobTwo.headline
          .toLowerCase()
          .localeCompare(jobOne.headline.toLowerCase());
      }
    });

    //console.log(value);

    // update the postJobDataAll with the filtered list
    //setFilteredJobData(filteredList);
    setPostJobData(filteredList);

    if (filteredList.length > 0) {
      setPostJobData(
        filteredList.map((item) => ({
          headline: item.headline,
          location: item.location,
          description: item.description,
          payment: item.payment_amount,
          paymentType: item.payment_type_id.type_name,
          manager_name: item.hire_manager_id.first_name,
          jobProgress: item.job_progress,
          skills: [item.main_skill_id],
          jobposttime: item.job_post_time,
          jobExpiry: item?.job_expiry,
          _id: item._id,
        }))
      );
    }

    //console.log(postJobData1);
    //console.log(filteredJobData);

    setCurrentPage(1);
  };

  /*const filtersearch = () => {
    //console.log("filtered")
    const {
      priceRangeLower,
      priceRangeGreator,
      projectType,
      location,
      complexity,
      skill,
    } = formData;

    const filteredList = originalJobSearchResults.filter((job) => {
      const paymentWithinRange =
        job.payment >= parseFloat(priceRangeLower) &&
        job.payment <= parseFloat(priceRangeGreator);
      const matchesProjectType =
        projectType.toLowerCase() === "all" ||
        job.paymentType.toLowerCase().includes(projectType.toLowerCase());
      const matchesLocation =
        !location ||
        job.location.toLowerCase().includes(location.toLowerCase());
      const matchesComplexity =
        complexity === "all" ||
        (job.complexity &&
          job.complexity.toLowerCase() === complexity.toLowerCase());
      const matchesSkill =
        skill === "all" ||
        (job.skills &&
          job.skills[0]?.toLowerCase().includes(skill.toLowerCase())); // Assuming job.skills is an array

      return (
        paymentWithinRange &&
        matchesProjectType &&
        matchesLocation &&
        matchesComplexity &&
        matchesSkill
      );
    });

    // Directly use the filtered list to setPostJobData without remapping if the structure is already correct
    setPostJobData(filteredList);

    setCurrentPage(1);
  };
  */

  const filtersearch = () => {
    // Destructure formData
    const {
      priceRangeLower,
      priceRangeGreator,
      projectType,
      location,
      complexity,
      skill,
      status, // Assuming you have a status field in your formData
    } = formData;
  
    
    /*console.log(priceRangeLower)
    console.log(priceRangeGreator)
    console.log(projectType)
    console.log(location)
    console.log(complexity)
    console.log(skill)
    console.log(status)*/

    
    // Filter the jobs
    const filteredList = originalJobSearchResults.filter((job) => {
      // Check if the job is expired
      if (job.job_expiry) {
        return false;
      }
  
      // Check payment range
      const paymentWithinRange =
        job.payment >= parseFloat(priceRangeLower) &&
        job.payment <= parseFloat(priceRangeGreator);
  
      // Check project type
      const matchesProjectType =
        projectType.toLowerCase() === "all" ||
        job.paymentType.toLowerCase().includes(projectType.toLowerCase());
  
      // Check location
      /*const matchesLocation =
        !location ||
        job.location.toLowerCase().includes(location.toLowerCase());*/
  
      // Check complexity
      const matchesComplexity =
        complexity === "all" ||
        (job.complexity &&
          job.complexity.toLowerCase() === complexity.toLowerCase());
  
      const matchesSkill =
          skill === "all" ||
          (job.skills &&
            job.skills.some(s => typeof s === 'string' && s.toLowerCase().includes(skill.toLowerCase()))); // Assuming job.skills is an array
  
      // Check status
      const matchesStatus =
        status === "all" ||
        (job.jobProgress &&
          job.jobProgress.toLowerCase() === status.toLowerCase());
  
      // Return true if all conditions are met
      return (
        paymentWithinRange &&
        matchesProjectType &&
        matchesComplexity &&
        matchesSkill &&
        matchesStatus
      );
    });
  
    setPostJobData(filteredList);

    if (filteredList.length > 0) {
      setPostJobData(
        filteredList.map((item) => ({
          headline: item.headline,
          location: item.location,
          description: item.description,
          payment: item.payment_amount,
          paymentType: item.payment_type_id.type_name,
          manager_name: item.hire_manager_id.first_name,
          jobProgress: item.job_progress,
          skills: [item.main_skill_id],
          jobposttime: item.job_post_time,
          jobExpiry: item?.job_expiry,
          _id: item._id,
        }))
      );
    }


    setCurrentPage(1);
  };
  

  const handleFilterAndClose = () => {
    filtersearch();
    setShow(false); // Close the modal
    setFilterOpen(false); // Close the filter toggle menu
  };

  const toggleFilter = () => {
    if (filterOpen) {
      setFilterOpen(false);
    } else {
      setFilterOpen(true);
    }
  };

  const openJobDetails = async (e) => {
    jobDetailData._id = e._id;
    jobDetailData.paymentType = e.paymentType;
    jobDetailData.payment = e.payment;
    jobDetailData.headline = e.headline;
    jobDetailData.location = e.location;
    jobDetailData.description = e.description;
    jobDetailData.manager_name = e.manager_name;
    jobDetailData.skill = e.skills[0];
    skillsGetbyId(jobDetailData.skill);
    setShowJobDetail(true);
  };

  const handleCloseJobDetail = () => setShowJobDetail(false);

  //console.log(postJobData1);
  /*
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }*/

  const clearSearchAndCallHandleSearch = () => {
    setValue("");
    setLocationInput("");
    handleSearch("");
    searchSort("");
    //postJobDataAll = []
    postJobGetAll(currentPage, jobsPerPage);
    setQueryMode(false);

  };

  if (user.type === "Freelancer" && !isfreelancerActionable) {
    return (
      <div className="account-review">
        <h5 className="text-primary text-center mb-0">
          Please enter your profile information in dashboard to continue 
        </h5>
      </div>
    );
  } else {
    return (
      <section className="main-page page-dashboard page-talent">
        <div className="container-fluid">
          <Alert />
          <section className="bg-white rounded shadow-sm p-1 mb-3">
            <div className="row advanced-search talent-search">
              <div className="col-md-5">
                <img src={SearchIcon} alt="search" />
                <Search
                  suggestions={
                    skills.length > 0
                      ? skills.map((item) => item.skill_name)
                      : []
                  }
                  setInput={setValue}
                  handleSearch={() => handleSearch(value)}
                  pre_value={value}
                  icon={false}
                />
              </div>
              <div className="col-md-5">
                <img src={MapIcon} alt="search" />
                <Search
                  // suggestions={countries.map((item) => item.name)}
                  suggestions={countries.map((item) => (
                    <div className="flex items-center">
                      <Flag code={getCode(item.name) || 'UN'} className='pr-2' style={{width: "35px"}} />
                      {item.name}
                    </div>
                  ))}
                  setInput={setLocationInput}
                  handleSearch={() => handleSearch(value)}
                  pre_value={locationInput}
                  placeholder={"Your Location"}
                  icon={false}
                />
              </div>
              <div className="col-md-2 pl-0 text-nowrap text-right">
                <Button onClick={() => handleSearch(value)}>Find Job</Button>
              </div>
            </div>

           {/**  <button
              className="Clear"
              onClick={clearSearchAndCallHandleSearch}
              style={{
                all: "unset",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Icon className="mx-2 icon-hover" icon={remove}></Icon>
              <span
                style={{
                  fontSize: "14px",
                  paddingTop: "3.4px",
                  fontWeight: "bold",
                  paddingLeft: "0",
                }}
              >
                Clear Search
              </span>
            </button>
            */}

            {value !== ""  || locationInput !== ""?
          <button
            className="Clear "
            onClick={clearSearchAndCallHandleSearch}
            style={{
              all: "unset",
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <Icon className="mx-2 icon-hover" icon={remove}></Icon>
            <span
              style={{
                fontSize: "14px",
                paddingTop: "3.4px",
                fontWeight: "bold",
                paddingLeft: "0",
              }}
            >
              Clear Search
            </span>
          </button> : null }
 
            <div className="suggestion-talent">
              <div className="title">Suggestions:</div>
              <div className="suggestions-list">
                Network Security, Cyber Risk Management, Malware Protection,
                Data Security, Penetration Testing
              </div>
            </div>
          </section>
          <div className="talent-page-header">
            <div className="title">
              All Searches (
              <strong>{jobCount ? jobCount: 0}</strong> Jobs
              found)
            </div>
            <div className="block wm-100 jcm-space">
              <div className="block sort-block">
                <div>Sort by:</div>
                <select onChange={handleSortChange}>
                  <option value="highestPrice">Highest Price</option>
                  <option value="lowestPrice">Lowest Price</option>
                </select>
              </div>

              <div className="block">
                <div>Filter:</div>
                <div className="filter-block" ref={filterRef}>
                  <div
                    className={`toggle ${filterOpen ? "active" : ""}`}
                    onClick={toggleFilter}
                  >
                    <img src={FilterIcon} alt="filter-icon" />
                  </div>
                  <div
                    className={`dropdown-box ${filterOpen ? "show" : ""}`}
                    ref={filterRef}
                  >
                    <div className="text-right">
                      <div
                        className="text-primary cursor-pointer"
                        onClick={clearFilter}
                      >
                        Clear Filters
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-left">
                          <label className="form-label font14">Price Range</label>
                          <div className="mt-2 filter-price-search font14">
                            <div>
                              <Input2
                                labelFor={`Price Range`}
                                placeholder={`0`}
                                required={true}
                                type={"text"}
                                name={"priceRangeLower"}
                                value={formData.priceRangeLower}
                                handlerOnChange={(e) => onChangeVal(e)}
                              />
                            </div>
                            <div>
                              <Input2
                                labelFor={`Price Range`}
                                placeholder={`10`}
                                required={true}
                                type={"text"}
                                name={"priceRangeGreator"}
                                value={formData.priceRangeGreator}
                                handlerOnChange={(e) => onChangeVal(e)}
                              />
                            </div>
                            {error && (
                              <div style={{ color: "red" }}>{error}</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-2 text-left py-2 ">
                        <label className="form-label font14">Project Type</label>
                        <ul className="options-list mt-2 font14">
                          <li>
                            <input
                              name="projectType"
                              value="Hourly"
                              onChange={(e) => onChangeVal2(e)}
                              type="radio"
                              checked={formData.projectType == "Hourly"}
                            />{" "}
                            Hourly
                          </li>
                          <li>
                            <input
                              name="projectType"
                              value="Fixed"
                              onChange={(e) => onChangeVal2(e)}
                              type="radio"
                              checked={formData.projectType == "Fixed"}
                            />{" "}
                            Fixed
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12 mb-2">
                        <div className="text-left">
                          <label className="form-label font14">Complexity</label>
                          <div className="mt-2 filter-price-search font14">
                            <div className="w-100">
                              <select
                                name="complexity"
                                value={formData.complexity}
                                className="form-control"
                                onChange={onChangeVal2}
                              >
                                <option value="all">All</option>
                                {complexityLevel?.length > 0
                                  ? complexityLevel.map((c) => (
                                      <option
                                        value={c.complexity}
                                        key={c.complexity}
                                      >
                                        {c.complexity}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                            {error && (
                              <div style={{ color: "red" }}>{error}</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <div className="text-left">
                          <label className="form-label font14">Skill</label>
                          <div className="mt-2 filter-price-search font14">
                            <div>
                              <select
                                name="skill"
                                value={formData.skill}
                                className="form-control"
                                onChange={onChangeVal2}
                              >
                                <option value="all">All</option>
                                {skills?.length > 0
                                  ? skills.map((s) => (
                                      <option
                                        value={s.skill_name}
                                        key={s.skill_name}
                                      >
                                        {s.skill_name}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                            {error && (
                              <div style={{ color: "red" }}>{error}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                    <div className="text-left">
                      <label className="form-label font14">Status</label>
                      <div className="mt-2 filter-price-search font14">
                        <div style={{width:'100%'}}>
                          <select
                            name="status"
                            value={formData.status}
                            className="form-control"
                            onChange={onChangeVal2}
                          >
                            <option value="all">All</option>
                            <option value="Not Started">Open</option>
                            <option value="In Progress">In Progress</option>
                            {/* Add more status options as needed */}
                          </select>
                        </div>
                        {error && <div style={{ color: "red" }}>{error}</div>}
                      </div>
                    </div>
                  </div>
                    </div>
                   
                    <div className="text-right">
                      <Button
                        variant="secondary"
                        onClick={handleFilterAndClose}
                        disabled={error}
                      >
                        Apply Filter
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="talent-list">
            {postJobData1 === null ? (
              [0, 1, 2, 3, 4].map((i) => (
                <div className="bg-white rounded shadow-sm">
                  <div
                    key={i}
                    className="border-bottom p-4 posted-jobs talent-block"
                  >
                    <div className="pt-1">
                      <div className="row">
                        <div className="col-md-10">
                          <Skeleton width={180} height={20} />
                          <div className="mb-2">
                            <Skeleton width={150} height={17} />
                          </div>
                          <div>
                            <div className="mb-2">
                              <Skeleton width={170} height={17} />
                            </div>
                            <Skeleton height={15} count={3} />
                            <Skeleton width={100} height={15} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <Modal
                  show={showJobDetail}
                  size="xl"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={handleCloseJobDetail}
                  //className={showJobDetail ? 'xl' : ''}
                >
                  <Modal.Header className="justify-content-end">
                    <Icon
                      className="mx-2 icon-hover"
                      icon={remove}
                      onClick={handleCloseJobDetail}
                    />
                  </Modal.Header>
                  <Modal.Body className="talent-modal">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 job-detail-modal">
                          <div className="title">
                            Job Detail - <span>{jobDetailData.headline}</span>
                          </div>
                          {jobDetailData && (
                            <>
                              {/* <h6 className="text-primary">Skills</h6>
                            <p className="text-muted text-justify">
                              {skills?.skill_name}
                            </p> */}
                              <h6 className="text-primary">Description</h6>
                              <p className="text-muted text-justify">
                                {jobDetailData?.description}
                              </p>
                              <h6 className="text-primary">Budget Type</h6>
                              <p className="text-muted">
                                {jobDetailData?.paymentType}
                              </p>
                              <h6 className="text-primary">Budget Amount</h6>
                              <p className="text-muted">
                                {jobDetailData?.payment}
                              </p>
                              <h6 className="text-primary">Posted By</h6>
                              <p className="text-muted">
                                {jobDetailData?.manager_name}
                              </p>
                              <h6 className="text-primary">Location</h6>
                              <p className="text-muted">
                                {jobDetailData?.location}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      class="btn btn-outline-dark"
                      onClick={handleCloseJobDetail}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
                {currentJobs.length > 0 ? (
                  currentJobs.length > 0 &&
                  [...currentJobs].map((jobDataItem, index) => (
                    <>
                      <div
                        className="border-bottom py-4 px-5 posted-jobs talent-block"
                        key={index}
                      >
                        <div className="row">
                          <div className="col-md-9 mt-0">
                            <span
                              onClick={() => openJobDetails(jobDataItem)}
                              className="cursorClass"
                            >
                              <h4 className="h4 mt-0 mb-2">
                                {jobDataItem.headline}
                              </h4>
                              <div className="mb-2 location-text">
                                <strong className="location-text-Bold">Posted By</strong>
                                {"  "}
                                {jobDataItem.manager_name}
                              </div>
                              <div className="text-gray">
                                <div className="mb-2 list-detail-items location-text">
                                  <ul>
                                    <li>
                                      <strong>Budget</strong> $
                                      {jobDataItem.payment}{" "}
                                      {jobDataItem.paymentType}
                                    </li>
                                    <li>
                                      <strong>Posted</strong>{" "}
                                      {moment
                                        .utc(jobDataItem.jobposttime)
                                        .format("DD/MM/YYYY")}
                                    </li>
                                  </ul>
                                </div>
                                {jobDataItem?.description?.length >
                                MAX_LENGTH ? (
                                  <p className="description mb-3">
                                    {`${jobDataItem.description.substring(
                                      0,
                                      MAX_LENGTH
                                    )}`}{" "}
                                    ....
                                  </p>
                                ) : (
                                  <p className="mb-3">
                                    {jobDataItem.description}
                                  </p>
                                )}
                                {jobDataItem.location && (
                                  <>
                                    <strong>
                                      <SVGLocation />
                                    </strong>{" "}
                                    <span className="text-muted">
                                      {jobDataItem.location}
                                    </span>
                                  </>
                                )}
                              </div>
                            </span>
                          </div>

                          <div
                            className={
                              windowWidth <= 767
                                ? "col-md-3  mt-0"
                                : "col-md-3 mt-0 text-right"
                            }
                          >
                            <div className="mt-0 mb-3">
                              {jobDataItem.jobExpiry ? (
                                <p className=" text-nowrap">
                                  <span style={{ color: "#000" }}>
                                    Job status:
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#e31818",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Expired
                                  </span>
                                </p>
                              ) : jobDataItem.jobProgress === "Not Started" ? (
                                <p className=" text-nowrap location-text">
                                  <span style={{ color: "#000" }}>
                                    Job Status:
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#008000",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Open
                                  </span>
                                </p>
                              ) : jobDataItem.jobProgress === "In Progress" ? (
                                <p className=" text-nowrap location-text">
                                  <span style={{ color: "#000" }}>
                                    Job Status:
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#23adf7",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Granted
                                  </span>
                                </p>
                              ) : jobDataItem.jobProgress === "Completed" ? (
                                <p className=" text-nowrap location-text">
                                  <span style={{ color: "#000" }}>
                                    Job Status:
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#23adf7",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Done
                                  </span>
                                </p>
                              ) : null}
                            </div>

                            <button
                              onClick={() => {
                                applyForJob(jobDataItem._id);
                              }}
                              className="btn btn-primary btn-sn"
                              disabled={jobDataItem.jobExpiry}
                            >
                              <span className="location-text-Bold" style={{fontSize:"12px"}}>Apply for Job</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <div className="p-1">
                    {/*}<h6 className="text-primary text-center mb-0">
                      No Search Result Found
                </h6>*/}
                    <div className="p-1">
                      <div className="message-not-available w-100">
                        <div className="text-center">
                          <img
                            src="assets/images/no-data-found.png"
                            width={"100px"}
                            alt="message"
                            className="mb-4"
                          />
                          <h1 className='location-text-Bold' style={{fontSize:"30px"}} >Sorry! No Record Found</h1>
                          <p className="land location-text">
                          Try adjusting your search to find what you are looking for.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {/*postJobDataAll.length == 0 ?
        <div className="mt-5 p-5">
          <h6 className="text-primary text-center mb-0">No Jobs available</h6>
        </div>
              : ""*/}
          </section>
        </div>
        <br />
        {/* Display the navigation buttons */}

        {totalPages > 1 && (
          <nav>
            <ul className="talent-pagination pagination justify-content-center">
              <div
                className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() =>
                  handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                }
              >
                {/** <Icon icon={arrowLeft} />*/}
                <PaginationArrowLeft/>
              </div>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
              <div
                className={`icon ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                onClick={() =>
                  handlePageChange(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
              >
                {/** <Icon icon={arrowRight} />*/}
                <PaginationArrowRight/>
              </div>
            </ul>
          </nav>
        )}
      </section>
    );
  }
};

const mapStateToProp = (state) => ({
  auth: state.auth,
  clientProfile: state.clientProfile,
  postJob: state.postJob,
  freelancerProfile: state.freelancerProfile,
});

export default connect(mapStateToProp, {
  setAlert,
  postJobGetAll,
  deleteJob,
  complexityGetbyId,
  expectedDurationGetbyId,
  paymentTypeGetbyId,
  skillsGetbyId,
  skillsGet,
  complexityGet,
  jobQuery
})(Job);
