import React, { useState , useEffect} from 'react';
import { connect , useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from 'react-icons-kit';
import {facebookSquare} from 'react-icons-kit/fa/facebookSquare';
import {linkedinSquare} from 'react-icons-kit/fa/linkedinSquare';
import {mapMarker} from 'react-icons-kit/fa/mapMarker';
import {userO} from 'react-icons-kit/fa/userO';
import {clockO} from 'react-icons-kit/fa/clockO';
import {paperPlane} from 'react-icons-kit/fa/paperPlane';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactStars from "react-rating-stars-component";
import {Helmet} from "react-helmet";
import { setAlert } from "../../actions/alert";
import Flag from 'react-world-flags';
import { getUserStats } from "../../actions/proposalAndContract";
import { getCode } from 'country-list';

import Skeleton from "./../../components/skeleton";
import { Detector } from "react-detect-offline";

const Profile = ({ freelancerProfile : { expertise, expertLevel, employment, certification, languageVal, education, hourlyRate, Location, TitleAndOverview, profileAndPhoto, phoneNumber , freelancerprofile, reviews} , auth: { isAuthenticated, user }, ContractSummary, getUserStats, userStats , setAlert}) => {
    
    //const dispatch = useDispatch();

    //console.log(reviews);

    if (expertise.skills == "")
    {
        //console.log("in");
        expertise = JSON.parse(localStorage.getItem('expertise'));
        expertLevel = JSON.parse(localStorage.getItem('expertLevel'));
        employment = JSON.parse(localStorage.getItem('employment'));
        certification = JSON.parse(localStorage.getItem('certification'));
        languageVal = JSON.parse(localStorage.getItem('languageVal'));
        education = JSON.parse(localStorage.getItem('education'));
        hourlyRate = JSON.parse(localStorage.getItem('hourlyRate'));
        Location = JSON.parse(localStorage.getItem('location'));
        TitleAndOverview = JSON.parse(localStorage.getItem('titleAndOverview'));
        profileAndPhoto = JSON.parse(localStorage.getItem('profilePhoto'));
        phoneNumber = JSON.parse(localStorage.getItem('phoneNumber'));
        user = JSON.parse(localStorage.getItem('user'));
        reviews = JSON.parse(localStorage.getItem('reviews'));

    }
    else
    {
        //console.log("out");
        localStorage.setItem('expertise', JSON.stringify(expertise));
        localStorage.setItem('expertLevel', JSON.stringify(expertLevel));
        localStorage.setItem('employment', JSON.stringify(employment));
        localStorage.setItem('certification', JSON.stringify(certification));
        localStorage.setItem('languageVal', JSON.stringify(languageVal));
        localStorage.setItem('education', JSON.stringify(education));
        localStorage.setItem('hourlyRate', JSON.stringify(hourlyRate));
        localStorage.setItem('location', JSON.stringify(Location));
        localStorage.setItem('titleAndOverview', JSON.stringify(TitleAndOverview)); 
        localStorage.setItem('profilePhoto', JSON.stringify(profileAndPhoto)); 
        localStorage.setItem('phoneNumber', JSON.stringify(phoneNumber));
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('reviews', JSON.stringify(reviews));
       
    }



    const [ratingText , setRatingText] = useState({
        ratingTextData: "",
      });

    
    //getFreelancer();
    //console.log(freelancerprofile);

    
    

    const {ratingTextData} = ratingText;

    const [show, setShow] = useState(false);

    const [showGetAQuote, setShowGetAQuote] = useState(false);

    const [showReview, setShowReview] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseGetAQuote = () => setShowGetAQuote(false);
    const handleShowGetAQuote = () => setShowGetAQuote(true);

    const handleCloseReview = () => {
        setShowReview(false);
        setFeedbackText('');
    };
    const handleShowReview = () => setShowReview(true);

    const ratingChanged = (newRating) => {
      setRatingText(newRating);
    };


    // Define state variables to store the feedback text and rating values
    const [feedbackText, setFeedbackText] = useState('');
    const [satisfactionRating, setSatisfactionRating] = useState(0);
    const [projectDeliveryRating, setProjectDeliveryRating] = useState(0);


    const handleProjectDeliveryRatingChange = (newRating) => {
        setProjectDeliveryRating(newRating);
        //console.log(newRating)
    };

    // Update the callback functions to set the state variables with the rating values
    const handleSatisfactionRatingChange = (newRating) => {
        setSatisfactionRating(newRating);
        //console.log(newRating)
        //console.log(feedbackText)
    };

    const [pageLoaded, setPageLoaded] = useState(false);


    useEffect(() => {
        setPageLoaded(true);
      }, []);

    useEffect(() => {
        getUserStats(); 
    }, [pageLoaded,getUserStats]);


    const profileUrl = `${window.location.origin}${window.location.pathname}/${hourlyRate.freelancer_id}`;

    const copyToClipboard = (url) => {
      navigator.clipboard.writeText(url).then(() => {

        setAlert('URL copied to clipboard!', "success");

      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    };
    

    
    const calculatePercentage = (total, max) => {
        return Math.round(total / max) * 100;
    }

    return (
        <section className="main-page page-dashboard">
        <Helmet>
            <title>Profile</title>
        </Helmet>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 left">
                    <div className="profile_info bg-white rounded shadow-sm">
                        <div className="seller-card">
                            <div className="clearfix">
                                <Detector
                                    render={({ online }) => (
                                        <div className="user-online-indicator is-online"
                                            style={{ color: online ? "#008000" : "#e31818" }}>
                                            <i className="fa fa-circle"></i> {online ? "online" : "offline"}
                                        </div>
                                    )}
                                />
                                <Link to="#" className="ambassadors-badge"
                                    style={{ backgroundColor: user?.type ? "#40ADDB" : "transparent" }}>
                                     {!user?.type ? (
                                        <Skeleton width={100} height={25} />
                                    ) : user.type === "Freelancer" ? (
                                        "Expert"
                                    ) : (
                                        user.type
                                    )}
                                </Link>
                            </div>
                            <div className="user-profile-info">
                                <div>
                                    <div className="user-profile-image">
                                        <label className="user-pict rounded-circle">
                                            {/*<img
                                                src="assets/images/BilalAhmedProfile.jpg"
                                                className="img-fluid user-pict-img" alt="Cyber2Cyber" />*/}
                                            <Link to="#" className="user-badge user-badge-round user-badge-round-med locale-en-us top-rated-seller">
                                           
                                                {
                                                    profileAndPhoto?.photo_link ?
                                                        <img src={profileAndPhoto.photo_link} alt="Profile picture" />
                                                    :
                                                    <h3 className="text-uppercase profile-avatar">{user.first_name.slice(0,1)}{user.last_name.slice(0,1)}</h3>
                                                }  
                                            </Link>
                                    </label>
                                    </div>
                                </div>
                                <div className="user-profile-label">
                                    <div className="username-line">
                                        <Link to="#" className="seller-link">{user.first_name} {user.last_name}</Link>
                                        {freelancerprofile.verification ==true ?  <img src="assets\images\checkmark.png" className="img-fluid user-pict-img" alt="Cyber2Cyber" title="Cyber to Cyber Verified Expert" style={{width: "5%", height: "4%", position: "relative", top: "-3px", left: "3px"}} /> : console.log("check false")  }
                                    </div>
                                    <div className="oneliner-wrapper">
                                        <small className="oneliner">{TitleAndOverview.title}</small>
                                        <div className="ratings-wrapper">
                                            {/*<p className="rating-text"><strong>5.0</strong> (1k+ reviews)</p>*/}
                                            <p className="rating-text"><strong>${hourlyRate.hourly_rate}</strong>/hr</p>
                                        </div>
                                    </div>
                                </div>
                                <section className="p-4">
                                    <div className="border-bottom pb-4">
                                        {/*user.type === 'Freelancer' && (
                                            <>
                                                {userStats && userStats.length > 0 ? (
                                                    <>
                                                        <div className="d-flex align-items-center py-1">
                                                            <span className="stats-title">Delivered on Time</span>
                                                            <div className="font-weight-bold ml-auto">
                                                                <span className="stats-value">
                                                                    {calculatePercentage(userStats[0].satisfaction_rating, userStats[0].count * 5)}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center py-1">
                                                            <span className="stats-title">Order Completion</span>
                                                            <div className="font-weight-bold ml-auto">
                                                                <span className="stats-value">
                                                                    {calculatePercentage(userStats[0].delivery_rating, userStats[0].count * 5)}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Skeleton width={100} height={18} />
                                                )}
                                            </>       
                                                )*/}
                                        {userStats ? (
                                            userStats.length > 0 ? (
                                                <>
                                                    <div className="d-flex align-items-center py-1">
                                                        <span className="stats-title">Delivered on Time</span>
                                                        <div className="font-weight-bold ml-auto">
                                                            <span className="stats-value">
                                                                {calculatePercentage(userStats[0].satisfaction_rating, userStats[0].count * 5)}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center py-1">
                                                        <span className="stats-title">Order Completion</span>
                                                        <div className="font-weight-bold ml-auto">
                                                            <span className="stats-value">
                                                                {calculatePercentage(userStats[0].delivery_rating, userStats[0].count * 5)}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="d-flex align-items-center py-1">
                                                    <span className="stats-title">Delivered on Time</span>
                                                    <div className="font-weight-bold ml-auto">
                                                        <span className="stats-value">N/A</span>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <Skeleton width={100} height={18} />
                                        )}
        
                                    </div>
                                    <div className="pt-3">
                                        {/*ContractSummary && ContractSummary.length > 0 ? (
                                            <div className="d-flex align-items-center py-1 h6">
                                                <span className="stats-title">Total Earnings</span>
                                                <div className="font-weight-bold ml-auto">
                                                    <span className="stats-value">
                                                        ${ContractSummary[0].total.toLocaleString()}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <Skeleton width={100} height={18} />
                                        )*/}
                                        {ContractSummary ? (
                                            ContractSummary.length > 0 ? (
                                                <div className="d-flex align-items-center py-1 h6">
                                                    <span className="stats-title">Total Earnings</span>
                                                    <div className="font-weight-bold ml-auto">
                                                        <span className="stats-value">
                                                            ${ContractSummary[0].total.toLocaleString()}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center py-1 h6">
                                                    <span className="stats-title">Total Earnings</span>
                                                    <div className="font-weight-bold ml-auto">
                                                        <span className="stats-value">N/A</span>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <Skeleton width={100} height={18} />
                                        )}
                                    </div>
                                    <hr />
                                </section>
                                <div className="bg-white my-2 w-100 pl-3 pr-4">
                                    <button onClick={() => copyToClipboard(profileUrl)} className="btn btn-primary ml-0 w-100">
                                        Copy profile URL
                                    </button>
                                    </div>
                            </div>
             
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 right">
                    <div className="seller-profile">
                        <div className="description">
                        <div className="text-right"> 
                        <Link to="/personaldetails" className="btn btn-primary">Edit Profile</Link>
                        <hr />
                        </div>
                        <h3>Description</h3>
                            <ul className="mt-2 pl-0">
                                <li className="interactive-bullet">{TitleAndOverview.professional_overview ?? 'No Record Found'}</li>
                            </ul>
                        </div>
                        <div className="languages">
                            <h3 className="mt-3">Languages</h3>
                            <ul className="mt-2 pl-0">
                            {languageVal.length > 0 ? languageVal.map((itemoflanguage,index) => (
                                <li className="interactive-bullet" key={index}>
                                   
                                    <p> {itemoflanguage.language}&nbsp;&nbsp;- <span>{itemoflanguage.proficiency}</span></p>
                                </li>
                                )) : 
                                <li className="interactive-bullet">
                                    No Record Found
                                </li>
                            }
                            </ul>
                        </div>
                        {/*<div className="linked-accounts">
                            <h3>Linked Accounts</h3>
                            <ul>
                                <li><Icon className="mr-2" icon={facebookSquare} /><span className="text">Facebook</span></li>
                                <li><Icon className="mr-2" icon={linkedinSquare} /><span className="text">LinkedIn</span></li>
                            </ul>
                        </div>*/}
                        <div className="skills">
                            <h3>Main Service You Offer</h3>
                            <ul className="mt-2 pl-0">
                            <li className="interactive-bullet">
                         
                            <p>{expertise.category ? expertise.category : "No Record Found"}</p>
                            </li>
                            </ul>
                        </div>
                        <div className="skills">
                            <h3>Skills</h3>
                            <ul className="mt-2 pl-0">
                                <li className="interactive-bullet">{expertise.skills ? expertise.skills :"No Record Found"}</li>
                                {/*<li className=""><Link to="">voice acting</Link></li>
                                <li className=""><Link to="">voiceover</Link></li>
                                <li className=""><Link to="">voice over</Link></li>
                                <li className=""><Link to="">voiceover talent</Link></li>
                                <li className=""><Link to="">voice actor</Link></li>
                                <li className=""><Link to="">voicetalent</Link></li>
                                <li className=""><Link to="">voiceacting</Link></li>
                                <li className=""><Link to="">voiceactor</Link></li>
                                <li className=""><Link to="">voiceover artist</Link></li>*/}
                            </ul>
                        </div>
                        <div className="education-list list">
                            <h3>Education</h3>
                            <ul className="mt-2 pl-0">
                            {education.length > 0 ? education.map((itemOfEducation, index) => (
                                <li key={index} className="interactive-bullet">
                                    <p>{itemOfEducation.degree} | {itemOfEducation.provider}, Graduated {itemOfEducation.to ? itemOfEducation.to.split('-')[0] : ''}</p>
                                   {/* |   <p></p> */}
                                </li>
                            )) :
                            <li className="interactive-bullet">
                                No Record Found
                            </li>}

                            </ul>
                        </div>

                        <div className="education-list list">
                            <h3>Certificate</h3>
                            <ul className="mt-2 pl-0">
                            {certification.length > 0 ? certification.map((itemofcertification,index) => (
                                <li className="interactive-bullet" key={index}> 
                                    <p>{itemofcertification.certification_name} | {itemofcertification.provider}</p>
                                    {/* <p>{itemofcertification.provider}</p> */}
                                </li>
                                )) :
                                <li className="interactive-bullet">
                                    No Record Found
                                </li>}
                            </ul>
                        </div>
                        <div className="education-list list">
                            <h3>Employment</h3>
                            <ul className="mt-2 pl-0">
                                {employment.length > 0 ? employment.map((itemofemployment, index) => (
                                    <li className="interactive-bullet" key={index}>
                                        <p>
                                            {itemofemployment.company_name} 
                                            ( <strong>
                                                {itemofemployment.from.split('T')[0]} - 
                                                {itemofemployment.current ? "Still working" : itemofemployment.to.split('T')[0]}
                                            </strong> )
                                        </p>
                                        <p>{itemofemployment.title}</p>
                                        <p>{itemofemployment.city}, {itemofemployment.country}</p>
                                    </li>
                                )) :
                                <li className="interactive-bullet">No Record Found</li>}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row mt-0">
                <div className="col-12 mt-0">
                    <section className='bg-white rounded shadow-sm sidebar-page-right' style={{minHeight: "10vh"}}>
                        <header className="box-title px-4 py-3">
                            <div className="review-section">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <h4 className="m-0">Reviews as Seller 
                                        <small>
                                            <span className="star-rating-s15"></span>
                                            <span><span className="total-rating-out-five header-average-rating" data-impression-collected="true"></span></span>
                                            <span><span className="total-rating header-total-rating" data-impression-collected="true"></span></span>
                                        </small>
                                    </h4>
                                    <select className="custom-select custom-select-sm border-0 shadow-sm ml-2">
                                        <option>Most Relevant</option>
                                        <option>Most Recent</option>
                                    </select>
                                </div>
                                {/** 
                                <div className="breakdown">
                                    <ul className="header-stars">
                                        <li>
                                            Seller communication level
                                            <small>
                                                <span className="star-rating-s15"></span>
                                                <span className="total-rating-out-five">5</span>
                                            </small>
                                        </li>
                                        <li>
                                            Recommend to a friend
                                            <small>
                                                <span className="star-rating-s15"></span>
                                                <span className="total-rating-out-five">5</span>
                                            </small>
                                        </li>
                                        <li>
                                            Service as described
                                            <small>
                                                <span className="star-rating-s15"></span>
                                                <span className="total-rating-out-five">5</span>
                                            </small>
                                        </li>
                                    </ul>
                                </div>*/}
                            </div>
                            <div className="review-list">
                                <ul>
                                    {reviews.length > 0 ? reviews.map((review, index) => (
                                        <li key={index}>
                                            <div className="d-flex">
                                                <div className="left">
                                                    <span>
                                                        <img
                                                            src="assets/images/user/s8.png"
                                                            className="profile-pict-img img-fluid" alt="" />
                                                    </span>
                                                </div>
                                                <div className="right">
                                                    <h4>
                                                        {review?.hire_manager_id?.user_name}
                                                        <span className="gig-rating text-body-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15"
                                                                height="15">
                                                                <path fill="currentColor"
                                                                    d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                                                                </path>
                                                            </svg>
                                                            {((review?.satisfaction_rating + review?.delivery_rating) / 2).toFixed(1)}
                                                        </span>
                                                    </h4>
                                                    <div className="country d-flex align-items-end">
                                                        <span>
                                                            {/* <img className="country-flag img-fluid" src="assets/images/flag/flag.png" alt="flag" /> */}
                                                            <Flag code={getCode(review?.hire_manager?.location) || 'UN'} className='pr-2' style={{width: "35px"}} />
                                                        </span>
                                                        <div className="country-name font-accent ">
                                                            {review?.hire_manager?.location || '-'}
                                                        </div>
                                                    </div>
                                                    <div className="review-description">
                                                        <p>{review?.message}</p>
                                                    </div>
                                                    <span className="publish py-3 d-inline-block w-100">Publish date {new Date(review?.createdAt)?.toLocaleDateString('en-GB')}</span>
                                                </div>
                                            </div>
                                        </li>
                                    )) : (
                                        <li>
                                            No Record Found
                                        </li>
                                    )}
                                </ul>
                            </div>      
                        </header>
                    </section>
                </div>
            </div>
        </div> 
    </section>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    freelancerProfile: state.freelancerProfile,
    userStats: state.proposalAndContract.userStats,
    ContractSummary: state.proposalAndContract.ContractSummary
  });
  
export default connect(mapStateToProps, { getUserStats , setAlert})(Profile);