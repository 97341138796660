import axios from 'axios';
import { setAlert } from './alert';
import {
	DISPUTES_GET_SUCCESS,
	DISPUTES_GET_FAIL
} from './types';
import setAuthToken from '../utils/setAuthToken';


//create dispute
// export const submitDispute = ({
//     job_id,
//     user_id,
//     reason,
//     description,
//     evidence, 
//     status,
//     proposalDetails
// }) => async dispatch => {
//     const config = {
//         headers: {
//             'Content-Type': 'Application/json'
//         }
//     };

//     const body = JSON.stringify({
//         job_id,
//         user_id,
//         reason,
//         description,
//         evidence,
//         status,
//         proposalDetails
//     });

//     try {
//         const res = await axios.post('/api/disputes', body, config);
		
//         if (!res.data.success) {
//             return false;
//         } else {
//             dispatch({
//                 type: 'DISPUTE_SUBMITTED',
//                 payload: res.data
//             });
//             return true;
//         }
//     } catch (err) {
// 		console.error(err); 
// 		if (err.response && err.response.data.errors) {
// 			const errors = err.response.data.errors;
// 			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
// 		}
// 	}
// };

export const submitDispute = ({
    job_id,
    user_id,
    reason,
    description,
    evidence, 
    status,
    proposalDetails
}) => async dispatch => {
    const formData = new FormData();
    const jobId = job_id._id; // Extract the ObjectId from the job object
formData.append('job_id', jobId); // Append the ObjectId// Convert job_id to JSON string
// formData.append('job_id',  JSON.stringify(job_id)); // Convert job_id to JSON string

    formData.append('user_id', user_id);
    formData.append('reason', reason);
    formData.append('description', description);
    formData.append('status', status);
    formData.append('proposalDetails', proposalDetails);

    if (evidence) {
        const filesArray = Array.from(evidence);
        filesArray.forEach((file, index) => {
            formData.append('evidence', file); // Use 'evidence[]' to send as an array
        });
    }
    try {
        const res = await axios.post('/api/disputes', formData);

        if (!res.data.success) {
            return false;
        } else {
            dispatch({
                type: 'DISPUTE_SUBMITTED',
                payload: res.data
            });
            return true;
        }
    } catch (err) {
        console.error(err);
        if (err.response && err.response.data.errors) {
            const errors = err.response.data.errors;
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        return false;
    }
};

// Get All Disputes
export const getAllDisputes = (setLoading) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
    setLoading(true)

	try {
		const res = await axios.get('/api/disputes/');

		dispatch({
			type: DISPUTES_GET_SUCCESS,
			payload: res.data
		});
    setLoading(false)


	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
		}
    setLoading(false)


		dispatch({
			type: DISPUTES_GET_FAIL
		});
	}
}

export const disputeupdate = (disputeid) => async dispatch => {
	try {
		
		const dispute = await axios.patch('/api/disputes/disputeupdate/' + disputeid);

		dispatch(setAlert(dispute.data?.msg || 'Success!', 'success'));

	} catch (error) {
		const errMessage = error?.response?.data?.msg ?? error.message;
		dispatch(setAlert(errMessage, 'danger'));
	}
}