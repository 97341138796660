import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, NavLink } from "react-router-dom";
import { budget_status } from "../../actions/enterprise";
import {
  contractDelete,
  contractGet,
  contractVerify,
  contractGetbyId,
  contractGetSummary,
  getUserStats,
  revokeContract,
} from "../../actions/proposalAndContract";
import { hireManagerGet } from "../../actions/clientProfile";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { edit } from "react-icons-kit/fa/edit";
import { uploadFile } from "../../actions/file-crud";
import Alert from "../../components/alert/Alert";
import { setAlert } from "../../actions/alert";
import {
  ProfileAndPhotoGet,
  freelancerIsCompleted,
  getFreelancer,
  getFreelancerProfilePercentage,
  updateFreelancer,
} from "../../actions/freelancerProfile";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { Bars } from "react-loader-spinner";
import Skeleton from "./../../components/skeleton";
import { Detector } from "react-detect-offline";
import { Helmet } from "react-helmet";
import { SVGFooterLogo, SVGSocial } from "../../components/SVG";
import ProgressBar from "react-bootstrap/ProgressBar";
import { remove } from "react-icons-kit/fa/remove";

import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";

import UserConfiguration from "./UserConfiguration";

import { Twitter, Linkedin } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";

const Dashboard = ({
  auth,
  auth: { isAuthenticated, user },
  proposalAndContract: {
    ContractsItemData,
    ContractData,
    ContractSummary,
    userStats,
  },
  clientProfile: { hireManager },
  freelancerProfile: {
    freelancerprofile,
    profileAndPhoto,
    certification,
    Location,
    phoneNumber,
    hourlyRate,
    TitleAndOverview,
    languageVal,
    employment,
    education,
    expertLevel,
    expertise,
    pageNumber,
    profileCompletedPercentage,
  },
  freelancerCompleted: { isProfileCompleted },
  contractGet,
  contractVerify,
  contractDelete,
  contractGetbyId,
  contractGetSummary,
  getUserStats,
}) => {
  const history = useHistory();
  const [deleteItem, setDeleteItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertBox, setAlertBox] = useState(false);

  const [showRejectedModal, setShowRejectedModal] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [paginatedDataItem, setPaginatedDataItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const totalPages = Math.ceil(ContractsItemData?.length / resultsPerPage);

  const expertPercentage = profileCompletedPercentage;

  
  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
  };

  const [showMilestoneDetail, setShowMilestoneDetail] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [showPay, setShowPay] = useState(true);

  // Handler to open the MilestoneDetails modal
  const handleOpenMilestoneDetail = (milestone) => {
    setSelectedMilestone(milestone);
    setShowMilestoneDetail(true);
  };

  // Handler to close the MilestoneDetails modal
  const handleCloseMilestoneDetail = () => {
    setSelectedMilestone(null);
    setShowMilestoneDetail(false);
  };

  const dispatch = useDispatch();

  const handleVerify = (id, status) => {
    let data = {
      contract_id: id,
      status: status,
      user_name: user.user_name,
      first_name: user.first_name,
      last_name: user.last_name
    };
    contractVerify(data);
  };

  const handleContract = (id) => {
    contractGetbyId(id)
      .then((res) => {
        if (res) {
          if (ContractData) {
            //console.log(ContractData);
            history.push("/contractDetails");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        //yahan error handle karo
      });
  };

  useEffect(() => {
    dispatch(getFreelancer());
  }, []);


  useEffect(() => {
    dispatch(budget_status(user.email)).then((res)=>{
      console.log(res)
      if(res === 'Paid'){
        setShowPay(false);
      }   
});
  }, []);


  useEffect(() => {
    if (user?.type == "Freelancer") {
      dispatch(getFreelancerProfilePercentage());
    }
  }, [hireManager, freelancerprofile]);

  useEffect(() => {
    if (expertPercentage === 100 && user?.type === "Freelancer") {
      const shareDialogue = localStorage.getItem(
        `shareProfileCompletionDialogue-${user?._id}`
      );
      if (!shareDialogue) {
        handleOpenNewModal();
        localStorage.setItem(
          `shareProfileCompletionDialogue-${user?._id}`,
          true
        );
      }
    } else if (user?.type === "Client") {
      const shareDialogue = localStorage.getItem(
        `shareProfileCompletionDialogue-${user?._id}`
      );
      if (!shareDialogue) {
        handleOpenNewModal();
        localStorage.setItem(
          `shareProfileCompletionDialogue-${user?._id}`,
          true
        );
      }
    }
  }, [hireManager, freelancerprofile]);

  const handleFileChange = async (e) => {
    //console.log(e.target.files[0]);

    if (
      e.target.files[0].name.toLowerCase().endsWith(".png") ||
      e.target.files[0].name.toLowerCase().endsWith(".jpg") ||
      e.target.files[0].name.toLowerCase().endsWith(".jpeg")
    ) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      Promise.all([setLoading(true), dispatch(uploadFile(formData))]).then(
        async (res) => {
          //console.log(res);
          const config = {
            headers: {
              "Content-Type": "Application/json",
            },
          };
          const body = JSON.stringify({
            photo_link: res[1].location,
          });
          await axios.post("/api/profilePhoto", body, config).then((res) => {
            setLoading(false);
            dispatch(ProfileAndPhotoGet());
          });
        }
      );
    } else {
      setAlert("File is not a PNG, JPG, or JPEG", "danger", 3000);
      //console.log('File is not a PNG, JPG, or JPEG');
    }
  };

  const deleteContractFunc = async (e) => {
    setDeleteItem(e);
    setAlertBox(true);
  };

  const confirmDelete = () => {
    setAlertBox(false);
    dispatch(revokeContract(deleteItem));
  };

  const calculatePercentage = (total, max) => {
    return Math.round(total / max) * 100;
  };

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (ContractsItemData) {
      setPaginatedDataItem([...ContractsItemData.reverse()]);
    }
  }, [ContractsItemData]);

  useEffect(() => {
    if (!auth.token && !isAuthenticated) {
      history.push("/login-register?role=client&type=login");
    }
    contractGet();
    contractGetSummary();
    getUserStats();
    if (user?.type == "Client") {
      dispatch(hireManagerGet());
    }
    if (user?.type == "Freelancer") {
      dispatch(freelancerIsCompleted());
      // if(typeof freelancerprofile !== "string"){
      //   dispatch(updateFreelancer());
      // }
    }
  }, [pageLoaded, isAuthenticated]);

  useEffect(() => {
    if (freelancerprofile) {
      if (freelancerprofile?.empty !== true) {
        dispatch(updateFreelancer());
      }
      // console.log(freelancerprofile?.empty);
    }
  }, [isAuthenticated, user, isProfileCompleted]);

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = paginatedDataItem
    ? [...paginatedDataItem].slice(startIndex, endIndex)
    : null;

  // State to control the visibility of the new modal
  const [showNewModal, setShowNewModal] = useState(false);

  // Handler to open the new modal
  const handleOpenNewModal = () => {
    setShowNewModal(true);
  };

  // Handler to close the new modal
  const handleCloseNewModal = () => {
    setShowNewModal(false);
  };

  // Function to handle Twitter sharing based on user type
  const shareOnTwitter = () => {
    let text;
    if (user?.type === "Freelancer") {
      text = encodeURIComponent(
        "I'm thrilled to offer my services on CybertoCyber, an emerging platform where individuals, businesses, & professionals work together to address #cybersecurity problems. Join me in this innovative marketplace, & let's make the world secure. #infosec"
      );
    } else if (user.type === "Client") {
      text = encodeURIComponent(
        "Elevate your online safety with #CybertoCyber! Dive into a platform connecting you with top-notch cybersecurity professionals. Let's fortify your digital defences and embrace a secure future! Explore www.cybertocyber.com"
      );
    }
    const url = encodeURIComponent("https://www.cybertocyber.com/");
    window.open(
      `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
      "_blank"
    );
  };

  // Function to handle LinkedIn sharing based on user type
  const shareOnLinkedIn = () => {
    let text;
    if (user?.type === "Freelancer") {
      text = encodeURIComponent(
        "I'm thrilled to offer my services on CybertoCyber, an emerging platform where individuals, businesses, & professionals work together to address cybersecurity problems. Join me in this innovative marketplace, & let's make the world secure. #infosec #Cybersecurity #ProfessionalNetwork #ExploreOpportunities #TechInnovation"
      );
    } else if (user.type === "Client") {
      text = encodeURIComponent(
        "Elevate the security of your cyberspace with CybertoCyber! Exciting news! I'm diving into CybertoCyber, a cutting-edge platform connecting businesses with top-tier cybersecurity professionals. Together, let's fortify our digital defenses and embrace a secure future! Are you ready to revolutionize cybersecurity for a safer world?"
      );
    }
    const encodedText = encodeURIComponent(text);
    const url = encodeURIComponent("https://www.cybertocyber.com/");
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}&mini=true&title=${encodedText}&summary=${encodedText}&source=CybertoCyber`;

    window.open(linkedInUrl, "_blank");
  };


  useEffect(() => {
    //console.log("Status-1",freelancerprofile?.verification_status)
    if (freelancerprofile?.verification_status === "Rejected") {
      setShowRejectedModal(true);
  }
  }, [freelancerprofile]);

  
  const handleCloseModal = () => {
     setShowRejectedModal(false);
};

const route_guide = localStorage.getItem("comingfromTalent");
if(route_guide === "true" && user.type === "Client"){
  localStorage.removeItem("comingfromTalent");
  history.push("/Talent")
}else{
  localStorage.removeItem("comingfromTalent");
}

const resubmit = () => {
  // Close the modal
  history.push("/personaldetails");

};

  return (
    
    <React.Fragment>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <SweetAlert
        warning
        showCancel
        show={alertBox}
        confirmBtnText="Yes, revoke it!"
        confirmBtnBsStyle="danger"
        cancelBtnCssClass="btn-cancel-popup btn-primary"
        title="Are you sure?"
        onConfirm={() => confirmDelete()}
        onCancel={() => setAlertBox(false)}
      >
        You will not be able to recover this!
      </SweetAlert>
      <div className="main-page page-dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 left">
              <div className="profile_info bg-white rounded shadow-sm">
                <div className="seller-card">
                  <div className="clearfix">
                    <Detector
                      render={({ online }) => (
                        <div
                          className="user-online-indicator is-online"
                          style={{
                            borderColor: !user?.type
                              ? "transparent"
                              : "inherit",
                            color: online ? "#008000" : "#e31818",
                          }}
                          data-user-id="1152855"
                        >
                          {!user?.type ? (
                            <Skeleton width={70} height={25} />
                          ) : (
                            <>
                              <i className="fa fa-circle"></i>
                              {online ? "online" : "offline"}
                            </>
                          )}
                        </div>
                      )}
                    />
                    <Link
                      to="#"
                      className="ambassadors-badge"
                      style={{
                        backgroundColor: !user?.type
                          ? "transparent"
                          : "#40ADDB",
                      }}
                    >
                      {!user?.type ? (
                        <Skeleton width={100} height={25} />
                      ) : user.type === "Freelancer" ? (
                        "Expert"
                      ) : (
                        user.type
                      )}
                    </Link>
                  </div>
                  <div className="user-profile-info">
                    <div className="user-profile-image">
                      <label className="user-pict rounded-circle">
                        <Link to="" className="user-badge">
                          {!user?.first_name ? (
                            <Bars
                              height="50"
                              width="50"
                              color="#ffffff"
                              ariaLabel="bars-loading"
                              wrapperStyle={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                              wrapperClass=""
                              visible={true}
                            />
                          ) : profileAndPhoto.photo_link ? (
                            <img
                              src={profileAndPhoto.photo_link}
                              alt="Profile picture"
                            />
                          ) : (
                            <h3 className="text-uppercase profile-avatar">
                              {user.first_name.slice(0, 1)}
                              {user.last_name.slice(0, 1)}
                            </h3>
                          )}
                        </Link>
                        <div className="edit-icon">
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) => handleFileChange(e)}
                          />
                          <Icon icon={edit} />
                        </div>
                      </label>
                    </div>
                    <div className="user-profile-label">
                      <div className="username-line">
                        <Link to="" className="seller-link">
                          {!user.first_name ? (
                            <Skeleton width={150} height={25} />
                          ) : (
                            <>
                              {console.log(freelancerprofile)}
                              <div className="d-flex justify-content-center align-items-center">
                                {user.first_name} {user.last_name}{" "}
                                {freelancerprofile?.verification === true ? (
                                  <img
                                    src="assets/images/checkmark.png"
                                    className="img-fluid user-pict-img"
                                    alt="Cyber2Cyber"
                                    title="Cyber to Cyber Verified Expert"
                                    style={{
                                      width: "6%",
                                      height: "20%",
                                      position: "relative",
                                      marginLeft: "0px",
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </Link>
                      </div>
                      <div className="oneliner-wrapper">
                        <small className="oneliner">
                          {/* Expert in Cyber Security */}
                        </small>
                        <div className="ratings-wrapper">
                          <p className="rating-text">
                            {!pageLoaded || userStats === null ? (
                              <Skeleton width={110} height={20} />
                            ) : (
                              user.type === "Freelancer" && (
                                <>
                                  <strong>
                                    {userStats?.[0]?.satisfaction_rating
                                      ? Math.round(
                                          userStats?.[0]?.satisfaction_rating /
                                            userStats?.[0]?.count
                                        ).toFixed(1)
                                      : "0.0"}
                                  </strong>{" "}
                                  ({userStats?.[0]?.count || 0} reviews)
                                </>
                              )
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <section className="p-4">
                      <div className="border-bottom pb-4">
                        {user.type === "Freelancer" && (
                          <>
                            <div className="d-flex align-items-center py-1">
                              {!pageLoaded ? (
                                <Skeleton width={100} height={18} />
                              ) : (
                                <span>Profile Completed</span>
                              )}
                              <div
                                className="font-weight-bold ml-auto d-flex align-items-center "
                                style={{ width: "35%" }}
                              >
                                <ProgressBar
                                  now={expertPercentage}
                                  variant="success"
                                  className="w-100 "
                                />
                                {/* <span className="btn btn-primary border-0 py-1 px-4 mr-2"></span>{" "} */}
                                {!pageLoaded ? (
                                  <Skeleton width={40} height={18} />
                                ) : (
                                  <span className="ml-1">
                                    {expertPercentage}%
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-1">
                              {!pageLoaded || userStats === null ? (
                                <Skeleton width={100} height={18} />
                              ) : (
                                <span>Delivered on Time</span>
                              )}
                              <div className="font-weight-bold ml-auto d-flex align-items-center">
                                <span className="btn btn-primary border-0 py-1 px-4 mr-2"></span>{" "}
                                {!pageLoaded || userStats === null ? (
                                  <Skeleton width={40} height={18} />
                                ) : userStats?.[0]?.satisfaction_rating ? (
                                  <span>
                                    {calculatePercentage(
                                      userStats?.[0]?.satisfaction_rating,
                                      userStats?.[0]?.count * 5
                                    )}
                                    %
                                  </span>
                                ) : (
                                  <span>N/A</span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-1">
                              {!pageLoaded || userStats === null ? (
                                <Skeleton width={100} height={18} />
                              ) : (
                                <span>Order Completion</span>
                              )}
                              <div className="font-weight-bold ml-auto d-flex align-items-center">
                                <span className="btn btn-primary border-0 py-1 px-4 mr-2"></span>{" "}
                                {!pageLoaded || userStats === null ? (
                                  <Skeleton width={40} height={18} />
                                ) : userStats?.[0]?.delivery_rating ? (
                                  <span>
                                    {calculatePercentage(
                                      userStats?.[0]?.delivery_rating,
                                      userStats?.[0]?.count * 5
                                    )}
                                    %
                                  </span>
                                ) : (
                                  <span>N/A</span>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="pt-4">
                        <div className="d-flex align-items-center py-1 h6">
                          {!pageLoaded || ContractSummary === null ? (
                            <Skeleton width={100} height={18} />
                          ) : (
                            <span>
                              Total{" "}
                              {user.type === "Client" || user.type === 'Enterprise Client' ? "Spent" : "Earnings"}
                            </span>
                          )}
                          <div className="font-weight-bold ml-auto d-flex align-items-center">
                            {!pageLoaded || ContractSummary === null ? (
                              <Skeleton width={40} height={18} />
                            ) : (
                              <span>
                                {ContractSummary?.[0]?.total
                                  ? `$${ContractSummary?.[0]?.total}`
                                  : "$0"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              {user.type === "Client" ? (
                !hireManager?.verified ? (
                  <NavLink to="/completeprofile">
                    <button className="btn btn-primary bg-white my-2 w-100">
                      Complete Profile
                    </button>
                  </NavLink>
                ) : (
                  <></>
                )
              ) : user.type === "Freelancer" && !isProfileCompleted ? (
                <NavLink to="/completeprofile">
                  <button className="btn btn-primary bg-white my-2 w-100">
                  Edit Profile
                  </button>
                </NavLink>
              ) : /*(
                <NavLink to="/completeprofile">
                  <button className="btn btn-primary bg-white my-2 w-100">
                    Edit Profile
                  </button>
                </NavLink>
              )*/ null}
              <button
                className="btn btn-primary bg-white my-2 w-100"
                onClick={handleOpenNewModal}
              >
                Share Profile
              </button>
            </div>

            {/*console.log("status",showRejectedModal)*/}
            <Modal onHide={handleCloseModal} className='px-5 py-5' size='md' show={showRejectedModal} centered>
            <Modal.Header>
            <Modal.Title>Account Verification Failed</Modal.Title>
            <Icon
                className="mx-2 icon-hover"
                icon={remove}
                onClick={handleCloseModal}
            />
            </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center justify-content-between'>
                    </div>
                    {/**  <div style={{flex:'1'}}>
                            <h3 className='text-center'></h3>
                         <div  className="mb-2" style={{backgroundColor: 'transparent', boxShadow: 'none', cursor:'pointer'}}  onClick={this.handleCloseModal}><Icon size={32} icon={close} /></div>
                    </div>*/}
                    <div className='text-left'>
                        <p style={{fontSize:'17px', color: 'red'}}>Reason: {freelancerprofile?.rejected_msg}</p>
                    </div>
                    <br></br>
                    <div className='text-left'><p>Please go to Profile and update the above mentioned information. Thank you!</p></div>
                </Modal.Body>
                <Modal.Footer>
                <Link className="btn btn-secondary" onClick={resubmit} >Go to Profile</Link>
                </Modal.Footer>
            </Modal>
            
            
            <Modal show={showNewModal} onHide={handleCloseNewModal} centered>
              <Modal.Header>
                <Modal.Title>Share Your Profile</Modal.Title>
                <Icon
                  className="mx-2 icon-hover"
                  icon={remove}
                  onClick={handleCloseNewModal}
                />
              </Modal.Header>
              <Modal.Body>
                <p>Share your profile on social media platforms:</p>
                <br></br>
                <div className="d-flex justify-content-around">
                  <button
                    className="btn btn-light btn-platform"
                    onClick={shareOnTwitter}
                  >
                    {/**<Twitter /> Share on Twitter */}
                    <div class="d-flex">
                      <SVGSocial icon={`twiiter_updated`} />{" "}
                    </div>
                    <div className="social-text">Share on X</div>
                  </button>
                  <button
                    className="btn btn-light btn-platform"
                    onClick={shareOnLinkedIn}
                  >
                    <Linkedin />
                    <div className="social-text">Share on LinkedIn</div>
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-secondary"
                  onClick={handleCloseNewModal}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>

            <div className="col-lg-8 right">
              <div className="d-flex align-items-center p-3 bg-white rounded shadow-sm">
                <h5 className="h5 m-0">Active orders</h5>
                <div className="ml-auto d-flex align-items-center text-muted">
                  {ContractSummary === null ? (
                    <Skeleton width={100} height={20} />
                  ) : (
                    <h5 className="h5 m-0 text-secondary">
                      {ContractSummary?.[0]?.count || 0}{" "}
                      {ContractSummary?.[0]?.total
                        ? `($${ContractSummary?.[0]?.total})`
                        : "($0)"}
                    </h5>
                  )}
                </div>
              </div>
              <div class=" overflow-scroll">
                {currentResults && currentResults.length > 0 ? (
                  <table className="table table-layout-fixed  table-striped">
                    <thead>
                      <tr>
                        {auth.user.type !== "Freelancer" ? (
                          <th>Expert</th>
                        ) : (
                          <th>Hire Manager</th>
                        )}
                        <th>Type</th>
                        <th>Job</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...currentResults].map((contract) => (
                        <tr key={contract._id}>
                          {auth.user.type !== "Freelancer" ? (
                            <td>
                              {contract?.freelancer_id?.user_id?.first_name +
                                " " +
                                contract?.freelancer_id?.user_id?.last_name}
                            </td>
                          ) : (
                            <td>
                              {contract?.hire_manager_id?.first_name +
                                " " +
                                contract?.hire_manager_id?.last_name}
                            </td>
                          )}

                          <td>{contract.contract_type}</td>
                          <td
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            title={contract.job_id.headline}
                          >
                            {contract.job_id.headline}
                          </td>
                          <td>{contract.contract_status}</td>
                          <td className="action-data">
                            {auth.user.type === "Freelancer" &&
                              contract.contract_status === "Pending" && (
                                <>
                                  <Modal
                                    show={showMilestoneDetail}
                                    onHide={handleCloseMilestoneDetail}
                                    centered
                                    size="lg"
                                    //className={showMilestoneDetail ? 'xl' : ''}
                                  >
                                    <Modal.Header>
                                      <Modal.Title>
                                        NDA for Technical Expert at Cyber To
                                        Cyber:
                                      </Modal.Title>
                                      <Icon
                                        className="mx-2 icon-hover"
                                        icon={remove}
                                        onClick={handleCloseMilestoneDetail}
                                      />
                                    </Modal.Header>
                                    <Modal.Body>
                                      <ol>
                                        <li>
                                          Cyber To Cyber may conduct appropriate
                                          personnel background checks on
                                          Technical Experts prior to authorizing
                                          access to the platform, data, service,
                                          or infrastructure. The background
                                          check may include criminal and credit
                                          record verification, validation of
                                          personal reference from previous
                                          experiences and or employers, part
                                          from academic and other
                                          qualifications.
                                        </li>
                                        <li>
                                          Technical Experts and other
                                          individuals delivering services to
                                          Cyber To Cyber must sign a
                                          Non-Disclosure Agreement (“NDA”).
                                        </li>
                                        <li>
                                          The Technical Experts shall not access
                                          or collect any Personal Data or
                                          sensitive data/ information
                                          (financial, information, account
                                          number, financial statement, etc.) of
                                          the customers of the Cyber To Cyber
                                          without the prior written consent from
                                          customer and approval of the Cyber To
                                          Cyber
                                        </li>
                                        <li>
                                          All individuals working at or for
                                          Cyber To Cyber are to comply with
                                          Cyber To Cyber Policies, Procedures
                                          and Standards, as well as the laws and
                                          regulations of the Kingdom of Saudi
                                          Arabia.
                                        </li>
                                        <li>
                                          Confidential Information shall not be
                                          disclosed to any third party without
                                          the express written approval of Cyber
                                          To Cyber, except if such disclosure is
                                          required by law or regulation, in
                                          which case Cyber To Cyber shall
                                          promptly be informed of such
                                          disclosure, where this is legally
                                          permissible. The Cyber To Cyber data
                                          shall never be used or shared for any
                                          secondary purpose by the Technical
                                          Experts.{" "}
                                        </li>
                                        <li>
                                          The customer related data shall not be
                                          retained by the Technical Experts
                                          longer than is required for fulfilment
                                          of the service and is to be returned
                                          or destroyed at the completion of the
                                          engagement.{" "}
                                        </li>
                                        <li>
                                          All electronic data shall be
                                          permanently deleted and removed from
                                          the Technical Experts’ electronic
                                          system and any physical copies Data
                                          shall be disposed.
                                        </li>
                                        <li>
                                          The Technical Experts shall maintain
                                          compliance with all applicable Kingdom
                                          of Saudi Arabia rules and regulations
                                          for information security and data
                                          protection.
                                        </li>
                                        <li>
                                          The data received by the Technical
                                          Experts through the Cyber To Cyber
                                          platform shall only be used for the
                                          intended purpose and shall never be
                                          used or shared for any secondary
                                          purpose, unless Cyber To Cyber has
                                          given written authority to the
                                          Technical Experts.
                                        </li>
                                        <li>
                                          The Technical Experts agrees that the
                                          intellectual property rights of the
                                          Cyber To Cyber including service and
                                          the authorship, systems, ideas,
                                          methods of operation, documentation,
                                          are proprietary intellectual property
                                          and the valuable trade secrets of the
                                          Cyber To Cyber. Any pre-existing
                                          proprietary or Confidential
                                          Information of the Cyber To Cyber used
                                          to render the service, or included
                                          into the results or deliverables of
                                          the service provision including but
                                          not limited to software, appliances,
                                          methodologies, code, templates, tools,
                                          policies, records, working papers,
                                          know-how, Data or other intellectual
                                          property, written or otherwise shall
                                          remain exclusive property of the Cyber
                                          To Cyber.
                                        </li>
                                        <li>
                                          The ultimate responsibility of the
                                          client/customer data lies with the
                                          technical expert offering the
                                          services, the experts will be held
                                          accountable and responsible for the
                                          protection of the customer data
                                          against leakages, exposures, and
                                          unauthorized disclosures. Cyber To
                                          Cyber cannot be held liable,
                                          accountable, or responsible for any
                                          exposures or infringements.
                                        </li>
                                        <li>
                                          {" "}
                                          The Technical Experts shall be liable
                                          for any breach of obligations listed
                                          in the NDA if the customer related or
                                          Cyber To Cyber trade secrets,
                                          financial, or intellectual properly
                                          related information is disclosed.
                                        </li>
                                        <li>
                                          Upon completion, closure,
                                          continuation, or novation of the
                                          project, the data protection liability
                                          of technical experts shall continue.{" "}
                                        </li>
                                        <li>
                                          The Technical Experts shall not
                                          subcontract the performance of the
                                          services to its 3rd parties without
                                          prior written consent from Cyber To
                                          Cyber. In the event Cyber To Cyber
                                          authorizes the Technical Expertsto
                                          subcontract to a third party the
                                          Technical Expertsshall be held
                                          responsible and accountable for the
                                          activities of the sub-contracting
                                          expert.
                                        </li>
                                      </ol>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <button
                                        onClick={() => {
                                          handleVerify(
                                            contract._id,
                                            "Accepted"
                                          );
                                          handleCloseMilestoneDetail();
                                        }}
                                        className="btn btn-primary"
                                      >
                                        Accept
                                      </button>

                                      <button
                                        className="btn btn-secondary"
                                        onClick={handleCloseMilestoneDetail}
                                      >
                                        Close
                                      </button>
                                    </Modal.Footer>
                                  </Modal>

                                  <button
                                    className="btn btn-primary btn-sn mx-1"
                                    onClick={() =>
                                      handleOpenMilestoneDetail(contract)
                                    }
                                  >
                                    Accept
                                  </button>

                                  <button
                                    onClick={() =>
                                      handleVerify(contract._id, "Rejected")
                                    }
                                    className="btn btn-danger btn-sn mx-1"
                                  >
                                    Reject
                                  </button>
                                </>
                              )}
                            {auth.user.type === "Client" &&
                              contract?.hire_manager_id?._id ===
                                auth.user._id &&
                              ["Accepted", "Pending"].indexOf(
                                contract.contract_status
                              ) >= 0 && (
                                <button
                                  onClick={() =>
                                    deleteContractFunc(contract?._id)
                                  }
                                  className="btn btn-secondary btn-sn mx-1"
                                >
                                  Revoke
                                </button>
                              )}
                            <button
                              className="btn btn-primary btn-sn mx-1 m-2"
                              onClick={() => handleContract(contract?._id)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  ContractsItemData !== null && (
                    <table className="table table-layout-fixed  table-bordered table-striped">
                      {/*<tr>
                        <td colSpan={5} className="text-center">
                          No results found
                        </td>
                      </tr>
                      * */}
                     <div className="mt-5 p-5">
                      <div className="p-5">
                        <div className="message-not-available w-100">
                          <div className="text-center">
                            {user.type === "Enterprise Client" && showPay? (
                              <>
                              <div className="mt-5">
                                <h1>Welcome</h1>
                                <p className="land">Define your budget to proceed</p>
                                <Link to="/wallet" style={{ marginTop: '5px' }} className="btn btn-primary">
                                    Pay
                                </Link>
                                </div>
                              </>
                            ) : (
                              <div className="mt-5">
                                <img
                                  src="assets/images/no-data-found.png"
                                  width={"200px"}
                                  alt="message"
                                  className="mb-4"
                                />
                                <h1>Sorry! No Data Found</h1>
                                <p className="land">
                                  Sorry, we couldn't find any jobs matching your
                                  search criteria
                                </p>
                                {user.type === "Freelancer" ? (
                                  <Link to="/job" className="btn btn-primary">
                                    GO TO FIND WORK
                                  </Link>
                                ) : user.type === "Client" ? (
                                  <Link to="/talent" className="btn btn-primary">
                                    GO TO FIND TALENT
                                  </Link>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    </table>
                  )
                )}
                {totalPages > 1 && (
                  //FIXED PAGINATION
                  <nav>
                    <ul className="talent-pagination pagination justify-content-center">
                      <div
                        className={`icon ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        onClick={() =>
                          handlePageChange(
                            currentPage === 1 ? 1 : currentPage - 1
                          )
                        }
                      >
                {/** <Icon icon={arrowLeft} />*/}
                <PaginationArrowLeft/>
                      </div>
                      {[...Array(totalPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              pageNumber === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          </li>
                        );
                      })}
                      <div
                        className={`icon ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                        onClick={() =>
                          handlePageChange(
                            currentPage === totalPages
                              ? totalPages
                              : currentPage + 1
                          )
                        }
                      >
                {/** <Icon icon={arrowRight} />*/}
                <PaginationArrowRight/>
                      </div>
                    </ul>
                  </nav>
                  /**
                   * OLD PAGINATION
                   * <nav>
                    <ul className="pagination justify-content-center">
                      {[...Array(totalPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              pageNumber === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                    */
                )}
                {ContractsItemData === null && (
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>
                          <Skeleton width={120} height={25} />
                        </th>
                        <th>
                          <Skeleton width={80} height={25} />
                        </th>
                        <th>
                          <Skeleton width={180} height={25} />
                        </th>
                        <th>
                          <Skeleton width={100} height={25} />
                        </th>
                        <th>
                          <Skeleton width={80} height={25} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                        <tr key={i}>
                          <td>
                            <Skeleton height={25} />
                          </td>
                          <td>
                            <Skeleton height={25} />
                          </td>
                          <td>
                            <Skeleton height={25} />
                          </td>
                          <td>
                            <Skeleton height={25} />
                          </td>
                          <td>
                            <Skeleton height={25} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              {/*}
              <div className="p-4 bg-white rounded shadow-sm my-3">
                {!pageLoaded ? (
                  <>
                    <Skeleton height={18} className="mb-3" />
                    <Skeleton height={15} count={3} />
                  </>
                ) : (
                  <>
                    <h5 className="mb-3">
                      How to build your success on CybertoCyber in 3 steps
                    </h5>
                    <p className="m-0">
                      The key to your success on CybertoCyber is the brand you
                      build for yourself through your CybertoCyber reputation.
                      We gathered some tips and resources to help you become a
                      leading seller on CybertoCyber.
                    </p>
                  </>
                )}
              </div>
              */}

              {/** 
                            <div className="p-4 bg-white rounded shadow-sm mb-3 step-for-best-seller">
                                <h5 className="mb-4">Take these steps to become a top seller on Cyber2Cyber
                                </h5>
                                <div className="row">
                                    <div className="col-lg-4 dashboard-box">
                                        <div className="display-4 my-2">
                                            <img src="assets/images/bell.png" alt=""/>
                                            {/* <i className="fa fa-volume-up text-primary" aria-hidden="true"></i> 
                                        </div>
                                        <h5 className="font-weight-bold text-primary">Get noticed</h5>
                                        <p className="text-muted">Hone your skills and expand your knowledge with online courses. You’ll be able to offer more services and <b>gain more exposure</b> with every course completed.
                                        </p>
                                        <button className="btn btn-secondary btn-block" type="submit"> Share Your Gigs </button>
                                    </div>

                                    <div className="col-lg-4 dashboard-box">
                                        <div className="display-4 my-2">
                                            {/* <i className="fa fa-book text-primary" aria-hidden="true"></i> 
                                            <img src="assets/images/more-skills.png" alt=""/>
                                        </div>
                                        <h5 className="font-weight-bold text-primary">Get more skills & exposure</h5>
                                        <p className="text-muted">Watch this free online course to learn how to create an outstanding service experience for your buyer and grow your career as an online freelancer.
                                        </p>
                                        <button className="btn btn-secondary btn-block" type="submit"> Explore Learn </button>
                                    </div>

                                    <div className="col-lg-4 dashboard-box">
                                        <div className="display-4 my-2">
                                            {/* <i className="fa fa-star text-primary" aria-hidden="true"></i> 
                                            <img src="assets/images/best-seller2.png" alt=""/>
                                        </div>
                                        <h5 className="font-weight-bold text-primary">Become a successful seller!</h5>
                                        <p className="text-muted">Hone your skills and expand your knowledge with online courses. You’ll be able to offer more services and <b>gain more exposure</b> with every course completed.
                                        </p>
                                        <button className="btn btn-secondary btn-block" type="submit"> Watch Free Course </button>
                                    </div>
                                    
                                </div>
                            </div>*/}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  proposalAndContract: state.proposalAndContract,
  freelancerProfile: state.freelancerProfile,
  freelancerCompleted: state.freelancerProfile,
  clientProfile: state.clientProfile,
});

export default connect(mapStateToProps, {
  contractGet,
  contractVerify,
  contractDelete,
  contractGetbyId,
  contractGetSummary,
  getUserStats,
  getFreelancerProfilePercentage,
})(Dashboard);
