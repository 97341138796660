import React from 'react';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { ArrowForward } from "@material-ui/icons";
import { Cyberproicon1, Cyberproicon2, Cyberproicon3, Cyberproicon4 } from "../SVG";
import classes from "./index.module.scss";

const CyberPro = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push('/login-register?role=pro&type=signup');
    };

    return (
        <div className={`container-fluid px-4 text-dark py-5 mb-5 ${classes.cyberpro}`}>
            <div className="px-md-5 px-lg-5">
                <div className="row gap-3">
                    <div className="col-md-5 mb-4">
                        <h1 className="fw-600 mb-4">Cyber Pro</h1>
                        <h5 className="mb-2 fw-bold">Pursue your passion, and forge rewarding connections in the global cybersecurity gig market.</h5>
                        <p className="mb-3">Achieve your financial goals quickly with exciting startups and renowned companies 
                            looking for cybersecurity expertise. Find jobs or attract clients to you. The possibilities are limitless.</p>
                        <button className="btn btn-primary mt-4 wm-100" onClick={handleClick}>
                            Join as a Cyber Pro <ArrowForward />
                        </button>
                    </div>
                    <div className="col-md-6 mb-4 mt-4 ml-6" >
                        <div className="row">
                            <div className="col-md-6 mb-4 d-flex text-left">
                                <div className={`${classes.sections}`}>
                                    <Cyberproicon1 />
                                </div>
                                <div className="cyber-sections-text">
                                    <h5 className="fw-bold">Create your profile</h5>
                                    <p>Plan, collaborate, and publish your content that drives meaningful engagement and growth for your brand</p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4 d-flex text-left">
                                <div className={`${classes.sections}`}>
                                    <Cyberproicon3 />
                                </div>
                                <div className="cyber-sections-text">
                                    <h5 className="fw-bold">Provide your service</h5>
                                    <p>Analyze your performance and create gorgeous reports</p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4 d-flex text-left">
                                <div className={`${classes.sections}`}>
                                    <Cyberproicon4 />
                                </div>
                                <div className="cyber-sections-text">
                                    <h5 className="fw-bold">Work with your client</h5>
                                    <p>Quickly navigate your tasks and engage with your audience</p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4 d-flex text-left">
                                <div className={`${classes.sections}`}>
                                    <Cyberproicon2 />
                                </div>
                                <div className="cyber-sections-text">
                                    <h5 className="fw-bold">Get paid for your service</h5>
                                    <p>Analyze your performance and create gorgeous reports</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect()(CyberPro);
