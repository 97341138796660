import { INSERTWALLET , PAYPAL_AMOUNT} from "./types";
import axios from "axios";
import setAuthToken from '../utils/setAuthToken';
import { json } from "body-parser";

export const getWallet = () => async dispatch => {
    if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}
	// const body = JSON.stringify({
	// 	amount, 
	// 	id
	// })
    try {
		let response = await axios.get('api/wallet', config);
	
		dispatch({
			type: INSERTWALLET,
			payload: response.data.result.amount
		});
	} catch (error) {
		dispatch({
			type: INSERTWALLET,
			payload: 0
		});
	}
}


export const paypalapi = (amount) => ({
	type: PAYPAL_AMOUNT,
	payload: amount,
  });  
