import Icon from 'react-icons-kit'
import {Form} from 'react-bootstrap'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { useDispatch } from "react-redux";
import { fileDownload } from '../../actions/file-crud';
import { filePdfO } from 'react-icons-kit/fa/filePdfO';
import { fileWordO } from 'react-icons-kit/fa/fileWordO';
import { ic_folder } from 'react-icons-kit/md/ic_folder';
import { fileExcelO } from 'react-icons-kit/fa/fileExcelO';
import { fileImageO } from 'react-icons-kit/fa/fileImageO';
import { filePowerpointO } from 'react-icons-kit/fa/filePowerpointO';
import { getPendingJobs, updateJobStatus } from '../../actions/adminDashboard';
import { remove } from 'react-icons-kit/fa/remove'

const PendingJobModal = ({ modal, setModal, currentJob }) => {

    const [status,setStatus]=useState(null)
    const [editorState,setEditorState]= useState(null);
    const dispatch = useDispatch();

    const handleSave = () => {
        let body ={
            status:status,
            reason:editorState ? editorState : null,
            email:currentJob.hire_manager_id.email,
            job_id:currentJob._id
        }
        dispatch(updateJobStatus(body,()=>{dispatch(getPendingJobs());setModal(false);}))
    }
    
    function IconType(type) {
        if (type.includes("jpg") || type.includes("jpeg") || type.includes("png")) {
            return fileImageO
        } else if (type.includes("spreadsheet")) {
            return fileExcelO
        } else if (type.includes("pdf")) {
            return filePdfO
        } else if (type.includes(".doc")) {
            return fileWordO
        } else if (type.includes("presentation")) {
            return filePowerpointO
        } else {
            return ic_folder
        }
    }

    return (
        <Modal show={modal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal(false)}>
            <Modal.Header>
                <Modal.Title>Job Detail - {currentJob.headline}</Modal.Title>
                <Icon className="mx-2 icon-hover" icon={remove} onClick={() => setModal(false)}/>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h6 className="text-primary">Description</h6>
                            <p className="text-muted mb-3">{currentJob.description}</p>
                            <h6 className="text-primary">Budget Type</h6>
                            <p className="text-muted mb-3">{currentJob.payment_type_id == "659ea2be361d1c67ed1c1764" ? "Fixed" : "Hourly"}</p>
                            <h6 className="text-primary">Budget Amount</h6>
                            <p className="text-muted mb-3">{currentJob.payment_amount}</p>
                        </div>
                        {currentJob?.attachments?.length > 0 && (
                            <div className="row">
                                {currentJob.attachments.map((item) => (
                                    <div key={item.file_url} className="col-2 mx-2">
                                        <a href={item.file_url} target="_blank" rel="noopener noreferrer" className="text-primary" onClick={(e) => { e.stopPropagation(); }}>
                                            <Icon size="40" icon={IconType(item.file_type)} />
                                            <span className="d-block text-center mt-2">View</span>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="row mx-3" style={{ marginLeft: '0px', marginTop: '15px' }}>
                    <div style={{ display: 'flex', gap: '50px' }}>
                        <Form.Check
                            label={
                                <div style={{ marginTop: '6px', marginLeft: '5px' }}>
                                    <span>Approve</span>
                                </div>
                            }
                            name="group1"
                            type="radio"
                            id="Approved"
                            onChange={(e) => setStatus(e.target.id)}
                            className='mx-1'
                        />

                        <Form.Check
                            label={
                                <div style={{ marginTop: '6px', marginLeft: '5px' }}>
                                    <span>Reject</span>
                                </div>
                            }
                            name="group1"
                            type="radio"
                            id="Rejected"
                            onChange={(e) => setStatus(e.target.id)}
                            className='mx-1'
                        />
                    </div>

                        
                    </div>

                    {status === "Rejected" && (
                        <div>
                            <p style={{ marginTop: '20px' }}>Provide reason</p>
                            <br></br>
                            <textarea value={editorState} className='form-control' style={{ width: '100%', height: '100px' }} onChange={(e) => setEditorState(e.target.value)} />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>handleSave()}>
                    Submit
                </Button>
                <Button variant="secondary" onClick={() => setModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>)
}

export default PendingJobModal