import { connect } from "react-redux";

import classes from "./index.module.scss";
import client from "./Hiring.png"
import profile from "./post.png"
import service from "./search.png"
import wallet from "./work.png"
import { ArrowForward } from "@material-ui/icons";
import { Link,useHistory, NavLink } from 'react-router-dom';
import {Cyberclienticon1,Cyberclienticon2,Cyberclienticon3 ,Cyberclienticon4} from "../SVG";

const CyberClient = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push('/login-register?role=client&type=signup');
    };
    
    return (
        <div className={`container-fluid px-4 text-dark py-5 ${classes.cyberpro}`}>
            <div className="px-md-5 px-lg-5">
                <div className="row gap-3 ">
                    <div className="col-md-5 mb-4">
                        <h1 className="fw-600 mb-4">Cyber Client</h1>
                        <h5 className="mb-2 fw-bold">Find talent that understands your vision and hire them to elevate cybersecurity of your business.</h5>
                        <p className="mb-3">Post your project and receive proposals from a pool of vetted cybersecurity experts. 
                            Once you find the right expert, discuss timelines, availability, and pricing before moving forward.</p>
                        <button className="btn btn-primary mt-4 wm-100" onClick={handleClick}>
                            Join as a Cyber Client <ArrowForward />
                        </button>
                    </div>
                    <div className="col-md-6 mb-4 mt-4 ml-6">
                        <div className="row">
                            <div className="col-md-6 mb-4 d-flex text-left">
                                <div className={`${classes.sections}`}>
                                    {/** <img src={profile} />*/}
                                    <Cyberclienticon1/>
                                </div>
                                <div className="cyber-sections-text">
                                <h5 className="fw-bold">Post a service you want</h5>
                                <p>Provide details about the work you want done!</p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4 d-flex text-left">
                            <div className={`${classes.sections}`}>
                                    {/**<img src={service} />*/}
                                    <Cyberclienticon2/>
                                </div>
                                <div className="cyber-sections-text">
                                <h5 className="fw-bold">Search a vetted professional</h5>
                                <p>Search and match the best talent to get your work done</p>
                                </div> 
                            </div>
                            <div className="col-md-6 mb-4 d-flex text-left">
                            <div className={`${classes.sections}`}>
                                    {/**<img src={client} />*/}
                                    <Cyberclienticon3/>
                            </div>
                                <div className="cyber-sections-text">
                                <h5 className="fw-bold">Hire the right professional</h5>
                                <p>Make your selection, chat with, and sign a contract with a cyber pro</p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4 d-flex text-left">
                            <div className={`${classes.sections}`}>
                                    {/** <img src={wallet} />*/}
                                    <Cyberclienticon4/>
                                </div>
                                <div className="cyber-sections-text">
                                <h5 className="fw-bold">Work with you professional to get best services</h5>
                                <p>Keep track of the work progress being done by your cyber pro</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect()(CyberClient);
