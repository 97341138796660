import React, { useState , useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import { addLocation } from "../../actions/freelancerProfile";
import { Country, State, City }  from 'country-state-city';
import Alert from '../../components/alert/Alert';
import {Helmet} from "react-helmet";

const Location = ( { freelancerProfile : { Location, freelancerprofile } , setAlert, addLocation, isAuthenticated}) => {

    const [formData , setFormData] = useState({
      country: "",
      address: "",
      suite: "",
      city: "",
      province: "",
      zip: ""
    });

    const [statesOfCountry, setStatesOfCountry] = useState([]);
    const [citiesOfState, setCitiesOfState] = useState([]);



  const { country,
            address,
            suite,
            city,
            province,
            zip } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCountryChange = (e) => {
    const { value, name } = e.target;
    const { isocode } = e.target.selectedOptions[0].dataset;
    setFormData({ ...formData, [name]: value });
    const states = State.getStatesOfCountry(isocode);
    setStatesOfCountry(states);
  }

  const onProvinceChange = (e) => {
    const { value, name } = e.target;
    const { isocode, countrycode } = e.target.selectedOptions[0].dataset;
    setFormData({ ...formData, [name]: value });
    const cities = City.getCitiesOfState(countrycode, isocode);
    setCitiesOfState(cities);
  }
  
  const countries = Country.getAllCountries();

  let history = useHistory();
  const regex = /^[a-zA-Z\s]*$/;

  const locationRegex = /^[a-zA-Z\s]*$/;
  // Allows only alphabetic characters and spaces.

  const postalCodeRegex = /^[0-9]*$/;
  // Allows only numeric characters.

  const addressRegex = /^[a-zA-Z0-9\s-.,'/()#]*$/;
  // Allows alphanumeric characters, spaces, hyphens, periods, commas, single quotes, forward slashes, parentheses, and hashes.

  const onSubmit = async (e) => {
    e.preventDefault();

  /*
  // Check the country, city, and province with the original regex
  if (!regex.test(country) || !regex.test(city) || !regex.test(province)) {
      setAlert("Country, City, and Province must contain only letters", "danger");
      return;
  }

  // Check the postal code with the postalCodeRegex
  if (!postalCodeRegex.test(zip)) {
      setAlert("ZIP/Postal Code must contain only numbers", "danger");
      return;
  }

  // Check the address and suite with the addressRegex
  if (!addressRegex.test(address) || !addressRegex.test(suite)) {
      setAlert("Address and Suite can only contain letters, numbers, hyphens, and spaces", "danger");
      return;
  }
  */

    // Check Country, City, and Province using locationRegex
    if (!locationRegex.test(country) || !locationRegex.test(city) || !locationRegex.test(province)) {
      setAlert("Country, City, and Province must contain only letters", "danger");
      //console.log("Invalid location details entered.");
      return;
    }

    // Validate ZIP/Postal Code using postalCodeRegex
    if (!postalCodeRegex.test(zip)) {
      setAlert("ZIP/Postal Code must contain only numbers", "danger");
      //console.log("Invalid ZIP/Postal Code entered.");
      return;
    }

    // Validate Address and Suite using addressRegex
    if (!addressRegex.test(address) || !addressRegex.test(suite)) {
      setAlert("Address and Suite can only contain letters, numbers, hyphens, and permitted special characters", "danger");
      //console.log("Invalid address or suite details entered.");
      return;
    }

    addLocation({
      country,
      address,
      suite,
      city,
      province,
      zip
    });
  };

  useEffect(() => {
    let check = JSON.parse(localStorage.getItem('location'));
    //console.log(check)
    if(check)
    {
      setFormData(Location);
      let country = countries.find((element) => {
        return element.name === Location['country'];
      });
      if(country){
        const states = State.getStatesOfCountry(country.isoCode);
        setStatesOfCountry(states);

        let city = states.find((element) => {
          return element.name === Location['province'];
        });

        if(city){
          const cities = City.getCitiesOfState(country.isoCode, city.isoCode);
          setCitiesOfState(cities);
        }

      }
    }
      
  }, []);

   if (!isAuthenticated) {
        return <Redirect to="/login-register?role=pro&type=login" />;
  }

  if(typeof freelancerprofile === "string"){
    return <Redirect to="/expert-email-verify" />;
  }
  

  return (
    <section className="main-page page-dashboard">
      <Helmet>
        <title>Location</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
             <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
              <div className="bg-white rounded shadow-sm sidebar-page-right">
                    <div className="p-5">
                      <h3 className="text-primary">Location</h3>
                      <p className="font-weight-bold">7 of 10</p>
                      <Alert />
                        <form onSubmit={(e) => onSubmit(e)}>
                          <h6 className="font-weight-bold mb-4 mt-4">Verify your identity to create a trusted marketplace</h6>
                          <p>We use your street address and phone number to verify your identity, and your zip code to show relevant jobs. Only your city will be shown to clients.</p>
                            <div className="row d-flex align-items-center form-group">
                                <div className="col-md-12 col-12">
                                    <p className="text-muted font-weight-bold">Country <span className="required-field">*</span></p>
                                </div>
                                <div className="col-md-6 col-12">
                                
                                <select className="form-control" id="select"
                                    name="country"
                                    value={country}
                                    title="Allows only alphabetic characters and spaces."
                                    onChange={(e) => onCountryChange(e)}>
                                      <option>Please Select</option>
                                  {
                                    countries.map(item => <option value={item.name} data-isoCode={item.isoCode}>{item.name}</option> )
                                  }
                                    </select></div>
                               
                                <div className="col-md-6 col-12">
                                  <input type="text" className="form-control" placeholder="Apt/Suite"
                                  name="suite"
                                  value={suite}
                                  title="Allows alphanumeric characters, spaces, hyphens, periods, commas, single quotes, forward slashes, parentheses, and hashes."
                                  onChange={(e) => onChange(e)} />
                                </div>
                                <div className="col-md-12 col-12 pt-4">
                                  <input type="text" className="form-control" 
                                  placeholder="Enter street address"
                                  name="address"
                                  value={address}
                                  title="Allows alphanumeric characters, spaces, hyphens, periods, commas, single quotes, forward slashes, parentheses, and hashes."
                                  onChange={(e) => onChange(e)} />
                                </div>
                                <div className="col-md-6 col-12 pt-4">
                                  <p className="font-weight-bold mb-2">State/Province <span className="required-field">*</span></p>
                                  
                                  <select className="form-control"
                                   name="province"
                                  value={province}
                                  title="Allows only alphabetic characters and spaces."
                                  onChange={(e) => onProvinceChange(e)}>
                                    <option>Please Select</option>
                                    {
                                      statesOfCountry.map(item => <option value={item.name} data-countryCode={item.countryCode} data-isoCode={item.isoCode}>{item.name}</option> )
                                    }
                                    
                                  </select>
                                 
                                </div>
                                <div className="col-md-6 col-12 pt-4">
                                  <p className="font-weight-bold mb-2">City <span className="required-field">*</span></p>
                                  
                                  <select className="form-control"
                                   name="city"
                                  value={city}
                                  title="Allows only alphabetic characters and spaces."
                                  onChange={(e) => onChange(e)}>
                                    <option>Please Select</option>
                                    {
                                      citiesOfState.map(item => <option value={item.name}>{item.name}</option> )
                                    }
                                    
                                  </select>
                                  
                                </div>                                
                                <div className="col-md-6 col-12 pt-4">
                                  <p className="font-weight-bold mb-2">ZIP/Postal code <span className="required-field">*</span></p>
                                  <input type="text" className="form-control"
                                  name="zip"
                                  value={zip}
                                  title="Allows only numeric characters."
                                  onChange={(e) => onChange(e)} />
                                </div>
                            </div>

                            

                            <div className="text-right ">
                                <Link to="/profileandphoto" className="btn btn-default mr-3">Back</Link>
                                {/*<Link to="/Phone" className="c-btn c-fill-color-btn">Next: Phone</Link>*/}
                                
                                <button className="btn btn-primary" type="submit" >
                                    Save
                                </button>
                                <Link to="/education" className="btn btn-pirmary ml-3">Next</Link>
                               
                            </div>
                            
                        </form>
                    </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Location.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile,
});


export default connect(mapStateToProp, { setAlert, addLocation })(Location, Alert);