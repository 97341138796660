import { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { connect, useDispatch } from "react-redux";

import { sendEmailFunc } from "../../actions/sendEmail";
import { getFreelancerProfilePercentage, updateFreelancer } from "../../actions/freelancerProfile";
import { setAlert } from "../../actions/alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EmailVerification = ({
  auth: { user },
  freelancerProfile: { freelancerprofile },
  isAuthenticated,
  onVerificationSuccess
}) => {
  const dispatch = useDispatch();
  let history = useHistory();
  // const [email, setEmail] = useState(user?.email ?? "");
  const [formData, setFormData] = useState({
    codeBodyTextVal: "",
  });
  const email = user?.email;

  const [verificationCode, setVerificationCode] = useState("");
  const [sendOnceOnLoad, setSendOnceOnLoad] = useState(false);

  const [sent, setSent] = useState(false);

  const [resendCode, setResendCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const { codeBodyTextVal } = formData;

  

  // const handleChangeEmail = (e) => {
  //   setEmail(e.target.value);
  // };

  let codeBody; 
  const sendCode = async (e) => {
    // e.preventDefault();
    
    codeBody = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem('Verify', JSON.stringify(codeBody));
    
    if(email){
      /*
      addPhoneNumber({
        phone: email,
        codeBody
      });
      */
      var emailType = codeBody;
      var emailAddress = email;
      dispatch(sendEmailFunc(
        emailAddress,{
        emailType
      }));
    }
   
  }


  const codeVerify = async (e) => {
    // e.preventDefault();

    // debugger;
    // console.log(codeBodyTextVal);
    //console.log(codeBody);
    /*
    PhoneNumberVerify({
      codeBodyTextVal
    })*/


    codeBody = JSON.parse(localStorage.getItem('Verify'));
    
    if(codeBodyTextVal && codeBodyTextVal == codeBody){
      dispatch(setAlert("Code Verified Successfully", 'success'));
      dispatch(updateFreelancer()).then((res)=>{
        if (res){
          //console.log("res",res)
          onVerificationSuccess();
        }
      });
      dispatch(getFreelancerProfilePercentage())
    }
    else{
      dispatch(setAlert("Invalid Token", "danger", 3000));
    }
  }

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if(user?.email !== null && user?.email !== undefined){
      if(typeof freelancerprofile === "string" || freelancerprofile?.empty === true){
        //console.log("here is state",sendOnceOnLoad, "user is : ", user);
        if(sendOnceOnLoad === false){
          sendCode();
          setSendOnceOnLoad(true);
        }
      }
    }
  }, [user]);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
        <Form onSubmit={(e) => e.preventDefault()}>
          <div className="col-12 ">
            <div className="d-flex justify-content-center align-items-center flex-column col-lg-12">
              <p>Please enter the verification code sent to <strong>{email}</strong></p>
              <Form.Group className="mt-4 col-lg-10 col-sm-12" controlId="exampleForm.ControlInput1">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control
                  type="text"
                  name="codeBodyTextVal"
                  value={codeBodyTextVal}
                  onChange={(e) => onChange(e)}
                  disabled={typeof freelancerprofile !== "string"? true : false}
                />
              </Form.Group>
              <div className="d-flex justify-content-between mt-3 col-lg-10 col-sm-12">
                <Button
                  className="mr-2"
                  variant="secondary"
                  onClick={()=>{sendCode();setResendCode(true)}}
                  disabled={typeof freelancerprofile !== "string"? true : false || resendCode}
                >
                  Resend Code
                </Button>
                <Button
                  variant="secondary"
                  onClick={()=>codeVerify()}
                  disabled={verifyLoading || typeof freelancerprofile !== "string"? true : false}
                >
                  Verify Email
                </Button>
              </div>
            </div>
          </div>
        </Form>
      
    </div>
  );
};

const mapStateToProp = (state) => ({
  user: state.user,
  auth: state.auth,
  freelancerProfile: state.freelancerProfile,
});

export default connect(mapStateToProp)(EmailVerification);
