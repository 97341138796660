import React, { useState } from "react";
import { Button, Offcanvas, Form, InputGroup } from "react-bootstrap";
import { disputeupdate } from "../../actions/dispute";
import { connect } from "react-redux";
import { adminSendEmail, adminSendMessage } from "../../actions/manageUsers";
import Modal from "react-bootstrap/esm/Modal";
import Input from "../../components/input/input";
import { useDispatch } from "react-redux";
import { remove } from "react-icons-kit/fa/remove";
import { Icon } from "react-icons-kit";

const DisputeOffcanvas = ({
  show,
  onHide,
  disputeData,
  auth: { isAuthenticated, user },
  disputeupdate,
}) => {
  const [disputeSubmitted, setDisputeSubmitted] = useState(false);
  const handleClose = () => onHide();

  const dispatch = useDispatch();

  const [sendingMessage, setSendingMessage] = useState(false);
  const [sendingTextMessage, setSendingTextMessage] = useState("");

  const [sendingEmail, setSendingEmail] = useState(false);
  const [sendingTextEmail, setSendingTextEmail] = useState("");

  //console.log(disputeData)

  const hiremanager = disputeData?.hiremanager._id;
  const freelancer = disputeData?.freelancer._id;

  const hiremanager_email = disputeData?.hiremanager.email;
  const freelancer_email = disputeData?.freelancer.email;

  function onCloseSendingMessage() {
    setSendingMessage(false);
  }

  const SendMessageModal = ({ hiremanager, freelancer }) => {
    const [recipient, setRecipient] = useState(null);

    const handleRecipientChange = (newValue) => {
      setRecipient(newValue);
    };

    const onMessageToUserSubmit = async (e) => {
      e.preventDefault();
      // Determine the user_id based on the recipient
      const userId = recipient === "client" ? hiremanager : freelancer;

      await dispatch(
        adminSendMessage({
          message_text: e.target.message.value,
          user_id: userId,
        })
      );
      onCloseSendingMessage();
      setSendingTextMessage("");
      handleClose();
    };

    return (
      <Modal
        show={sendingMessage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mt-1">
          <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>
        <form onSubmit={onMessageToUserSubmit}>
          <Modal.Body className="mt-0">
            <div
              className="row mx-3"
              style={{ marginLeft: "0px", marginTop: "15px" }}
            >
              <div style={{ display: "flex", gap: "50px" }}>
                <Form.Check
                  label={
                    <div style={{ marginTop: "4px", marginLeft: "5px" }}>
                      <span>Send to Client</span>
                    </div>
                  }
                  name="group1"
                  type="radio"
                  id="sendToClientCheckbox"
                  onChange={() => handleRecipientChange("client")}
                  className="mx-1"
                />

                <Form.Check
                  label={
                    <div style={{ marginTop: "4px", marginLeft: "5px" }}>
                      <span>Send to Expert</span>
                    </div>
                  }
                  name="group1"
                  type="radio"
                  id="sendToExpertCheckbox"
                  onChange={() => handleRecipientChange("expert")}
                  className="mx-1"
                />
              </div>

            </div>
            <br></br>
            <Input
              name={"message"}
              type={"textarea"}
              placeholder={"Write your message..."}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="secondary">
              Send
            </Button>
            <Button variant="secondary" onClick={onCloseSendingMessage}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  };

  function onCloseSendingEmail() {
    setSendingEmail(false);
  }

  const SendEmailModal = ({ hiremanager_email, freelancer_email }) => {
    const [recipient, setRecipient] = useState(null);

    const handleRecipientChange = (newValue) => {
      setRecipient(newValue);
    };

    const onEmailToUserSubmit = async (e) => {
      // setSendingTextMessage(e.target.value);
      e.preventDefault();
      const userEmail =
        recipient === "client" ? hiremanager_email : freelancer_email;
      await dispatch(
        adminSendEmail({
          email_text: e.target.email.value,
          user_email: userEmail,
        })
      );
      onCloseSendingEmail();
      setSendingTextEmail("");
      handleClose();
    };

    return (
      <Modal
        show={sendingEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mt-1">
          <Modal.Title>Send Email</Modal.Title>
        </Modal.Header>
        <form onSubmit={onEmailToUserSubmit}>
          <Modal.Body className="mt-0">
            <div
              className="row mx-3"
              style={{ marginLeft: "0px", marginTop: "15px" }}
            >
              <div style={{ display: "flex", gap: "50px" }}>
                <Form.Check
                  label={
                    <div style={{ marginTop: "4px", marginLeft: "5px" }}>
                      <span>Send to Client</span>
                    </div>
                  }
                  name="group1"
                  type="radio"
                  id="sendToClientCheckbox"
                  onChange={() => handleRecipientChange("client")}
                  className="mx-1"
                />

                <Form.Check
                  label={
                    <div style={{ marginTop: "4px", marginLeft: "5px" }}>
                      <span>Send to Expert</span>
                    </div>
                  }
                  name="group1"
                  type="radio"
                  id="sendToExpertCheckbox"
                  onChange={() => handleRecipientChange("expert")}
                  className="mx-1"
                />
              </div>
            </div>
            <br></br>
            <Input
              name={"email"}
              type={"textarea"}
              placeholder={"Write your email..."}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="secondary">
              Send
            </Button>
            <Button variant="secondary" onClick={onCloseSendingEmail}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  };

  const handleOnSendingMessage = () => {
    setSendingMessage(true);
  };

  const handleOnSendingEmail = () => {
    setSendingEmail(true);
  };

  const handleUpdateDispute = (id) => {
    disputeupdate(id);
  };

  const sectionStyles = {
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "15px",
    overflowY: "auto",
    maxHeight: "300px",
  };

  const detailItemStyles = {
    marginBottom: "10px",
  };

  const chatHistoryStyles = {
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "15px",
  };

  const chatHistorySection = {
    maxHeight: "400px",
    overflowY: "auto",
  };

  const disputeDetailStyles = {
    maxHeight: "300px",
    overflowY: "auto",
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "15px",
  };

  const messageStyles = {
    display: "flex",
    marginBottom: "10px",
  };

  const messageBoxStyles = {
    maxWidth: "80%",
    padding: "10px",
    borderRadius: "10px",
    margin: "5px",
  };

  const hireManagerMessageStyles = {
    ...messageBoxStyles,
    backgroundColor: "#e0e0e0",
    marginLeft: "auto",
    textAlign: "right",
  };

  const freelancerMessageStyles = {
    ...messageBoxStyles,
    backgroundColor: "#f0f0f0",
    marginRight: "auto",
    textAlign: "left",
  };

  const chatSectionTitleStyle = {
    textAlign: "center",
    margin: "10px 0",
  };

  const sectionTitleStyle = {
    marginBottom: "10px",
  };

  const uniqueUsernames =
    disputeData && disputeData.messages
      ? [...new Set(disputeData.messages.map((msg) => msg.user_name))]
      : [];

  const ChatMessage = ({ message }) => {
    // Determine if the message is from the first unique user. If so, align it to one side; otherwise, align to the other.
    const isFirstUserMessage = message.user_name === uniqueUsernames[0];

    // Use dynamic styling based on the sender
    const messageStyle = isFirstUserMessage
      ? freelancerMessageStyles
      : hireManagerMessageStyles;

    return (
      <div style={{ ...messageStyles }}>
        <div style={messageStyle}>
          <p>{message.message_text}</p>
          <small>{new Date(message.message_date).toLocaleString()}</small>
        </div>
      </div>
    );
  };

  return (
    <>
      <SendEmailModal
        hiremanager_email={hiremanager_email}
        freelancer_email={freelancer_email}
      />
      <SendMessageModal hiremanager={hiremanager} freelancer={freelancer} />
      <Modal show={show} size="lg" onHide={onHide}>
        <Modal.Header className="pb-0">
          <Modal.Title>Resolve Dispute</Modal.Title>
          <Icon
            className="mx-2 icon-hover"
            icon={remove}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="dispute-modal">
          {disputeData && (
            <>
              <div style={{ ...disputeDetailStyles }}>
                <h3 style={{ ...sectionTitleStyle }}>Dispute Details</h3>
                <div style={{ ...detailItemStyles }}>
                  <strong>Description:</strong> {disputeData.description}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>Status:</strong> {disputeData.status}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>Reason:</strong> {disputeData.reason}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>Job ID:</strong> {disputeData.job_id}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>Proposal ID:</strong> {disputeData.proposalDetails}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>Submitted On:</strong>{" "}
                  {new Date(disputeData.created_at).toLocaleDateString()}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>Submitted By:</strong>{" "}
                  {disputeData.disputeuser.first_name} {disputeData.disputeuser.last_name} ({disputeData.disputeuser.type === "Freelancer" ? "Expert" : disputeData.disputeuser.type})
                </div>
                {disputeData.evidence && disputeData.evidence.length > 0 && (
                  <div style={{ ...detailItemStyles }}>
                  <strong>Evidence:</strong>
                  <ul>
                    {disputeData.evidence.map((item, index) => (
                      <li key={index}>
                        {item.description || "Evidence item"}
                
                        <a
                          href={item.path}
                          target="_blank"
                          download={item.original_name}
                          style={{ marginLeft: '10px' }}
                        >
                          File
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                )}
              </div>

              <div style={{ ...disputeDetailStyles }}>
                <h3 style={{ ...sectionTitleStyle }}>Expert Details</h3>
                <div style={{ ...detailItemStyles }}>
                  <strong>Name:</strong> {disputeData.freelancer.first_name} {disputeData.freelancer.last_name}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>email:</strong> {disputeData.freelancer.email}
                </div>
              </div>

              <div style={{ ...disputeDetailStyles }}>
                <h3 style={{ ...sectionTitleStyle }}>Client Details</h3>
                <div style={{ ...detailItemStyles }}>
                  <strong>Name:</strong> {disputeData.hiremanager.first_name} {disputeData.hiremanager.last_name}
                </div>
                <div style={{ ...detailItemStyles }}>
                  <strong>email:</strong> {disputeData.hiremanager.email}
                </div>
              </div>

              <div className="d-flex justify-content-end mb-3">
                <button
                  className="btn btn-secondary"
                  onClick={() => handleUpdateDispute(disputeData?._id)}
                >
                  Resolve Dispute
                </button>
                <button
                  className="btn btn-primary ml-4"
                  onClick={() => handleOnSendingMessage()}
                >
                  Send Message
                </button>
                <button
                  className="btn btn-primary ml-4"
                  onClick={() => handleOnSendingEmail()}
                >
                  Send Email
                </button>
              </div>

              <div style={{ ...chatHistoryStyles }}>
                <h3 style={{ ...sectionTitleStyle }}>Chat History</h3>
                <div style={{ ...chatHistorySection }}>
                  {disputeData.messages && disputeData.messages.length > 0 ? (
                    <div>
                      {disputeData.messages.map((message, index) => (
                        <ChatMessage key={index} message={message} />
                      ))}
                    </div>
                  ) : (
                    <p>No messages available.</p>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dispute: state.dispute,
});

export default connect(mapStateToProps, { disputeupdate })(DisputeOffcanvas);
