import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { sendEmailFunc } from "../../actions/sendEmail";
import { hireManagerGet, verifyClient } from "../../actions/clientProfile";
import { setAlert } from "../../actions/alert";

const EmailVerification = ({
  auth: { user },
  clientProfile: { hireManager },
}) => {
  const dispatch = useDispatch();

  let history = useHistory();

  const [email, setEmail] = useState(user?.email ?? "");
  const [verificationCode, setVerificationCode] = useState("");

  const [sent, setSent] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const sendVerificationCode = async () => {
    setLoading(true);

    const currentCode = localStorage.getItem("email-verification");

    if (email && !hireManager.verified) {
      const code = Math.floor(100000 + Math.random() * 900000);
      localStorage.setItem("email-verification", code);

      await dispatch(sendEmailFunc(email, { emailType: code }));
    }

    setLoading(false);
  };

  const handleOpenModal = () => {
    setLoading(true);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setLoading(false);
    setShowModal(false);
  };

  const handleVerifyCode = async () => {
    setVerifyLoading(true);
    if (
      parseInt(JSON.parse(localStorage.getItem("email-verification"))) ===
      parseInt(verificationCode)
    ) {
      await dispatch(verifyClient(email));
      handleCloseModal();
      //console.log("forward");
      history.push("/clientProfile")
    } else {
      dispatch(setAlert("Invalid Verification Code", "danger"));
    }
    setVerifyLoading(false);
  };


  useEffect(() => {
    if(hireManager.verified !== true){
      sendVerificationCode();
    }
    dispatch(hireManagerGet());
  }, []);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
        <Form onSubmit={(e) => e.preventDefault()}>
          <div className="col-12 ">
            <div className="d-flex justify-content-center align-items-center flex-column col-lg-12">
              <p>Please enter the verification code sent to <strong>{email}</strong></p>
              <Form.Group className="mt-4 col-lg-10 col-sm-12" controlId="exampleForm.ControlInput1">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  disabled={hireManager.verified}
                />
              </Form.Group>
              <div className="d-flex justify-content-between mt-3 col-lg-10 col-sm-12">
                <Button
                  className="mr-2"
                  variant="secondary"
                  onClick={()=>{sendVerificationCode();setResendCode(true)}}
                  disabled={hireManager.verified || resendCode}
                >
                  Resend Code
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleVerifyCode}
                  disabled={verifyLoading || hireManager.verified}
                >
                  Verify Email
                </Button>
              </div>
            </div>
          </div>
        </Form>
      
    </div>
  );
};

const mapStateToProp = (state) => ({
  user: state.user,
  auth: state.auth,
  clientProfile: state.clientProfile,
});

export default connect(mapStateToProp)(EmailVerification);
