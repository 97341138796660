import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  getApprovedCharges,
  tapRefund,
  getRefunds
} from "../../actions/checkout";
import Alert from "../../components/alert/Alert";
import Skeleton from "./../../components/skeleton";
// import "./ContractList.scss";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { setAlert } from "../../actions/alert";
import LoadingOverlay from "../wallet/Loading";
import { Button, Modal, Form } from "react-bootstrap";
import { remove } from 'react-icons-kit/fa/remove'

import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";


import classes from "../contractTrack/contract.module.css";
const Refund = ({auth, taps: {charges}, getApprovedCharges , tapRefund , getRefunds , setAlert} ) => {

  const dispatch = useDispatch();
  
  const [updateRefundData, setUpdateRefundData] = useState(null);
  const [paginatedDataItem, setPaginatedDataItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);  
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("");


  useEffect(() => {
    getApprovedCharges();
  }, []);

  useEffect(() => {
    document.title = "Contract List";
    if (charges) {
      setPaginatedDataItem([...charges]);
    }
  }, [charges]);

  
  const totalPages = paginatedDataItem
  ? Math.ceil(paginatedDataItem.length / resultsPerPage)
  : 0;

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = paginatedDataItem
    ? [...paginatedDataItem].slice(startIndex, endIndex) 
    : null;


    const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    
    //console.log("new",newPage);
    setCurrentPage(newPage);

  };  

  const refund = (id , amount) => {
    //console.log(id , amount)
    setIsLoading(true);
    setShowConfirm(false);
    tapRefund(id, amount).then((res) => {
      //console.log("Refund Created", res.data)

      /*getRefunds(res.data.id).then((res) => {
        console.log("Refund_status", res)
      });*/
      
      console.log(res)
      if(res.data.response.code === '000'){
        setAlert("Refunded Sucessfuly", "success");
        setIsLoading(false);
      }
      
      if(res.data.error?.length > 0){
        //this.setState({ isLoading: false });
        setAlert(res.data.error[0].description, "danger");
      }
      //setUpdateRefundData(res);
      //setPaginatedDataItem(res)
    })
  };

 

  const handleCloseLogout = () => {
    setShowConfirm(false);
  };

  const Confirm = (charge) => {
    setSelectedTransaction(charge); 
    setShowConfirm(true);
  };


  return (
    <section className="main-page page-dashboard">
       <Modal
          show={showConfirm}
          onHide={handleCloseLogout}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Refund</Modal.Title>
            <Icon
              className="mx-2 icon-hover"
              icon={remove}
              onClick={handleCloseLogout}
            />
          </Modal.Header>
          <Modal.Body>The selected Transaction Amount will be deducted from your wallet. Are you sure you want to refund the selected transaction?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseLogout}>
              No
            </Button>
            <Button variant="secondary" onClick={() => refund(selectedTransaction.charge_id, selectedTransaction.amount)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
                {isLoading && <LoadingOverlay />}
    <div className="container">
      <div className="panel-box">
        <header
          className="panel-box--header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 className="panel-box--title">Transactions</h1>

          <div
            className="block sort-block"
            style={{ display: "flex", alignItems: "center" }}
          >
          </div>
        </header>

        <main className="panel-box--body overflow-scroll">
          <Alert />

          {charges === null && (
            <div style={{ overflowX: "scroll" }}>
              <table className="table table-layout-fixed table-bordered table-striped">
                <thead>
                  <tr>
                    <th>
                      <Skeleton width={120} height={25} />
                    </th>
                    <th>
                      <Skeleton width={180} height={25} />
                    </th>
                    <th>
                      <Skeleton width={100} height={25} />
                    </th>
                    <th>
                      <Skeleton width={80} height={25} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                    <tr key={i}>
                      <td>
                        <Skeleton height={25} />
                      </td>
                      <td>
                        <Skeleton height={25} />
                      </td>
                      <td>
                        <Skeleton height={25} />
                      </td>
                      <td>
                        <Skeleton height={25} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {currentResults && currentResults.length > 0 ? (
            <div style={{ overflowX: "scroll" }}>
              <table
                className={`table table-layout-fixed table-striped ${classes.customeTable}`}
              >
                <thead>
                  <tr>
                  <th>Charge Id</th>
                  <th>Transaction type</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Mode</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {[...currentResults].reverse().map((charge) => (
                    <tr key={charge._id}>
                      <td>
                        {charge.charge_id}
                      </td>
                      <td>
                      Money was added to the wallet
                      </td>
                      <td>
                        {charge.date.split('T')[0]}
                      </td>

                      <td>
                        {charge.amount? charge.amount+"$" : "-"}
                      </td>

                      {charge.charge_mode? <td>{charge.charge_mode === "false"? "Test" : "Live"}</td> :
                       <td>{"-"}</td>
                      }
                      
                      <td className="action-data">
                      <button
                        className="btn btn-secondary btn-sn mx-1" onClick={() => Confirm(charge)}>
                        Refund
                      </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
                  </main>
      </div>
      
      {totalPages > 1 && (
              //FIXED PAGINATION
              <nav>
                <ul className="talent-pagination pagination justify-content-center">
                  <div
                    className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                    onClick={() =>
                      handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                    }
                  >
                {/** <Icon icon={arrowLeft} />*/}
                <PaginationArrowLeft/>
                  </div>
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  })}
                  <div
                    className={`icon ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    onClick={() =>
                      handlePageChange(
                        currentPage === totalPages
                          ? totalPages
                          : currentPage + 1
                      )
                    }
                  >
                {/** <Icon icon={arrowRight} />*/}
                <PaginationArrowRight/>
                  </div>
                </ul>
              </nav>)
          }
            {currentResults && currentResults.length > 0 ? null : (
          <>
            {/** <h4>No Record Found</h4>*/}
            <div className="mt-5 p-5">
              <div className="p-5">
                <div className="message-not-available w-100">
                  <div className="text-center">
                    <img
                      src="assets/images/no-data-found.png"
                      width={"200px"}
                      alt="message"
                      className="mb-4"
                    />
                    <h1>Sorry! No Data Found</h1>
                    <p className="land">No wallet transaction found </p>
                    <div className="mt-5">
                      <Link to="/wallet" className="btn btn-primary">
                        GO TO WALLET
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  taps: state.taps,
});

export default connect(mapStateToProps, {
  getApprovedCharges,
  tapRefund,
  getRefunds,
  setAlert
})(Refund);
