import React, { useEffect } from "react";

const Blank = () => {
  useEffect(() => {
    const sendMessage = () => {
      window.parent.postMessage({ status: 'payment_success' }, 'https://cybertocyber.com/wallet');
    };
    // Trigger the message once the component mounts
    // sendMessage();
  }, []);

  const loaderStyle = {
    width: "100px",          
    height: "100px",          
    border: "12px solid #f3f3f3",
    borderTop: "12px solid #3498db", 
    borderRadius: "50%",
    animation: "spin 2s linear infinite"
  };

  return (
    <>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div className="p-5" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <div style={loaderStyle}></div>
        <h3 style={{ marginTop: '20px' }}>Redirecting...</h3>
      </div>
    </>
  );
};

export default Blank;
