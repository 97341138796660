import { AmbientLight, DirectionalLight, PointLight } from "three";

type CreateLightsProps = {
  ambient?: string;
  directionalLeft?: string;
  directionalTop?: string;
  point?: string;
};

function createLights({
  ambient = "#ff0000",
  directionalLeft = "#ff0000",
  directionalTop = "#000000",
  point = "#ffffff",
}: CreateLightsProps) {
  const ambientLight = new AmbientLight(ambient, 0.3);

  var dLight = new DirectionalLight(0xffffff, 2.2);
  dLight.position.set(-950, 1500, 200);

  var dLight1 = new DirectionalLight(0x7982f6, 2.0);
  dLight1.position.set(-150, 700, 200);

  var dLight2 = new PointLight(0x8566cc, 0.005);
  dLight2.position.set(-200, 500, 200);

      // Adding a new directional light for the top left edge
  const dLightTopLeft = new DirectionalLight("ff0000", 100); // Set color to white for glow effect
  dLightTopLeft.position.set(500, 5000, 100); // Adjust position for desired glow effect
  
    return { ambientLight, dLight, dLight1,  dLight2, dLightTopLeft };
}

export { createLights };
