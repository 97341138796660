import { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useDispatch } from "react-redux";

import { endContract } from "../../actions/proposalAndContract";
import { remove } from 'react-icons-kit/fa/remove'
import { Icon } from "react-icons-kit";

export default function EndContract({
  contractId,
  show,
  handleClose,
  contractType = "Hourly",
}) {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(null);

  const handleEndContract = async () => {
    setLoading(true);
    await dispatch(endContract(contractId));
    handleClose();
    setLoading(false);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
        <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to proceed with ending the contract? It's important to understand that this action is permanent and cannot be reversed.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleClose}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          onClick={handleEndContract}
          disabled={loading}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
