import { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useDispatch } from "react-redux";
import { addWithdrawal } from "../../actions/withdrawal";
import { remove } from 'react-icons-kit/fa/remove'
import { Icon } from "react-icons-kit";

export default function WithdrawalModal({ walletAmount = 0, selectedBankId = '' }) {
  const dispatch = useDispatch();

  const [withdrawAmount, setWithdrawAmount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  }

  const handleWithdrawal = async () => {
    setLoading(true);
    await dispatch(addWithdrawal({
      bank_id: selectedBankId,
      amount: withdrawAmount
    }));
    handleClose();
    setLoading(false);
    setWithdrawAmount(0);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        disabled={!walletAmount || !selectedBankId}
      >
        Request Withdrawal
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Request Withdrawal</Modal.Title>
          <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-3">
              <Form.Label>Amount to withdraw</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                value={withdrawAmount}
                onChange={(e) => {
                  const value = e.target.value;
                  //const min = 0;
                  //const max = walletAmount;

                  /*if (!isNaN(value) && value >= min && value <= max) {
                    setWithdrawAmount(value);
                  }*/

                  if (/^\d*$/.test(value) && value >= 0) {
                    setWithdrawAmount(value);
                  }
                }}
                max={walletAmount}
                min={0}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={handleWithdrawal}
            disabled={loading}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
