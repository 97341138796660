import axios from "axios";

import {
  GET_WITHDRAWAL_REQUESTS,
  GET_WITHDRAWAL,
  GET_WITHDRAWAL_SUMMARY
} from "./types";
import { setAlert } from "./alert";
import { getWallet } from "./wallet";

import setAuthToken from "../utils/setAuthToken";

export const getWithdrawalRequests = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get("/api/withdrawals", config);

    dispatch({
      type: GET_WITHDRAWAL_REQUESTS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_WITHDRAWAL_REQUESTS,
      payload: [],
    });
    
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const getWithdrawal = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(`/api/withdrawals/${id}`, config);

    dispatch({
      type: GET_WITHDRAWAL,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_WITHDRAWAL,
      payload: {},
    });
    
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const addWithdrawal = (body) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = JSON.stringify(body);
  try {
    await axios.post("/api/withdrawals", data, config);

    dispatch(getWithdrawalRequests());
    dispatch(getWallet());
    dispatch(getWithdrawalSummary());
    dispatch(setAlert("Withdrawal request created", "success"));
  } catch (error) {
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const approveWithdrawal = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = JSON.stringify({
    withdrawal_id: id,
  });
  try {
    await axios.post("/api/withdrawals/approve", data, config);

    dispatch(getWithdrawalRequests());
    dispatch(getWallet());
    dispatch(getWithdrawalSummary());
    dispatch(setAlert("Withdrawal request approved", "success"));
  } catch (error) {
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const rejectWithdrawal = (id, description) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = JSON.stringify({
    withdrawal_id: id,
    description
  });
  try {
    await axios.post("/api/withdrawals/reject", data, config);

    dispatch(getWithdrawalRequests());
    dispatch(getWallet());
    dispatch(getWithdrawalSummary());
    dispatch(setAlert("Withdrawal request rejected", "success"));
  } catch (error) {
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const getWithdrawalSummary = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get("/api/withdrawals/summary", config);

    dispatch({
      type: GET_WITHDRAWAL_SUMMARY,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_WITHDRAWAL_SUMMARY,
      payload: {},
    });
    
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};
