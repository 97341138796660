import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect , NavLink } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit';
import {graduationCap} from 'react-icons-kit/fa/graduationCap';
import {plusCircle} from 'react-icons-kit/fa/plusCircle'
import Table from 'react-bootstrap/Table';
import Alert from '../../components/alert/Alert';
import {edit} from 'react-icons-kit/fa/edit';
import {trash} from 'react-icons-kit/fa/trash';
import { addCertificate, deleteCertificate, updateCertificate } from "../../actions/freelancerProfile";
import SweetAlert from 'react-bootstrap-sweetalert';
import { remove } from 'react-icons-kit/fa/remove'

const Certificate = ({ freelancerProfile : { certification, freelancerprofile } , setAlert, addCertificate, deleteCertificate, updateCertificate, isAuthenticated }) => {

  const [DataOfCertificate , setDataOfCertificate] = useState([]);
  const [deleteItem,setDeleteItem] = useState(null);
  const [alertBox,setAlertBox] = useState(false);

  const [formData , setFormData] = useState({
    certification_name: "",
    provider: "",
    description: "",
    certification_link: "",
    _id: ""
  });
    useEffect(() => {
        document.title = 'Certificate';
    }, []);
  const { certification_name, provider, description, certification_link , _id } = formData;

    const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const regex = /^[a-zA-Z\s]*$/;
  
  const certificationNameRegex = /^[a-zA-Z0-9\s.'&-]+$/;
  // Allows alphanumeric characters, spaces, periods, ampersands, single quotes, and hyphens.

  const providerRegex = /^[a-zA-Z0-9\s.'&-]+$/;
  // Allows alphanumeric characters, spaces, periods, ampersands, single quotes, and hyphens.

  const descriptionRegex = /^[a-zA-Z0-9\s.,!?@#%^&*()_+-=<>:;"'/\]{}|~]+$/;
  // Allows alphanumeric characters, spaces, and most common punctuation.

  const certificationLinkRegex = /^(https||http):\/\/(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\/[^?#\s]*)?(\?[^#\s]*)?(#[^\s]*)?$/;
  // Allows valid URLs.


  const onSubmit = async (e) => {
    e.preventDefault();

    // Check that Title and Professional Overview fields contain at least one letter
    //if (!regex.test(certification_name) || !regex.test(provider) || !regex.test(description) || !regex.test(certification_link)){
    //  setAlert("All fields must contain at least one letter", "danger");
    //  console.log("All fields must contain at least one letter")
    //  return;
    //}

    if (!certificationNameRegex.test(certification_name)) {
      setAlert("Please enter a valid certification name.", "danger");
      return;
    }

    if (!providerRegex.test(provider)) {
      setAlert("Please enter a valid certification provider name.", "danger");
      return;
    }

    if (!descriptionRegex.test(description)) {
      setAlert("Please use valid characters in the certification description.", "danger");
      return;
    }

    if (!certificationLinkRegex.test(certification_link)) {
      setAlert("Please enter a valid certification verification link.", "danger");
      return;
    }


    // setDataOfCertificate(DataOfCertificate => [...DataOfCertificate, formData]);
    if (showUpdate == true) {
      updateCertificate(_id, {
        certification_name,
        provider,
        description,
        certification_link
      });
    } else {
      addCertificate({
        certification_name,
        provider,
        description,
        certification_link
      });
    }
    setFormData({});
    setShow(false);
    setShowUpdate(false);
  };

  const updateCertificateFunc = async (e) => {
    setShowUpdate(true);
    setShow(true);
    setFormData(e);
  }

  const deleteCertificateFunc = async (e) => {
    setDeleteItem(e);
    setAlertBox(true);
  }

  const confirmDelete = () => {
    setAlertBox(false);
    deleteCertificate(deleteItem);
  }

    useEffect(() => {
      debugger;
      let check = JSON.parse(localStorage.getItem('certification'));
      //console.log(check)
      if(check)
      {
        setDataOfCertificate(certification);
      }
      
  }, [certification]);

   const [show, setShow] = useState(false);
   const [showUpdate, setShowUpdate] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => {
     setShowUpdate(false);
     setShow(true);
     setFormData({});
   }

   if (!isAuthenticated) {
        return <Redirect to="/login-register?role=client&type=login" />;
    }
    if(typeof freelancerprofile === "string"){
      return <Redirect to="/expert-email-verify" />;
    }

  return (
    <section className="main-page page-dashboard">
      <SweetAlert
        warning
        showCancel
        show={alertBox}
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="secondary"
        cancelBtnCssClass="btn-cancel-popup btn-primary"
        title="Are you sure?"
        onConfirm={()=> confirmDelete()}
        onCancel={()=> setAlertBox(false)}
      >
        You will not be able to recover this data!
      </SweetAlert>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
             <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
              <div className="bg-white rounded shadow-sm sidebar-page-right">
                     <div className="p-5">
                             <Alert />
                              <h3 className="text-primary">Certification</h3>
                              <p className="font-weight-bold">09 of 10</p>
                              <div className="row form-group">
                                <div className="col-md-8">
                                  <h6 className="font-weight-bold mb-4 mt-4">Update your certification info</h6>
                                </div>
                                <div className="col-md-4 text-right mt-4">
                                  <Button onClick={handleShow} className="c-btn c-fill-color-btn pull-right">{/** <Icon icon={plusCircle} /> */}Add Certificate</Button>
                                </div>
                              </div>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Certificate Name</th>
                                    <th>Provider</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                              {DataOfCertificate.length > 0 && DataOfCertificate.map((itemofCertificate,index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{itemofCertificate.certification_name}</td>
                                    <td>{itemofCertificate.provider}</td>
                                    <td>{itemofCertificate.description}</td>
                                    <td>
                                      
                                      <div className="d-flex align-items-center">
                                        <Icon icon={edit} onClick={() => updateCertificateFunc(itemofCertificate)} style={{ marginLeft: '10px' }} /> 
                                        <Icon icon={trash} onClick={() => deleteCertificateFunc(itemofCertificate._id)}  style={{display: 'flex' }} />
                                      </div>
                                      </td>
                                    {/** <td><button onClick={() => updateCertificateFunc(itemofCertificate)} className="btn btn-info btn-sm"><Icon icon={edit} /></button> <button onClick={() => deleteCertificateFunc(itemofCertificate._id)} className="btn btn-danger btn-sm"><Icon icon={trashO} /></button></td>*/}
                                  </tr>
                                ))}
                               </tbody>
                              </Table>
                               <Modal show={show} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
                              <Modal.Header>
                                <Modal.Title>Add Certificate</Modal.Title>
                                <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleClose} />
                              </Modal.Header>
                              <Modal.Body>
                                  <Alert />
                                  <form onSubmit={(e) => onSubmit(e)}>
                                    <div className="row d-flex align-items-center form-group">
                                        <div className="col-md-12 col-12">
                                            <p className="text-muted font-weight-bold">Certification Name/Title <span className="required-field">*</span></p>
                                        </div>
                                        <div className="col-md-12 col-12">
                                          <input type="text" 
                                          className="form-control" 
                                          placeholder="Ex: AWS Certified Developer"
                                          name="certification_name"
                                          value={certification_name}
                                          title="Allows alphanumeric characters, spaces, periods, ampersands, single quotes, and hyphens."
                                          onChange={(e) => onChange(e)}
                                           />
                                        </div>
                                        <div className="col-md-12 col-12 mt-4">
                                          <p className="font-weight-bold">Provider <span className="required-field">*</span></p>
                                        </div>
                                        <div className="col-md-12 col-12">
                                          <input type="text" className="form-control" placeholder="Ex: AWS"
                                          name="provider"
                                          value={provider}
                                          title="Allows alphanumeric characters, spaces, periods, ampersands, single quotes, and hyphens."
                                          onChange={(e) => onChange(e)}
                                           />
                                        </div>
                                        <div className="col-md-12 col-12 mt-4">
                                          <p className="font-weight-bold">Description <span className="required-field">*</span></p>
                                        </div>
                                        <div className="col-md-12 col-12">
                                          <textarea className="form-control" rows="6"
                                          name="description"
                                          value={description}
                                          title="Allows alphanumeric characters, spaces, and most common punctuation."
                                          onChange={(e) => onChange(e)}
                                          ></textarea>
                                        </div>
                                        <div className="col-md-12 col-12 mt-4">
                                          <p className="font-weight-bold">Certification Verification Link <span className="required-field">*</span></p>
                                        </div>
                                        <div className="col-md-12 col-12">
                                          <input type="text" className="form-control" placeholder="Ex: https://www.abc.com"
                                          name="certification_link"
                                          value={certification_link}
                                          title="Allows valid URLs."
                                          onChange={(e) => onChange(e)}
                                           />
                                          {(showUpdate == true) ?
                                            <input type="hidden" className="form-control"
                                          name="_id"
                                          value={_id}
                                          onChange={(e) => onChange(e)}
                                           /> : ""
                                          }
                                        </div>
                                    </div>
                                    <p className="text-right">
                                    <button type="submit" className="btn btn-primary">
                                      {(showUpdate == true) ? "Update" : "Add"}
                                    </button>
                                    </p>
                                  </form>
                              </Modal.Body>
                              <Modal.Footer>
                              </Modal.Footer>
                            </Modal>
                              <div className="row justify-content-center complete_profile_buttons">
                                <div className="text-right">
                                  <Link to="/education" className="btn btn-default mr-3">Back</Link>
                                  <Link to="/employment" className="btn btn-primary">Next</Link>
                                </div>
                              </div>

                            </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Certificate.propTypes = {
  isAuthenticated: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProp = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile,
});


export default connect(mapStateToProp, {setAlert, addCertificate, deleteCertificate, updateCertificate})(Certificate, Alert);