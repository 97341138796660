import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
//import { Link } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { mapMarker } from "react-icons-kit/fa/mapMarker";
import { userO } from "react-icons-kit/fa/userO";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { trash } from "react-icons-kit/fa/trash";
import { edit } from "react-icons-kit/fa/edit";
import { remove } from "react-icons-kit/fa/remove";
import {
  postJobGet,
  deleteJob,
  complexityGetbyId,
  expectedDurationGetbyId,
  paymentTypeGetbyId,
  skillsGetbyId,
  postJobGetById,
} from "../../actions/postJob";
import { setAlert } from "../../actions/alert";
import Alert from "../../components/alert/Alert";
import { fileImageO } from "react-icons-kit/fa/fileImageO";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import { filePdfO } from "react-icons-kit/fa/filePdfO";
import { fileWordO } from "react-icons-kit/fa/fileWordO";
import { filePowerpointO } from "react-icons-kit/fa/filePowerpointO";
import { ic_folder } from "react-icons-kit/md/ic_folder";
import { fileDownload } from "../../actions/file-crud";
import { SVGLocation } from "../../components/SVG";
import moment from "moment";
import Skeleton from "./../../components/skeleton";
import { hireManagerGet } from "../../actions/clientProfile";
import { Helmet } from "react-helmet";

import { ProfileAndPhotoGet } from "../../actions/freelancerProfile";
import { Detector } from "react-detect-offline";

const ClientProfile = ({
  clientProfile: { hireManager },
  auth,
  auth: { isAuthenticated, user },
  postJob: {
    postJobData,
    deleteJobData,
    jobDetailData,
    complexityLevel,
    expectedDurationVal,
    paymentTypeVal,
    skills,
  },
  freelancerProfile: { profileAndPhoto },
  setAlert,
  postJobGet,
  deleteJob,
  complexityGetbyId,
  expectedDurationGetbyId,
  paymentTypeGetbyId,
  skillsGetbyId,
  hireManagerGet,
  ContractSummary,
  postJobGetById,
}) => {
  //console.log(hireManager);
  //console.log(skills)
  if (hireManager) {
    //console.log("check");
    hireManager = JSON.parse(localStorage.getItem("hireManager"));
  } else {
    localStorage.setItem("hireManager", JSON.stringify(hireManager));
  }

  
  const dispatch = useDispatch();

  const [showDelete, setShowDelete] = useState(false);

  const [showJobDetail, setShowJobDetail] = useState(false);

  const skillMap = {
    "63f769e8287a98aaf8f0c5e3": "Vulnerability Assessments",
    "63f769e8287a98aaf8f0c5e4": "Penetration Testing - Application",
    "63f769e8287a98aaf8f0c5e5": "Penetration Testing - Network & Appliances",
    "63f769e8287a98aaf8f0c5e6": "Forensic Services",
    "63f769e8287a98aaf8f0c5e7": "Data Recovery Services",
    "63f769e8287a98aaf8f0c5e8": "Configuration Management",
    "63f769e8287a98aaf8f0c5e9": "Security Baseline Analysis",
    "63f769e8287a98aaf8f0c5ea": "Malware Analysis",
    "63f769e8287a98aaf8f0c5eb": "Endpoint Protection",
    "63f769e8287a98aaf8f0c5ec": "Firewall",
    "63f769e8287a98aaf8f0c5ed": "IDS/IPS",
    "63f769e8287a98aaf8f0c5ee": "Incident Response",
    "63f769e8287a98aaf8f0c5ef": "Patch Management",
    "63f769e8287a98aaf8f0c5f0": "Update/Upgrade",
    "63f769e8287a98aaf8f0c5f1": "SIEM/SOAR",
    "63f769e8287a98aaf8f0c5f2": "Password Recovery",
    "63f769e8287a98aaf8f0c5f3": "XDR/MDR",
    "63f769e8287a98aaf8f0c5f4": "Cloud Security",
    "657d815d31c78106b51e9ed0": "Cyber Security",
    "6583f222fa974b88a1057269":
      "Information security/Cybersecurity documentation",
    "657d80a131c78106b51e9ecf": "Information Security",
    "658bdb2d5d1a1044efbcac67": "Cyber Mental Health",
    "658bdb895d1a1044efbcac68": "Cybersecurity Consultation",
    "658bdbaf5d1a1044efbcac69": "Cybersecurity Awareness",
    "658bdbc85d1a1044efbcac6a": "Cybersecurity Training",
    "658bdbe55d1a1044efbcac6b": "Secure Frontend",
    "658bdbfa5d1a1044efbcac6c": "Secure Backend",
    "658bdc0e5d1a1044efbcac6d": "Secure Coding",
    "658bdc255d1a1044efbcac6e": "Cybersecurity Education",
    "658c407fd195604e80eef1de": "Other",
  };

  function isImageFileName(filename) {
    const imageExtensions = /^image\/(jpg|jpeg|png|gif)$/i;
    return imageExtensions.test(filename);
  }

  const handleCloseDelete = () => setShowDelete(false);

  const handleCloseJobDetail = () => setShowJobDetail(false);
  const history = useHistory();
  //debugger;

  const [paginatedDataItem, setPaginatedDataItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const totalPages = Math.ceil(postJobData?.length / resultsPerPage);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    postJobGet();
    hireManagerGet();
  }, []);

  useEffect(() => {
    if (Array.isArray(postJobData)) {
      const reversedData = [...postJobData].reverse();
      setPaginatedDataItem(reversedData);
    } else {
      setPaginatedDataItem(postJobData);
    }
  }, [postJobData]);

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = paginatedDataItem
    ? [...paginatedDataItem].slice(startIndex, endIndex)
    : null;

  const MAX_LENGTH = 250;

  const deleteJobFunc = async (e) => {
    deleteJobData.id = e._id;
    deleteJobData.headline = e.headline;
    setShowDelete(true);
  };

  const yesDeleteFunc = async (e) => {
    setShowDelete(false);
    deleteJob(e);
  };


  const openJobDetails = async (e) => {
    jobDetailData._id = e._id;
    jobDetailData.hire_manager_id = e.hire_manager_id;
    e?.expected_duration_id && expectedDurationGetbyId(e.expected_duration_id);
    // jobDetailData.expected_duration_id = e.expected_duration_id;
    e?.complexity_id && complexityGetbyId(e.complexity_id);
    // jobDetailData.complexity_id = e.complexity_id;
    jobDetailData.description = e.description;
    e?.main_skill_id && skillsGetbyId(e.main_skill_id);
    // jobDetailData.main_skill_id = e.main_skill_id;
    // paymentTypeGetbyId(e.payment_type_id)
    jobDetailData.payment_type_id = e.payment_type_id;
    jobDetailData.payment_amount = e.payment_amount;
    jobDetailData.headline = e.headline;
    jobDetailData.attachments = e.attachments;
    setShowJobDetail(true);
  };

  function IconType(type) {
    if (type.includes("jpg") || type.includes("jpeg") || type.includes("png")) {
      return fileImageO;
    } else if (type.includes("spreadsheet")) {
      return fileExcelO;
    } else if (type.includes("pdf")) {
      return filePdfO;
    } else if (type.includes(".doc")) {
      return fileWordO;
    } else if (type.includes("presentation")) {
      return filePowerpointO;
    } else {
      return ic_folder;
    }
  }

  const editPostedJob = async (e) => {
    //console.log("---------------------- ",e)
    deleteJobData.id = e._id;
    postJobGetById(deleteJobData.id);
    const value = "edit_job";
    history.push(`/postjobclient?value=${value}`);
    //history.push("/postjobclient");
  };



  return (
    <div className="main-page page-dashboard">
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 left">
            <div className="profile_info bg-white rounded shadow-sm">
              <div className="seller-card">
                <div className="clearfix">
                  <Detector
                    render={({ online }) => (
                      <div
                        className={`user-online-indicator ${
                          online ? "is-online" : ""
                        }`}
                      >
                        <i className="fa fa-circle"></i>{" "}
                        {online ? "Online" : "Offline"}
                      </div>
                    )}
                  />
                  <div className="ambassadors-badge">{user.type}</div>
                </div>
                <div className="user-profile-info">
                  <div className="user-profile-image">
                    <label className="user-pict rounded-circle">
                      {profileAndPhoto && profileAndPhoto.photo_link ? (
                        <img
                          src={profileAndPhoto.photo_link}
                          alt="Profile picture"
                          style={{
                            borderRadius: "50%",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      ) : (
                        <div className="user-badge">
                          <h3 className="text-uppercase">
                            {user.first_name.slice(0, 1)}
                            {user.last_name.slice(0, 1)}
                          </h3>
                        </div>
                      )}
                    </label>
                  </div>

                  <div className="user-profile-label">
                    <div className="username-line">
                      <div className="seller-link text-primary">
                        {user.first_name.length > 10
                          ? `${user.first_name.splice(0, 10)}..`
                          : user.first_name}{" "}
                        {user.last_name.length > 10
                          ? `${user.last_name.splice(0, 10)}..`
                          : user.last_name}
                      </div>
                    </div>
                    <div className="p-4">
                      {ContractSummary && ContractSummary.length > 0 ? (
                        <div className="user-stats">
                          <span>Total Spent</span>
                          <strong>${ContractSummary[0].total}</strong>
                        </div>
                      ) : (
                        // Render something if there's no ContractSummary data
                        <div className="mt-5 p-5">
                        {/** <h6 className="text-primary text-center mb-0">
                          No Search Result Found
                        </h6>
                        
                        <div className="p-5">
                        <div className="message-not-available w-100">
                        <div className="text-center">
                          <img src="assets/images/no-data-found.png" width={'200px'} alt="message" className="mb-4"/>
                          <h4>No Data Found</h4>
                        </div>
                        </div>
                        </div>*/}
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="profile_info">
                            <div className="seller-card">
                                <div><Link to="" className="ambassadors-badge">{user.type}</Link></div>
                                <div className="user-profile-info">
                                    <div>
                                        <div className="user-profile-image">
                                            <label className="user-pict">
                                                <div className="pt-4">
                                                    <h3 className="text-uppercase profile-avatar">{user.first_name.slice(0, 1)}{user.last_name.slice(0, 1)}</h3>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="user-profile-label">
                                        <div className="username-line">
                                            <Link to="" className="seller-link">{user.first_name} {user.last_name}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-stats-desc">
                                    <ul className="user-stats">
                                        <li className="pl-0"><Icon className="mr-2" icon={mapMarker} /> From<strong>{hireManager.location}</strong></li>
                                        //<li className="pl-0"><Icon className="mr-2" icon={userO} /> Member since<strong>{user.date.split('T')[0]}</strong></li>
                                        <li className="pl-0"><Icon className="mr-2" icon={userO} /> Member since<strong>{user.date == undefined ? "" :user.date.split('T')[0]}</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="seller-profile">
                            <div>
                                <h3>Overview</h3>
                                <p className="mt-2 p-2">{hireManager.overview}</p>
                            </div>
                            <div>
                                <h3>Company Name</h3>
                                <ul className="mt-2 p-2">
                                    <li>{hireManager.company_id[0]}</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="mb-4">Group of people you are looking for</h3>
                                {hireManager.skills_level_id.length > 0 && hireManager.skills_level_id.map((itemofSkills, index) => (
                                    <span className="mt-2 border border-primary p-2 rounded" key={index}>{itemofSkills}</span>
                                ))}
                            </div>
                            <div>
                                <h3>Level of experience you are looking for </h3>
                                <ul className="mt-2 p-3">
                                    <li>{hireManager.experience_level}</li>
                                </ul>
                            </div>
                                </div>*/}
          </div>

          <div className="col-lg-8 right">
            <section className="bg-white rounded shadow-sm sidebar-page-right-old">
              <header className="box-title border-bottom px-4 py-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="h4 m-0">Job(s) you have posted</h4>
                  </div>
                  <div className="col text-right">
                    {hireManager?.data?.verified ? (
                      <Link
                        to="/postjobclient"
                        className="btn btn-sn btn-primary"
                      >
                        {" "}
                        Post Job{" "}
                      </Link>
                    ) : (
                      <button
                        onClick={() =>
                          setAlert(
                            "Cannot posting a job before verification",
                            "danger"
                          )
                        }
                        className="btn btn-sn btn-primary"
                      >
                        Post Job
                      </button>
                    )}
                  </div>
                </div>
              </header>
              <div className="p-0">
                <Alert />
                {/* <h5 className="text-info font-weight-bold">Job you have posted:</h5> */}
                {postJobData === null &&
                  [0, 1, 2, 3, 4].map((i) => (
                    <div key={i} className="border-bottom p-4 posted-jobs">
                      <div className="pt-1">
                        <div className="row">
                          <div className="col-md-9">
                            <div className="mb-3">
                              <Skeleton width={180} height={17} />
                            </div>
                            <div className="mb-3">
                              <Skeleton width={150} height={17} />
                            </div>
                            <div className="mb-2">
                              <Skeleton width={170} height={17} />
                            </div>
                            <div>
                              <Skeleton height={15} count={3} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {currentResults &&
                  currentResults?.length > 0 &&
                  [...currentResults].map((jobDataItem) => (
                    <>
                      <Modal
                        show={showDelete}
                        size="md"
                        aria-labelledby="containemdd-modal-title-vcenter"
                        centered
                        onHide={handleCloseDelete}
                      >
                        <Modal.Header>
                          <Modal.Title>
                            Delete Job - {deleteJobData.headline}
                          </Modal.Title>
                          <Icon
                            className="mx-2 icon-hover"
                            icon={remove}
                            onClick={handleCloseDelete}
                          />
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="row">
                              <p>Are you sure you want to delete this job?</p>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={() => yesDeleteFunc(deleteJobData.id)}
                          >
                            Yes
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={handleCloseDelete}
                          >
                            No
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        show={showJobDetail}
                        size="xl" 
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={handleCloseJobDetail}
                      >
                        <Modal.Header>
                          <Modal.Title>
                            Job Detail - {jobDetailData.headline}
                          </Modal.Title>
                          <Icon
                            className="mx-2 icon-hover"
                            icon={remove}
                            onClick={handleCloseJobDetail}
                          />
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 job-detail-modal">
                                <h6 className="text-primary">Description</h6>
                                <p className="text-muted text-justify">
                                  {jobDetailData?.description && jobDetailData.description.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </p>
                                <h6 className="text-primary">
                                  Project Complexity
                                </h6>
                                <p className="text-muted">
                                  {complexityLevel?.complexity}
                                </p>
                                {/* <h6 className="text-primary">
                                  How long will work take?
                                </h6>
                                <p className="text-muted">
                                  {expectedDurationVal.duration_text}
                                </p> */}
                                <h6 className="text-primary">Main Skill</h6>
                                <p className="text-muted">
                                  {skills?.skill_name}
                                </p>
                                <h6 className="text-primary">Budget Type</h6>
                                <p className="text-muted">
                                  {jobDetailData?.payment_type_id ==
                                  "659ea2be361d1c67ed1c1764"
                                    ? "Fixed"
                                    : "Hourly"}
                                </p>
                                <h6 className="text-primary">Budget Amount</h6>
                                <p className="text-muted">
                                  $ {jobDetailData.payment_amount}
                                </p>
                              </div>
                              {jobDetailData?.attachments?.length > 0 && (
                                <div className="col-12">
                                  {jobDetailData.attachments.map((item) =>
                                    isImageFileName(item.file_type) ? (
                                      <img
                                        src={item.file_url}
                                        alt={item.file_name}
                                        //width={200}
                                        //height={200}
                                        style={{ width: '200px' , height:'200px'}}
                                        className={`mr-4 mb-4 cursor ${
                                          jobDetailData?.attachments?.length - 1 ? 'mr-2' : '' // Apply mr-2 class to all except the last image
                                        }`}
                                        onClick={() =>
                                          window.open(item.file_url, "_blank")
                                        }
                                      />
                                    ) : (
                                      <Icon
                                        className="mx-2 cursor text-primary"
                                        size="40"
                                        icon={IconType(item.file_type)}
                                        onClick={() =>
                                          window.open(item.file_url, "_blank")
                                        }
                                      />
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCloseJobDetail}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="border-bottom p-4 posted-jobs">
                        <div className="pt-1">
                          <div className="row">
                            <div className="col-md-12">
                            {jobDataItem?.contracts?.length < 1 && (
                                 <p className="text-right">
                                  <button
                                    onClick={() => deleteJobFunc(jobDataItem)}
                                    className="btn2 btn2-danger p-1"
                                    style={{
                                      border: "none",
                                      background: "none",
                                    }}
                                  >
                                    <Icon
                                      icon={trash}
                                      style={{ color: "#e31818" }}
                                    />
                                  </button>

                                  <Icon
                                    icon={edit}
                                    onClick={() => editPostedJob(jobDataItem)}
                                    style={{ marginLeft: "8px" }}
                                    className="btn2 btn2-danger p-1 icon-hover" // Adjust the margin or spacing as needed
                                  />
                                </p>

                                )}
                              <span
                                onClick={() => openJobDetails(jobDataItem)}
                                className="cursorClass"
                              >
                                
                                <h4 className="h4 mt-0 mb-3">
                                  {jobDataItem.headline}
                                </h4>

                                <h3
                                  className="h4 mt-0 mb-3"
                                  style={{ color: "#23adf7" }}
                                >
                                  {skillMap[jobDataItem.main_skill_id]}
                                </h3>
                                <div className="text-gray">
                                  <div className="mb-2 list-detail-items">
                                    <ul>
                                      <li>
                                        <strong>Budget</strong> $
                                        {jobDataItem.payment_amount}{" "}
                                        {jobDataItem.payment_type_id ==
                                        "659ea2be361d1c67ed1c1764"
                                          ? " Fixed price"
                                          : "/ hr"}
                                      </li>
                                      <li>
                                        <strong>Posted</strong>{" "}
                                        {moment
                                          .utc(jobDataItem.job_post_time)
                                          .format("DD/MM/YYYY")}
                                      </li>
                                    </ul>
                                  </div>
                                  {/*jobDataItem?.description?.length >
                                  MAX_LENGTH ? (
                                    <p>
                                      {`${jobDataItem.description.substring(
                                        0,
                                        MAX_LENGTH
                                      )}`}{" "}
                                      ....
                                    </p>
                                  ) : (
                                    <p className="mb-3">
                                      {jobDataItem.description}
                                    </p>
                                  )*/}

                                    <p className="mb-3">
                                      {jobDataItem.description}
                                    </p>

                                  {jobDataItem?.status === "Rejected" ? (
                                    <p
                                      style={{
                                        color: "red",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Job Rejected. Reason:{" "}
                                      {jobDataItem?.rejected_msg}
                                    </p>
                                  ) : (
                                    <></>
                                  )}

                                  {hireManager.location && (
                                    <>
                                      <strong>
                                        <SVGLocation />
                                      </strong>{" "}
                                      <span className="text-muted">
                                        {hireManager.location}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </span>
                            </div>
                            {jobDataItem?.contracts?.length < 1 && (
                              <div className="col-md-3"> {/**
                                <p className="text-right">
                                  <button
                                    onClick={() => deleteJobFunc(jobDataItem)}
                                    className="btn2 btn2-danger p-1"
                                    style={{
                                      border: "none",
                                      background: "none",
                                    }}
                                  >
                                    <Icon
                                      icon={trash}
                                      style={{ color: "#e31818" }}
                                    />
                                  </button>

                                  <Icon
                                    icon={edit}
                                    onClick={() => editPostedJob(jobDataItem)}
                                    style={{ marginLeft: "8px" }}
                                    className="btn2 btn2-danger p-1" // Adjust the margin or spacing as needed
                                  />
                                </p>*/}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {postJobData?.length == 0 ? (
                   <div className="p-5">
                   {/** <h6 className="text-primary text-center mb-0">
                     No Search Result Found
                   </h6>
                   */}
                   <div className="p-5">
                   <div className="message-not-available w-100">
                   <div className="text-center">
                     <img src="assets/images/no-data-found.png" width={'200px'} alt="message" className="mb-4"/>
                     <h1>Sorry! No Data Found</h1>
                     <p className="land">No Jobs posted yet</p>
                   </div>
                   </div>
                   </div>
                 </div>
                 
                ) : (
                  ""
                )}
              </div>
            </section>

            {totalPages > 1 && (
              //FIXED PAGINATION

              <nav>
                <ul className="talent-pagination pagination justify-content-center">
                  <div
                    className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                    onClick={() =>
                      handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                    }
                  >
                    <Icon icon={arrowLeft} />
                  </div>
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  })}
                  <div
                    className={`icon ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    onClick={() =>
                      handlePageChange(
                        currentPage === totalPages
                          ? totalPages
                          : currentPage + 1
                      )
                    }
                  >
                    <Icon icon={arrowRight} />
                  </div>
                </ul>
              </nav>

              /* 
              OLD PAGINATION
              <nav>
                <ul className="pagination justify-content-center">
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </nav> */
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  clientProfile: state.clientProfile,
  profileAndPhoto: state.freelancerProfile.profileAndPhoto,
  freelancerProfile: state.freelancerProfile,
  postJob: state.postJob,
  ContractSummary: state.proposalAndContract.ContractSummary,
});

export default connect(mapStateToProps, {
  setAlert,
  postJobGet,
  postJobGetById,
  deleteJob,
  complexityGetbyId,
  expectedDurationGetbyId,
  paymentTypeGetbyId,
  skillsGetbyId,
  hireManagerGet,
})(ClientProfile);
