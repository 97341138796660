import { INSERTWALLET , APPROVED_TAPS_CHARGE} from "./types";
import axios from "axios";
import setAuthToken from '../utils/setAuthToken';
import { json } from "body-parser";


export const pay = (token) => async dispatch => {
	//console.log("Acessed")
	//console.log(token);
    try{
		if (localStorage.token) {
			setAuthToken(localStorage.token);
		}
		const config = {
			headers: {
				'Content-Type': 'Application/json',
				'Accept': 'application/json'
			}
		}
		//console.log("check-0")
		//console.log(JSON.stringify(token))
		//let response = await axios.post('api/stripe/pay', JSON.stringify(token), config);
		let response = await axios.post('api/checkout/pay', { token: token }, config);
		//console.log(response.data.message)
		//console.log(response.data.status)
		//console.log(response.data.success)
		//console.log(response.data.amount)
		if(response.data.success) {
			dispatch({
				type: INSERTWALLET,
				payload: response.data.amount
			})
		}
		return response.data.message;
	}
	catch{
		console.log("error")
	}
}
export const getTapsCharge = (id) => async dispatch => {
   
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get('/api/checkout/getCharge/'+id, config);
		
        return response.data

        // Handle response data here
    } catch (error) {
        console.log(error);
		return error
    }
}


export const getTapsstatus = () => async dispatch => {
   
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get('/api/checkout/getpaystatus', config);
		
        return response.data

        // Handle response data here
    } catch (error) {
        console.log(error);
    }
}


export const getApprovedCharges = () => async dispatch => {
   
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get('/api/checkout/getApprovedCharges', config);
		
        //console.log(response)
        dispatch({
			type: APPROVED_TAPS_CHARGE,
			payload: response.data
		});

		return response.data

        // Handle response data here
    } catch (error) {
        console.log(error);
    }
}


export const updateTapsstatus = (action , id) => async dispatch => {
   
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

	const data = JSON.stringify({
		id: id,
		action: action,
	  });

    try {
        const response = await axios.post('/api/checkout/updatepaystatus', data , config);
		
        return response.data

        // Handle response data here
    } catch (error) {
        console.log(error);
    }
}


export const tapRefund = (id,amount) => async dispatch => {
   
    if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	
	const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

	const data = JSON.stringify({
		id: id,
		amount: amount,
	  });

    try {
        const response = await axios.post('/api/checkout/refund', data, config);
		
        //console.log("response",response);
        getApprovedCharges();
        
		return response

        // Handle response data here
    } catch (error) {
        console.log(error);
    }
}

export const getRefunds = (id) => async dispatch => {
   
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get('/api/checkout/getRefund/'+id, config);
		
        //console.log(response)
		return response.data

        // Handle response data here
    } catch (error) {
        console.log(error);
    }
}




export const addToWallet = (amount , id , email , type) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	  }
	
	  const config = {
		headers: {
		  'Content-Type': 'Application/json'
		}
	  }
	
	  /*const data = JSON.stringify({
		...response.callback,
		provider: 'tap'
	  });*/
	
	  try {

	  const data = JSON.stringify({
		id: id,
		amount: amount,
		provider: 'tap',
		email: email,
        type
	  });
	  
	  const response = await axios.post('/api/checkout/verify', data, config);
	  return response.data

	} catch (error) {
        console.log(error);
    }
}





