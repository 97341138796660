import { connect } from "react-redux";
import React, { useState, useEffect } from 'react';
import classes from "./index.module.scss";
import illustration from "./Illustration.png"
import icon from "./icon.png"
//import image from "./Image_default.png"
import image from "./default_Avatar.jpg"
import shahid from "./shahid.jpeg"
import irfan from "./irfan.jpeg"
import basit from "./basit.jpeg"
import tech from "./tech.jpeg"
import modon from "./modon.jpeg"
import { Arrow90degRight, ArrowBarRight } from "react-bootstrap-icons";
import { ArrowBackIos, ArrowForward, ArrowForwardIos } from "@material-ui/icons";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useRef } from "react";

const testimonials = [
  {
    text: "Cybertocyber.com really impressed me! I found an expert in no time, and the service was quick and spot on. Honestly, I didn’t expect such a fast turnaround. Definitely going to use them again!",
    name: "Yousuf Aljuhaini",
    title: "KSA",
    image: image // Replace with actual image URL if available
  },
  {
    text: "I found a super knowledgeable expert through Cybertocyber.com. They really knew their stuff and helped me out a lot. Honestly, I was a bit worried at first, but they made it all easy to understand. Definitely gonna use them again!",
    name: "Srikanth S.",
    title: "KSA",
    image: image // Replace with actual image URL if available
  },
  {
    text: "Amazing! Cybertocyber.com is awesome! The requests just keep rolling in, and I’m loving the freedom to work whenever I want. I get to pick projects that interest me, and the pace is just perfect. Couldn’t ask for a better setup!",
    name: "M. Shahid",
    title: "KSA",
    image: shahid // Replace with actual image URL if available
  },
  {
    text: "Cybertocyber.com lets me work from my own space, no more dealing with traffic and commuting headaches. I get to focus on what I do best without any distractions, and it’s been a game changer for my work-life balance.",
    name: "Irfan Khan",
    title: "KSA",
    image: irfan // Replace with actual image URL if available
  },
  {
    text: "As a corporate client and service provider on Cybertocyber.com, we've been thoroughly impressed with the platform. It’s streamlined our ability to connect with top-tier cybersecurity experts when needed, while also providing us with a valuable channel to offer our services. The efficiency and professionalism we've experienced have greatly benefited our operations.",
    name: "Techvisions",
    title: "KSA",
    image: tech // Replace with actual image URL if available
  },
  {
    text: "Cybertocyber.com has been instrumental in helping us meet our strategic KPIs as a cybersecurity services provider. The on-demand access to skilled talent has significantly eased our talent pool challenges, allowing us to deliver on critical projects with speed and efficiency. This platform has truly enhanced our ability to scale and succeed.",
    name: "ModonTech",
    title: "KSA",
    image: modon // Replace with actual image URL if available
  },
  {
    text: "I recently got advisory services through Cybertocyber.com, and it was worth every penny. The expert had the exact experience and knowledge I needed, and their guidance helped me impress my management at work. I couldn’t be happier with the results!",
    name: "Basit Siddiqui",
    title: "Dubai",
    image: basit // Replace with actual image URL if available
  },
  // Add more testimonials here if needed
];


const TestimonialSlider = () => {

  const slider = useRef(null);

  const [width, setWidth] = useState("");


useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setWidth('500px');
    } else {
      setWidth('300px');
    }
  };
  handleResize();
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <p className={`${classes.carouselControl} ${classes.prev}`}>{">>>>"}</p>,
    nextArrow: <p className={`${classes.carouselControl} ${classes.next}`}>&#10095;</p>,
  };
  return (
    <div className={`container-fluid  text-dark py-5 px-1 mb-5 ${classes.testinomial}`}>


      <div className={`${classes.testimonialslider} container mt-3`}>
        <div className={classes.sliderWrapper}>
        <h2 className="fw-600 text-center">What Our Clients Say About Us</h2>

          <Slider ref={slider} {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className={classes.testimonialitem}>
                <div className={classes.testimonialcard}>
                  <div className={classes.testimonialcontent}>
                    <p className={`mb-5 ${classes.fontweightbold} `}>{testimonial.text}</p>
                    <div className="d-flex">
                    <img src={icon} width={50} />
                    <div className="ml-3">
                    <p className={`${classes.name} ${classes.fontweightbold} mb-0 `}>{testimonial.name}</p>
                    <p className={`${classes.name} text-muted`}>{testimonial.title}</p>

                    </div>
                    </div>
                    <button className={`${classes.carouselControl} ${classes.prev}`} onClick={() => slider?.current?.slickPrev()}><ArrowBackIos/></button>
                    <button className={`${classes.carouselControl} ${classes.next}`} onClick={() => slider?.current?.slickNext()}><ArrowForwardIos /></button>
                    
                  </div>
                  <div className={classes.testimonialimage}>
                  <div id={classes.bordertop}></div>
                  <div id={classes.borderbottom}></div>
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="img-fluid"
                      style={{ width: width, height: '300px' }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>

  );
};

export default connect()(TestimonialSlider);