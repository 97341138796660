import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { SVGSearchMagnifine, SVGArrowDown } from "../SVG.js";
import Search from "./search.js";
import { skillsGet } from "../../actions/clientProfile.js";
import classes from "./main-slider.module.css";
import { World } from "./utils/globe/index.ts";
import { globeConfig } from "./utils/config.globe.ts";
import { Fade } from "react-awesome-reveal";
import BrandSlider from "./brandsSlider.js";

const sliderContent = [
  {
    heading1: "Protect your digital world by verified & trustworthy",
    heading2: "cybersecurity professionals",
    heading2color: "rgb(196, 0, 0)",
  },
  {
    heading1: "Post your project and get it done by a top-rated ",
    heading2: "cybersecurity professional",
    heading2color: "rgb(196, 0, 0)",
  },
  {
    heading1: "Want to offer your services? ",
    heading2: "cybersecurity professional",
    heading2color: "rgb(196, 0, 0)",
  },
  {
    heading1: "Got a cybersecurity problem or an incident? ",
    heading2: "Use our AI-powered algorithm to find a top cyber talent",
    heading2color: "rgb(196, 0, 0)",
  },
];

const MainSlider2 = ({
  auth: { isAuthenticated, user },
  clientProfile: { skills },
  skillsGet,
}) => {
  const [searchLocation, setSearchLocation] = useState("Talent");
  const history = useHistory();
  const [value, setValue] = useState("");
  const [active, setActive] = useState(0);

  const handleLinkClick = (e) => {
    e.preventDefault();
    setValue(e.target.innerText);
  };

  useEffect(() => {
    const globeCanvas = document.getElementById("globe-canvas");
    if (!globeCanvas) {
      const container = document.querySelector("#scene-container");
      const world = new World(container, undefined, globeConfig);
      world.start();
    }
  }, []);

  useEffect(() => {
    skillsGet();
  }, [skillsGet]);

  useEffect(() => {
    const id = setTimeout(() => {
      setActive((prevActive) => (prevActive + 1) % sliderContent.length);
    }, 5000);
    return () => clearTimeout(id);
  }, [active]);

  const Search_input = () => {
    localStorage.setItem("search", JSON.stringify(value));
    if (searchLocation === "Talent") {
      history.push(`/talent?value=${value}`);
    } else {
      history.push(`/job?value=${value}`);
    }
  };

  const curr = sliderContent[active];

  return (
    <section
      style={{
        backgroundSize: "cover",
        background: "linear-gradient(180deg, rgb(16 29 59) 16%, rgb(7 22 57) 48%, rgb(4, 13, 34) 67%, rgb(4 7 20) 91%)",
      }}
      className={`${classes.ourSlider}`}
    >
      <div className={classes.container + " main-slider"}>
        <div className={classes.info}>
        
          <div key={active} className={classes.headings}>
            <div>{curr.heading1}</div>
            <div style={{ color: curr.heading2color }}>
              {curr.heading2}
            </div>
          </div>
          
          <div className={classes.search}>
            <div className="homepage-search-form">
              <form className="form-noborder">
                <div className="form-row row">
                  <div className="col-lg-2 col-md-2 col-xs-12">
                    <div className="search-location-dropdown">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" variant="default">
                          <span style={{ fontSize: "14px", color: "#01131D" }}>
                            {searchLocation}
                          </span>{" "}
                          <SVGArrowDown />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <React.Fragment>
                            <Dropdown.Item
                              eventKey="1"
                              onClick={() => setSearchLocation("Talent")}
                            >
                              Talent
                            </Dropdown.Item>
                            {user.type === "Freelancer" || !isAuthenticated ? (
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => setSearchLocation("Job")}
                              >
                                Job
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                eventKey="2"
                                disabled
                                onClick={() => setSearchLocation("Job")}
                              >
                                Job
                              </Dropdown.Item>
                            )}
                          </React.Fragment>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-5 col-xs-12">
                    <div className="search-input">
                      <SVGSearchMagnifine />
                      <Search
                        suggestions={
                          skills.length > 0
                            ? skills.map((item) => item.skill_name)
                            : []
                        }
                        setInput={setValue}
                        setIndicator={() => {}}
                        pre_value={value}
                        onEnterPress={Search_input}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-3 col-xs-12">
                    <div className="search-btn">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary-new p-0"
                        style={{
                          backgroundColor: "#40addb",
                          color: "white",
                        }}
                        onClick={Search_input}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="popular">
              <span
                className="popular-heading"
                style={{ color: "#ffffff", fontSize: "1rem" }}
              >
                Popular:
              </span>
              <ul>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Network Security
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Cyber Risk Management
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Malware Protection
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Data Security
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Penetration Testing
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`${classes.globe}`} style={{ overflow: "" }}>
          <Fade delay={0.8}>
            <div
              id="scene-container"
              style={{
                width: window.innerWidth >= 1300 ? "145vw" : "100vw",
                height: "110vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </Fade>
        </div>
      </div>
      <BrandSlider />
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  clientProfile: state.clientProfile,
});

export default connect(mapStateToProps, { skillsGet })(MainSlider2);