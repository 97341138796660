import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
//import { Link, Redirect } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { setAlert } from "../../actions/alert";
//import { register } from "../../actions/auth";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import Alert from "../../components/alert/Alert";
import { sendEmailFunc } from "../../actions/sendEmail";
import Input from "../../components/input/input";

import { MenuItem, Select , InputLabel , FormControl } from '@material-ui/core';
//import FormControl from '@mui/material/FormControl';
//import InputLabel from '@mui/material/InputLabel';

import document from "ejs/ejs";
import { Country } from "country-state-city";
import { Helmet } from "react-helmet";

import Modal from "react-bootstrap/Modal";
import { remove } from "react-icons-kit/fa/remove";
import { Icon } from "react-icons-kit";

const Register = ({ setAlert, register, sendEmailFunc, isAuthenticated }) => {
  const history = useHistory();

  const locHistory = useLocation();
  const searchParams = new URLSearchParams(locHistory.search);
  const [role, setRole] = useState(searchParams.get("role") || "client");

  useEffect(() => {
    document.title = "Register";
  }, []);

  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    checked: false,
    type: "",
    location: "",
  });

  const setChecked = () => {
    //console.log(checked)
    formData["checked"] = false;
    setFormData(formData);
  };

  const acceptPolicy = () => {
    formData["checked"] = !formData["checked"];
    //console.log("accept" , checked)
    setFormData(formData);
  };

  const [showpolicy, setShowPolicy] = useState(false);
  const [acceptpolicy, setAcceptPolicy] = useState(false);
  const handleClosepolicy = () => {
    setShowPolicy(false);
  };

  const countries = Country.getAllCountries();

  const [errors, setErrors] = useState({
    error_first_name: "",
    error_last_name: "",
    error_type: "",
    error_checked: "",
    error_password: "",
  });

  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorChecked, setErrorChecked] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPassword2, setErrorPassword2] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorLocation, setErrorLocation] = useState("");

  const {
    user_name,
    email,
    first_name,
    last_name,
    password,
    checked,
    type,
    location,
  } = formData;

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
    } else {
      if (
        (name === "first_name" ||
          name === "last_name" ||
          name === "user_name") &&
        value.length > 15
      ) {
        return;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  const buttonText = () => {
    switch (formData.type) {
      case "Client":
        return "Create my 'Cyber Client' account";
      case "Freelancer":
        return "Create my 'Cyber Pro' account";
      default:
        return "Create my account";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Prevent updates if the value exceeds 15 characters for specific fields
    if (
      (name === "first_name" || name === "last_name" || name === "user_name") &&
      value.length > 15
    ) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function getRoleFromURL() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('role'); // 'client' or 'pro'
  }

  
  useEffect(() => {
    const role = getRoleFromURL();
    if (role) {
      const userType = role === 'pro' ? 'Freelancer' : 'Client';
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: userType
      }));
    } else {
      // You might want to handle errors or default values here
      setAlert("User type is required and not specified in the URL.", "danger", 3000);
    }
  }, []);

  
  const onSubmit = async (e) => {
    e.preventDefault();

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const emailRegex =
      /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/;
    const nameRegex = /\d/;

    //console.log(location)
    if (type === "") {
      setAlert("User type is required", "danger", 3000);
      //setErrors({ ...errors, error_type: "User type is required" });
      setErrorType("User type is required");

      setErrorFirstName("");
      setErrorLastName("");
      setErrorPassword("");
      setErrorPassword2("");
      setErrorLocation("");
    } /*else if(formData['checked'] === false){
      setAlert("Please accept the privacy policy", "danger", 3000);

      error = "Please accept the privacy policy";
      setErrors({ ...errors, ["error_checked"]: error });
      //console.log("checkign -- 2" , error)
      //setErrors({ ...errors, ["error_type"]: "" });
      //setErrors({ ...errors, ["error_first_name"]: "" });

    }*/ else if (nameRegex.test(first_name) || nameRegex.test(last_name)) {
      setAlert("Please enter a valid name without numbers", "danger", 3000);

      //setErrors({ ...errors, error_type: "testinggg" }); // Reset error type
      setErrorType("");
      setErrorPassword("");
      setErrorPassword2("");
      setErrorLocation("");

      if (first_name && nameRegex.test(first_name)) {
        //setErrors({ ...errors, error_first_name: "Please enter a valid name without numbers" });
        setErrorLastName("");
        setErrorFirstName("Please enter a valid name without numbers");
      }
      if (last_name && nameRegex.test(last_name)) {
        //setErrors({ ...errors, error_last_name: "Please enter a valid name without numbers" });
        setErrorFirstName("");
        setErrorLastName("Please enter a valid name without numbers");
      }
    }
    //else if(!['gmail', 'hotmail', 'yahoo', 'outlook'].includes(email.split('@')[1].split('.')[0])){
    //setAlert("Please use working email.", "danger", 3000);
    //}
    else if (location === "Please Select" || !location) {
      setAlert("Location is required", "danger", 3000);

      setErrorFirstName("");
      setErrorLastName("");
      setErrorType("");
      setErrorPassword("");
      setErrorPassword2("");
      setErrorLocation("Location is required");
    } else if (!passwordRegex.test(password)) {
      setAlert(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character (@, $, !, %, *, ?, &), and be at least 8 characters long.",
        "danger",
        3000
      );

      setErrorFirstName("");
      setErrorLastName("");
      setErrorType("");
      setErrorLocation("");
      setErrorPassword("Please enter a strong password");
    } else {
      setErrorFirstName("");
      setErrorLastName("");
      setErrorType("");
      setErrorPassword("");
      setErrorPassword2("");
      setErrorLocation("");

      setShowPolicy(true);
    }
  };

  const registered = () => {
    debugger
    register({
      user_name,
      email,
      first_name,
      last_name,
      password,
      type,
      history,
      location,
    })
      .then((res) => {
        /*
     debugger;
     var emailType = "new";
     var emailAddress = email;
     sendEmailFunc(
       emailAddress,{
       emailType
     });
     */

        //console.log("response",res)

        if (res.data) {
          debugger
          setTimeout(() => {
            if (type === "Freelancer") {
              history.push("/expert-email-verify");
            } else {
              history.push("/completeprofile");
            }
          }, 1000); // 1000 milliseconds = 1 second delay
        } else {
          if (res[0].msg === "Email already exists") {
            setErrorEmail("Email already exists");
            setErrorUsername("");
            setShowPolicy(false);
          }
          if (res[0].msg === "User already exists") {
            setErrorUsername("User already exists - please choose a different username or log in");
            setErrorEmail("");
            setShowPolicy(false);
          }
        }
      })
      .catch((err) => {
        console.log(err)
        //yahan error handle karo
      });
  };

  /*
  if (isAuthenticated) {
    return <Redirect to="/completeprofile" />;
  }
  */

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const modalBodyRef = useRef(null);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
        const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;
        if (event.key === "ArrowUp") {
          modalBodyRef.current.scrollTop -= 10; // Adjust the scrolling speed as per your preference
        } else if (event.key === "ArrowDown") {
          modalBodyRef.current.scrollTop += 10; // Adjust the scrolling speed as per your preference
        }
        if (scrollTop + clientHeight >= scrollHeight) {
          // Scrolled to the bottom
          //console.log('Scrolled to the bottom');
          setAcceptPolicy(true);
        }
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []); // Ensure this effect runs only once

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;
    if (scrollTop + clientHeight >= scrollHeight) {
      // Scrolled to the bottom
      //console.log('Scrolled to the bottom');
      setAcceptPolicy(true);
    }
  };
  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      {showpolicy == true ? (
        <Modal
          show={showpolicy}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClosepolicy}
          //className={showpolicy ? 'xl' : ''}
        >
          <Modal.Header>
            <Modal.Title>Privacy Policy CyberToCyber</Modal.Title>
            <Icon
              className="mx-2 icon-hover"
              icon={remove}
              onClick={handleClosepolicy}
            />
          </Modal.Header>

          <Modal.Body ref={modalBodyRef} onScroll={handleScroll}>
            <p style={{ textAlign: "justify" }}>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </p>
            <p>
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy has been created with the help of the TermsFeed Privacy
              Policy Generator.
            </p>
            <h4 className="mt-4">Interpretation and Definitions</h4>
            <h5 className="mt-2">Interpretation</h5>
            <p style={{ textAlign: "justify" }}>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h5 className="mt-4">Definitions</h5>
            <p style={{ textAlign: "justify" }}>
              For the purposes of this Privacy Policy:
              <br />
              Account means a unique account created for You to access our
              Service or parts of our Service.
              <br />
              Affiliate means an entity that controls, is controlled by or is
              under common control with a party, where "control" means ownership
              of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing
              authority.
              <br />
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to CybertoCbyer Inc.
              <br />
              Cookies are small files that are placed on Your computer, mobile
              device or any other device by a website, containing the details of
              Your browsing history on that website among its many uses.
              <br />
              <br />
              Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
              <br />
              Personal Data is any information that relates to an identified or
              identifiable individual.
              <br />
              Service refers to the Website.
              <br />
              Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company
              in analyzing how the Service is used.
              <br />
              Third-party Social Media Service refers to any website or any
              social network website through which a User can log in or create
              an account to use the Service.
              <br />
              Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
              <br />
              Website refers to Cyber to Cyber, accessible from
              http://cybertocyber.com
              <br />
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </p>
            <h4 className="mt-4">Collecting and Using Your Personal Data</h4>
            <h5 className="mt-4">Types of Data Collected:</h5>
            <h6 className="mt-2">Personal Data</h6>
            <p style={{ textAlign: "justify" }}>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
              <br />
              Email address
              <br />
              First name and last name
              <br />
              Phone number
              <br />
              Address, State, Province, ZIP/Postal code, City
              <br />
              Usage Data
            </p>
            <h6 className="mt-4">Usage Data</h6>
            <p style={{ textAlign: "justify" }}>
              Usage Data is collected automatically when using the Service.
              <br />
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
              <br />
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
              <br />
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
            <h4 className="mt-4">
              Information from Third-Party Social Media Services
            </h4>
            <p style={{ textAlign: "justify" }}>
              The Company allows You to create an account and log in to use the
              Service through the following Third-party Social Media Services:
              <ul>
                <li className="interactive-bullet">Google</li>
                <li className="interactive-bullet">Facebook</li>
                <li className="interactive-bullet">Twitter</li>
                <li className="interactive-bullet">LinkedIn</li>
              </ul>
              If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal data
              that is already associated with Your Third-Party Social Media
              Service's account, such as Your name, Your email address, Your
              activities, or Your contact list associated with that account.
              <br />
              You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media Service's
              account. If You choose to provide such information and Personal
              Data, during registration or otherwise, You are giving the Company
              permission to use, share, and store it in a manner consistent with
              this Privacy Policy.
            </p>

            <h4 className="mt-4">Tracking Technologies and Cookies</h4>
            <p style={{ textAlign: "justify" }}>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
              <ul>
                <br />
                <li className="interactive-bullet">
                  Cookies or Browser Cookies. A cookie is a small file placed on
                  Your Device. You can instruct Your browser to refuse all
                  Cookies or to indicate when a Cookie is being sent. However,
                  if You do not accept Cookies, You may not be able to use some
                  parts of our Service. Unless you have adjusted Your browser
                  setting so that it will refuse Cookies, our Service may use
                  Cookies.
                </li>
                <br />
                <li className="interactive-bullet">
                  Web Beacons. Certain sections of our Service and our emails
                  may contain small electronic files known as web beacons (also
                  referred to as clear gifs, pixel tags, and single-pixel gifs)
                  that permit the Company, for example, to count users who have
                  visited those pages or opened an email and for other related
                  website statistics (for example, recording the popularity of a
                  certain section and verifying system and server integrity).
                </li>
              </ul>
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. You can learn more about cookies on TermsFeed
              website article.
              <br />
              We use both Session and Persistent Cookies for the purposes set
              out below:
              <br />
              <h6 className="mt-4">Necessary / Essential Cookies</h6>
              Type: Session Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
              <br />
              <h6 className="mt-4">
                Cookies Policy / Notice Acceptance Cookies
              </h6>
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
              <br />
              <h6 className="mt-4">Functionality Cookies</h6>
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
              <br />
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>
            <h4 className="mt-4">Use of Your Personal Data</h4>
            <p style={{ textAlign: "justify" }}>
              The Company may use Personal Data for the following purposes:
              <ul>
                <li className="interactive-bullet">
                  To provide and maintain our Service, including to monitor the
                  usage of our Service.
                </li>
                <li className="interactive-bullet">
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user.
                </li>
                <li className="interactive-bullet">
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract
                  with Us through the Service.
                </li>
                <li className="interactive-bullet">
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li className="interactive-bullet">
                  To provide You with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </li>
                <li className="interactive-bullet">
                  To manage Your requests: To attend and manage Your requests to
                  Us.
                </li>
                <li className="interactive-bullet">
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
                <li className="interactive-bullet">
                  For other purposes: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, services,
                  marketing and your experience.
                </li>
              </ul>
              We may share Your personal information in the following
              situations:
              <ul>
                <li className="interactive-bullet">
                  With Service Providers: We may share Your personal information
                  with Service Providers to monitor and analyze the use of our
                  Service, to contact You.
                </li>
                <li className="interactive-bullet">
                  For business transfers: We may share or transfer Your personal
                  information in connection with, or during negotiations of, any
                  merger, sale of Company assets, financing, or acquisition of
                  all or a portion of Our business to another company.
                </li>
                <li className="interactive-bullet">
                  With Affiliates: We may share Your information with Our
                  affiliates, in which case we will require those affiliates to
                  honor this Privacy Policy. Affiliates include Our parent
                  company and any other subsidiaries, joint venture partners or
                  other companies that We control or that are under common
                  control with Us.
                </li>
                <li className="interactive-bullet">
                  With business partners: We may share Your information with Our
                  business partners to offer You certain products, services or
                  promotions.
                </li>
                <li className="interactive-bullet">
                  With other users: when You share personal information or
                  otherwise interact in the public areas with other users, such
                  information may be viewed by all users and may be publicly
                  distributed outside. If You interact with other users or
                  register through a Third-Party Social Media Service, Your
                  contacts on the Third-Party Social Media Service may see Your
                  name, profile, pictures, and description of Your activity.
                  Similarly, other users will be able to view descriptions of
                  Your activity, communicate with You, and view Your profile.
                </li>
                <li className="interactive-bullet">
                  With Your consent: We may disclose Your personal information
                  for any other purpose with Your consent.
                </li>
              </ul>
            </p>

            <h5 className="mt-4">Retention of Your Personal Data</h5>
            <p style={{ textAlign: "justify" }}>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
              <br />
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
            <h5 className="mt-4">Transfer of Your Personal Data</h5>
            <p style={{ textAlign: "justify" }}>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
              <br />
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
              <br />
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <h5 className="mt-4">Delete Your Personal Data</h5>
            <p style={{ textAlign: "justify" }}>
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
              <br />
              Our Service may give You the ability to delete certain information
              about You from within the Service.
              <br />
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
              <br />
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
            <h4 className="mt-4">Disclosure of Your Personal Data</h4>
            <h6 className="mt-2">Business Transactions</h6>
            <p style={{ textAlign: "justify" }}>
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <h6 className="mt-4">Law enforcement</h6>
            <p style={{ textAlign: "justify" }}>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h6 className="mt-4">Law enforcement</h6>
            <p style={{ textAlign: "justify" }}>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h6 className="mt-4">Other legal requirements</h6>
            <p style={{ textAlign: "justify" }}>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
              <ul>
                <li className="interactive-bullet">
                  Comply with a legal obligation
                </li>
                <li className="interactive-bullet">
                  Protect and defend the rights or property of the Company
                </li>
                <li className="interactive-bullet">
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li className="interactive-bullet">
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li className="interactive-bullet">
                  Protect against legal liability
                </li>
              </ul>
            </p>

            <h5 className="mt-4">Security of Your Personal Data</h5>
            <p style={{ textAlign: "justify" }}>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
            <h4 className="mt-4">Children's Privacy</h4>
            <p style={{ textAlign: "justify" }}>
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
              <br />
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>
            <h4 className="mt-4">Links to Other Websites</h4>
            <p style={{ textAlign: "justify" }}>
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
              <br />
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h4 className="mt-4">Changes to this Privacy Policy</h4>
            <p style={{ textAlign: "justify" }}>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
              <br />
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
              <br />
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h4 className="mt-4">Contact Us</h4>
            <p style={{ textAlign: "justify" }}>
              If you have any questions about this Privacy Policy, You can
              contact us:
              <ul>
                <li className="interactive-bullet">
                  By email: info@cybertocyber.com
                </li>
              </ul>
            </p>
          </Modal.Body>
          <Modal.Footer>
            {/** <button
                                      className="btn btn-secondary"
                                      onClick={handleClosepolicy}
                                    >
                                      Close
                                    </button>*/}
            <button
              onClick={() => {
                registered();
              }}
              className="btn btn-primary mx-1"
              disabled={!acceptpolicy}
            >
              Accept
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        console.log("Null")
      )}

      <section className="box-shadow m-auto" >
        {/*
              <div className="text-center logo">
                <Link to="/">
                  <SVGWidgetLogo />
                </Link>
              </div>
    */}

        {/* <h3 className="heading">Join us</h3>
            <p className="description">
              Make the most of your professional life
            </p> */}
        {errorType ? <p className="error-message mb-2">{errorType}</p> : null}

        <Alert />

        <form onSubmit={(e) => onSubmit(e)}>
          {errorFirstName ? (
            <p className="error-message mb-2">{errorFirstName}</p>
          ) : null}
          <Input
            labelfor={`FirstName`}
            id={`FirstName`}
            className={"error"}
            // label={`Username`}
            group={true}
            name={`first_name`}
            placeholder={`First Name`}
            iconType={"names"}
            required={true}
            type={"text"}
            value={first_name}
            handlerOnChange={(e) => onChange(e)}
          >
            {/* <span className="error-text">Error</span> */}
          </Input>
          {errorLastName ? (
            <p className="error-message mb-2">{errorLastName}</p>
          ) : null}

          <Input
            labelfor={`LastName`}
            id={`LastName`}
            className={"error"}
            // label={`Username`}
            group={true}
            name={`last_name`}
            placeholder={`Last Name`}
            iconType={"names2"}
            //icon={<FormIcon icon={'names'} />}
            required={true}
            type={"text"}
            value={last_name}
            handlerOnChange={(e) => onChange(e)}
          >
            {/* <span className="error-text">Error</span> */}
          </Input>

          {errorUsername ? (
            <p className="error-message mb-2">{errorUsername}</p>
          ) : null}
          <Input
            parentclass={`user`}
            labelfor={`userName`}
            id={`user1`}
            className={"error"}
            // label={`Username`}
            group={true}
            name={`user_name`}
            placeholder={`Username`}
            iconType={"user"}
            maxLength={20}
            required={true}
            type={"text"}
            value={user_name}
            handlerOnChange={(e) => onChange(e)}
          >
            {/* <span className="error-text">Error</span> */}
          </Input>

          {errorEmail ? (
            <p className="error-message mb-2">{errorEmail}</p>
          ) : null}
          <Input
            labelfor={`Email`}
            id={`Email`}
            className={"error"}
            // label={`Username`}
            group={true}
            name={`email`}
            placeholder={`Email`}
            iconType={"email"}
            //icon={<FormIcon icon={'email'} />}
            required={true}
            type={"email"}
            value={email}
            handlerOnChange={(e) => onChange(e)}
          >
            {/* <span className="error-text">Error</span> */}
          </Input>

          {errorPassword ? (
            <p className="error-message mb-2">{errorPassword}</p>
          ) : null}
          <Input
            labelfor={`password`}
            id={`password`}
            className={"error"}
            group={true}
            name={`password`}
            placeholder={`Password (8 or more characters)`}
            iconType={"password"}
            //icon={<FormIcon icon={'password'} />}
            required={true}
            type={"password"}
            handlerOnChange={(e) => onChange(e)}
            minLength={8}
            value={password}
          >
          <p className="location-text" style={{fontSize:"10px" , marginTop:"4px" ,  color:"#000000" , fontWeight:"800"}}>Passwords must adhere to the following rules:</p>
          <p className="location-text" style={{fontSize:"10px" , marginTop:"4px"}}>8 characters long, combination of capital, numeric and special character.</p>

            {/* <span className="error-text">Error</span> */}
          </Input>

          {/* {errorPassword2 ? (
                <p className="error-message mb-2">{errorPassword2}</p>
              ) : null} */}
          {/* 
              <Input
                labelfor={`ConfirmPassword`}
                id={`ConfirmPassword`}
                className={"error"}
                // label={`Username`}
                group={true}
                name={`password2`}
                placeholder={`Confirm Password`}
                iconType={"confirmpassword"}
                //icon={<FormIcon icon={'password'} />}
                required={true}
                type={"password"}
                handlerOnChange={(e) => onChange(e)}
                minLength={8}
                value={password2}
              >
              </Input> */}
          {/* <span className="error-text">Error</span> */}

          {errorLocation ? (
            <p className="error-message mb-2">{errorLocation}</p>
          ) : null}
          <div className="form-group">
            {/**<label className="form-label mb-1">
              Select Your Location <span className="required-field">*</span>
            </label>

            <select
              className="form-control"
              name="location"
              value={formData.location}
              required={true}
              onChange={(e) => onChange(e)}
            >
              <option>Please Select</option>
              {countries.map((item) => (
                <option value={item.name} data-isoCode={item.isoCode}>
                  {item.name}
                </option>
              ))}
            </select> */}
            <FormControl variant="standard" style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-standard-label" style={{ color: '#2AAAE1' }}>
              Location *
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              value={formData.location}
              onChange={(e) => onChange(e)}
              inputProps={{
                name: 'location',
                id: 'location',
              }}
              required={true}
              label={'Location'}
              style={{
                paddingLeft: "14px",
                fontFamily: "Inter, Arial, sans-serif", // Custom font family
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {countries.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </div>

          <div className="row justify-content-between">
            <div className="col-12 agreement-checkbox">
              {/** {errors.error_checked && <p className="error-message mb-2">{errors.error_checked}</p>}             
                <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" className="custom-control-input" id="customCheck1" checked={formData.checked} onChange={() => setShowPolicy(true)}/>
                    <label className="custom-control-label" htmlFor="customCheck1">You agree to CyberToCyber <Link to="/privacyPolicy" onClick={scrollToTop}>Privacy Policy</Link>.</label>
                </div>
                */}
            </div>
          </div>

          <button className="btn btn-default btn-block" type="submit">
            {buttonText()}
          </button>

          {/*<span className="text"><Link to="/registeradmin">Admin Register</Link></span>*/}
          {/* <div className="py-3 login-here d-flex justify-content-between">
            <span className="text">
              Already have an account?{" "}
              <Link to="/login" onClick={scrollToTop}>
                Sign In
              </Link>
            </span>
          </div> */}
        </form>
      </section>
    </>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProp, { setAlert, register, sendEmailFunc })(
  Register
);
