import { useEffect } from "react";

const Captcha = ({
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  userCaptcha,
  setUserCaptcha,
}) => {
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, [loadCaptchaEnginge]);

  const handleInputChange = (event) => {
    setUserCaptcha(event.target.value);
  };

  return (
    <div className="d-flex">
      <LoadCanvasTemplateNoReload />
      <input
        placeholder="Enter Captcha"
        id="user_captcha_input"
        name="user_captcha_input"
        type="text"
        value={userCaptcha}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default Captcha;
