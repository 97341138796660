import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { forgotPassword } from "../../actions/auth";
import PropTypes from "prop-types";
import { Icon } from "react-icons-kit";
import { envelopeO } from "react-icons-kit/fa/envelopeO";
import { sendEmailFunc } from "../../actions/sendEmail";
import Alert from "../../components/alert/Alert";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./forgotPassword.css";
import Input from "../../components/input/input";
import { SVGWidgetLogo, FormIcon } from "../../components/SVG";
import document from "ejs/ejs";

import Captcha from "../../components/captcha/Captcha";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { setAlert } from "../../actions/alert";

import { Helmet } from "react-helmet";
import { Height } from "@material-ui/icons";

const ForgetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [key, setKey] = useState("email");
  const [phone, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(1);
  const [btnDisable, setBtnDisable] = useState(false);
  const [formData, setFormData] = useState({
    emailAddress: "",
  });

  const [userCaptcha, setUserCaptcha] = useState("");

  const { emailAddress } = formData;

  useEffect(() => {
    document.title = "Forget Password";
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const goBack = () => {
    // history.goBack();
    props.setResetPassword(false)
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setBtnDisable(true);

    if (!validateCaptcha(userCaptcha)) {
      dispatch(setAlert("Captcha Does Not Match", "danger"));
      setUserCaptcha("");
      setBtnDisable(false);
      return;
    }

    let body;
    if (key === "email") {
      body = {
        email: formData.emailAddress,
        reset_type: key,
      };
    }
    if (key === "phone") {
      body = {
        phone: phone,
        reset_type: key,
      };
    }
    dispatch(forgotPassword(body, setLoading, setSteps, history)).then(() => {
      setBtnDisable(false);
    });
    // try {
    //   setLoading(true);
    //   const res = await axios.post('/api/auth/forgot-password', body);

    //   setLoading(false);

    //   if (body.reset_type === 'email') {
    //     setSteps(0);
    //     setTimeout(() => {
    //       history.push('/')
    //     }, 2000);
    //   } else {
    //     history.push('/change-password?reset_type=phone')
    //   }
    // } catch (err) {
    //   setLoading(false);
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
    //   }
    // }
  };

  return (
    <section className="w-100 page-forgetpassword page-auth page-registration">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className=" container p-5">
        <section className="box-shadow m-auto mb-5">
          {steps === 1 && (
            <div className="login">
              <h3 className="heading">Forgot your password?</h3>
              <p className="description">
                Please enter your email or phone to reset your password
              </p>

              {/* <div className="text-center mb-4">
                <Link to="/">
                  <img src="assets/images/fav.svg" alt="" />
                </Link>              
              </div> */}

              <Alert />
              <Input
                    parentclass={`emailAddress`}
                    labelfor={`emailAddress`}
                    id={`emailAddress`}
                    className={"error"}
                    // label={`Email`}
                    group={true}
                    name={`emailAddress`}
                    placeholder={`Email`}
                    iconType={"email"}
                    required={true}
                    type={"email"}
                    value={emailAddress}
                    handlerOnChange={(e) => onChange(e)}
                  >
                       {/* <span className="error-text">Error</span> */}
                  </Input>
              {/* <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="email" title="Reset using Email">
                  <Input
                    parentclass={`emailAddress`}
                    labelfor={`emailAddress`}
                    id={`emailAddress`}
                    className={"error"}
                    // label={`Email`}
                    group={true}
                    name={`emailAddress`}
                    placeholder={`Email`}
                    iconType={"email"}
                    required={true}
                    type={"email"}
                    value={emailAddress}
                    handlerOnChange={(e) => onChange(e)}
                  >
                    {/* <span className="error-text">Error</span> */}
                  {/* </Input> */}
                  {/*
                  <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            <div class="g-recaptcha" data-sitekey="YOUR_SITE_KEY">asdasd</div>*/}

                  {/* <div className="form-group">
                    <Alert />
                    <label className="mb-1">Email</label>
                    <div className="position-relative icon-form-control">
                      <Icon className="position-absolute icon-react" icon={envelopeO} />
                      <input
                        type="email"
                        className="form-control"
                        name="emailAddress"
                        value={emailAddress}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div> */}
                  <div
                    className="mb-4 p-5 captcha-container"
                    style={{ width: "100%" }}
                  >
                    <Captcha
                      loadCaptchaEnginge={loadCaptchaEnginge}
                      LoadCanvasTemplateNoReload={LoadCanvasTemplateNoReload}
                      userCaptcha={userCaptcha}
                      setUserCaptcha={setUserCaptcha}
                      style={{ width: "100%", height: "auto" }} // Testing full width
                    />
                  </div>

                  <button
                    className="btn btn-default btn-block"
                    type="button"
                    onClick={(e) => onSubmit(e)}
                    disabled={btnDisable}
                  >
                    {" "}
                    Send Email{" "}
                  </button>
                {/* </Tab> */}
                {/* <Tab eventKey="phone" title="Reset using Phone">
                  <div className="form-group">
                    <Alert />
                     <label className="mb-1">Phone</label> 
                    <div className="icon-form-control form-group cyber-form-control">
                      
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="PK"
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={setValue}
                        error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'} />
                      <span className="error-text">{phone && isValidPhoneNumber(phone) ? '' : 'Incorrect Phone Number'}</span>
                      

                    </div>
                  </div>
                  <button disabled={!isValidPhoneNumber(phone)} className="btn btn-default btn-block" type="button" onClick={onSubmit}> Send OTP </button>

                </Tab> */}
              {/* </Tabs> */} 
              <div className="py-3 d-flex align-items-center justify-content-between join-now">
                <span>
                  Already have CyberToCyber account?{" "}
                  <span
                    onClick={goBack}
                    style={{ cursor: "pointer", color: "#007bff", textDecoration: "underline" }}
                  >
                    Sign In
                  </span>
                </span>
              </div>
              <div className="py-3 d-flex align-item-center join-now">
                {/*<span className="ml-auto">
                  <Link to="/login">Sign In</Link>
                  <span className="ml-auto"> New to freelance? <Link to="/register">Join now</Link></span>
                </span>*/}
              </div>
            </div>
          )}

          {steps === 0 && (
            <div className="osahan-login py-4">
              <h3 className="heading">Recovery link sent to your email.</h3>
              <p className="description">Please check your email.</p>
            </div>
          )}

          {/* <div className="bkg-login">
      <div className="container">
        <div className="row justify-content-center align-items-center d-flex">
          <div className="col-lg-4 mx-auto">
            
            
          </div>
        </div>
      </div>
    </div> */}
        </section>
       
      </div>
   
    </section>
  );
};

ForgetPassword.propTypes = {
  forgetpassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  sendEmail: state.sendEmail,
});

export default connect(mapStateToProp, { forgotPassword })(ForgetPassword);
