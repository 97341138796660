import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";

import { approveWithdrawal, rejectWithdrawal } from "../../actions/withdrawal";

import Skeleton from "./../../components/skeleton";
import { remove } from "react-icons-kit/fa/remove";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
export default function WithdrawalList({ withdrawals, user }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showDetailForm, setShowDetailFrom] = useState(false);
  const [showApproveFrom, setShowApproveForm] = useState(false);
  const [showRejectFrom, setShowRejectForm] = useState(false);

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [description, setDescription] = useState("");

  const [filter, setFilter] = useState("All");

  const [paginatedDataItem, setPaginatedDataItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setPaginatedDataItem(withdrawals);
  }, [withdrawals]);

  const handleApprove = async (id) => {
    setLoading(true);
    await dispatch(approveWithdrawal(id));
    setLoading(false);
    resetApproveForm();
  };
  const handleReject = async (id) => {
    setLoading(true);
    await dispatch(rejectWithdrawal(id, description));
    setLoading(false);
    resetRejectForm();
  };

  const resetDetailForm = () => {
    setShowDetailFrom(false);
    setSelectedRequest(null);
  };
  const resetApproveForm = () => {
    setShowApproveForm(false);
    setSelectedRequest(null);
  };
  const resetRejectForm = () => {
    setShowRejectForm(false);
    setSelectedRequest(null);
  };

  const applyFilter = (arr) => {
    if (filter === "All") return arr;
    return arr.filter((item) => item.status === filter);
  };

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = paginatedDataItem
    ? [...paginatedDataItem].slice(startIndex, endIndex)
    : null;
  const totalPages = Math.ceil(
    applyFilter(paginatedDataItem)?.length / resultsPerPage
  );

  //console.log(withdrawals);

  return (
    <div className="box-table bg-white border border-top-0 p-2">
      {/* Detail modal */}
      <Modal
        show={showDetailForm}
        onHide={resetDetailForm}
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header>
          <Modal.Title className="mt-4 font-weight-light"> <span className="font-weight-bold text-dark">Withdrawal Request</span> -  {selectedRequest?._id}</Modal.Title>
          <Icon
            className="mx-2 icon-hover"
            icon={remove}
            onClick={resetDetailForm}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap" style={{ gap: 24 }}>
            {/* <div className="d-flex flex-wrap" style={{ gap: 32 }}>
              <div className="d-flex flex-column">
                <p>Amount</p>
                <h4 className="font-weight-light">${selectedRequest?.amount}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Status</p>
                <h4 className="font-weight-light">{selectedRequest?.status}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Requested at</p>
                <h4 className="font-weight-light">
                  {new Date(selectedRequest?.createdAt || Date.now())
                    ?.toISOString()
                    ?.replace("T", " ")
                    ?.slice(0, 16)}
                </h4>
              </div>
              <div className="d-flex flex-column">
                <p>
                  {selectedRequest?.status === "Rejected"
                    ? "Rejected"
                    : "Approved"}{" "}
                  at
                </p>
                <h4 className="font-weight-light">
                  {selectedRequest?.status !== "Pending"
                    ? new Date(selectedRequest?.updatedAt || Date.now())
                        ?.toISOString()
                        ?.replace("T", " ")
                        ?.slice(0, 16)
                    : "-"}
                </h4>
              </div>
              <div className="d-flex flex-column">
                <p>Freelancer Username</p>
                <h4 className="font-weight-light">{selectedRequest?.user_id?.user_name}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Freelancer Email</p>
                <h4 className="font-weight-light">{selectedRequest?.user_id?.email}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Bank type</p>
                <h4 className="font-weight-light">
                  {selectedRequest?.bank?.type === "local"
                    ? "Local Saudi Bank"
                    : "International Bank"}
                </h4>
              </div>
              <div className="d-flex flex-column">
                <p>IBAN No</p>
                <h4 className="font-weight-light">{selectedRequest?.bank?.iban}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Beneficiary Name</p>
                <h4 className="font-weight-light">{selectedRequest?.bank?.beneficiary_name}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Bank Name</p>
                <h4 className="font-weight-light">{selectedRequest?.bank?.bank_name}</h4>
              </div>
              <div className="d-flex flex-column">
                {selectedRequest?.description ? (
                  <>
                    <p>Rejected Reason</p>
                    <h4 className="font-weight-light">{selectedRequest?.description}</h4>
                  </>
                ) : null}
              </div>
            </div> */}

            <div className="row g-2" style={{ gap: 9 }}>
              <div className="col-md-2 col-3   mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Amount</h4>
                <p className="font-weight-light mb-2">${selectedRequest?.amount}</p>
                
              </div>
              <div className="col-md-2  col-3   mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Status</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.status}</p>
              </div>
              <div className="col-md-2  col-5  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Requested at</h4>
                <p className="font-weight-light mb-2">
                  {new Date(selectedRequest?.createdAt || Date.now())
                    ?.toISOString()
                    ?.replace("T", " ")
                    ?.slice(0, 16)}
                </p>
              </div>
              <div className="col-md-2  col-3  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">
                  {selectedRequest?.status === "Rejected"
                    ? "Rejected"
                    : "Approved"}{" "}
                  at
                </h4>
                <p className="font-weight-light mb-2">
                  {selectedRequest?.status !== "Pending"
                    ? new Date(selectedRequest?.updatedAt || Date.now())
                      ?.toISOString()
                      ?.replace("T", " ")
                      ?.slice(0, 16)
                    : "-"}
                </p>
              </div>
              <div className="col-md-3  col-4  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Freelancer Username</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.user_id?.user_name}</p>
              </div>
              <div className="col-md-2  col-4  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Freelancer Email</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.user_id?.email}</p>
              </div>
              <div className="col-md-2  col-4  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Bank type</h4>
                <p className="font-weight-light mb-2">
                  {selectedRequest?.bank?.type === "local"
                    ? "Local Saudi Bank"
                    : "International Bank"}
                </p>
              </div>
              <div className="col-md-2  col-3  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">IBAN No</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.iban}</p>
              </div>
              <div className="col-md-3  col-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary Name</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_name}</p>
              </div>
              <div className="col-md-2  col-4  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Bank Name</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.bank_name}</p>
              </div>
              {selectedRequest?.description && (
                <div className="col-md-3  col-4  mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                  <h4 className="text-primary mb-1">Rejected Reason</h4>
                  <p className="font-weight-light mb-2">{selectedRequest?.description}</p>
                </div>
              )}
            </div>


            {/* <div className="d-flex flex-wrap" style={{ gap: 32 }}>
              <div className="d-flex flex-column">
                <p>Freelancer Username</p>
                <h4 className="font-weight-light">{selectedRequest?.user_id?.user_name}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Freelancer Email</p>
                <h4 className="font-weight-light">{selectedRequest?.user_id?.email}</h4>
              </div>
            </div> */}

            {/* <div className="d-flex flex-wrap" style={{ gap: 32 }}>
              <div className="d-flex flex-column">
                <p>Bank type</p>
                <h4 className="font-weight-light">
                  {selectedRequest?.bank?.type === "local"
                    ? "Local Saudi Bank"
                    : "International Bank"}
                </h4>
              </div>
              <div className="d-flex flex-column">
                <p>IBAN No</p>
                <h4 className="font-weight-light">{selectedRequest?.bank?.iban}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Beneficiary Name</p>
                <h4 className="font-weight-light">{selectedRequest?.bank?.beneficiary_name}</h4>
              </div>
              <div className="d-flex flex-column">
                <p>Bank Name</p>
                <h4 className="font-weight-light">{selectedRequest?.bank?.bank_name}</h4>
              </div>
              <div className="d-flex flex-column">
                {selectedRequest?.description ? (
                  <>
                    <p>Rejected Reason</p>
                    <h4 className="font-weight-light">{selectedRequest?.description}</h4>
                  </>
                ) : null}
              </div>
            </div> */}

            {selectedRequest?.bank?.type !== "local" && (
              <div className="row g-2" style={{ gap: 6 }}>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Swift Code</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.swift_code}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Bank Country</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.bank_country}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Bank City</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.bank_city}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Place of Issuance</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.place_of_issuance}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary ID No</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_id_no}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary Date of Birth</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_date_of_birth}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary Phone</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_phone}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary Address</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_address}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary Country</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_country}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary City</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_city}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary State</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_state}</p>
              </div>
              <div className="col-md-2 col-sm-4 mb-3" style={{borderBottom: "1px solid #aba9a9"}}>
                <h4 className="text-primary mb-1">Beneficiary Zip Code</h4>
                <p className="font-weight-light mb-2">{selectedRequest?.bank?.beneficiary_zip_code}</p>
              </div>
            </div>
            
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={resetDetailForm}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Approve modal */}
      <Modal
        show={showApproveFrom}
        onHide={resetApproveForm}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Approve Withdrawal Request?</Modal.Title>
          <Icon
            className="mx-2 icon-hover"
            icon={remove}
            onClick={resetApproveForm}
          />
        </Modal.Header>
        <Modal.Body>
          Are you sure to approve this withdrawal request? this action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={resetApproveForm}
            disabled={loading}
          >
            No
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleApprove(selectedRequest._id)}
            disabled={loading}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Reject modal */}
      <Modal
        show={showRejectFrom}
        onHide={resetRejectForm}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Reject Withdrawal Request?</Modal.Title>
          <Icon
            className="mx-2 icon-hover"
            icon={remove}
            onClick={resetRejectForm}
          />
        </Modal.Header>
        <Modal.Body>
          Are you sure to reject this withdrawal request? this action cannot be
          undone.
          <Form.Group className="my-3">
            <Form.Label>Reject reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              type="text"
              placeholder="Reject reason (optional)"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="my-2 w-100"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={resetRejectForm}
            disabled={loading}
          >
            No
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleReject(selectedRequest._id)}
            disabled={loading}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-end align-items-center">
        <Form.Group className="my-2">
          <Form.Label>Filter status</Form.Label>

          <select
            className="form-control"
            id="select"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{ marginRight: "32px", marginBottom: "12px" }}
          >
            <option value="All">All</option>
            {["Pending", "Approved", "Rejected"].map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </Form.Group>
      </div>
      <div style={{ overflowX: "scroll" }}>
        <table
          className="table table-layout-fixed m-0"
          style={{ overflowX: "auto", minWidth: "350px" }}
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {withdrawals === null &&
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                <tr key={i}>
                  <td>
                    <Skeleton height={25} />
                  </td>
                  <td>
                    <Skeleton height={25} />
                  </td>
                  <td>
                    <Skeleton height={25} />
                  </td>
                  <td>
                    <Skeleton height={25} />
                  </td>
                </tr>
              ))}

            {withdrawals !== null &&
              (currentResults && currentResults.length > 0 ? (
                applyFilter([...currentResults]).map((wd) => (
                  <tr key={wd._id}>
                    <td>
                      {new Date(wd.createdAt)
                        ?.toISOString()
                        ?.replace("T", " ")
                        ?.slice(0, 16)}
                    </td>
                    <td>{wd.amount}</td>
                    <td>{wd.status}</td>
                    <td className="d-flex flex-wrap" style={{ gap: 8 }}>
                      {user.type === "Admin" && wd.status === "Pending" && (
                        <>
                          <Button
                            variant="primary"
                            disabled={loading}
                            onClick={() => {
                              setSelectedRequest(wd);
                              setShowApproveForm(true);
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="danger"
                            disabled={loading}
                            onClick={() => {
                              setSelectedRequest(wd);
                              setShowRejectForm(true);
                            }}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                      <Button
                        variant="primary"
                        disabled={loading}
                        onClick={() => {
                          setSelectedRequest(wd);
                          setShowDetailFrom(true);
                        }}
                      >
                        Details
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No record found
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {totalPages > 1 && (
          //FIXED PAGINATION

          <nav>
            <ul className="talent-pagination pagination justify-content-center">
              <div
                className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() =>
                  handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                }
              >
                <Icon icon={arrowLeft} />
              </div>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${pageNumber === currentPage ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
              <div
                className={`icon ${currentPage === totalPages ? "disabled" : ""
                  }`}
                onClick={() =>
                  handlePageChange(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
              >
                <Icon icon={arrowRight} />
              </div>
            </ul>
          </nav>

          /*
          OLD PAGINATION
          <nav>
            <ul className="pagination justify-content-center">
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
            </ul>
          </nav> */
        )}
      </div>
    </div>
  );
}
