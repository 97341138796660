import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import Alert from "../../components/alert/Alert";
import Input from "../../components/input/input";
import { SVGWidgetLogo, FormIcon } from "../../components/SVG";
import ForgetPassword from "../forgetPassword/forgetPassword";

const Login = ({ setAlert, login, isAuthenticated, user }) => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
  });

  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const dispatch = useDispatch();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const { user_name, password } = formData;

  useEffect(() => {
    if (localStorage.getItem("username")) {
      formData["user_name"] = localStorage.getItem("username");
      setFormData(formData);
    }
    if (localStorage.getItem("password")) {
      formData["password"] = localStorage.getItem("password");
      setFormData(formData);
    }
  }, []);

  useEffect(() => {
    document.title = "Login";

    if (isAuthenticated) {
      if (user.type === "Admin") {
        history.push("/admindashboard");
      } else {
        history.push("/Dashboard");
      }
    }
  }, [isAuthenticated]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (remember) {
      localStorage.setItem("username", user_name);
      localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    }
    setLoading(true);
    login(user_name, password, () => history.push("/Dashboard")).then((res) => {
      setLoading(false);
    });
  };

  return (
    <>
    { !resetPassword ?
    <section className="box-shadow m-md-auto ">
      {/*
          <div className="text-center logo">
            <Link to="/">
              <SVGWidgetLogo />
            </Link>
          </div>
          */}
      {/* <h3 className="heading">Welcome Back</h3> */}
      {/* <p className="description">
            Don't miss your next opportunity.<br />
            Sign in to stay updated on your professional world.
          </p> */}
      <Alert />

      <form onSubmit={(e) => onSubmit(e)}>
        <Input
          parentclass={`user`}
          labelfor={`userName`}
          id={`user1`}
          className="form-control"
          // label={`Username`}
          group={true}
          name={"user_name"}
          placeholder={`Username or email`}
          iconType={"user"}
          required={true}
          type={"text"}
          value={formData.user_name}
          handlerOnChange={(e) => onChange(e)}
        ></Input>
        <Input
          parentclass={`password`}
          labelfor={`password`}
          id={`user1`}
          className="form-control"
          // label={`Username`}
          group={true}
          name={"password"}
          placeholder={`Password`}
          iconType={"password"}
          required={true}
          type={"password"}
          handlerOnChange={(e) => onChange(e)}
          minLength={6}
          value={password}
        ></Input>
        <div className="row justify-content-between">
          <div className="col-lg-7 col-md-7 col-sm-7 col-7  remember-password">
            <div className="custom-control custom-checkbox mb-3 p-0">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
                onClick={() => setRemember(!remember)}
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Remember password
              </label>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-5  forget-password d-flex justify-content-end">
            <Link 
            // to="/ForgetPassword" 
            // onClick={scrollToTop}
            onClick={() => setResetPassword(true)}

            >
              Forgot password?
            </Link>
          </div>
        </div>
        <button
          className="btn btn-default btn-block mt-2"
          type="submit"
          disabled={loading}
        >
          Login
        </button>
        {/* <div className="py-3 d-flex align-item-center join-now">
          <span>
            {" "}
            New to CybertoCyber?{" "}
            <Link to="/register" onClick={scrollToTop}>
              Join now
            </Link>
          </span>
        </div> */}
      </form>
    </section>
    :
    <ForgetPassword setResetPassword={setResetPassword}/>
        }
    </>
  );
};

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProp, { setAlert, login })(Login);
