import { Link, useLocation } from "react-router-dom";
import classes from "./registerRole.module.css";
import agency from "./images/agency.svg";
import client from "./images/client.svg";
import hire from "./images/hire.svg";
import pro from "./images/pro.svg";
import { useState } from "react";
import BtnRole from "./BtnRole";

const cards = [
  {
    id: "individual",
    title: "Request Service as an Individual.",
    content:
      "Tell us the problem you are facing to get personalized cybersecurity services. Complete our tailored form to get started.",
    img: agency,
    link: "/question1/individual",
  },
  {
    id: "company",
    title: "Request Service as a Company",
    content:
      "Don’t let hackers compromise security of your company. Get all your problems solved with our talented experts. Let’s tell us your problem.",
    img: hire,
    link: "/question1/company",
  },

];

export default function RequestService() {
  const [selected, setSelected] = useState("");
debugger
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type") || "signup";

  function handleSelect(id) {
    if (id === selected) setSelected("");
    else setSelected(id);
  }

  let to = "/";
  let btnLink;
  if (selected === "individual") to = "/question1/individual";
  if (selected === "company") to = "/question1/company";
  if (selected === "client") to = `/login-register?role=client&type=${type}`;
  if (selected === "pro") to = `/login-register?role=pro&type=${type}`;

  if (!selected) {
    btnLink = <button className="wm-100">Next</button>;
  } else {
    btnLink = <Link to={to} className="wm-100">Next</Link>;
  }
  return (
    <section className="box-under-container page-registration-service page-auth">
      <div className={`container ${classes.roleContainer}`}>
        <header className={classes.header}>
          <h1>Facing a cybersecurity issue or incident?</h1>
          <p>
          Request a service and let our AI-powered algorithm connect you with top cyber experts.
          </p>
        </header>
        <main className={classes.cards}>
          {cards.map((card, index) => (
            <BtnRole
              key={card.id}
              {...card}
              onClickHandler={handleSelect}
              selected={selected}
            />
          ))}
        </main>
        <footer className={classes.footer}>{btnLink}</footer>
      </div>
    </section>
  );
}
