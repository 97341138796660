import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import { Icon } from 'react-icons-kit';
import { sendO } from 'react-icons-kit/fa/sendO';
import { messageGetbyId, proposalGetbyId, messageAdd, messageDelete, messageRead , proposalRead, contractGetbyId } from "../../actions/proposalAndContract";
import './chat.css';
import { FileUploader } from "react-drag-drop-files";

import { filePdfO } from 'react-icons-kit/fa/filePdfO';
import { fileWordO } from 'react-icons-kit/fa/fileWordO';
import { fileExcelO } from 'react-icons-kit/fa/fileExcelO';
import { filePowerpointO } from 'react-icons-kit/fa/filePowerpointO';
import { fileImageO } from 'react-icons-kit/fa/fileImageO';
import { ic_folder } from 'react-icons-kit/md/ic_folder';
import { upload } from 'react-icons-kit/fa/upload';
import { trash } from 'react-icons-kit/fa/trash'
import { download } from 'react-icons-kit/fa/download'
import { fileDownload, uploadFile } from "../../actions/file-crud";
import { checkSquareO } from 'react-icons-kit/fa/checkSquareO';
import { check } from 'react-icons-kit/fa/check';
import moment from "moment";
import Highlighter from "react-highlight-words";
import Modal from 'react-bootstrap/Modal';

import { allow_release } from "../../actions/proposalAndContract";
import { useHistory } from "react-router-dom";

const Chat = ({ socket, founds, pagination, setFounds, username, searchString, room, proposal_id, freelancer_id, hire_manager_id, auth: { user }, proposalAndContract: { MessagesItemData, ProposalsItemData, ContractsItemData }, messageGetbyId, proposalGetbyId, messageAdd, contractGetbyId }) => {

  //console.log(proposal_id)
  const dispatch = useDispatch();

  const textAreaRef = useRef(null);
  const emojiRef = useRef(null);

  const [currentMessage, setCurrentMessage] = useState("");

  const [MessagesItems, setMessageList] = useState([]);
  const [ProposalItem, setProposalItemData] = useState([]);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const [tempFile, setTempFile] = useState(null);
  const [tempImage, setTempImage] = useState(null);

  const [showedAttachment, setShowedAttachment] = useState("file");

  const [isEventListenerAdded, setEventListenerAdded] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [systemMessage, setSystemMessage] = useState('');

  const [modalState, setModalState] = useState({
    isVisible: false,
    messageContent: "",
    type: ""
  });
  
  const handleOpenModal = (message = "", type = "") => setModalState({
    isVisible: true,
    messageContent: message,
    type: type
  });
  
  const handleCloseModal = () => setModalState(prevState => ({
    ...prevState,
    isVisible: false
  }));

  const history = useHistory();

  const fileTypes = ["PDF", "DOC", "DOCX", "XLS", "XLSX", "PPT", "PPTX"];
  const imageTypes = ["JPEG", "PNG", "JPG"];
  

  const handleChange = (file) => {
    setFile(file);
    const fileUrl = URL.createObjectURL(file);
    setTempFile(fileUrl);
    setShowedAttachment(null);
  };
  
  const handleChangeImage = (image) => {
    setImage(image);
    const imageUrl = URL.createObjectURL(image);
    setTempImage(imageUrl);
    setShowedAttachment(null);
  }

  const handleRemoveFile = () => {
    setFile(null);
    setTempFile(null);
    setImage(null);
    setTempFile(null);
  }

  const handleAddEmoji = useCallback((e) => {
    const emoji = e.detail.unicode;
    setCurrentMessage((curr) => {
      const textBeforeCursor = curr.slice(0, cursorPosition);
      const textAfterCursor = curr.slice(cursorPosition);
      return textBeforeCursor + emoji + textAfterCursor;
    });
    setCursorPosition((prevPosition) => prevPosition + emoji.length);
  }, [cursorPosition]);

  const handleShowAttachment = (type) => {
    if (type === showedAttachment) {
      setShowedAttachment(null);
    } else {
      setShowedAttachment(type);
    }
  }
  
  const insertNewLine = () => {
    const textarea = textAreaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const textBefore = textarea.value.substring(0, start);
    const textAfter = textarea.value.substring(end);

    const newText = textBefore + '\n' + textAfter;

    textarea.value = newText;
    textarea.selectionStart = textarea.selectionEnd = start + 1;
  };
  
  const sendMessage = async () => {
    
    setShowEmojis(false);

    if (file !== null || image !== null) {
      const formData = new FormData();
      
      if (image) {
        formData.append("file", image);
      } else {
        formData.append("file", file);
      }

      Promise.all([setLoading(true), dispatch(uploadFile(formData))]).then(async (res) => {
        var message_type = "file";
        var message_file_properties = {
          file_name: res[1].key,
          file_url: res[1].location,
          file_type: res[1].mimetype
        }
        const messageData = {
          room: room,
          user_name: username,
          read: false,
          message_text: currentMessage,
          message_type,
          message_file_properties,
          message_time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
        };
        await socket.emit("send_message", messageData);
        setMessageList((list) => [...list, messageData]);

        var proposal_catalog_status_id = "";
        var user_name = username;
        var message_text = currentMessage;

        messageAdd({
          freelancer_id,
          hire_manager_id,
          user_name,
          message_type,
          message_file_properties,
          message_text,
          proposal_id,
          proposal_catalog_status_id,
          user
        });

        setCurrentMessage("");

        setShowedAttachment(null);

        setFile(null);
        setImage(null);

        setLoading(false);
      });
    } else {
      if (currentMessage !== "") {
        setLoading(true);
        const messageData = {
          room: room,
          user_name: username,
          message_text: currentMessage,
          message_type: 'text',
          read: false,
          message_time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };
        await socket.emit("send_message", messageData);
        //console.log(messageData);
        setMessageList((list) => [...list, messageData]);
        var proposal_catalog_status_id = "";
        var user_name = username;
        var message_text = currentMessage;
        messageAdd({
          freelancer_id,
          hire_manager_id,
          user_name,
          message_type: 'text',
          message_text,
          proposal_id,
          proposal_catalog_status_id,
          user
        });

        setCurrentMessage("");
        setShowedAttachment(null);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    
    setMessageList([]);
    //messageGetbyId(proposal_id);
    //proposalGetbyId(proposal_id);

    const script = document.createElement('script');

    script.src = "https://cdn.jsdelivr.net/npm/emoji-picker-element@^1/index.js";
    script.async = true;
    script.type = "module";

    document.body.appendChild(script);


    return () => {
      document.body.removeChild(script);
    }
  }, [MessagesItemData, ContractsItemData]);

  //console.log("proposal by id:   ",proposal_id)
  const jobID = ProposalsItemData.job_id;
  //console.log("job id of proposal:   ", jobID)

  //console.log("all contract data:   ",ContractsItemData)
  

  const matchingContract = ContractsItemData?.find(contract => contract?.job_id?._id === jobID);

  const handleContract = (id) => {
    contractGetbyId(id)
      .then((res) => {
        if (res) {
          const value = "release-payment";
          //history.push("/contractDetails");
          history.push(`/contractDetails?value=${value}`); 
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const allowLatestMilestone = async (id) => {
    setLoading(true);
    await dispatch(allow_release(id));
    setLoading(false);    
  }

  const handleContract1 = (id) => {
      contractGetbyId(id)
        .then((res) => {
          if (res) {
            //const value = "release-payment";
            history.push("/contractDetails");
            //history.push(`/contractDetails?value=${value}`); 
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

  useEffect(() => {
    if (searchString.length > 0) {
      setFounds(MessagesItems.filter(item => item.message_text?.toLowerCase()?.includes(searchString?.toLowerCase())).reverse());
    } else {
      setFounds([])
    }
  }, [searchString]);

  useEffect(() => {
    if (emojiRef.current && !isEventListenerAdded) {
      emojiRef.current.addEventListener("emoji-click", handleAddEmoji);
      setEventListenerAdded(true);
    }

    return () => {
      if (emojiRef.current && isEventListenerAdded) {
        emojiRef.current.removeEventListener("emoji-click", handleAddEmoji);
        setEventListenerAdded(false);
      }
    };
  }, [handleAddEmoji, isEventListenerAdded]);

  useEffect(() => {
    setMessageList(MessagesItemData);
  }, [MessagesItemData]);

  useEffect(() => {
    setProposalItemData(ProposalsItemData);
  }, [ProposalsItemData]);

  useEffect(() => {
    socket.on("receive_message", async (data) => {
      setMessageList((list) => [...list, data]);
      dispatch(messageRead(proposal_id, async () => await socket.emit("receive_message_successfull", data)));
    });
    socket.on("receive_message_successfull_done", async () => {
      messageGetbyId(proposal_id)
    });
  }, [socket]);

  function IconType(type) {
    if (type.includes("jpg") || type.includes("jpeg") || type.includes("png")) {
      return fileImageO
    } else if (type.includes("spreadsheet")) {
      return fileExcelO
    } else if (type.includes("pdf")) {
      return filePdfO
    } else if (type.includes(".doc")) {
      return fileWordO
    } else if (type.includes("presentation")) {
      return filePowerpointO
    } else {
      return ic_folder
    }
  }

  function isImageFileName(filename) {
    const imageExtensions = /^image\/(jpg|jpeg|png|gif)$/i;
    return imageExtensions.test(filename);
}

  return (
    <>
        <div className="messages-box--chat-box p-3 border-bottom bg-light position-relative">
          <ScrollToBottom className="message-container h-100">
            <>{/*ProposalItem.freelancer_comment||ProposalItem.client_comment &&
              <div className="d-flex align-items-center osahan-post-header" id={username === ProposalItem.user_name ? "you" : "other"}>
                {console.log(ProposalItem.freelancer_comment)}
                {console.log(ProposalItem.client_comment)}
                <p className="p-2 bg-white rounded"><span className="messageSize">{ProposalItem.freelancer_comment||ProposalItem.client_comment}</span>  <span className="text-right text-muted pt-1 small">{ProposalItem.proposal_time}</span></p>
              </div>
             */}
            </>
            {MessagesItems.length > 0 && [...MessagesItems].map((messageContent, index) => {
              return (
                <div key={messageContent?._id || `message${index}`} id={messageContent?._id || `message${index}`} className="chat-area">
                  
                  {moment(MessagesItems[index - 1]?.message_date || 0).format('MM-DD-YYYY') !== moment(messageContent.message_date).format('MM-DD-YYYY') && <div className="text-center chat-area--main-date">
                  <span> {moment(messageContent.message_date).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY') ? 'Today' : moment(messageContent.message_date).format('MMM-DD-YYYY')}
                    </span></div>}
                  <div className={`chat-area--${username === messageContent.user_name ? 'you' : 'other'}`} id={username === messageContent.user_name ? 'you'+messageContent?._id : "other"+messageContent?._id}>
                    {messageContent.message_type === "file" ? (
                      <p className="chat-area--bubble">
                        {isImageFileName(messageContent.message_file_properties.file_type) ? (
                          <img
                            src={messageContent.message_file_properties.file_url}
                            alt={messageContent.message_file_properties.file_name}
                            width="auto"
                            height={250}
                            className="mr-2 cursor w-100"
                            onClick={() => window.open(messageContent.message_file_properties.file_url, '_blank')}
                          />
                        ) : (
                          <div
                            className="mr-2 cursor"
                            style={{
                              paddingTop: "3px",
                              paddingBottom: "3px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              fontSize: "2rem",
                              border: "1px solid grey",
                              borderRadius: "10px"
                            }}
                            onClick={() => window.open(messageContent.message_file_properties.file_url, '_blank')}
                          >
                            <i className="mdi mdi-file-document"></i>
                            <span style={{ fontSize: "1rem" }}>
                              {messageContent.message_file_properties.file_name}
                            </span>
                          </div>
                        )}
                        
                        {username === messageContent.user_name && (
                          <Icon
                            className="mr-2 cursor m-2"
                            icon={trash}
                            onClick={() => dispatch(messageDelete(messageContent._id))}
                          />
                        )}
                        <br></br>
                          <Icon
                            className="mr-2 cursor m-2"
                            icon={download}
                            onClick={() => fileDownload(messageContent.message_file_properties.file_url, messageContent.message_file_properties.file_name)}
                          />
                        <br></br>
                        <span className="chat-area--bubble-text m-2">
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchString]}
                            autoEscape={true}
                            textToHighlight={messageContent?.message_text || ""}
                          />
                        </span>
                        <span className="chat-area--bubble-time">
                          {messageContent.message_time}
                        </span>
                      </p>
                    ) :  messageContent.message_type === "systext" ? (
                      <div className="chat-area--bubble" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                          <div style={{ padding: "10px" }}>
                            {user.type === "Client" && messageContent.message_text === "Take action regarding \"Request payment refund from escrow\""
                              ? "Request for action has been sent regarding \"Request payment refund from escrow\""
                              : user.type === "Freelancer" && messageContent.message_text === "Take action regarding \"Request payment release from escrow\""
                                ? "Request for action has been sent regarding \"Request payment release from escrow\""
                                : messageContent.message_text}
                          </div>
                          {!(user.type === "Client" && messageContent.message_text === "Take action regarding \"Request payment refund from escrow\"") &&
                           !(user.type === "Freelancer" && messageContent.message_text === "Take action regarding \"Request payment release from escrow\"") && (
                            <button onClick={() => handleOpenModal(messageContent.message_text, user.type === "Client" ? "client" : "Freelancer")} className="btn btn-primary" style={{ marginTop: "10px" }}>
                              Take Action
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="chat-area--bubble">
                        <span className="chat-area--bubble-text">
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchString]}
                            autoEscape={true}
                            textToHighlight={messageContent?.message_text || ""}
                          />
                        </span>
                        <span className="chat-area--bubble-time">
                          {moment(messageContent.message_date).format("h:mm A")}
                        </span>
                        {username === messageContent.user_name && (messageContent.read == "false" ? <Icon className="chat-area--bubble-icon" color="green" icon={checkSquareO} /> : <Icon className="chat-area--bubble-icon" icon={check} />)}
                      </p>
                    )}
                    <Modal show={modalState.isVisible} onHide={handleCloseModal} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalState.type === "client" ? "Client Action Required" : "Expert Action Required"}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {modalState.type === "client" ? (
                          <div>The expert has asked for contract payment to be released from escrow. If you agree with this decision, press 'accept', otherwise please contact your expert regarding this matter. You can also file a dispute if you're unable to resolve this matter with the expert.</div>
                        ) : modalState.type === "Freelancer" ? (
                          <div>The client has asked for contract payment to be released from escrow and refunded back to their account. If you agree with this decision, press 'accept', otherwise please contact your client regarding this matter. You can also file a dispute if you're unable to resolve this matter with the client.</div>
                        ) : (
                          modalState.messageContent
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <button 
                          className="btn btn-secondary" 
                          onClick={() => {
                            handleCloseModal();
                            if (modalState.type === "client") {
                              handleContract(matchingContract._id);
                            } else if (modalState.type === "Freelancer") {
                              allowLatestMilestone(matchingContract._id);
                            }
                          }}
                        >
                          Accept
                        </button>
                        <button className="btn btn-secondary" onClick={() => {
                            handleCloseModal();
                            if (modalState.type === "client") {
                              handleContract1(matchingContract._id);
                            } else if (modalState.type === "Freelancer") {
                              handleContract1(matchingContract._id);
                            }
                          }}>
                          File a dispute
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              );
            })}
          </ScrollToBottom>
        </div>
        <div className="chat-bottom-content position-relative">
          <div
            className={`${
              showEmojis ? "d-flex" : "d-none"
            } position-fixed`}
            style={{
              top: "50%",
              right: 10,
              transform: "translateY(-50%)"
            }}
          >
            <emoji-picker ref={emojiRef}></emoji-picker>
          </div>
          <div className="w-100 border-top border-bottom text-area">
            <textarea
              ref={textAreaRef}
              name="message_text"
              value={currentMessage}
              disabled={loading}
              onChange={(event) => {
                setCurrentMessage(event.target.value);
                setCursorPosition(event.target.selectionStart);
              }}
              onKeyDown={(event) => {
                if (event.ctrlKey && event.key === 'Enter') {
                  insertNewLine();
                }

                if (event.key === 'Enter') {
                  event.preventDefault();
                  sendMessage();
                }
              }}
              onFocus={() => setShowEmojis(false)}
              onClick={(e) => setCursorPosition(e.target.selectionStart)}
              placeholder="Write a message…" className="form-control border-0 p-3 shadow-none" rows="2"
            />

          </div>
          <div>
            {image || file ? (
                <div
                  className="d-flex"
                >
                  {image && (
                    <img
                      src={tempImage}
                      alt={image?.name || ""}
                      width="auto"
                      height={75}
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={handleRemoveFile}
                    />
                  )}
                  {file && (
                    <div
                      style={{
                        cursor: "pointer",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        fontSize: "2rem",
                        border: "1px solid grey",
                        borderRadius: "10px"
                      }}
                      onClick={handleRemoveFile}
                    >
                      <i className="mdi mdi-file-document"></i>
                      <span style={{ fontSize: "0.8rem" }}>
                        {file?.name}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <FileUploader
                    classes={`drag-drop ${showedAttachment === "file" ? "d-flex" : "d-none"}`}
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  />

                  <FileUploader
                    classes={`drag-drop ${showedAttachment === "image" ? "d-flex" : "d-none"}`}
                    multiple={false}
                    handleChange={handleChangeImage}
                    name="image"
                    types={imageTypes}
                  />
                </>
              )}
          </div>
          <div className="p-3 d-flex align-items-center">
            <span className="mr-auto">
              <button 
                onClick={() => handleShowAttachment("file")}
                className="btn btn-sm mr-2 px-2 py-1"
                style={{
                  fontSize: "1.2rem"
                }}
              >
                <i className="mdi mdi-paperclip"></i>
              </button>
              <button 
                onClick={() => handleShowAttachment("image")}
                className="btn btn-sm mr-2 px-2 py-1"
                style={{
                  fontSize: "1.2rem"
                }}
              >
                <i className="mdi mdi-image-area"></i>
              </button>
              <button 
                onClick={() => setShowEmojis(!showEmojis)}
                className="btn btn-sm mr-2 px-2 py-1"
                style={{
                  fontSize: "1.2rem"
                }}
              >
                <i className="mdi mdi-emoticon-happy"></i>
              </button>
            </span>
            <span className="ml-auto">            
              <button onClick={sendMessage} disabled={loading} className="btn btn-primary">
                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <Icon className="mr-2" icon={sendO} />
                    Send
                  </>
                )}
              </button>
            </span>
          </div>
        </div>
    </>
  );
}

const mapStateToProp = (state) => ({
  proposalAndContract: state.proposalAndContract,
  auth: state.auth,
});

export default connect(mapStateToProp, { messageGetbyId, proposalGetbyId, messageAdd, contractGetbyId })(Chat);