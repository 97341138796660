import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classes from "./homepagevideo.module.css";

import office from "./v2_121_updated.png";
const HomePageVideo = () => {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <section className="main-introduction">
      <div className="container">
        <div className={classes.box_flex}>
          <div className={classes.left}>
            <div className={classes.header}>
              <div className={classes.circle}></div>
              <span>CyberToCyber </span>
              Your All-in-One Destination for Personal and Business
              Cybersecurity
            </div>
            <p>
              Embark on a cybersecurity journey at CyberToCyber, where
              individuals and businesses converge. Discover tailored solutions,
              connect with top-tier experts, and fortify your digital presence
              all seamlessly integrated into one powerful platform.
            </p>

            <Link
              to={"/login-register?role=client&type=login"}
              onClick={scrollToTop}
              className={classes.getStarted}
            >
              Get Started
            </Link>
          </div>
          <div className={classes.right}>
            <div className={classes.blue}></div>
            <div className={classes.green}></div>
            <img src={office} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect()(HomePageVideo);
