import { Form, Button } from "react-bootstrap";

export default function InternationalBank({
  internationalBankData,
  handleChangeTextInternational,
  handleChangeLetterInternational,
  handleChangeInternational,
  loading,
  step,
  setStep,
}) {
  return step === 1 ? (
    <>
      <Form.Group>
        <Form.Label>Beneficiary's Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's Name"
          name="beneficiary_name"
          value={internationalBankData.beneficiary_name}
          onChange={handleChangeLetterInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's ID No</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's ID No"
          name="beneficiary_id_no"
          value={internationalBankData.beneficiary_id_no}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's Date of Birth</Form.Label>
        <Form.Control
          type="date"
          placeholder="Beneficiary's Date of Birth"
          name="beneficiary_date_of_birth"
          value={internationalBankData.beneficiary_date_of_birth}
          onChange={handleChangeInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Place of issuance</Form.Label>
        <Form.Control
          type="text"
          placeholder="Place of issuance"
          name="place_of_issuance"
          value={internationalBankData.place_of_issuance}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Tel / Phone No</Form.Label>
        <Form.Control
          type="number"
          placeholder="Tel / Phone No"
          name="beneficiary_phone"
          value={internationalBankData.beneficiary_phone}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's Address</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's Address"
          name="beneficiary_address"
          value={internationalBankData.beneficiary_address}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's Country</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's Country"
          name="beneficiary_country"
          value={internationalBankData.beneficiary_country}
          onChange={handleChangeLetterInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's State</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's State"
          name="beneficiary_state"
          value={internationalBankData.beneficiary_state}
          onChange={handleChangeLetterInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's City</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's City"
          name="beneficiary_city"
          value={internationalBankData.beneficiary_city}
          onChange={handleChangeLetterInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's Zip Code</Form.Label>
        <Form.Control
          type="number"
          placeholder="Beneficiary's Zip Code"
          name="beneficiary_zip_code"
          value={internationalBankData.beneficiary_zip_code}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <div className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          type="button"
          className="mr-4"
          onClick={() => setStep(0)}
          disabled={loading}
        >
          Back
        </Button>
        <Button variant="secondary" type="submit" disabled={loading}>
          Submit
        </Button>
      </div>
    </>
  ) : (
    <>
      <Form.Group>
        <Form.Label>Beneficiary's IBAN No</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's IBAN No"
          name="iban"
          value={internationalBankData.iban}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Beneficiary's Bank Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Beneficiary's Bank Name"
          name="bank_name"
          value={internationalBankData.bank_name}
          onChange={handleChangeLetterInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Swift Code</Form.Label>
        <Form.Control
          type="number"
          placeholder="Swift Code"
          name="swift_code"
          value={internationalBankData.swift_code}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Bank's Address</Form.Label>
        <Form.Control
          type="text"
          placeholder="Bank's Address"
          name="bank_address"
          value={internationalBankData.bank_address}
          onChange={handleChangeTextInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Bank's City</Form.Label>
        <Form.Control
          type="text"
          placeholder="Bank's City"
          name="bank_city"
          value={internationalBankData.bank_city}
          onChange={handleChangeLetterInternational}
          className="mb-4"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Bank's Country</Form.Label>
        <Form.Control
          type="text"
          placeholder="Bank's Country"
          name="bank_country"
          value={internationalBankData.bank_country}
          onChange={handleChangeLetterInternational}
          className="mb-4"
        />
      </Form.Group>

      <div className="d-flex justify-content-end align-items-center">
        <Button variant="secondary" type="submit" disabled={loading}>
          Next
        </Button>
      </div>
    </>
  );
}
