import { connect } from "react-redux";

import classes from "./findcyber.module.css";
import cyberImage from "./Why-C2C 1.png";
import img2 from "./image.png";

const FindCyber = () => {
  return (
    <section className="main-find-services pt-0">
      <div className="container">
        <div className="Mainheading">
          {/**<span class="v2_199">
             <span style={{ color: "#2F327D" }}>Why</span>{" "}
            <span style={{ color: "#23BDEE" }}>CybertoCyber?</span>
            
          </span>*/}
          <h2 className="fw-600 text-center">Why Cyber to Cyber? </h2>
          {/** <div class="v2_201"></div>
          <div class="v2_200"></div>*/}
        </div>

        {/* <div className="col1">
            <span class="v2_202">
              A world of top cybersecurity professionals at your service.
            </span>
            <div class="v2_203"></div>
            <div class="v2_204"></div>
            <div class="v2_205"></div>
            <span class="v2_206">
              On-demand, trustworthy, and dedicated experts to address your
              cybersecurity problems.
            </span>
            <div class="v2_207">
              <div class="v2_208"></div>
              <div class="v2_209"></div>
              <div class="v2_210"></div>
              <div class="v2_211"></div>
            </div>
            <span class="v2_212">
              Freedom for clients and professionals to work together.
            </span>
            <div class="v2_213">
              <div class="v2_214"></div>
              <div class="v2_215"></div>
            </div>
            <div class="v2_216">
              <div class="v2_217"></div>
            </div>
          </div>

    
          <div class="v2_223">
            <img src={image1} width={"300px"} alt="message" className="mb-4" />
          </div>

       
          <div className="col2">
    
            <span class="v2_222">
              Seamless process for job posting and project bidding.
            </span>
            <div class="v2_219"></div>
            <div class="v2_228">
              <div class="v2_229"></div>
              <div class="v2_230"></div>
              <div class="v2_231"></div>
              <div class="v2_232"></div>
            </div>

            <span class="v2_233">
              Satisfaction of clients and professionals is our first priority.
            </span>
            <div class="v2_220"></div>
            <div class="v2_234">
              <div class="v2_235"></div>
              <div class="v2_236"></div>
            </div>

   
            <span class="v2_218">
              Committed to providing the best services that no one offers.
            </span>
            <div class="v2_221"></div>
            <div class="v2_237">
              <div class="v2_238"></div>
            </div>
          </div> */}

        <div className={classes.cy_flex}>
          <div className={classes.col1}>
            <div className={classes.cy_box}>
              <div>
                <span></span>
              </div>
              <p>A world of top cybersecurity professionals at your service.</p>
            </div>

            <div className={classes.cy_box}>
              <div>
                <span></span>
              </div>
              <p>
                On-demand, trustworthy, and dedicated experts to address your
                cybersecurity problems.
              </p>
            </div>
            <div className={classes.cy_box}>
              <div>
                <img src={img2} alt="cyberImage" />
              </div>
              <p>Freedom for clients and professionals to work together.</p>
            </div>
          </div>

          <div className={classes.col2}>
            <div className={classes.cy_img}>
              <img src={cyberImage} alt="questinMark" />
            </div>
          </div>
          <div className={classes.col1}>
            <div className={classes.cy_box}>
              <div>
                <span></span>
              </div>
              <p>Seamless process for job posting and project bidding.</p>
            </div>
            <div className={classes.cy_box}>
              <div>
                <span></span>
              </div>
              <p>
                Satisfaction of clients and professionals is our first priority.
              </p>
            </div>
            <div className={classes.cy_box}>
              <div>
                <img src={img2} alt="cyberImage" />
              </div>
              <p>
                Committed to providing the best services that no one offers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect()(FindCyber);
