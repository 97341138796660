import { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useDispatch } from "react-redux";
import { addMilestone } from "../../actions/proposalAndContract";
import { getWallet } from "../../actions/wallet";

import { removeFloatingPoint } from "../../utils/number";
import { remove } from 'react-icons-kit/fa/remove'
import { Icon } from "react-icons-kit";

export default function AddMilestone({
  contractId,
  show,
  handleClose,
  walletAmount = 0,
}) {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState(0);

  const maxValue = walletAmount - (parseFloat(walletAmount * 0.05));
  const finalValue = parseFloat(value * 1.05);

  const [loading, setLoading] = useState(null);

  const handleAddMilestone = async () => {
    setLoading(true);
    //console.log(value)
    await dispatch(addMilestone(contractId, title, value, description));
    handleClose();
    dispatch(getWallet());
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Add milestone</Modal.Title>
        <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Milestone Title</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={title}
              onChange={(e) => {
                const value = e.target.value;
                setTitle(value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Milestone Description</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              rows={3}
              value={description}
              onChange={(e) => {
                const value = e.target.value;
                setDescription(value);
              }}
            />
          </Form.Group>
           
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Budget amount</Form.Label>
           <Form.Control
              type="text"
              value={value}
              onChange={(e) => {
                const newValue = e.target.value;
          
                // Use a regular expression to check for a valid non-negative number
                if (/^\d*$/.test(newValue) && newValue >= 0) {
                  setValue(newValue);
                }
              }}
              max={maxValue}
              min={0}
            />
          </Form.Group>
           
        </Form>
        <div>
          You currently have{" "}
          <span style={{ fontWeight: "bold" }}>${removeFloatingPoint(walletAmount)}</span> in your
          wallet. Upon proceeding with milestone creation, you will escrow{" "}
          <span style={{ fontWeight: "bold" }}>${value}</span> and <span style={{ fontWeight: "bold" }}>${removeFloatingPoint(value * 0.05)}</span> fee,
          leaving you with a remaining balance of{" "}
          <span style={{ fontWeight: "bold" }}>
            ${removeFloatingPoint(walletAmount - finalValue)}
          </span>{" "}
          in your wallet.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} disabled={loading}>
          Close
        </Button>
        <Button variant="secondary" onClick={handleAddMilestone} disabled={loading}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
