import { useState } from "react";
import Login from "../login/Login";
import Register from "../register/Register";
import classes from "./loginRegister.module.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import { SVGWidgetLogo, BackButton } from "../../components/SVG";


export default function LoginRegister() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type") || "signup";
  const isSignUp = type === "signup";

  const goBack = () => {
    history.goBack();
  };

  const [curState, setCurState] = useState(type);
  const [leftUtilClass, setLeftUtilClass] = useState(
    isSignUp ? classes.trans_to_left : classes.trans_to_right
  );

  const [leftTxtUtilClass, setLeftTxtUtilClass] = useState(
    isSignUp ? classes.leftTxtToLeft : classes.leftTxtToRight
  );
  const [rightUtilClass, setRightUtilClass] = useState(
    isSignUp ? classes.trans_to_right_f : classes.trans_to_left_f
  );

  function handleCurState() {
    if (curState === "signup") {
      setCurState("login");
      setLeftUtilClass(classes.trans_to_right);
      setRightUtilClass(classes.trans_to_left_f);
      setLeftTxtUtilClass(classes.leftTxtToRight);
    } else {
      setCurState("signup");
      setLeftUtilClass(classes.trans_to_left);
      setRightUtilClass(classes.trans_to_right_f);
      setLeftTxtUtilClass(classes.leftTxtToLeft);
    }
  }

  return (
    <section className="box-under-container page-registration page-auth">
      <div className="container">
        <div className={classes.box}>
          <div className={`${classes.left} ${leftUtilClass}`}>
            <button onClick={goBack} className={classes.goBack}>
              <BackButton />
              <p style={{ marginTop: "1px" }}>Go Back</p>
            </button>


            <div className={classes.logo}>
              <Link to="/">
                <SVGWidgetLogo />
              </Link>
            </div>
            <div className={classes.leftTxt}>
              <div className={leftTxtUtilClass}>
                <div>
                  <h3 className="heading">JOIN US</h3>
                  <p className="description">
                    Make the most of your professional life
                  </p>
                </div>

                <div>
                  <h3 className="heading">WELCOME BACK</h3>
                  <p className="description">
                    Sign in to stay updated on your professional world.
                  </p>
                </div>
              </div>
            </div>

            <span className="description-e mb-2">
              {curState === "login" ? "New to CybertoCyber?" : "Already have an account?"}
            </span>

            <button className={classes.btn} onClick={handleCurState}>
              {curState === "login" ? "Sign Up" : "Sign In"}
            </button>

          </div>

          <div className={`${classes.right} ${rightUtilClass}`}>

            <div className={classes.register}>

              <Register />

            </div>
            <div
              className={`${classes.login} ${curState === "login" ? classes.login_appear : ""
                }`}
            >
              <Login />
           
            </div>
            <div className="d-flex justify-content-center copyright container mt-0 mb-n3 mt-md-5 mb-md-n5 pb-3" style={{position: "relative", zIndex: "100px"}}>
              <div>
                <p>
                  &copy; Copyright {new Date().getFullYear()} CybertoCyber. All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
