import React, {useState, useEffect} from "react";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from 'react-icons-kit';
import { filter } from 'react-icons-kit/fa/filter';
import { Country, } from 'country-state-city';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputRange from 'react-input-range';
import {SVGLocation} from '../../components/SVG';

const About = () => {
    useEffect(()=>{
      document.title = 'About Us';
    });

    return (<section className="main-page page-about">
        
        <section className="py-5 bg-dark inner-header">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1 className="mt-0 mb-3 text-white">About Us</h1>
                        
                    </div>
                </div>
            </div>
        </section>

        <div className="m-5 p-5">
       
          
          <h4 className="mt-4">Our Vision</h4>
          <p style={{ textAlign: 'justify' }}>
            In an increasingly digital world, securing our online presence has never been more critical. Our vision at CybertoCyber is to be the leading platform connecting individuals and businesses with top-tier cybersecurity professionals. We strive to empower our users to navigate the digital world with confidence, safe in the knowledge that their digital assets are protected by the best in the business.
          </p>

          <h4 className="mt-4">Our Goal</h4>
          <p style={{ textAlign: 'justify' }}>
            Our goal is to streamline the process of finding, hiring, and collaborating with cybersecurity experts. We believe that cybersecurity should be accessible and understandable, not intimidating. By facilitating connections between clients and professionals, we aim to create a more secure digital landscape for everyone.
          </p>

          <h4 className="mt-4">What We Provide?</h4>
          <p style={{ textAlign: 'justify' }}>
            CybertoCyber offers a platform where cybersecurity professionals can offer their services, and individuals or businesses can hire them based on their needs. We offer a wide range of services, including Network Security, Cyber Risk Management, Malware Protection, Data Security, and Penetration Testing. We're dedicated to ensuring that you can find the right professional for your specific cybersecurity needs.
          </p>

          <h4 className="mt-4">Transforming Lives</h4>
          <p style={{ textAlign: 'justify' }}>
            We believe that good cybersecurity practices can transform lives by providing peace of mind and safety in the digital realm. By allowing our users to access top-rated cybersecurity professionals at their fingertips, we aim to make the digital world a safer place for all.
          </p>

          <h4 className="mt-4">Our Beginnings</h4>
          <p style={{ textAlign: 'justify' }}>
            Born from a desire to democratize access to quality cybersecurity services, CybertoCyber has grown into a trusted platform connecting cybersecurity professionals with those who need their expertise. We're proud of our growth and remain committed to continuous improvement and expansion
          </p>

          <h4 className="mt-4">Our Marketplace</h4>
          <p style={{ textAlign: 'justify' }}>
            CybertoCyber is more than a platform—it's a marketplace. Our users don't just find services here; they find professionals who are passionate about what they do and dedicated to providing the best possible service. We're proud to host a community of cybersecurity experts who are committed to helping our users secure their digital worlds
          </p>

          <h4 className="mt-4">Our Professionals</h4>
          <p style={{ textAlign: 'justify' }}>
            The professionals who offer their services on CybertoCyber are the heart of our platform. We work with a diverse group of experts in various cybersecurity areas, each committed to providing comprehensive, trustworthy, and efficient services. Our professionals are not just experts in their field—they're dedicated to helping others navigate the complex world of cybersecurity
          </p>

          <h4 className="mt-4">Join Us</h4>
          <p style={{ textAlign: 'justify' }}>
            Whether you're a cybersecurity professional looking to offer your services or a business or individual seeking cybersecurity services, we invite you to join our growing community. Let's work together to make the digital world a safer place.
          </p>

          <h4 className="mt-4">Need Help?</h4>
          <p style={{ textAlign: 'justify' }}>
            If you need assistance or have any questions about our platform, please don't hesitate to contact us. Our dedicated team is always here to help you navigate our platform and find the right cybersecurity solutions for your needs.
          </p>
          
        </div>
        {/**
        <section className="py-5 bg-white">
            <div className="section-title text-center mb-5">
                <h2>Trusted Agents</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="agents-card text-center">
                            <img className="img-fluid mb-4" src="assets/images/user/s1.png" alt="" />
                            <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                            <h6 className="mb-0 text-primary">- Stave Martin</h6>
                            <small>Buying Agent</small>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="agents-card text-center">
                            <img className="img-fluid mb-4" src="assets/images/user/s2.png" alt="" />
                            <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                            <h6 className="mb-0 text-primary">- Mark Smith</h6>
                            <small>Selling Agent</small>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="agents-card text-center">
                            <img className="img-fluid mb-4" src="assets/images/user/s3.png" alt="" />
                            <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                            <h6 className="mb-0 text-primary">- Ryan Printz</h6>
                            <small>Real Estate Broker</small>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         */}

    </section>)
}

export default About;