import React, {useEffect, useState} from "react";


const FeeAndCharges = () => {
    useEffect(() => {
        document.title = 'Fees And Charges';
    }, []);
    return (
        <div className="m-5 p-5">
             <h3 className="mb-4">Fees And Charges</h3>
             <p>Lorem ipsum dolor sit amet. Et explicabo voluptas ut modi earum et quasi asperiores ea eaque numquam aut tempore provident a minima harum est sapiente architecto. Eos nulla quaerat non quos debitis sit officia commodi sed numquam inventore 33 aliquam voluptas.

Rem animi ipsa ut aspernatur odit iusto quia sed voluptatem temporibus vel ullam maiores est quam quidem aut maiores velit. Vel molestias dolor et iste velit qui rerum rerum sed placeat sapiente. Id galisum atque ut dolorem quae ea doloribus dolores ut saepe velit qui excepturi similique. Ex corrupti velit est consequuntur cumque est galisum debitis eos corrupti galisum et galisum cumque qui dolor porro.

Id voluptas nulla est dolorum recusandae aut consequatur autem. Ut omnis rerum eos nostrum rerum At facilis recusandae qui sunt dolorem ut ipsam animi qui sint totam qui quisquam commodi? Et quia autem ut mollitia dolorum id perspiciatis similique et dolores magnam aut fugiat explicabo.</p>
        </div>
      );
};



export default FeeAndCharges;