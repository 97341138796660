import React, { useEffect } from 'react';
import { connect } from "react-redux";

const PayPalButton = () => {

    useEffect(() => {
        const paypalScript = document.createElement('script');
        paypalScript.src = "https://www.paypal.com/sdk/js?client-id=AWB4VQkOj4Demyk655WOWYN6NzT1jEZrENInKlk14qhlum2UI8oxOdPcwCxNfaV9L2RXuXveFEVDit8P";
        paypalScript.async = true;
    
        paypalScript.onload = () => {
            // Once PayPal SDK is loaded, then load your paycheckout.js script
            const checkoutScript = document.createElement('script');
            checkoutScript.src = "/paycheckout.js";
            checkoutScript.async = true;
            document.body.appendChild(checkoutScript);
        };
    
        document.body.appendChild(paypalScript);
    
        // Cleanup function
        return () => {
            document.body.removeChild(paypalScript);
        };
    }, []);

  return (
    <div>
      <div id="paypal-button-container"></div>
      <p id="result-message"></p>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PayPalButton);