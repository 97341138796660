import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { remove } from 'react-icons-kit/fa/remove'
import { Icon } from "react-icons-kit";

export default function MilestoneDetails({ milestone, show, handleClose }) {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Add milestone</Modal.Title>
        <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Milestone Title</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={milestone?.title}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Milestone Description</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              rows={3}
              value={milestone?.description}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Budget amount</Form.Label>
            <Form.Control
              type="number"
              value={milestone?.value}
              readOnly
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
