import { Link } from "@material-ui/core";
import { useState , useEffect} from "react";
import Autosuggest from "react-autosuggest";

import { Form, FormControl, InputGroup } from "react-bootstrap";


export default function Search({ suggestions, setInput, setIndicator, pre_value, onEnterPress }) {

  const [value, setValue] = useState(pre_value || "");;
  const [suggestionsList, setSuggestions] = useState([]);

  //console.log("pre",pre_value)
  useEffect(() => {
    // Set the input value when the component is mounted
    if (pre_value) {
      setValue(pre_value);
      setInput(pre_value);
    }
  }, [pre_value, setInput]);

  const getSuggestions = (inputValue) => {
    
    const inputValueLowerCase = inputValue.toLowerCase();
    let suggestion = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValueLowerCase)
    );

    if(suggestion.length > 0){
      setIndicator(false);
    }else{
      setIndicator(true);
    }
    return suggestion;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submit action
      onEnterPress(); // Call the provided function when Enter is pressed
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (_, { suggestion }) => {
    setInput(suggestion);
  };

  const onChange = (_, { newValue }) => {
    setInput(newValue);
    setValue(newValue);
  };

  const onBlur = () => {
    setIndicator(true)
  }

  /*const inputProps = {
    placeholder: "Find Services...",
    value,
    onChange
  };*/

  const inputProps= {
    placeholder: "Find Services...",
    className: "form-control border-0 form-control-lg",
    style: { fontSize: "14px", color: "#01131D" },
    value,
    onChange,
    onBlur,
    onKeyPress: handleKeyPress
  }

  const renderSuggestion = (suggestion) => {
    return (
      <div className="d-flex justify-content-between align-items-center p-1">
        <span>{suggestion}</span>
      </div>
    );
  };

  return (
    <Form style={{ position: 'relative', width: '100%' }}

      /*onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}*/
    >
      <Autosuggest
        containerProps={{
          className: "w-100"
        }}
        suggestions={suggestionsList}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={(inputProps) => (
          <input {...inputProps} />
        )}
        shouldRenderSuggestions={() => true} 
        
      />
    </Form>
  );
}
