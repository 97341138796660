import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { setAlert } from "../../actions/alert";
import { useDispatch } from "react-redux";
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import Logo from '../../components/logo/Logo';

const Question5 = () => {
  const { formType } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  // Define the storage key based on the form type
  const storageKey = `answers5-${formType}`;

  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem(storageKey);
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission


  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    // Store answers with the appropriate storage key
    localStorage.setItem(storageKey, JSON.stringify(answers));
  }, [answers, storageKey]); // Include storageKey in the dependency array


  useEffect(() => {
    // Retrieve answers with the appropriate storage key
    const savedAnswers = localStorage.getItem(storageKey);
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, [storageKey]); // Include storageKey in the dependency array

  const scrollToField = (fieldId) => {
    const element = document.getElementById(fieldId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const unansweredQuestions = questions[formType].filter(question => !answers.hasOwnProperty(question.id) || answers[question.id].trim() === '');

    if (unansweredQuestions.length === 0) {
      history.push(`/question6/${formType}`);
    } else {
      unansweredQuestions.forEach(question => scrollToField(`question_${question.id}`));
      const errorMessage = unansweredQuestions.map(question => {
        const fieldName = question.question;
        return `${fieldName} is required.`;
      }).join(' ');

      //dispatch(setAlert(errorMessage, 'danger'));
    }
  };


  const placeholders = {
    '5.1': {
      individual: "Information about the Project(s) or Tasks",
      company: "Information about the Project(s)"
    },
    '5.2': {
      individual: "Anticipated Duration or Timeline",
      company: "Anticipated Duration of the Project(s)"
    },
    '5.3': {
      company: "Working Independently or as Part of a Team"
    },
    '5.4': {
      company: "Specific Tools or Technologies"
    }
  };

  const questions = {
    individual: [
      { id: 5.1, question: "Can you provide more information about the cybersecurity project(s) or tasks that you need assistance with?" },
      { id: 5.2, question: "What is the anticipated duration or timeline for the cybersecurity assistance?" }
    ],
    company: [
      { id: 5.1, question: "Can you provide more information about the cybersecurity project(s) that the expert will be working on?" },
      { id: 5.2, question: "What is the anticipated duration of the project(s)?" },
      { id: 5.3, question: "Will the cybersecurity expert work independently or as part of a team?" },
      { id: 5.4, question: "Are there any specific tools or technologies that the cybersecurity expert should be proficient in?" }
    ]
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  const currentQuestions = questions[formType] || [];

  return (
    <section className="main-page page-dashboard" style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      height: '100vh', backgroundColor: '#f4f4f4', maxWidth: "100vw", overflowX: "hidden"
    }}>
      <div className="container" style={{ maxWidth: '1200px' }}>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className='w-100 row row justify-content-center d-flex d-sm-none'>


              <Logo />
            </div>
            <div className="bg-white rounded shadow-sm sidebar-page-right" style={{padding: "2rem" }}>
              <Link to={`/question4/${formType}`} className=" text-dark d-flex mb-2"
                style={{ alignItems: "center", gap: "3px" }}
              >
                <KeyboardBackspaceOutlined />  Back

              </Link>
              <div className="p-3">
                <h4> {formType === 'individual' ? 'Individual' : 'Company'}</h4>
                <h3 className="text-primary">Project Details</h3>
                <p >
                  <span /*style={{ textDecoration: "underline" }}*/ className="font-weight-bold">05</span> of 10
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="scrollable">
                    {currentQuestions.map((question, index) => (
                      <div key={index} id={`question_${question.id}`}>
                        <h6 className="font-weight-normal mt-4">{question.question}</h6>
                        {question.id === 5.3 ? (
                          <div className=" d-flex form-group" style={{ marginBottom: '15px', gap: '15px' }}>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name={`question_${question.id}`}
                                  value="independently"
                                  checked={answers[question.id] === 'independently'}
                                  onChange={() => handleAnswerChange(question.id, 'independently')}

                                />
                                Independently
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name={`question_${question.id}`}
                                  value="as_part_of_a_team"
                                  checked={answers[question.id] === 'as_part_of_a_team'}
                                  onChange={() => handleAnswerChange(question.id, 'as_part_of_a_team')}

                                />
                                As part of a team
                              </label>
                            </div>
                            { // Add conditional rendering for error message
                              formSubmitted && !answers[question.id]?.trim() && (
                                <div className="text-danger mt-2">
                                  {/*`${question.question} is required.`*/}
                                  Please fill the required field.
                                </div>
                              )
                            }
                          </div>
                        ) : question.options ? (
                          <div className="form-group" style={{ marginTop: "0px" }}>
                            {question.options.map((option, optionIndex) => (
                              <div
                                key={optionIndex}
                                onClick={() => handleAnswerChange(option)}
                                className={`option-item ${answers[question.id] === option ? 'selected' : ''}`}
                                style={{
                                  marginBottom: '30px',
                                  cursor: 'pointer',
                                  padding: '10px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  backgroundColor: answers[question.id] === option ? '#007bff' : 'transparent',
                                  color: answers[question.id] === option ? 'white' : 'black',

                                }}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="form-group" style={{ marginBottom: "30px" }} >
                            <input type="text" className="form-control" placeholder={"Enter " + placeholders[question.id][formType]} value={answers[question.id] || ''} onChange={(e) => {
                              const value = e.target.value;
                              const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                              handleAnswerChange(question.id, capitalizedValue);
                            }} />
                            { // Add conditional rendering for error message
                              formSubmitted && !answers[question.id]?.trim() && (
                                <div className="text-danger mt-2">
                                  {/*`${question.question} is required.`*/}
                                  Please fill the required field.
                                </div>
                              )
                            }
                          </div>
                        )}

                      </div>
                    ))}
                  </div>

                  {/** <div className="d-flex justify-content-end pb-3">
                    <div className="text-right">
                      <Link to={`/question4/${formType}`} className="btn btn-default mr-3">Back</Link>
                      <button type="submit" className="btn btn-primary">Save & Next</button>
                    </div>
                  </div>
                  */}
                  {/** <div className="row justify-content-center forms_buttons">
                    <div className="text-right ">
                        <Link to={`/question4/${formType}`} className="btn btn-default mr-3">Back</Link>
                        {/*<Link to="/education" className="c-btn c-fill-color-btn ">Next: Education</Link>
                        
                          <button type="submit" className="btn btn-primary">
                            Save & Next
                          </button>
                    </div>
                    </div>*/}

                  {/* {formType === 'individual' ?
                        <div className="row forms_buttons" style={{ width: '96%' }}>
                        <div className="col-md-5 text-left d-none d-sm-block">
      
                          <Link to={`/question4/${formType}`} className="btn btn-default  ">
                            Back
      
                          </Link>
                          </div>
                          <div className="col-sm-11 col-md-6 text-right forms_buttons_SaveNext mr-4 mr-md-0" >
      
                            <button type="submit" className="btn btn-primary wm-100 " style={{ width: '95% !important' }}>
                              Save & Next
                            </button>
                          </div>
                        </div>

                    :
                    <div className="row forms_buttons w-100 w-sm-96" style={{ width: '96%' }}>
                    <div className="col-md-5 text-left d-none d-sm-block">
  
                      {/* <Link to={`/question4/${formType}`} className="btn btn-default  ">
                        Back
  
                      </Link> */}
                  {/* </div>
                      <div className="col-sm-11 col-md-6 col-11 text-right forms_buttons_SaveNext mr-4 mr-md-0" >
  
                        <button type="submit" className="btn btn-primary wm-100 " style={{ width: '95% !important' }}>
                          Save & Next
                        </button>
                      </div>
                    </div>}  */}
                  <div className='w-100 bg-white d-flex justify-content-end' style={{}}>
                    <button type="submit" className="btn btn-primary wm-100 mr-2" style={{ width: '95% !important' }}>
                      Save & Next
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question5;


