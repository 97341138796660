import { connect } from "react-redux";
import classes from "./index.module.scss";
import illustration from "./Illustration.png"
import { Arrow90degRight, ArrowBarRight } from "react-bootstrap-icons";
import { ArrowForward } from "@material-ui/icons";
import { Link,useHistory, NavLink } from 'react-router-dom';

const CyberEnterprise = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push('/request-service');
    };

    return (
        <div className={`container-fluid  text-dark py-5 mb-0 ${classes.cyberpro}`}>
           <div className="row gap-3">
            <div className={`col-md-5 mb-4 overflow-hidden ${classes.illustration}`}>
                <img src={illustration} alt="Illustration" />
            </div>
            <div className="col-md-5 mb-4 mt-1 ml-mb-4 ml-lg-4 ml-1 d-flex flex-column justify-content-center" style={{ marginBottom: '5%' }}>
                <h1 className="fw-600 mt-5 mb-0">Cyber to Cyber</h1>
                <h1 className="fw-600 mb-3">Enterprise Solution</h1>
                <p className="mb-3">
                    Elevate cybersecurity of your business with the top talent. Bridge your skill gaps with
                    our specialized experts or build your dream team using our customizable, full-service platform.
                </p>
                <button className="btn btn-primary mt-3 mx-auto mx-md-0 mx-lg-0 wm-100"  onClick={handleClick}>
                    Hire Professional <ArrowForward />
                </button>
                <div style={{ height: '8%' }}></div>
            </div>

        </div>
        </div>

    );
};

export default connect()(CyberEnterprise);
