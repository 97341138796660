export const globeConfig = {
  pointSize: 3,
  globeColor: "#4d12e3",
  showAtmosphere: true,
  atmosphereColor: "#529bfa",
  atmosphereAltitude: 0.2,
  emissive: "#000000",
  emissiveIntensity: 1.1,
  shininess: 10.9,
  polygonColor: "#ffffff",
  ambientLight: "rgba(0,0,0,0.1)",
  directionalLeftLight: "#000000",
  directionalTopLight: "#ffffff",
  pointLight: "#ff0000",
  flightTime: 3000,
  flightLength: 0.9,
  rings: 2,
  maxRings: 3,
  initialPosition: { lat: 51.5072, lng: -0.1276 },
  autoRotate: true,
  autoRotateSpeed: 0.6,
};

export const flights = [
  {
    order: 2,
    from: "Brasília",
    to: "Los Angeles",
    startLat: -15.785493,
    startLng: -47.909029,
    endLat: 34.052235,
    endLng: -118.243683,
    arcAlt: 0.3,
    color: "#ff0000", // e49afc
  },
  {
    order: 3,
    from: "Sydney",
    to: "Toronto",
    startLat: -33.8688,
    startLng: 151.2093,
    endLat: 43.65107,
    endLng: -79.347015,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 3,
    from: "Jakarta",
    to: "Berlin",
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 52.52,
    endLng: 13.405,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 4,
    from: "Buenos Aires",
    to: "Mexico City",
    startLat: -34.6037,
    startLng: -58.3816,
    endLat: 19.4326,
    endLng: -99.1332,
    arcAlt: 0.7,
    color: "#ff0000",
  },
  {
    order: 4,
    from: "London",
    to: "Lisbon",
    startLat: 51.5072,
    startLng: -0.1276,
    endLat: 38.7223,
    endLng: -9.1393,
    arcAlt: 0.1,
    color: "#ff0000",
  },
   {
    order: 3,
    from: "Jakarta",
    to: "London",
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 4,
    from: "Buenos Aires",
    to: "Hong Kong",
    startLat: -34.6037,
    startLng: -58.3816,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.7,
    color: "#ff0000",
  },
  {
    order: 4,
    from: "London",
    to: "Paris",
    startLat: 51.5072,
    startLng: -0.1276,
    endLat: 48.8566,
    endLng: -2.3522,
    arcAlt: 0.1,
    color: "#ff0000",
  },
  {
    order: 5,
    from: "Manila",
    to: "London",
    startLat: 14.5995,
    startLng: 120.9842,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 15,
    from: "Rome",
    to: "Madrid",
    startLat: 41.9028,
    startLng: 12.4964,
    endLat: 40.4168,
    endLng: -3.7038,
    arcAlt: 0.4,
    color: "#ff0000",
  },
  {
    order: 15,
    from: "Amsterdam",
    to: "Paris",
    startLat: 52.3676,
    startLng: 4.9041,
    endLat: 48.8566,
    endLng: 2.3522,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 15,
    from: "Berlin",
    to: "London",
    startLat: 52.52,
    startLng: 13.405,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 15,
    from: "Madrid",
    to: "Rome",
    startLat: 40.4168,
    startLng: -3.7038,
    endLat: 41.9028,
    endLng: 12.4964,
    arcAlt: 0.4,
    color: "#ff0000",
  },
  {
    order: 7,
    from: "Belo Horizonte",
    to: "Cuiabá",
    startLat: -19.885592,
    startLng: -43.951191,
    endLat: -15.595412,
    endLng: -56.05918,
    arcAlt: 0.1,
    color: "#ff0000",
  },
  {
    order: 8,
    from: "Luanda",
    to: "Cidade do Cabo",
    startLat: -8.833221,
    startLng: 13.264837,
    endLat: -33.936138,
    endLng: 18.436529,
    arcAlt: 0.2,
    color: "#ff0000",
  },
  {
    order: 8,
    from: "Singapore",
    to: "New York City",
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: 40.7128,
    endLng: -74.006,
    arcAlt: 0.5,
    color: "#ff0000",
  },

  {
    order: 10,
    from: "Jakarta",
    to: "Amsterdam",
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 52.3676,
    endLng: 4.9041,
    arcAlt: 0.3,
    color: "#ff0000",
  },
 
  {
    order: 12,
    from: "Los Angeles",
    to: "San Francisco",
    startLat: 34.0522,
    startLng: -118.2437,
    endLat: 37.7749,
    endLng: -122.4194,
    arcAlt: 0.1,
    color: "#ff0000",
  },

  {
    order: 13,
    from: "Berlin",
    to: "Hong Kong",
    startLat: 52.52,
    startLng: 13.405,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.3,
    color: "#ff0000",
  },


  {
    order: 15,
    from: "San Francisco",
    to: "Tokyo",
    startLat: 37.7749,
    startLng: -122.4194,
    endLat: 35.6762,
    endLng: 139.6503,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 15,
    from: "Brisbane",
    to: "Los Angeles",
    startLat: -27.4698,
    startLng: 153.0251,
    endLat: 34.052235,
    endLng: -118.243683,
    arcAlt: 0.4,
    color: "#ff0000",
  },
  {
    order: 15,
    from: "Toronto",
    to: "Mexico City",
    startLat: 43.65107,
    startLng: -79.347015,
    endLat: 19.4326,
    endLng: -99.1332,
    arcAlt: 0.3,
    color: "#ff0000",
  },
  {
    order: 10,
    from: "Nairobi",
    to: "New York",
    startLat: -1.303396,
    startLng: 36.852443,
    endLat: 40.712776,
    endLng: -74.005974,
    arcAlt: 0.6,
    color: "#ff0000",
  },
];
