import axios from "axios";

import { GET_BANKS, GET_BANK } from "./types";
import { setAlert } from "./alert";

import setAuthToken from "../utils/setAuthToken";

export const getBanks = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get("/api/banks", config);

    dispatch({
      type: GET_BANKS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BANKS,
      payload: [],
    });

    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const getBankById = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(`/api/banks/${id}`, config);

    dispatch({
      type: GET_BANK,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BANK,
      payload: {},
    });
    
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const addBank = (body) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const data = JSON.stringify(body);

  try {
    await axios.post("/api/banks", data, config);

    dispatch(setAlert("Bank details added", "success"));
    dispatch(getBanks());
  } catch (error) {
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const updateBank = (id, body) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const data = JSON.stringify(body);

  try {
    await axios.patch(`/api/banks/${id}`, data, config);

    dispatch(setAlert("Bank details updated", "success"));
    dispatch(getBankById(id));
  } catch (error) {
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};

export const deleteBank = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    await axios.delete(`/api/banks/${id}`, config);

    dispatch(setAlert("Bank deleted", "success"));
    dispatch(getBanks());
  } catch (error) {
    const errorMessage = error?.response?.data?.msg || error.message;
    dispatch(setAlert(errorMessage, "danger"));
  }
};
