import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SVGFooterLogo, SVGSocial } from "../SVG";

const Footer = (props) => {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <footer
        className={`main-footer ${props.type === 3 ? "light" : ""} ${
          props.type === 2 ? "dark" : ""
        } `}
      >
        <div className=" container">
          <div className="row justify-content-between main-footer--content">
            <div className="col col-md-2_ col-lg-2_ col-xl-2_ col-xxl-2_">
              <div className="footer-logo">
                <Link to="" onClick={scrollToTop}>
                  {/*<img src="assets/images/logo-footer.svg" width="150" height="150" />*/}
                  <SVGFooterLogo />
                </Link>
                <span className="logo-bottom-line">&nbsp;</span>

                <ul className="social-icons">
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/cybertocyber",
                          "_blank"
                        )
                      }
                    >
                      <SVGSocial icon={`linkedIn`} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          "https://twitter.com/cybertocyber",
                          "_blank"
                        )
                      }
                    >
                      <SVGSocial icon={`twiiter_updated`} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
              <div className="d-flex justify-content-between">
                <div className="footer-list"></div>
                <div className="footer-list" style={{marginBottom:"-15px"}}>
                  <h2>About</h2>
                  <ul className="list">
                    <li>
                      <Link to="/login-register?role=client&type=login" onClick={scrollToTop}>
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/register-role?type=signup" onClick={scrollToTop}>
                        Sign Up
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={scrollToTop}>
                        About Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-list">
                  <h2>Terms and Conditions</h2>
                  <ul className="list">
                    <li>
                      <Link to="/coc" onClick={scrollToTop}>
                        Code of Conduct
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacyPolicy" onClick={scrollToTop}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactus" onClick={scrollToTop}>
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="footer-list"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright container">
          <div>
            <p>
              &copy; Copyright {new Date().getFullYear()} CybertoCyber. All
              Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default connect()(Footer);
