import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { loadUser, PhoneNumberGet } from "../actions/auth";
import { hireManagerGet } from "../actions/clientProfile";
import {
  CertificateGet,
  EducationGet,
  EmploymentGet,
  expertiseGet,
  expertLevelGet,
  HourlyRateGet,
  LanguageGet,
  locationGet,
  ProfileAndPhotoGet,
  TitleAndOverviewGet,
} from "../actions/freelancerProfile";
import { postJobGet } from "../actions/postJob";

import {
  ContractDetails,
  ContractList,
  OtpAuth,
  Phone,
  PostJobClient,
  Profile,
  profileAndPhoto,
  RegisterAdmin,
  SecureAccount,
  Contracts,
  About,
  Report,
  BankDetails,
  PayoutApproval,
  Blank,
  Refund,
  LoginRegister,
  Enterprise,
  RegisterRole,
} from "../pages";
import {
  Question1,
  Question2,
  Question3,
  Question4,
  Question5,
  Question6,
  Question7,
  Question8,
  Question9,
  Question10,
} from "../pages";


import {
  AdminDashboard,
  ApplyForJob,
  Budget,
  Certificate,
  ChangePassword,
  ClientCompleteProfile,
  ClientProfile,
  CompleteProfile,
  Dashboard,
  Education,
  Employment,
  PersonalDetails,
  Expertise,
  ExpertiseLevel,
  Finance,
  ForgetPassword,
  Headline,
  Home,
  HourlyRate,
  Job,
  Languages,
  Location,
  Login,
  Messages,
  Qa,
  Register,
  Scope,
  settings,
  Skills,
  Talent,
  TitleAndOverview,
  coc,
  feeAndCharges,
  privacyPolicy,
  copyright,
  Wallet,
  ExpertVerify,
  Stripe,
  PaymentForm,
  Verification,
  PayPalButton,
  NotFound,
  ManageUsers,
  Dispute,
} from "../pages";
import store from "../store";
import setAuthToken from "../utils/setAuthToken";
import MyRoute from "./MyRoute";
import startContract from "../pages/startContract";
import publicProfile from "../pages/profile/publicProfile";
import { HashRouter } from "react-router-dom/cjs/react-router-dom.min";
import ScrollToTop from "./scrollToTop";
import RequestService from "../pages/requestService/RequestService";

const AppRoutes = (props) => {
  const [authenticated, setAuthenticated] = React.useState(false);

  useEffect(() => {
    setAuthenticated(store.getState().auth.isAuthenticated);
  }, [store.getState().auth]);

  useEffect(() => {
    if (store.getState().auth.token) {
      store.dispatch(loadUser());
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      store.dispatch(loadUser());
      store.dispatch(expertiseGet());
      store.dispatch(expertLevelGet());
      store.dispatch(EducationGet());
      store.dispatch(EmploymentGet());
      store.dispatch(LanguageGet());
      store.dispatch(HourlyRateGet());
      store.dispatch(ProfileAndPhotoGet());
      store.dispatch(PhoneNumberGet());
      store.dispatch(locationGet());
      store.dispatch(TitleAndOverviewGet());
      store.dispatch(CertificateGet());
      store.dispatch(hireManagerGet());
      store.dispatch(postJobGet());
    }
  }, [authenticated]);

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
  }, []);

  return (
    <ScrollToTop>
      <Switch>
        <MyRoute exact path="/" component={Home} />
        <MyRoute exact path="/about" component={About} />
        <MyRoute exact path="/contractDetails" component={ContractDetails} />
        <MyRoute exact path="/report" component={Report} />
        <MyRoute exact path="/contracts" component={Contracts} />
        <MyRoute exact path="/login" component={Login} />
        <MyRoute exact path="/optAuth" component={OtpAuth} />
        <MyRoute exact path="/secureaccount" component={SecureAccount} />
        <MyRoute exact path="/register" component={Register} />
        <MyRoute exact path="/login-register" component={LoginRegister} />
        <MyRoute exact path="/register-role" component={RegisterRole} />
        <MyRoute exact path="/request-service" component={RequestService} />
        <MyRoute exact path="/registeradmin" component={RegisterAdmin} />
        <MyRoute exact path="/ForgetPassword" component={ForgetPassword} />
        <MyRoute exact path="/completeprofile" component={CompleteProfile} />
        <MyRoute
          exact
          path="/clientcompleteprofile"
          component={ClientCompleteProfile}
        />
        <MyRoute exact path="/messages" component={Messages} />
        <MyRoute exact path="/job" component={Job} />
        <MyRoute exact path="/applyforjob" component={ApplyForJob} />
        <MyRoute exact path="/talent" component={Talent} />
        <MyRoute exact path="/personaldetails" component={PersonalDetails} />
        <MyRoute exact path="/expertise" component={Expertise} />
        <MyRoute exact path="/expertiselevel" component={ExpertiseLevel} />
        <MyRoute exact path="/education" component={Education} />
        <MyRoute exact path="/certificate" component={Certificate} />
        <MyRoute exact path="/employment" component={Employment} />
        <MyRoute exact path="/languages" component={Languages} />
        <MyRoute exact path="/hourlyrate" component={HourlyRate} />
        <MyRoute exact path="/titleandoverview" component={TitleAndOverview} />
        <MyRoute exact path="/profileandphoto" component={profileAndPhoto} />
        {/* <MyRoute exact path="/location" component={Location} /> */}
        <MyRoute exact path="/phone" component={Phone} />
        <MyRoute exact path="/dashboard" component={Dashboard} />
        <MyRoute exact path="/admindashboard" component={AdminDashboard} />
        <MyRoute exact path="/profile" component={Profile} />
        <MyRoute exact path="/profile/:id" component={publicProfile} />
        <MyRoute exact path="/clientprofile" component={ClientProfile} />
        <MyRoute exact path="/settings" component={settings} />
        <MyRoute exact path="/finance" component={Finance} />
        <MyRoute exact path="/contactus" component={Qa} />
        <MyRoute exact path="/coc" component={coc} />
        <MyRoute exact path="/feeAndCharges" component={feeAndCharges} />
        <MyRoute exact path="/privacyPolicy" component={privacyPolicy} />
        <MyRoute exact path="/copyright" component={copyright} />
        <MyRoute exact path="/postjobclient" component={PostJobClient} />
        <MyRoute exact path="/contract" component={ContractList} />
        <MyRoute exact path="/budget" component={Budget} />
        <MyRoute exact path="/headline" component={Headline} />
        <MyRoute exact path="/startcontract" component={startContract} />
        <MyRoute exact path="/skills" component={Skills} />
        <MyRoute exact path="/scope" component={Scope} />
        <MyRoute exact path="/change-password" component={ChangePassword} />
        <MyRoute exact path="/wallet" component={Wallet} />
        <MyRoute exact path="/dispute" component={Dispute} />
        <MyRoute exact path="/verification" component={Verification} />
        <MyRoute exact path="/paypal" component={PayPalButton} />
        <MyRoute exact path="/bank-details" component={BankDetails} />
        <MyRoute exact path="/payout-approval" component={PayoutApproval} />
        <MyRoute exact path="/manage-users" component={ManageUsers} />
        <MyRoute exact path="/expert-email-verify" component={ExpertVerify} />
        <MyRoute exact path="/blank" component={Blank} />
        <MyRoute exact path="/refund" component={Refund} />
        <MyRoute exact path="/enterprise" component={Enterprise} />
        <MyRoute exact path="/question1/:formType" component={Question1} />
        <MyRoute exact path="/question2/:formType" component={Question2} />
        <MyRoute exact path="/question3/:formType" component={Question3} />
        <MyRoute exact path="/question4/:formType" component={Question4} />
        <MyRoute exact path="/question5/:formType" component={Question5} />
        <MyRoute exact path="/question6/:formType" component={Question6} />
        <MyRoute exact path="/question7/:formType" component={Question7} />
        <MyRoute exact path="/question8/:formType" component={Question8} />
        <MyRoute exact path="/question9/:formType" component={Question9} />
        <MyRoute exact path="/question10/:formType" component={Question10} />
        <MyRoute exact path="/*" component={NotFound} />
      </Switch>
      </ScrollToTop>
   
  );
};

export default AppRoutes;
