import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { userPlus } from "react-icons-kit/fa/userPlus";
import { sellsy } from "react-icons-kit/fa/sellsy";
import { list } from "react-icons-kit/fa/list";
import { dollar } from "react-icons-kit/fa/dollar";
import { Column } from "@ant-design/charts";
import { Pie } from "@ant-design/charts";
import { Line } from "@ant-design/charts";
import { getPendingJobs } from "../../actions/adminDashboard";
import PendingJobModal from "./pendingJobModal";
import {
  getTotalUsers,
  countUsersfromDate,
  countUsersfromCountry,
  countUsersfromCountrychart,
  getTotalUsersCharts,
  getGoogleAnalyticsReport,
} from "../../actions/auth";
import { Country, State, City } from "country-state-city";
import axios from "axios";

import { getWallet } from "../../actions/wallet";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const AdminDashboard = ({
  auth: {
    isAuthenticated,
    user,
    count,
    charts_counter,
    totaluserscharts_counter,
    bydateUsers,
    bycountryUsers,
    googleAnalyticsReport,
  },
  getdateUsers,
  pendingJobs,
  pendingJobs2,
  getPendingJobs,
  getTotalUsers,
  countUsersfromCountrychart,
  getTotalUsersCharts,
  // googleAnalyticsReport
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const [currentJob, setCurrentJob] = useState(false);
  const [modal, setModal] = useState(false);
  const [showdateresult, setShowdateresult] = useState(false);
  const [showdateresultcountry, setShowdateresultcountry] = useState(false);
  const [config, setConfig] = useState({
    data: [],
    isGroup: true,
    xField: "xfieldData",
    yField: "yfieldData",
    seriesField: "name",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  });
  const [freelancerConfigPie, setFreelancerConfigPie] = useState({
    appendPadding: 10,
    data: [],
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{name} {percentage}",
    },
    interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
  });
  const [clientConfigPie, setClientConfigPie] = useState({
    appendPadding: 10,
    data: [],
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{name} {percentage}",
    },
    interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
  });
  // const [countrycharts, setCountrychart] = useState([]);
  const [Userchart, setUserchart] = useState([]);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //dispatch(countUsersfromCountrychart());

  useEffect(() => {
    document.title = "Dashboard";
    countUsersfromCountrychart();
    dispatch(getGoogleAnalyticsReport());
  }, []);

  useEffect(() => {
    getTotalUsersCharts().then((data) => {
      setUserchart([data]);
      //setCountbydate([data]);
    });
  }, []);

  useEffect(() => {
    if (Userchart.length > 0) {
      function transformData(data) {
        // This will hold our transformed data
        var transformedData = [];

        // We will go through each item in the data array
        for (var i = 0; i < data.length; i++) {
          // For each object, we create a new object with the mapped data
          var newItem = {
            name: data[i].name,
            xfieldData: data[i].xfieldData,
            yfieldData: data[i].yfieldData,
          };
          //console.log(newItem)
          // We add this new object to our transformedData array
          transformedData.push(newItem);
        }

        return transformedData;
      }

      var transformedData = transformData(totaluserscharts_counter);

      setConfig({
        data: transformedData,
        isGroup: true,
        xField: "xfieldData",
        yField: "yfieldData",
        seriesField: "name",
        label: {
          position: "middle",
          layout: [
            { type: "interval-adjust-position" },
            { type: "interval-hide-overlap" },
            { type: "adjust-color" },
          ],
        },
      });
    }
  }, [Userchart]);

  useEffect(() => {
    if (
      charts_counter?.freelancerCountryCharts?.length > 0 ||
      charts_counter?.clientCountryCharts?.length > 0
    ) {
      // Assume newData is the response you get
      function transformData(charts_counter) {
        // This will hold our transformed data
        let transformedData = [];

        // We will go through each inner array in the outer array
        for (var i = 0; i < charts_counter.length; i++) {
          var newItem = {
            type: charts_counter[i]._id,
            value: charts_counter[i].count,
          };
          //console.log(newItem)
          // We add this new object to our transformedData array
          transformedData.push(newItem);
        }

        return transformedData;
      }

      var transformedFreelancerData = transformData(
        charts_counter?.freelancerCountryCharts
      );
      var transformedClientData = transformData(
        charts_counter?.clientCountryCharts
      );

      setFreelancerConfigPie({
        appendPadding: 10,
        data: transformedFreelancerData,
        angleField: "value",
        colorField: "type",
        radius: 0.8,
        label: {
          type: "outer",
          content: "{name} {percentage}",
        },
        interactions: [
          { type: "pie-legend-active" },
          { type: "element-active" },
        ],
      });

      setClientConfigPie({
        appendPadding: 10,
        data: transformedClientData,
        angleField: "value",
        colorField: "type",
        radius: 0.8,
        label: {
          type: "outer",
          content: "{name} {percentage}",
        },
        interactions: [
          { type: "pie-legend-active" },
          { type: "element-active" },
        ],
      });
    }
  }, [charts_counter]);

  useEffect(() => {
    if (isAuthenticated && user.type === "Admin") {
      getPendingJobs();
    }

  }, [isAuthenticated, user]);

  useEffect(() => {
    debugger
    const timer = setTimeout(() => {
      if (!isAuthenticated) {
        history.push('/'); // Redirect to home if not authenticated
      }
    }, 7000);

    return () => clearTimeout(timer);
  }, [isAuthenticated, history]);

  useEffect(() => {
    dispatch(getWallet());
  }, []);

  const MAX_LENGTH = 250;

  const [formData, setFormData] = useState({
    from: "",
    to: "",
    country: "",
  });

  const { from, to, country } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCountryChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



  const [counter, setCount] = useState([]);
  // const [countbydate, setCountbydate] = useState([]);
  const countries = Country.getAllCountries();
  // const [countOfCountry, setCountOfCountry] = useState([]);

  useEffect(() => {
    if (isAuthenticated && user.type === "Admin") {
      getTotalUsers()
        .then((data) => {
          setCount([data]);
        })
        .catch((error) => {
          console.error("Error fetching count data:", error);
        });
    }
  }, []);

  //const MAX_LENGTH = 250;
  //debugger;
  //pendingJobs = JSON.parse(localStorage.getItem('pendingjobs'))

  //console.log(pendingJobs2.le);

  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(6);
  const totalPages = Math.ceil(pendingJobs2.length / resultsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = [...pendingJobs2].slice(startIndex, endIndex);

  const handleSearch = async () => {
    if (showdateresult == true) {
      setShowdateresult(false);
    } else {
      setShowdateresult(true);
      if (from && to) {
        const data = {
          fromDate: from,
          toDate: to,
        };
        dispatch(countUsersfromDate(data));
      }
    }
  };

  const handleSearchCountry = () => {
    if (showdateresultcountry == true) {
      setShowdateresultcountry(false);
    } else {
      setShowdateresultcountry(true);
      if (country) {
        const data = {
          CountryUsers: country,
        };
        dispatch(countUsersfromCountry(data));
      }
    }
  };

  var dataLastMonthSale = [
    {
      year: "Jan",
      value: 1000,
    },
    {
      year: "Feb",
      value: 1500,
    },
    {
      year: "Mar",
      value: 3500,
    },
    {
      year: "Apr",
      value: 5000,
    },
    {
      year: "May",
      value: 4900,
    },
    {
      year: "June",
      value: 6000,
    },
    {
      year: "July",
      value: 7000,
    },
    {
      year: "Aug",
      value: 9000,
    },
    {
      year: "Sep",
      value: 13000,
    },
  ];

  var configLastMonthSale = {
    data: dataLastMonthSale,
    xField: "year",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: { showMarkers: false },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [{ type: "marker-active" }],
  };

  var dataLastWeekSale = [
    {
      year: "week1",
      value: 3000,
    },
    {
      year: "week2",
      value: 4000,
    },
    {
      year: "week3",
      value: 3500,
    },
    {
      year: "week4",
      value: 5000,
    },
  ];

  var configLastWeekSale = {
    data: dataLastWeekSale,
    xField: "year",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: { showMarkers: false },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [{ type: "marker-active" }],
  };

  return (
    <React.Fragment>
      <div className="main-page second py-5">
        <div className="container-fluid">
          <div className="">
            <div className="d-flex align-items-center p-3 bg-white rounded shadow-sm h5 m-0">
              <b>Admin Dashboard</b>
              <div className="ml-auto d-flex align-items-center h5 m-0 text-muted">
                Name
              </div>
            </div>

            <div className="row p-3 mb-0">
              {counter.length > 0 ? (
                <div className="p-2 col-md-3">
                  <div className="bg-white rounded shadow-sm p-4 h-100">
                    <div className="d-flex align-items-center font-weight-bold">
                      <div className="mr-2">
                        <Icon
                          size={30}
                          icon={userPlus}
                          className="text-primary"
                        />
                      </div>
                      <div>
                        <h5 className="mb-0 mt-1">Total Users</h5>
                      </div>
                    </div>
                    <h2 className="py-2 text-muted">
                      {counter.length > 0 ? count[0] : "N/A"}
                    </h2>
                    <div className="d-flex align-items-center my-1">
                      <Icon size={25} className="mr-3" icon={userPlus} />
                      <h6 className="mb-0 mt-1 font-weight-bold">
                        Total Clients:
                      </h6>
                      <h6
                        className="mb-0 mt-1 font-weight-bold"
                        style={{ marginLeft: "10px" }}
                      >
                        {counter.length > 0
                          ? count[4]
                          : +count[4] === 0
                            ? 0
                            : "N/A"}
                      </h6>
                    </div>

                    <div className="d-flex align-items-center my-1">
                      <Icon
                        size={25}
                        className="mr-3 font-weight-bold"
                        icon={userPlus}
                      />
                      <h6 className="mb-0 mt-1 font-weight-bold">
                        Total Experts:
                      </h6>
                      <h6
                        className="mb-0 mt-1 font-weight-bold"
                        style={{ marginLeft: "10px" }}
                      >
                        {counter.length > 0 ? count[5] : "N/A"}
                      </h6>
                    </div>

                    <div className="d-flex align-items-center my-1">
                      <Icon
                        size={25}
                        className="mr-3 font-weight-bold"
                        icon={userPlus}
                      />
                      <h6 className="mb-0 mt-1 font-weight-bold">
                        Total Enterprise Clients:
                      </h6>
                      <h6
                        className="mb-0 mt-1 font-weight-bold"
                        style={{ marginLeft: "10px" }}
                      >
                        {counter.length > 0 ? count[6] : "N/A"}
                      </h6>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-2 col-md-3">
                  <div className="bg-white rounded shadow-sm p-4">
                    <div className="d-flex align-items-center font-weight-bold">
                      <div className="mr-2">
                        <Icon
                          size={30}
                          icon={userPlus}
                          className="text-primary"
                        />
                      </div>
                      <div>
                        <h5 className="mb-0 mt-1">Total Users</h5>
                      </div>
                    </div>
                    <h6 className="py-2 text-muted">{"N/A"}</h6>
                  </div>
                </div>
              )}
              <div className="p-2 col-md-5">
                <div className="bg-white rounded shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center font-weight-bold">
                    <div className="mr-2">
                      <Icon size={30} icon={sellsy} className="text-primary" />
                    </div>
                    <div>
                      <h5 className="mb-2 mt-1">Registrations</h5>
                    </div>
                  </div>

                  <div className="d-flex mt-5 align-items-center my-1">
                    <Icon size={25} className="mr-3" icon={sellsy} />
                    <h6 className="mb-0 mt-1 font-weight-bold">
                      Today's Registration:
                    </h6>
                    <h6
                      className="mb-0 mt-1 font-weight-bold"
                      style={{ marginLeft: "10px" }}
                    >
                      {counter.length > 0 ? count[1] : "N/A"}
                    </h6>
                  </div>

                  <div className="d-flex align-items-center my-1">
                    <Icon size={25} className="mr-3" icon={sellsy} />
                    <h6 className="mb-0 mt-1 font-weight-bold">
                      Weekly Registration:
                    </h6>
                    <h6
                      className="mb-0 mt-1 font-weight-bold"
                      style={{ marginLeft: "10px" }}
                    >
                      {counter.length > 0 ? count[2] : "N/A"}
                    </h6>
                  </div>

                  <div className="d-flex align-items-center my-1">
                    <Icon size={25} className="mr-3" icon={sellsy} />
                    <h6 className="mb-0 mt-1 font-weight-bold">
                      Monthly Registration:
                    </h6>
                    <h6
                      className="mb-0 mt-1 font-weight-bold"
                      style={{ marginLeft: "10px" }}
                    >
                      {counter.length > 0 ? count[3] : "N/A"}
                    </h6>
                  </div>
                </div>
              </div>


              {/* <div className="p-2 col-md-3">
                <div className="bg-white rounded shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center font-weight-bold">
                    <div className="mr-2">
                      <Icon size={30} icon={list} className="text-primary" />
                    </div>
                    <div>
                      <h5 className="mb-0 mt-1">Weekly Registrations</h5>
                    </div>
                  </div>
                  <h2 className="py-2 text-muted">
                    {counter.length > 0 ? count[2] : "N/A"}
                  </h2>
                </div>
              </div> */}
              <div className="p-2 col-md-4">
                <div className="bg-white rounded shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center font-weight-bold">
                    <div className="mr-2">
                      <Icon size={30} icon={dollar} className="text-primary" />
                    </div>
                    <div>
                      <h5 className="mb-0 mt-1">CybertoCyber Digital Wallet</h5>
                    </div>
                  </div>
                  <h2 className="py-2 text-muted">
                    ${wallet?.walletAmount ?? 0}
                  </h2>
                </div>
              </div>
            </div>

            <div className="row px-4 py-1">
              {Userchart.length > 0 ? (
                <div className="col-md-7 bg-white rounded shadow-sm p-4">
                  <h5>User Registrations Graph </h5>
                  <hr />
                  <Column {...config} />
                </div>
              ) : (
                <></>
              )}
              {charts_counter?.freelancerCountryCharts?.length > 0 ? (
                <div className="col-md-5">
                  <div className="bg-white rounded shadow-sm p-4">
                    <h5>Experts Registration By Countries</h5>
                    <hr />
                    <Pie {...freelancerConfigPie} />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="row p-3">
              <div className="col-md-4 mt-0 p-2">
                <div className="bg-white rounded shadow-sm p-4 h-100">
                  <h5 className="mb-3">Filter Registration By Date</h5>
                  <hr />
                  {showdateresult == false ? (
                    <div>
                      <h6 className="mb-3">From:</h6>
                      <input
                        type="date"
                        className="form-control"
                        name="from"
                        value={from}
                        onChange={(e) => onChange(e)}
                      />

                      <br></br>

                      <h6 className="mb-3">To:</h6>
                      <input
                        type="date"
                        className="form-control"
                        name="to"
                        value={to}
                        onChange={(e) => onChange(e)}
                      />

                      <br></br>
                    </div>
                  ) : bydateUsers?.length > 0 ? (
                    <div className="mt-5 p-5">
                      <h2 className="text-primary text-center mb-0">
                        {"Total: " + bydateUsers[0]}
                      </h2>
                      <h4 className="text-primary text-center mb-0">
                        {"Clients: " +
                          bydateUsers[3] +
                          "  &  " +
                          "Experts: " +
                          bydateUsers[4]}
                      </h4>
                      <p className="text-primary text-center mb-0">
                        {"Registrations From: " +
                          bydateUsers[1] +
                          " To: " +
                          bydateUsers[2]}
                      </p>
                    </div>
                  ) : (
                    <div className="mt-5 p-5">
                      <h6 className="text-primary text-center mb-0">N/A.</h6>
                    </div>
                  )}
                  {showdateresult == false ? (
                    <button
                      className="btn btn-default mr-1"
                      onClick={handleSearch}
                    >
                      Filter
                    </button>
                  ) : (
                    <button
                      className="btn btn-default mr-1"
                      onClick={handleSearch}
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 mt-0 p-2">
                <div className="bg-white rounded shadow-sm p-4 h-100">
                  <h5 className="mb-3">Find Registrations by Country</h5>
                  <hr />
                  {showdateresultcountry == false ? (
                    <div>
                      <select
                        className="form-control"
                        name="country"
                        value={country}
                        onChange={(e) => onCountryChange(e)}
                      >
                        <option>Select Country</option>
                        {countries.map((item) => (
                          <option value={item.name} data-isoCode={item.isoCode}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <br></br>
                    </div>
                  ) : bycountryUsers.length > 0 ? (
                    <div className="mt-5 p-5">
                      <h2 className="text-primary text-center mb-0">
                        {"Total: " + bycountryUsers[2]}
                      </h2>
                      <h4 className="text-primary text-center mb-0">
                        {"Clients: " +
                          bycountryUsers[1] +
                          "  &  " +
                          "Experts: " +
                          bycountryUsers[0]}
                      </h4>
                      <p className="text-primary text-center mb-0">
                        {"Registrations from Country: " + bycountryUsers[3]}
                      </p>
                    </div>
                  ) : (
                    <div className="mt-5 p-5">
                      <h6 className="text-primary text-center mb-0">N/A.</h6>
                    </div>
                  )}
                  {showdateresultcountry == false ? (
                    <button
                      className="btn btn-default mr-1"
                      onClick={handleSearchCountry}
                    >
                      Filter
                    </button>
                  ) : (
                    <button
                      className="btn btn-default mr-1"
                      onClick={handleSearchCountry}
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
              {charts_counter?.freelancerCountryCharts?.length > 0 ? (
                <div className="col-md-4 mt-0 p-2">
                  <div className="bg-white rounded shadow-sm p-4">
                    <h5>Client Registration By Countries</h5>
                    <hr />
                    <Pie {...clientConfigPie} />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/**<div className="row p-3">
              <div className="col-md-6 bg-white rounded shadow-sm p-4">
                <h6>Last Months Sales</h6>
                <p className="text-danger font-weight-bold">$40250</p>
                <Line {...configLastMonthSale} />
              </div>
              <div className="col-md-6 bg-white rounded shadow-sm p-4">
                <h6>Last Weeks Sales</h6>
                <p className="text-primary font-weight-bold">$10250</p>
                <Line {...configLastWeekSale} />
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-6">
                <div className="bg-white rounded shadow-sm p-4">
                  <h5>Google analytics report</h5>
                  <hr />
                  <div
                    className="border pt-4 pl-3 pe-5 pb-5 rounded mt-4"
                    style={{ position: "relative" }}
                  >
                    <p
                      style={{
                        position: "absolute",
                        top: "-18px",
                        backgroundColor: "white",
                        padding: "4px",
                        fontSize: "20px",
                        left: "30px",
                      }}
                    >
                      Summary
                    </p>
                    <div className="analytics-page">
                      <div>
                        <p>Active Users</p>
                        <p style={{ fontSize: "36px" }}>
                          {googleAnalyticsReport?.activeUsers}
                        </p>
                      </div>
                      <div>
                        <p>New Users</p>
                        <p style={{ fontSize: "36px" }}>
                          {googleAnalyticsReport?.newUsers}
                        </p>
                      </div>
                      <div>
                        <p>Total Pages Views</p>
                        <p style={{ fontSize: "36px" }}>
                          {googleAnalyticsReport?.totalPageViews}
                        </p>
                      </div>
                      <div>
                        <p>
                          Page View Per User <sub>avg</sub>
                        </p>
                        <p style={{ fontSize: "36px" }}>
                          {googleAnalyticsReport?.pageViewsPerUser}
                        </p>
                      </div>
                      <div>
                        <p>Total Event Counts</p>
                        <p style={{ fontSize: "36px" }}>
                          {googleAnalyticsReport?.totalEventCounts}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5" style={{ position: "relative" }}>
                    <p
                      style={{
                        position: "absolute",
                        top: "-18px",
                        backgroundColor: "white",
                        padding: "4px",
                        fontSize: "20px",
                        left: "30px",
                      }}
                    >
                      Top Pages
                    </p>
                    <div className="border px-4 py-4 rounded mt-4">
                      <table class="table table-borderless table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Page path</th>
                            <th scope="col">Views</th>
                            <th scope="col">Active Users</th>
                          </tr>
                        </thead>
                        <tbody>
                          {googleAnalyticsReport?.mostViewedPages?.map(
                            (pageDetails, index) => {
                              return (
                                <tr>
                                  <td>{pageDetails?.pagePath}</td>
                                  <td>{pageDetails?.pageViews}</td>
                                  <td>{pageDetails?.activeUsers}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className=" bg-white rounded shadow-sm p-4 h-100">
                  <h5 className="mb-3">Pending Job requests</h5>
                  <hr />
                  {currentResults.length > 0 ? (
                    currentResults.length > 0 &&
                    [...currentResults].map((job) => (
                      <div
                        onClick={() => {
                          setModal(!modal);
                          setCurrentJob(job);
                        }}
                        className="row m-0 mt-3 rounded bg-white shadow-sm py-3"
                      >
                        <div className="col-md-12 m-0">
                          <span className="cursorClass">
                            <div className="d-flex justify-content-between my-2">
                              <h6 className="text-primary mb-0">
                                {"Job Tile: " + job.headline}
                              </h6>
                              <p>
                                <span className="font-weight-bold">
                                  Posted:
                                </span>{" "}
                                {job.job_post_time.split("T")[0]}{" "}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between my-2">
                              <p className="mb-0">
                                <span className="font-weight-bold">
                                  Payment Type: {"$ " + job.payment_amount}
                                </span>{" "}
                                {job.payment_type_id ==
                                  "659ea2be361d1c67ed1c1764"
                                  ? "- Fixed Amount"
                                  : "/ hr"}{" "}
                              </p>
                              <p>
                                <span className="font-weight-bold">
                                  Status:
                                </span>{" "}
                                <span className="text-muted">{job.status}</span>
                              </p>
                            </div>
                            <p className="font-weight-bold mb-3">
                              {"Job Description: " + job.description}
                            </p>
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-5 p-5">
                      <h6 className="text-primary text-center mb-0">
                        No Pending Jobs Found
                      </h6>
                    </div>
                  )}
                  {/* Display the navigation buttons */}
                  {totalPages > 1 && (
                    //FIXED PAGINATION
                    <nav>
                      <ul className="talent-pagination pagination justify-content-center">
                        <div
                          className={`icon ${currentPage === 1 ? "disabled" : ""
                            }`}
                          onClick={() =>
                            handlePageChange(
                              currentPage === 1 ? 1 : currentPage - 1
                            )
                          }
                        >
                          <Icon icon={arrowLeft} />
                        </div>
                        {[...Array(totalPages)].map((_, index) => {
                          const pageNumber = index + 1;
                          return (
                            <li
                              key={pageNumber}
                              className={`page-item ${pageNumber === currentPage ? "active" : ""
                                }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          );
                        })}
                        <div
                          className={`icon ${currentPage === totalPages ? "disabled" : ""
                            }`}
                          onClick={() =>
                            handlePageChange(
                              currentPage === totalPages
                                ? totalPages
                                : currentPage + 1
                            )
                          }
                        >
                          <Icon icon={arrowRight} />
                        </div>
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PendingJobModal
        modal={modal}
        setModal={setModal}
        currentJob={currentJob}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  pendingJobs: state.adminDashboard.pendingJobs,
  pendingJobs2: state.adminDashboard.pendingJobs2,
  getdateUsers: state,
});

export default connect(mapStateToProps, {
  getPendingJobs,
  getTotalUsers,
  countUsersfromDate,
  countUsersfromCountry,
  countUsersfromCountrychart,
  getTotalUsersCharts,
})(AdminDashboard);
